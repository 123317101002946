import { useContext, useEffect, useState } from 'react'
import { Button, } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import swal from 'sweetalert'
import { Layout, TableLayout, } from '../../components'
import { IconEdit, IconLeftArrow } from '../../components/Icons'
import { CustomModalService } from '../../components/services'
import { LangContext } from '../../context'

import { PageContext } from '../../context/Page'
import { customRoute, dataService, getDataService, handleClose, handleDateTimeFormat, handleShow, inputChangeService, postMethod, responseService } from '../../services'


const ChangePrices = () => {
    const history = useHistory()
    const { dispatch: { translate } } = useContext(LangContext);
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [data, setData] = useState([])
    const [page, setPage] = useContext(PageContext)

    const [formData, setFormData] = useState({
        name: "",
        description: "",
        inventoryType: "All",
        action: "Increase",
        inflationRate: "",
    })


    const inventoryTypes = [{ label: "All" }, { label: "Retail" }, { label: "Wholesale" },]
    const actionTypes = [{ label: "Increase" }, { label: "Decrease" }]

    const fields = [
        { id: "name", label: "Name *", value: formData.name, },
        { id: "description", label: "Description", value: formData.description, },
        { id: "inventoryType", label: "Inventory type", value: formData.inventoryType, type: "select", data: inventoryTypes, wantString: true, disableInititalSelect: true },
        // { id: "inventorySubType", label: "Inventory sub type", value: formData.inventorySubType, type: "select", disableInititalSelect: true, data: formData.inventoryType === "Jewellery" ? jewelleryTypes : gemsStoneTypes, wantString: true, hide: formData.inventoryType === "All" },
        { id: "action", label: "Action", value: formData.action, type: "select", data: actionTypes, wantString: true, disableInititalSelect: true },
        { id: "inflationRate", label: "Inflation rate (%) *", value: formData.inflationRate, inputType: "number", },
    ]

    useEffect(() => {
        handleGetData(page.current)
        // eslint-disable-next-line
    }, [])


    const handleGetData = (skip: number) => {
        getDataService(customRoute('pricebooks', skip), setLoading, setData, setPage, skip)
    }

    const handleChange = (evt: any, wantString?: boolean) => {
        inputChangeService(evt, formData, setFormData, wantString);
    }

    const showForm = () => {
        setFormData({
            name: "",
            description: "",
            inventoryType: "All",
            action: "Increase",
            inflationRate: "",
        })
        handleShow(setShow);
    }


    const handleResponse = (result: any) => {
        responseService(result, handleGetData, handleClose(setShow), null, null, setLoading)
    }

    const handleSubmit = () => {
        if (formData.name && formData.inflationRate) {
            swal({
                // title: `Are you sure to delete?`,
                text: `Are you sure to change prices!`,
                icon: 'warning',
                buttons: [true, true],
                dangerMode: true,
            }).then(async (willDelete) => {
                if (willDelete) {
                    setLoading(true)
                    console.log(formData);

                    dataService(postMethod, formData, [], 'pricebooks').then(result => {
                        handleResponse(result)
                    })
                }
            })
        } else {
            toast.error("Please fill data!")
        }
    }


    return (
        <Layout title='Change prices'>
            <div className="flex-between mb-4">
                <div className="cursor" onClick={() => history.goBack()}>
                    <p className="mb-0"><IconLeftArrow /> {translate("configuration")}</p>
                </div>

                <div className='d-flex align-items-center'>
                    <Button className="text-white bg-grad ms-3" onClick={showForm}><IconEdit /> <span className="ms-2">Update price</span></Button>
                </div>

            </div>

            <TableLayout loading={loading} data={data} page={page} setPage={setPage} handleGetData={handleGetData}>
                <thead>
                    <tr>
                        <th >No.</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Inventory type</th>
                        <th>Action type</th>
                        <th>Rate (%)</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item: any, index: any) =>
                        <tr key={index}>
                            <td><span className='flex-center'>{index + 1}</span></td>
                            <td><span className='flex-center'>{item.name}</span></td>
                            <td><span className='flex-center'>{item.description}</span></td>
                            <td><span className='flex-center'>{item.inventoryType}</span></td>
                            <td><span className='flex-center'>{item.action}</span></td>
                            <td><span className='flex-center'>{item.inflationRate} %</span></td>
                            <td><span className="flex-center">{handleDateTimeFormat(item.createdAt)}</span></td>
                        </tr>
                    )}
                </tbody>
            </TableLayout>

            <CustomModalService
                show={show}
                handleClose={() => handleClose(setShow)}
                id={null}
                handleSubmit={handleSubmit}
                loading={loading}
                fields={fields}
                setShow={setShow}
                handleChange={handleChange}
                formData={formData}
                setFormData={setFormData}
                customTitle="Update prices"
                lg />
        </Layout >
    )
}

export default ChangePrices