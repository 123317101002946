import { useContext } from "react";
import Layout from "../../components/Layout"
import { LangContext } from "../../context";
import IncomeByPayment from "./components/IncomeByPayment"

function IncomeReport() {
    const { dispatch: { translate } } = useContext(LangContext);
    return (
        <Layout title={translate('incomeReports')} >
            <IncomeByPayment />
        </Layout>
    )
}

export default IncomeReport

