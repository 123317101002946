import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button, ButtonGroup, Form, Offcanvas, FormCheck } from 'react-bootstrap';
import { IconFilter, IconPrint } from '../../components/Icons';
import DatePicker from "react-datepicker";
import { useReactToPrint } from "react-to-print"

import { dateFormat, dateFormat2, getDailySaleReportRoute, getData, getNumber, handleClose, handleDateFormat, handleShow, numberFormat, } from '../../services';
import { PageContext, LangContext } from '../../context';
import { TableLayout, Layout } from '../../components';
import { toast } from 'react-toastify';
import DailyReportSalePdf from './components/DailyReportSalePdf';


const DailySaleReport = () => {
    const { dispatch: { translate } } = useContext(LangContext);
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<any>([])
    const [page, setPage] = useContext(PageContext)
    const [result, setResult] = useState<any>()
    const [orderType, setOrderType] = useState("")
    const [show, setShow] = useState(false);
    const [isCustomRage, setCustomRange] = useState(false);
    const dateStatus = ["Today", "Yesterday", "This week", "This month", "Last month"]
    const [dateType, setDateType] = useState("")
    const [formData, setFormData] = useState(
        {
            orderType: "",
            // startDate: handleDateFormat(new Date(), dateFormat2),
            startDate: "",
            endDate: "",
        }
    )

    const [summaryTotal, setSummaryTotal] = useState(
        {
            numOfSales: 0,
            orderedQty: 0,
            totalAmount: 0,
        }
    )


    useEffect(() => {
        handleGetData(page.current, orderType, "", "")
        // eslint-disable-next-line
    }, [])

    const handleGetData = (skip: number, type: string, from: string, to: string) => {
        setLoading(true)
        getData(getDailySaleReportRoute(skip, type, from, to)).then((result) => {
            setResult(result)
            setData(result.data)
            let _numOfSales = 0;
            let _orderedQty = 0;
            let _totalAmount = 0;


            if (result.summary && result.summary.length > 0) {
                result.summary.forEach((item: any) => {
                    _numOfSales += item.numOfSales
                    _orderedQty += item.orderedQty
                    _totalAmount += item.totalAmount
                })
            }

            setSummaryTotal({
                numOfSales: _numOfSales,
                orderedQty: _orderedQty,
                totalAmount: _totalAmount,
            })

            setPage &&
                setPage({
                    current: skip,
                    total: result.count,
                    sorting: 'desc',
                })
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        })
    }


    //Lists Print
    const componentRef = useRef(null);
    const reactToPrintContent = useCallback(() => {
        return componentRef.current;
    }, []);
    const PrintAction = useReactToPrint({
        content: reactToPrintContent,
    });

    //Date in Filter
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const onChange = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        start && setFormData({ ...formData, startDate: handleDateFormat(start, dateFormat2), endDate: "" })
        end && setFormData({ ...formData, endDate: handleDateFormat(end, dateFormat2) })

        if (!start && !end) {
            setFormData({ ...formData, startDate: "", endDate: "" })
        }
    };

    const handleChangePage = (skip: number) => {
        handleGetData(skip, orderType, formData.startDate, formData.endDate)
    }


    const handleOrderType = (type: any) => {
        setOrderType(type)
        handleGetData(page.current, type, formData.startDate, formData.endDate)
    }

    const handleDate = (e: any) => {
        let _dateType = e.target.value;
        const today: any = new Date();

        if (_dateType === "Today") {
            setStartDate(today);
            setEndDate(null);
            today && setFormData({ ...formData, startDate: handleDateFormat(today, dateFormat2), endDate: "" })
        } else if (_dateType === "Yesterday") {
            const yesterday: any = new Date();
            yesterday.setDate(today.getDate() - 1);
            setStartDate(yesterday);
            setEndDate(null);
            yesterday && setFormData({ ...formData, startDate: handleDateFormat(yesterday, dateFormat2), endDate: "" })
        } else if (_dateType === "This week") {
            const _start: any = new Date();
            _start.setDate(today.getDate() - 6);
            setStartDate(_start);
            setEndDate(today);
            setFormData({ ...formData, startDate: handleDateFormat(_start, dateFormat2), endDate: handleDateFormat(today, dateFormat2) })
        }
        else if (_dateType === "This month") {
            const _start: any = new Date(today.getFullYear(), today.getMonth(), 1);
            setStartDate(_start);
            setEndDate(today);
            setFormData({ ...formData, startDate: handleDateFormat(_start, dateFormat2), endDate: handleDateFormat(today, dateFormat2) })
        }
        else if (_dateType === "Last month") {
            const _firstDate: any = new Date(today.getFullYear(), today.getMonth(), 1);
            const _start: any = new Date(today.getFullYear(), today.getMonth() - 1, 1);
            const _end: any = new Date();
            _end.setDate(_firstDate.getDate() - 1);
            setStartDate(_start);
            setEndDate(_end);
            setFormData({ ...formData, startDate: handleDateFormat(_start, dateFormat2), endDate: handleDateFormat(_end, dateFormat2) })
        }


        setDateType(_dateType)
    }


    const handleFilterAction = () => {
        // var nDays = diffDays(startDate, endDate);

        handleGetData(page.current, orderType, formData.startDate, formData.endDate)
        handleClose(setShow)

        // if (nDays <= 30) {
        //     handleGetData(page.current, orderType, formData.startDate, formData.endDate)
        //     handleClose(setShow)
        // } else {
        //     toast.error("You can filter within 30 days!")
        // }
    }

    const handleClearFilter = () => {
        setStartDate(null)
        setEndDate(null)
        setFormData({ ...formData, startDate: "", endDate: "", })
        handleGetData(page.current, orderType, "", "")
        handleClose(setShow)
    }

    return (
        <Layout title={translate('dailySaleReport')}>
            <section className="d-flex justify-content-between mb-4">
                <div className='d-flex align-items-center'>
                    {/* <Form.Label>Order type</Form.Label>
                    <Form.Control as="select" aria-label="Select" id="orderType" value={orderType} onChange={handleOrderType} >
                        {orderTypes.map((item: string, index: number) => (
                            <option value={item} key={index}>{item}</option>
                        ))}
                    </Form.Control> */}

                    <ButtonGroup className='me-4'>
                        <Button
                            onClick={() => handleOrderType("")}
                            className={orderType === "" ? "text-white bg-primary-grad" : "bg-white text-primary"}
                        >{translate('all')}</Button>
                        <Button
                            onClick={() => handleOrderType("Retail")}
                            className={orderType === "Retail" ? "text-white bg-primary-grad" : "bg-white text-primary"}
                        >{translate('retail')}</Button>
                        <Button
                            onClick={() => handleOrderType("Wholesale")}
                            className={orderType === "Wholesale" ? "text-white bg-primary-grad" : "bg-white text-primary"}>{translate('wholesale')}</Button>
                    </ButtonGroup>

                    <span><small className='text-muted'>Result : </small>{page.total}</span>
                </div>

                <section>
                    {/* {startDate && <span><small className='text-muted'>{endDate ? "From date" : "Date"} : </small>{handleDateFormat(startDate, dateFormat)}</span>}
                    {endDate && <span className='ms-3'><small className='text-muted'>End date : </small>{handleDateFormat(endDate, dateFormat)}</span>} */}
                    <Button variant="primary" onClick={() => handleShow(setShow)} className="mx-3 bg-primary-grad">
                        <IconFilter />
                        <span className="ms-2">Date filter</span>
                    </Button>
                    <section className="d-none">
                        <DailyReportSalePdf ref={componentRef}
                            data={data}
                            summaryTotal={summaryTotal}
                            orderType={orderType}
                            startDate={startDate && handleDateFormat(startDate, dateFormat)}
                            endDate={endDate && handleDateFormat(endDate, dateFormat)} />
                    </section>
                    <Button onClick={() => data.length > 0 ? PrintAction() : toast.error("No data found!")} className="bg-primary-grad"><IconPrint /> <span className="ms-2">Print</span></Button>
                </section>
            </section>

            <div className='bg-card'>
                <div className='flex-between'>
                    <div>
                        {/* <span className="d-block mb-2"><small className="text-muted">Order type</small> : <small>{orderType ? orderType : "Retail , Wholesale"}</small></span> */}
                        {startDate && <span className="d-block mb-2"><small className="text-muted">{endDate ? "From date" : "Date"}</small> : <small>{handleDateFormat(startDate ? startDate : new Date(), dateFormat)}</small></span>}
                        {endDate && <span className='d-block mb-3'><small className='text-muted'>End date</small> : <small>{handleDateFormat(endDate, dateFormat)}</small></span>}
                    </div>
                    <div className='d-flex flex-column align-items-end'>
                        {result?.salesSummary?.numOfSales ? <span className="d-block mb-2"><small className="text-muted">No. of sale</small> : <small>{numberFormat(result?.salesSummary.numOfSales)}</small></span> : <></>}
                        {result?.salesSummary?.quantity ? <span className="d-block mb-2"><small className="text-muted">{translate('productQuantity')}</small> : <small>{numberFormat(result?.salesSummary.quantity)}</small></span> : <></>}
                        {result?.salesSummary?.amount ? <span className='d-block mb-2'><small className='text-muted'>Total amount</small> : <small>{numberFormat(result?.salesSummary.amount)} {translate('mmk')}</small></span> : <></>}
                    </div>
                </div>
                <div className="tableFixHead">
                    <TableLayout loading={loading} data={data} page={page} setPage={setPage} handleGetData={handleGetData} isFilter handleChangePage={handleChangePage} paginationLimit={25} noBg>
                        <thead>
                            <tr>
                                <th>{translate('no.')}</th>
                                <th>{translate('date')}</th>
                                <th>{translate('type')}</th>
                                <th>{translate('numberOfSale')}</th>
                                <th>{translate('productQuantity')}</th>
                                <th>{translate('total')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item: any, index: number) =>
                                <tr key={index}>
                                    <td><span className='flex-center'>{getNumber(index, page?.current, 25)}</span></td>
                                    <td><span className='flex-center'>{handleDateFormat(item.orderedDate)}</span></td>
                                    <td><span className='flex-center'>{item?.orderType}</span></td>
                                    <td><span className='flex-end'>{numberFormat(item?.numOfSales)}</span></td>
                                    <td><span className='flex-end'>{numberFormat(item?.orderedQty)}</span></td>
                                    <td><span className='flex-end'>{numberFormat(item?.totalAmount)}</span></td>
                                </tr>)}
                            <tr>
                                <th colSpan={2} className="ps-4"><span className='flex-center'>{translate('total')}</span></th>
                                <th />
                                <th><span className="flex-end">{numberFormat(summaryTotal.numOfSales)}</span></th>
                                <th><span className="flex-end">{numberFormat(summaryTotal.orderedQty)}</span></th>
                                <th><span className="flex-end">{numberFormat(summaryTotal.totalAmount)}</span></th>
                            </tr>
                        </tbody>
                    </TableLayout>
                </div>

            </div>

            <Offcanvas show={show} onHide={() => handleClose(setShow)} placement={"end"}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Filter</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>
                        <Form.Group className="mb-4 input-form-control" >

                            <section className="flex-between">
                                <Form.Label>{translate("date")}</Form.Label>
                                <FormCheck
                                    id="switch"
                                    className="switch"
                                    type="switch"
                                    label={"Custom date range"}
                                    checked={isCustomRage}
                                    onChange={() => setCustomRange(!isCustomRage)}
                                />
                            </section>

                            {isCustomRage ?
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    onChange={onChange}
                                    startDate={startDate}
                                    placeholderText="Select date"
                                    className="form-control"
                                    endDate={endDate}
                                    selectsRange
                                /> :
                                <Form.Control as="select" aria-label="Select" className='mb-4' id="dateType" value={dateType} onChange={handleDate} >
                                    {dateStatus.map((item: string, index: number) => (
                                        <option value={item} key={index}>{item}</option>
                                    ))}
                                </Form.Control>}

                        </Form.Group>
                    </Form>
                    <div className='d-flex  mt-4'>
                        <Button className='w-50 py-2 bg-primary-grad me-2' onClick={handleFilterAction}>Search</Button>
                        <Button className='w-50 py-2 bg-danger' onClick={handleClearFilter}>Clear filter</Button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </Layout >
    );
};



export default DailySaleReport;




