import React, { useState, createContext } from 'react';

interface Variable {
    isSidebarActive: boolean,
    isList: boolean,
    isSKU: boolean,
    isFilter: boolean,
    isProductFilter: boolean,
    productFilterData: object,
    productFilterActionData: object,
    isOrderFilter: boolean,
    orderFilterData: object,
    orderFilterActionData: object,
    isSaleFilter: boolean,
    saleFilterData: object,
    saleFilterActionData: object,
    productSearchType: string,
    searchString: string,
    username: string,
    avatar: string
}

const contextDefaultValues: Variable = {
    isSidebarActive: true,
    isList: false,
    isSKU: true,
    isFilter: false,
    isProductFilter: false,
    productFilterData: {},
    productFilterActionData: {},
    isOrderFilter: false,
    orderFilterData: {},
    orderFilterActionData: {},
    isSaleFilter: false,
    saleFilterData: {},
    saleFilterActionData: {},
    productSearchType: "productCode",
    searchString: "",
    username: "",
    avatar: ""
}

const VariableContext = createContext<any>(contextDefaultValues);

function VariableProvider(props: any) {

    const [variable, setVariable] = useState<Variable>(contextDefaultValues)

    return (
        <VariableContext.Provider value={[variable, setVariable]}>
            {props.children}
        </VariableContext.Provider>
    );
}

export { VariableContext, VariableProvider };