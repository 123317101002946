import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom"
import { DetailLoading, Layout } from "../../../components";
import { IconLeftArrow } from "../../../components/Icons";
import { LangContext } from "../../../context/Lang";
import { getData, getProductColorRoute, getProductDetailById, getProductSizeRoute, getVariantRoute } from "../../../services";
import { Info, Count, Colors } from "./cpn";
import Variants from "../varients";
import WholesaleVariants from "../varients/WholesaleVariants";

function Detail() {
    const history = useHistory();
    const { dispatch: { translate } } = useContext(LangContext);
    const location: any = useLocation();
    const item = location.state.item
    const [product, setProduct] = useState<any>()
    const [loading, setLoading] = useState(false)

    const [counts, setCounts] = useState({
        color: 0,
        size: 0,
        variant: 0,
        stock: 0,
        productTypeId: null
    })

    useEffect(() => {
        handleGetData()
        // eslint-disable-next-line
    }, [])


    const handleGetData = async () => {
        let color = 0, size = 0, variant = 0;
        setLoading(true)

        const colorResult = await getData(getProductColorRoute(item.id))
        color = colorResult.data.length;

        const sizeResult = await getData(getProductSizeRoute(item.id))
        size = sizeResult.data.length;

        const variantResult = await getData(getVariantRoute(item.id, 0, 'asc'))
        variant = variantResult.count

        getData(getProductDetailById(item.id)).then((result) => {
            setProduct(result.data)
            setCounts({
                color: color,
                size: size,
                variant: variant,
                stock: result.data.stockCount,
                productTypeId: result.data.productTypes.id
            })
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        })
    }

    const handleColorRefresh = async () => {
        let color = 0;
        setLoading(true)

        const colorResult = await getData(getProductColorRoute(item.id))
        color = colorResult.data.length;

        getData(getProductDetailById(item.id)).then((result) => {
            setProduct(result.data)
            setCounts({
                ...counts,
                color: color,
                stock: result.data.stockCount,
                productTypeId: result.data.productTypes.id
            })
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        })
    }


    return (
        <Layout title={translate('productDetail')}>
            <section className="mb-4 cursor" onClick={() => history.goBack()}>
                <p className="mb-0"><IconLeftArrow /> {translate('products')}</p>
            </section>

            {loading ?
                <DetailLoading /> :
                <>
                    {product &&
                        <section className="detail">
                            <Info product={product} />
                            <Count counts={counts} />
                            <Colors handleRefresh={handleColorRefresh} />
                            <Variants />

                        </section>
                    }
                </>
            }
        </Layout>
    )
}

export default Detail