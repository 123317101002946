import { useContext, useEffect, useState } from 'react'
import { LangContext } from '../../context/Lang'
import { dataService, dateFormat2, deleteService, getBookkeepingRoute, getData, getDataService, handleClose, handleDateFormat, handleShow, inputChangeService, numberFormat, putMethod, responseService } from '../../services'
import { Layout, CustomModal, TableLayout, TableRowButtons } from '../../components'
import { Button, Col, Form, InputGroup, Row, OverlayTrigger, Tooltip, Offcanvas } from 'react-bootstrap'
import { IconAdd, IconClose, IconFile, IconFilter, IconSearch } from '../../components/Icons'
import { DateInput, FormFieldService } from '../../components/services'
import { toast } from 'react-toastify'
import swal from 'sweetalert'
import { PageContext } from '../../context'

const Bookkeeping = () => {
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const { dispatch: { translate } } = useContext(LangContext);
    const [data, setData] = useState([])
    const [page, setPage] = useContext(PageContext)
    const [accountsTypes, setAccountsTypes] = useState([])
    const [accountCode, setAccountCode] = useState("")
    const [chartOfAccounts, setChartOfAccounts] = useState([])
    const [chartOfAccountsFilter, setChartOfAccountsFilter] = useState([])
    const [bookkeepingId, setBookkeepingId] = useState("")
    const [searchString, setSearchString] = useState("")
    const [formData, setFormData] = useState<any>({
        chartOfAccountsId: null,
        particular: "",
        amount: "",
        note: null,
        createdAt: new Date()
    })

    const [filterData, setFilterData] = useState<any>({
        accountCode: "",
        accountType: "",
        accountName: "",
        startDate: "",
        endDate: ""
    })

    const fields = [
        { id: "particular", label: "Particular", value: formData.particular, colCount: 12 },
        { id: "amount", label: "Amount", value: formData.amount, inputType: "number", },
        { id: "createdAt", label: "Date", value: handleDateFormat(formData.createdAt, dateFormat2), type: "date", },
        { id: "note", label: "Notes", value: formData.note, inputType: "textarea", colCount: 12 },
    ]

    const required = ["chartOfAccountsId", "amount"]

    useEffect(() => {
        handleGetData(0, "")
        getData('accounts/types').then(result => {
            setAccountsTypes(result.data)
        })
        // eslint-disable-next-line
    }, [])


    const handleGetData = (skip: number, keyword: string) => {
        getDataService(getBookkeepingRoute(skip, filterData.accountType, filterData.accountName, filterData.startDate, filterData.endDate, keyword), setLoading, setData, setPage)
    }

    const handleSubmit = (method: string) => {
        if (formData.chartOfAccountsId || formData.amount) {
            formData.createdAt = formData.createdAt && new Date(formData.createdAt).toISOString()
            formData.amount = formData.amount && formData.amount.toString()
            setLoading(true)
            dataService(method, formData, required, method === putMethod ? `accounts/bookkeeping/${bookkeepingId}` : 'accounts/bookkeeping').then(result => {
                handleResponse(result)
            })
        } else {
            toast.error("Please, fill data");
        }
    }

    const handleDelete = (item: any) => {
        deleteService(item.particular, {}, `accounts/bookkeeping/${item.id}`, handleResponse)
    }

    const handleResponse = (result: any) => {
        responseService(result, handleGetData, handleClose(setShow), null, null, setLoading)
    }

    const handleChange = (evt: any) => {
        inputChangeService(evt, formData, setFormData);
    }

    const showForm = (item?: any) => {
        if (item) {
            setBookkeepingId(item.id)
            setAccountCode(item.accountCode)
            handleGetAccuntNames(item.accountCode)
        } else {
            setBookkeepingId("")
            setAccountCode("")
            setChartOfAccounts([])
        }

        setFormData({
            chartOfAccountsId: item.chartOfAccountsId,
            particular: item.particular,
            amount: item.amount,
            note: item.note,
            createdAt: item.createdAt || new Date()
        })
        handleShow(setShow);
    }

    const handleSelectAccountType = (evt: any) => {
        const value = evt.target.value
        setAccountCode(value)
        handleGetAccuntNames(value)
    }

    const handleSelectAccountTypeForFilter = (evt: any) => {
        const value = evt.target.value
        var index = evt.nativeEvent.target.selectedIndex;
        var label = evt.nativeEvent.target[index].text;
        setFilterData({ ...filterData, accountCode: value, accountType: label })
        getData(`chartofaccounts?accountCode=${value}`).then(result => {
            setChartOfAccountsFilter(result.data)
        })
        handleGetAccuntNames(value)
    }

    const handleEnterAction = (e: any) => {
        if (e.keyCode === 13) {
            setSearchString(e.target.value)
            handleGetData(0, e.target.value)
        }
    }

    const handleGetAccuntNames = async (accountCode: string) => {
        getData(`chartofaccounts?accountCode=${accountCode}`).then(result => {
            setChartOfAccounts(result.data)
        })
    }


    const clearFilter = () => {
        setFilterData({
            accountCode: "",
            accountType: "",
            accountName: "",
            startDate: "",
            endDate: ""
        })
        getDataService(getBookkeepingRoute(0, "", "", "", ""), setLoading, setData, setPage)
        handleClose(setShowFilter)
    }

    return (
        <Layout title={translate('bookkeeping')}>

            <section className="d-flex flex-between mb-4">
                <Col md={6}>
                    <div className='d-flex align-items-center'>
                        <Col md={5}>
                            <InputGroup className="search-input">
                                <InputGroup.Text className="bg-primary-grad text-white">
                                    <IconSearch />
                                </InputGroup.Text>
                                <Form.Control placeholder="Search" value={searchString} onChange={(e) => setSearchString(e.target.value)} onKeyDown={(e) => handleEnterAction(e)} />
                                {searchString && <OverlayTrigger overlay={<Tooltip>Clear Search</Tooltip>} >
                                    <InputGroup.Text className="bg-danger cursor" onClick={() => { setSearchString(""); handleGetData(0, "") }}>
                                        <IconClose />
                                    </InputGroup.Text>
                                </OverlayTrigger>}
                            </InputGroup>
                        </Col>
                        {page.total ? <span><small className='text-muted ms-3'>Result : </small>{numberFormat(page.total)}</span> : <></>}
                    </div>
                </Col>

                <section className='d-flex align-items-center'>
                    <Button className="text-white bg-primary-grad me-2" onClick={showForm}><IconAdd /> <span className="ms-2">{translate("createNew")}</span></Button>
                    {/* <Button className="text-white bg-danger mx-2" onClick={showForm}><IconAdd /> <span className="ms-2">Add expense</span></Button> */}

                    <Button variant="primary" onClick={() => handleShow(setShowFilter)} className="me-2 bg-primary-grad">
                        <IconFilter />
                        <span className="ms-2">Filter</span>
                    </Button>
                </section>
            </section>

            <TableLayout loading={loading} data={data} noBg page={page} setPage={setPage} handleGetData={handleGetData}>
                <thead>
                    <tr>
                        <th>{translate("no.")}</th>
                        <th>Account type</th>
                        <th>Account name</th>
                        <th>Particular</th>
                        <th>Amount</th>
                        <th>Note</th>
                        <th>{translate("date")}</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {data.map((item: any, index: any) =>
                        <tr key={index}>
                            <td><span className='flex-center'>{index + 1}</span></td>
                            <td>{item.accountType}</td>
                            <td>{item.accountName}</td>
                            <td>{item.particular}</td>
                            <td><span className={`flex-end ${item?.textColor}`}>{numberFormat(item.amount)}</span></td>
                            <td><span className="flex-center">{item.note ? <Button className="btn-sm" onClick={() => {
                                swal(item.note);
                            }}><IconFile /></Button> : "-"}</span></td>
                            <td><span className='flex-center'>{handleDateFormat(item.createdAt)}</span></td>
                            <td><TableRowButtons item={item} showForm={showForm} handleDelete={handleDelete} flex="flex-center" /></td>
                        </tr>
                    )}
                </tbody>
            </TableLayout>

            {/* <CustomModal show={show} handleClose={handleClose} id={formData?.factoryId} handleSubmit={handleSubmit} loading={loading}>
                <CustomInput label="Factory Name" id="factory" handleChange={handleChange} value={formData?.factory} />
                <CustomInput label="Location" id="location" handleChange={handleChange} value={formData?.location} />
            </CustomModal> */}
            <CustomModal show={show} handleClose={() => handleClose(setShow)} id={bookkeepingId} handleSubmit={handleSubmit} loading={loading} lg>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-4 input-form-control">
                            <Form.Label>Account type</Form.Label>
                            <Form.Control as="select" aria-label="Select" className="mb-4" id="areaCode" value={accountCode} onChange={handleSelectAccountType}>
                                <option value="">Select</option>
                                {accountsTypes.map((item: any, index: number) =>
                                    <option value={item.accountCode} key={index}>{item.accountType}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-4 input-form-control">
                            <Form.Label>Account name</Form.Label>
                            <Form.Control as="select" aria-label="Select" className="mb-4" id="chartOfAccountsId" value={formData?.chartOfAccountsId} onChange={handleChange}>
                                <option value="">Select</option>
                                {chartOfAccounts.map((item: any, index: number) =>
                                    <option value={item.id} key={index}>{item.accountName}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <FormFieldService fields={fields} handleChange={handleChange} formData={formData} setFormData={setFormData} colCount={6} />
            </CustomModal>

            <Offcanvas show={showFilter} onHide={() => handleClose(setShowFilter)} placement={"end"}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Filter</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>
                        <Form.Group className="mb-4 input-form-control">
                            <Form.Label>Account type</Form.Label>
                            <Form.Control as="select" aria-label="Select" className="mb-4" id="areaCode" value={filterData.accountCode} onChange={handleSelectAccountTypeForFilter}>
                                <option value="">Select</option>
                                {accountsTypes.map((item: any, index: number) =>
                                    <option value={item.accountCode} key={index}>{item.accountType}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-4 input-form-control">
                            <Form.Label>Account name</Form.Label>
                            <Form.Control as="select" aria-label="Select" className="mb-4" id="accountName" value={filterData?.accountName} onChange={(e: any) => setFilterData({ ...filterData, accountName: e.target.value })}>
                                <option value="">Select</option>
                                {chartOfAccountsFilter.map((item: any, index: number) =>
                                    <option value={item.accountName} key={index}>{item.accountName}</option>
                                )}
                            </Form.Control>
                        </Form.Group>

                        <DateInput id={"startDate"} label={"Start date"} value={filterData.startDate} handleChange={(e: any) => setFilterData({ ...filterData, startDate: e.target.value })} />
                        <DateInput id={"endDate"} label={"End date"} value={filterData.endDate} handleChange={(e: any) => setFilterData({ ...filterData, endDate: e.target.value })} />
                    </Form>
                    <div className='d-flex  mt-4'>
                        <Button className='w-50 py-2 bg-primary-grad me-2' onClick={() => { handleGetData(0, ""); handleClose(setShowFilter) }}>Search</Button>
                        <Button className='w-50 py-2 bg-danger' onClick={clearFilter}>Clear filter</Button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </Layout >
    )
}

export default Bookkeeping