import { useContext } from 'react';
import { Col } from 'react-bootstrap';
import Layout from '../../components/Layout';
import { LangContext } from '../../context/Lang';
import PosCart from './PosCart';
import PosItem from './PosItem';

const Index = () => {
    const { dispatch: { translate } } = useContext(LangContext);

    return (
        <Layout title={translate('pointOfSale')} noPadding>
            <section className="d-flex">
                <Col md={8}>
                    <PosItem />
                </Col>
                <Col md={4}>
                    <PosCart />
                </Col>
            </section>
        </Layout>
    );
};

export default Index;
