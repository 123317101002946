import { useCallback, useContext, useRef } from 'react'
import { Button, Col, Image } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { IconLeftArrow, IconPrint } from '../../components/Icons'
import Layout from '../../components/Layout'
import { LangContext } from '../../context/Lang'
import ReceiptHeaderPrint from './components/ReceiptHeaderPrint'


const ReceiptHeader = () => {
    const history = useHistory()
    const { dispatch: { translate } } = useContext(LangContext);

    //Receipt Header Print
    const componentRef = useRef(null);
    const reactToPrintContent = useCallback(() => {
        return componentRef.current;
    }, []);
    const PrintAction = useReactToPrint({
        content: reactToPrintContent,
    });


    return (
        <Layout title="Receipt Header">
            <style type="text/css" media="print">{"@page { size: Legal landscape; }"}</style>
            <section className="flex-between mb-4">
                <section className="cursor" onClick={() => history.goBack()}>
                    <p className="mb-0"><IconLeftArrow /> {translate("configuration")}</p>
                </section>

                <section className="d-none"><ReceiptHeaderPrint ref={componentRef} /></section>
                <Button onClick={PrintAction}><IconPrint /> <span className="ms-2">Print</span></Button>
            </section>

            <section className="d-flex justify-content-center">
                <Col md={8}>
                    <section className="bg-card" style={{ height: "70vh" }}>
                        <section className="flex-between">
                            <Image src="logo.png" width={"100"} height={"100"} />
                            <section className="text-center">
                                <Image src="images/bawgawadi_text.png" width={"300"} />
                                <h3 className="mt-2 fw-bold">Cupid & Vision</h3>
                                <p>ကွန်ပျူတာပန်းထိုးလုံချည် နှင့် အမျိုးသမီးအင်္ကျီ လက်လီ / လက်ကား (ပင်ရင်း)</p>
                            </section>
                            <Image src="images/bawgawadi.png" width={"110"} height={"110"} />
                        </section>
                    </section>
                </Col>
            </section>
        </Layout >
    )
}

export default ReceiptHeader