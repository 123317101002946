import BarChart from "./BarChart"

function TotalSale() {
    return (
        <section className="shadow-sm p-4 mb-4 rounded h-400">
            <h6 className="fw-bold text-primary text-center">Products by brand</h6>
            <BarChart />
        </section>
    )
}

export default TotalSale