import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Badge, Button, Col, Form, InputGroup, Modal, Offcanvas, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Layout, SortingButton, TableLayout } from '../../components';
import { IconClose, IconEdit, IconFile, IconFilter, IconPrint, IconSearch } from '../../components/Icons';
import { OrderContext, PageContext, VariableContext } from '../../context';
import { LangContext } from '../../context/Lang';
import DatePicker from "react-datepicker";
import { actionData, dataService, dateFormat2, deliverySerivcesRoute, getData, getDataService, getNumber, getSaleOrderRouteRoute, handleClose, handleDateFormat, handleDateTimeFormat, handleShow, inputChangeService, numberFormat, paymentRoute, postMethod, putMethod, saleOrderRoute } from '../../services';
import { toast } from 'react-toastify';
import { handleCloseSearch } from '../../utils';
import { useReactToPrint } from 'react-to-print';
import SaleListPrint from './cpn/Print';
const limit: any = process.env.REACT_APP_PAGINATION_LIMIT

const Index = () => {
    const history = useHistory()
    const { dispatch: { translate } } = useContext(LangContext);
    // const [isFilter, setIsFilter] = useState(false);
    // const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState<any[]>();
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false);
    const [showMutiActionForm, setShowMutiActionForm] = useState(false);
    const [data, setData] = useState([])
    const [page, setPage] = useContext(PageContext)
    const [variable, setVariable] = useContext(VariableContext)
    const [, setOrderItems] = useContext(OrderContext);
    const [deliveryServices, setDeliveryServices] = useState([])
    const [payments, setPayments] = useState([])
    const tempUser: any = localStorage.getItem("USER")
    const user = tempUser && JSON.parse(tempUser)
    const [searchString, setSearchString] = useState("")
    //Date in Filter
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const onChange = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        start && setFormData({ ...formData, startDate: handleDateFormat(start, dateFormat2), endDate: "" })
        end && setFormData({ ...formData, endDate: handleDateFormat(end, dateFormat2) })

        if (!start && !end) {
            setFormData({ ...formData, startDate: "", endDate: "" })
        }
    };

    const deliveryStatus = ["All", "Pending", "Ready to Ship", "Shipped", "Delivered", "Canceled", "Lost & Damaged", "Failed Delivery", "Returned"]
    const shippingMethodData = ["All", "Delivery", "Local Pickup"]
    const paymentStatus = ["All", "UNPAID", "PAID"]
    const orderTypes = ["All", "Retail", "Wholesale"]
    const paymentStatusForMulti = ["Select", "UNPAID", "PAID"]
    const deliveryStatusForMulti = ["Select", "Pending", "Ready to Ship", "Shipped", "Delivered", "Canceled", "Lost & Damaged", "Failed Delivery", "Returned"]
    const orderStatusData = ["All", "Confirmed", "Canceled"]
    const [formData, setFormData] = useState(
        {
            orderBy: "desc",
            // skip: 0,
            take: limit,
            deliveryServiceId: "",
            deliveryStatusId: null,
            deliveryStatus: "",
            shippingMethods: "",
            shippingMethodId: null,
            orderType: "",
            orderTypeId: null,
            paymentTypeId: "",
            paymentStatus: "",
            paymentStatusId: null,
            orderStatusId: null,
            orderStatus: "",
            startDate: "",
            endDate: "",
        }
    )
    const [multiFormData, setMultiFormData] = useState(
        {
            paymentStatusId: null,
            deliveryStatusId: null,
        }
    )
    // const [filterData, setFilterData] = useState()

    //Sale Lists Print
    const componentRef = useRef(null);
    const reactToPrintContent = useCallback(() => {
        return componentRef.current;
    }, []);
    const PrintAction = useReactToPrint({
        content: reactToPrintContent,
    });


    const handleChange = (evt: any) => {
        inputChangeService(evt, formData, setFormData);
    }

    const handleMultiChange = (evt: any) => {
        // console.log(evt.taget.value);

        inputChangeService(evt, multiFormData, setMultiFormData);
    }

    useEffect(() => {
        variable?.isSaleFilter ? handleGetDataByFilter(page.current, variable?.saleFilterActionData) : handleGetData(page.current, page.sorting, "")
        if (variable?.saleFilterData) {
            let _data = variable?.saleFilterData;
            setFormData({
                ...formData, orderBy: "desc",
                take: limit,
                deliveryServiceId: _data?.deliveryServiceId,
                deliveryStatusId: _data?.deliveryStatusId,
                deliveryStatus: _data?.deliveryStatus,
                shippingMethods: _data?.shippingMethods,
                shippingMethodId: _data?.shippingMethodId,
                orderType: _data?.orderType,
                orderTypeId: _data?.orderTypeId,
                paymentTypeId: _data?.paymentTypeId,
                paymentStatus: _data?.paymentStatus,
                paymentStatusId: _data?.paymentStatusId,
                orderStatusId: _data?.orderStatusId,
                orderStatus: _data?.orderStatus,
                startDate: _data?.startDate,
                endDate: _data?.endDate,
            })
        }

        handleGetPayments()
        handleGetDeliveryServices()
        // eslint-disable-next-line
    }, [])

    const handleGetDeliveryServices = async () => {
        getData(deliverySerivcesRoute).then(result => {
            setDeliveryServices(result.data)
        })
    }

    const handleGetPayments = async () => {
        getData(paymentRoute).then(result => {
            setPayments(result.data)
        })
    }

    const handleGetData = (skip: number, sorting: string, keyword: string) => {
        getDataService(getSaleOrderRouteRoute(skip, sorting, keyword), setLoading, setData, setPage, skip, sorting)
    }


    const handleEnterAction = (e: any) => {
        if (e.keyCode === 13) {
            setSearchString(e.target.value)
            handleGetData(0, page.sorting, e.target.value)
        }
    }

    const handleFilterAction = () => {
        if (formData.shippingMethodId) {
            formData["shippingMethods"] = shippingMethodData[formData.shippingMethodId];
        } else {
            formData["shippingMethods"] = "";
        }

        if (formData.paymentStatusId) {
            formData["paymentStatus"] = paymentStatus[formData.paymentStatusId];
        } else {
            formData["paymentStatus"] = "";
        }

        if (formData.orderTypeId) {
            formData["orderType"] = orderTypes[formData.orderTypeId];
        } else {
            formData["orderType"] = "";
        }

        if (formData.deliveryStatusId) {
            formData["deliveryStatus"] = deliveryStatus[formData.deliveryStatusId];
        } else {
            formData["deliveryStatus"] = "";
        }

        if (formData.orderStatusId) {
            formData["orderStatus"] = orderStatusData[formData.orderStatusId];
        } else {
            formData["orderStatus"] = "";
        }

        let _data: any = {}


        for (let [key, value] of Object.entries(formData)) {
            if (value !== null && value !== '' && value !== undefined && value !== "All") {
                if (key !== "deliveryStatusId" && key !== "orderStatusId" && key !== "shippingMethodId" && key !== "paymentStatusId") {
                    _data[key] = value
                }
            }
        }

        setVariable({ ...variable, saleFilterData: formData, saleFilterActionData: _data, isSaleFilter: true })
        handleClose(setShow)
        // setIsCheckAll(false)
        setIsCheck([])
        handleGetDataByFilter(0, _data)
    }

    const handleGetDataByFilter = (skip: number, _formData: any) => {
        _formData["skip"] = skip * limit;
        setLoading(true)
        actionData(postMethod, saleOrderRoute, _formData).then((result) => {
            setData(result.data)
            setPage({
                current: skip,
                total: result.count,
                sorting: 'desc',
            })
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        })
    }

    const handleClearFilter = () => {
        setFormData({
            ...formData, orderBy: "desc",
            // skip: 0,
            take: limit,
            deliveryServiceId: "",
            deliveryStatusId: null,
            deliveryStatus: "",
            shippingMethods: "",
            shippingMethodId: null,
            orderType: "",
            orderTypeId: null,
            paymentTypeId: "",
            paymentStatus: "",
            paymentStatusId: null,
            orderStatusId: null,
            orderStatus: "",
            startDate: "",
            endDate: "",
        })
        setStartDate(null)
        setEndDate(null)
        handleClose(setShow)
        handleGetData(page.current, page.sorting, "")
        setVariable({ ...variable, saleFilterData: {}, saleFilterActionData: {}, isSaleFilter: false })
    }

    // const Checkbox = ({ id, type, name, handleClick, isChecked }: { id: any, type: any, name: string, handleClick: any, isChecked: any }) => {
    //     return (
    //         <input
    //             id={id}
    //             name={name}
    //             type={type}
    //             onChange={handleClick}
    //             checked={isChecked}
    //             className="form-check-input cursor"
    //         />
    //     );
    // };

    // const handleSelectAll = (evt: any) => {
    //     setIsCheckAll(!isCheckAll);

    //     if (isCheckAll) {
    //         setIsCheck([]);
    //     } else {
    //         let isCheckTemp: any[] = [];
    //         let itemsTemp: any[] = [];

    //         data.forEach((item: any) => {
    //             isCheckTemp.push(item.id)
    //             itemsTemp.push(item)
    //         })

    //         setIsCheck(isCheckTemp);
    //     }
    // };

    // const handleClick = (evt: any, id: number) => {
    //     // Check uncheck action
    //     const { checked } = evt.target;

    //     let temp: any[] = [];
    //     isCheck?.forEach(item => temp.push(item));

    //     if (checked) {
    //         temp.push(id)
    //     }
    //     else {
    //         isCheck?.forEach((item: any, index: number) => {
    //             if (item === id) {
    //                 temp.splice(index, 1);
    //             }
    //         })
    //     };
    //     setIsCheck(temp)
    // };

    const handleChangePage = (skip: number) => {
        variable?.isSaleFilter ? handleGetDataByFilter(skip, variable?.saleFilterActionData) : handleGetData(skip, page.sorting, searchString)
    }


    const handleMultiAction = () => {
        let _formData: any = {}
        _formData["salesOrderId"] = isCheck ? isCheck : []

        if (multiFormData.paymentStatusId) {
            _formData["paymentStatus"] = paymentStatusForMulti[multiFormData.paymentStatusId];
        }

        if (multiFormData.deliveryStatusId) {
            _formData["deliveryStatus"] = deliveryStatusForMulti[multiFormData.deliveryStatusId];
        }

        if (_formData["paymentStatus"] || _formData["deliveryStatus"]) {
            dataService(putMethod, _formData, [], "sales/order/bulk").then(result => {
                if (result?.success) {
                    toast.success(result?.message)
                    handleGetData(page.current, page.sorting, "")
                    // setIsCheckAll(false)
                    setIsCheck([])
                    handleClose(setShowMutiActionForm)
                } else {
                    toast.error(result?.message)
                }
            })
        } else {
            toast.error("Select at least one!")
        }
    }

    return (
        <Layout title={translate('sales')}>
            <section className="d-flex flex-between mb-4">
                <Col md={6}>
                    <div className='d-flex align-items-center'>
                        <Col md={5}>
                            <InputGroup className="search-input">
                                <InputGroup.Text className="bg-primary-grad text-white">
                                    <IconSearch />
                                </InputGroup.Text>
                                <Form.Control placeholder="Search" value={searchString} onChange={(e) => setSearchString(e.target.value)} onKeyDown={(e) => handleEnterAction(e)} />
                                {searchString && <OverlayTrigger overlay={<Tooltip>Clear Search</Tooltip>} >
                                    <InputGroup.Text className="bg-danger cursor" onClick={() => handleCloseSearch(setSearchString, handleGetData)}>
                                        <IconClose />
                                    </InputGroup.Text>
                                </OverlayTrigger>}
                            </InputGroup>
                        </Col>
                        {page.total ? <span><small className='text-muted ms-3'>Result : </small>{numberFormat(page.total)}</span> : <></>}
                    </div>
                </Col>

                <section className='d-flex align-items-center'>
                    {(isCheck && isCheck.length > 0) && <label className='me-2'>{isCheck.length} selected</label>}

                    {isCheck && isCheck?.length > 0 && <Button variant="primary" onClick={() => { handleShow(setShowMutiActionForm); setMultiFormData({ paymentStatusId: null, deliveryStatusId: null, }) }} className="me-2 bg-primary-grad">
                        <span>Multi action</span>
                    </Button>}
                    <Button variant="primary" onClick={() => handleShow(setShow)} className="bg-primary-grad">
                        <IconFilter />
                        <span className="ms-2">Filter</span>
                    </Button>

                    <section className="d-none"><SaleListPrint ref={componentRef} data={data} /></section>
                    <Button onClick={PrintAction} className="bg-primary-grad mx-2"><IconPrint /> <span className="ms-2">Print</span></Button>

                    <SortingButton page={page} setPage={setPage} handleGetData={handleGetData} />
                </section>
            </section>

            <TableLayout loading={loading} data={data} page={page} setPage={setPage} handleGetData={handleGetData} isFilter handleChangePage={handleChangePage} noBg>
                <thead>
                    <tr>
                        {/* <th className="ps-4">
                            <Checkbox
                                type="checkbox"
                                name="selectAll"
                                id="selectAll"
                                handleClick={handleSelectAll}
                                isChecked={isCheckAll}
                            />
                        </th> */}
                        <th>{translate('no.')}</th>
                        <th>{translate('saleNumber')}</th>
                        <th>Order type</th>
                        <th>{translate('customer')}</th>
                        {/* <th>Payment</th>
                        <th>Delivery</th>*/}
                        {/* <th>{translate('paymentStatus')}</th> */}
                        <th>{translate('paymentStatus')}</th>
                        <th>{translate('date')}</th>
                        <th>{translate('total')} (MMK)</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {data.map((item: any, index: any) =>
                        <tr key={index}>
                            {/* <td className="ps-4">
                                <Checkbox
                                    key={index}
                                    type="checkbox"
                                    name={item.productSKU}
                                    id={index}
                                    handleClick={(e: any) => handleClick(e, item.id)}
                                    isChecked={isCheck && isCheck!.includes(item.id)}
                                />
                            </td> */}
                            <td><span className='flex-center'>{getNumber(index, page?.current)}</span></td>
                            <td><span className='flex-center'>{item.saleOrdersCode}</span></td>
                            <td><span className='flex-center'>{item.orderType}</span></td>
                            <td>{item.customerName}</td>
                            <td><span className='flex-center'><Badge className={item.paymentStatus === "PAID" ? "bg-primary" : item?.paymentStatus === "UNPAID" ? "bg-danger" : "bg-success"}>{item.paymentStatus}</Badge></span></td>
                            {/* <td><Badge className={item.orderStatus === "Confirmed" ? "bg-primary" : item.orderStatus === "Canceled" ? "bg-danger" : "bg-info"}>{item.orderStatus}</Badge></td> */}
                            <td><span className='flex-center'>{handleDateTimeFormat(item.createdAt)}</span></td>
                            <td><span className="flex-end">{numberFormat(item.totalAmount)}</span></td>
                            <td>
                                <span className="flex-center">
                                    {(user?.userRole === "Owner" || user?.userRole === "Admin" || user?.userRole === "Manager") && <>
                                        {item.orderStatus !== "Canceled" && <OverlayTrigger overlay={<Tooltip>Edit sale</Tooltip>}>
                                            <Button className="btn-sm" onClick={() => {
                                                history.push({
                                                    pathname: item.orderType === "Retail" ? '/edit-sale' : '/edit-wholesale',
                                                    state: { order: item }
                                                }); setOrderItems([])
                                            }}><IconEdit /></Button>
                                        </OverlayTrigger>}
                                    </>}


                                    <OverlayTrigger overlay={<Tooltip>{translate('viewDetail')}</Tooltip>}>
                                        <Button className="btn-sm ms-2 border-none" onClick={() => history.push({
                                            pathname: '/sale-detail',
                                            state: { id: item.id }
                                        })}><IconFile /></Button>
                                    </OverlayTrigger>
                                </span>
                            </td>
                        </tr>
                    )}
                </tbody>
            </TableLayout>

            <Offcanvas show={show} onHide={() => handleClose(setShow)} placement={"end"}>
                {/* <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Filter</Offcanvas.Title>
                </Offcanvas.Header> */}
                <Offcanvas.Body>
                    <Form>
                        <Form.Label>{translate("date")}</Form.Label>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            onChange={onChange}
                            startDate={startDate}
                            placeholderText="Select Date"
                            className="form-control mb-4"
                            endDate={endDate}
                            selectsRange
                        />

                        <Form.Label>Order type</Form.Label>
                        <Form.Control as="select" aria-label="Select" className='mb-4' id="orderTypeId" value={formData?.orderTypeId || ""} onChange={handleChange}>
                            {orderTypes.map((item: any, index: number) =>
                                <option value={index} key={index}>{item}</option>
                            )}
                        </Form.Control>

                        <Form.Label>Shipping methods</Form.Label>
                        <Form.Control as="select" aria-label="Select" className='mb-4' id="shippingMethodId" value={formData?.shippingMethodId || ""} onChange={handleChange}>
                            {shippingMethodData.map((item: any, index: number) =>
                                <option value={index} key={index}>{item}</option>
                            )}
                        </Form.Control>

                        <Form.Label>Payment types</Form.Label>
                        <Form.Control as="select" aria-label="Select" className='mb-4' id="paymentTypeId" value={formData?.paymentTypeId} onChange={handleChange}>
                            <option value="">Select</option>
                            {payments.map((item: any, index: number) =>
                                <option value={item.id} key={index}>{item.paymentName}</option>
                            )}
                        </Form.Control>

                        <Form.Label>Payment status</Form.Label>
                        <Form.Control as="select" aria-label="Select" className='mb-4' id="paymentStatusId" value={formData?.paymentStatusId || ""} onChange={handleChange}>
                            {paymentStatus.map((item: any, index: number) =>
                                <option value={index} key={index}>{item}</option>
                            )}
                        </Form.Control>

                        <Form.Label>Delivery</Form.Label>
                        <Form.Control as="select" aria-label="Select" className='mb-4' id="deliveryServiceId" value={formData?.deliveryServiceId} onChange={handleChange}>
                            <option value="">Select</option>
                            {deliveryServices.map((item: any, index: number) =>
                                <option value={item.id} key={index}>{item.deliveryService}</option>
                            )}
                        </Form.Control>


                        <Form.Label>Delivery status</Form.Label>
                        <Form.Control as="select" aria-label="Select" className='mb-4' id="deliveryStatusId" value={formData?.deliveryStatusId || ""} onChange={handleChange} >
                            {deliveryStatus.map((item: string, index: number) => (
                                <option value={index} key={index}>{item}</option>
                            ))}
                        </Form.Control>

                        <Form.Label>Order status</Form.Label>
                        <Form.Control as="select" aria-label="Select" id="orderStatusId" value={formData?.orderStatusId || ""} onChange={handleChange}>
                            {orderStatusData.map((item: any, index: number) =>
                                <option value={index} key={index}>{item}</option>
                            )}
                        </Form.Control>
                    </Form>
                    <div className='d-flex  mt-4'>
                        <Button className='w-50 py-2 bg-primary-grad me-2' onClick={handleFilterAction}>Search</Button>
                        <Button className='w-50 py-2 bg-danger' onClick={handleClearFilter}>Clear filter</Button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>

            <Modal show={showMutiActionForm} onHide={() => handleClose(setShowMutiActionForm)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Multi action form</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <section className="bg-light mb-3 rounded p-3">
                        {/* <section className="d-flex flex-between mb-3">
                            <small className="text-muted d-block "><span className="fw-bold">{product.productName}</span></small>
                            <small className="text-muted d-block ">Code : <span className="fw-bold">{product.productCode}</span></small>
                        </section> */}
                        {/* <small className="text-muted d-block ">{translate('colors')} : {productColors.map((item: any, index: number) => (<span className="fw-bold" key={index}>{item.attrValue} {productColors.length - 1 !== index && ","} </span>))}</small> */}
                        <small className="text-muted d-block ">For {isCheck && isCheck.length} sale orders</small>
                    </section>

                    <Form.Group className="mb-4 input-form-control" >
                        <Form.Label>Payment status</Form.Label>
                        <Form.Control as="select" aria-label="Select" id="paymentStatusId" value={multiFormData?.paymentStatusId || ""} onChange={handleMultiChange} >
                            {paymentStatusForMulti.map((item: string, index: number) => (
                                <option value={index} key={index}>{item}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    {variable?.isSaleFilter && variable?.saleFilterActionData && variable?.saleFilterActionData?.shippingMethods === "Delivery" && <Form.Group className="mb-4 input-form-control" >
                        <Form.Label>Delivery status</Form.Label>
                        <Form.Control as="select" aria-label="Select" id="deliveryStatusId" value={multiFormData?.deliveryStatusId || ""} onChange={handleMultiChange} >
                            {deliveryStatusForMulti.map((item: string, index: number) => (
                                <option value={index} key={index}>{item}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => handleClose(setShowMutiActionForm)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleMultiAction}>
                        Action done
                    </Button>
                </Modal.Footer>
            </Modal>
        </Layout>
    );
};

export default Index;




