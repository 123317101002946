import React, { useState, createContext } from 'react';

const contextDefaultValues: any = {
    filter: {},
    setFilter: () => { },
}

const FilterContext = createContext<any>(contextDefaultValues);

function FilterProvider(props: any) {

    const [filter, setFilter] = useState();

    return (
        <FilterContext.Provider value={[filter, setFilter]}>
            {props.children}
        </FilterContext.Provider>
    );
}

export { FilterContext, FilterProvider };