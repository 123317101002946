import { Badge, Col, Table } from "react-bootstrap";
import { NoData, } from "../../../components";
import { handleDateFormat, numberFormat, } from "../../../services";

const Payments = ({ detail }: { detail: any, }) => {
    const data = detail?.paymentHistory;

    return (
        <Col md={12}>
            <section className="bg-card mb-4">
                <section className="flex-between mb-3">
                    <h6 className="fw-bold">Payment history</h6>
                </section>

                {data && data.length > 0 ?
                    <>
                        <Table responsive striped bordered className="center-table">
                            <thead>
                                <tr>
                                    <th>Paid amount</th>
                                    <th>Payment type</th>
                                    <th>Payment status</th>
                                    <th>Payment date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item: any, index: number) => (
                                    <tr key={index}>
                                        <td><span className="flex-end">MMK {item?.totalAmountPaid ? numberFormat(item?.totalAmountPaid) : 0}</span></td>
                                        <td><span className="flex-center">{item?.paymentName || "-"}</span></td>
                                        <td><span className="flex-center"><Badge className={item?.paymentStatus === "PAID" ? "bg-primary" : item?.paymentStatus === "UNPAID" ? "bg-danger" : "bg-success"}>{item?.paymentStatus}</Badge></span></td>
                                        <td><span className="flex-center">{handleDateFormat(item?.paymentDate)}</span></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </>
                    :
                    <NoData noImage />
                }
            </section>
        </Col >
    );
};

export default Payments;
