import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap"
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { NoData, TableLoading } from "../../../components";
import { IconAdd, IconDelete, IconEdit, IconFile } from "../../../components/Icons"
import { LangContext, } from "../../../context";
import { actionData, deleteService, getData, getDataService, getNumber, getProductColorRoute, getProductSizeRoute, getVariantRoute, inputChangeService, numberFormat, postMethod, putMethod, responseService, variantBulkRoute, variantRoute } from "../../../services";
import { disableScroll } from "../../../utils"
const limit: any = process.env.REACT_APP_PAGINATION_LIMIT

function WholesaleVariants() {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const { dispatch: { translate } } = useContext(LangContext);
    // const [page, setPage] = useContext(SecondPageContext)
    const location: any = useLocation();
    const product = location.state.item
    const [sizeName, setSizeName] = useState("")

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showVariantForm, setShowVariantForm] = useState(false);
    const handleCloseVariantForm = () => setShowVariantForm(false);
    const handleShowVariantForm = () => setShowVariantForm(true);

    const [productColors, setProductColors] = useState([])
    const [productSizes, setProductSizes] = useState([])

    const [data, setData] = useState([])
    const [size, setSize] = useState("")
    const [color, setColor] = useState("")
    const [colorCode, setColorCode] = useState("")

    const tempUser: any = localStorage.getItem("USER")
    const user = tempUser && JSON.parse(tempUser)

    const [formData, setFormData] = useState({
        variantId: null,
        productsId: product.id,
        productColorsId: null,
        productSizesId: null,
        currenciesId: 92,
        sku: "",
        investedCost: "",
        skuFor: "Wholesale",
        wholesalePrice: null,
        isDelete: false,
        publish: true
    })

    const required = ["wholesalePrice"]


    useEffect(() => {
        handleGetData()
        handleGetAttributes()
        // eslint-disable-next-line
    }, [])


    const handleGetData = () => {
        getDataService(getVariantRoute(product.id, 0, "desc", "Wholesale"), setLoading, setData)
    }


    const handleGetAttributes = async () => {
        getData(getProductColorRoute(product.id)).then(result => { setProductColors(result.data) })
        getData(getProductSizeRoute(product.id)).then(result => { setProductSizes(result.data) })
    }

    const handleChange = (evt: any, wantString?: boolean,) => {
        inputChangeService(evt, formData, setFormData, wantString);
    }

    const handleSizeChange = (evt: any) => {
        const target = evt.target
        const name = target.id
        const value = target.value
        const index = evt.nativeEvent.target.selectedIndex;
        const label = value === "" ? "" : evt.nativeEvent.target[index].text

        setSizeName(label)
        setFormData({ ...formData, [name]: value ? parseInt(value) : "" })
    }

    const handleColorChange = (evt: any) => {
        const target = evt.target
        const name = target.id
        const value = target.value === "" ? target.value : parseInt(target.value)

        productColors.forEach((item: any) => {
            if (item.id === value) {
                setColorCode(item.attrValuesId)
            }
        });


        setFormData({ ...formData, [name]: value ? parseInt(value) : "" })
    }


    const handleSubmit = () => {
        let inputObject: any = {};

        for (let [key, value] of Object.entries(formData)) {
            if (value !== null && value !== "") {
                inputObject[key] = value;
            }
        }

        if (inputObject.attrValuesId) {
            inputObject.attrValuesId = parseInt(inputObject.attrValuesId);
        }

        let _sizeCode = sizeName ? `-S${sizeName}` : "";
        let _colorCode = colorCode ? `-C${colorCode}` : ""

        // inputObject.sku = product.productTypes.id === 1 ? `P${product.productCode}-S${sizeName}-C${colorCode}` : `P${product.productCode}-C${colorCode}`;


        if (sizeName || colorCode) {
            inputObject.sku = `P${product.productCode}-W${_sizeCode}${_colorCode}`;

            for (const field of required) {
                if (inputObject.hasOwnProperty(field) === false) {
                    return toast.error('Please fill data!');
                }
            }


            actionData(postMethod, variantRoute, inputObject).then(result => {
                if (result?.success) {
                    toast.success(result?.message);
                    handleGetData()
                    handleClose()
                } else {
                    toast.error(result?.message)
                }
            });
        } else {
            toast.error("Must be at least color or size!")
        }


    }

    const handleEdit = () => {
        let inputObject: any = {};

        for (let [key, value] of Object.entries(formData)) {
            if (value !== null && value !== "") {
                inputObject[key] = value;
            }
        }

        for (const field of required) {
            if (inputObject.hasOwnProperty(field) === false) {
                return toast.error('Please fill data!');
            }
        }

        let finalData: any = {}


        for (let [key, value] of Object.entries(formData)) {
            if (value === "" || value === undefined) {
                finalData[key] = null
            } else {
                finalData[key] = value
            }
        }

        if (finalData.attrValuesId) {
            finalData.attrValuesId = parseInt(finalData.attrValuesId);
        }


        actionData(putMethod, variantRoute, finalData).then(result => {
            if (result?.success) {
                toast.success(result?.message);
                handleGetData()
                handleClose()
            } else {
                toast.error(result?.message)
            }
        });
    }

    const handleDelete = (item: any) => {
        deleteService(item.sku, { "variantId": item.id }, variantRoute, handleResponse)
    }

    const handleResponse = (result: any) => {
        responseService(result, handleGetData, handleClose)
    }

    const showForm = (variant?: any) => {
        variant && setSize(variant.productSizes)
        variant && setColor(variant.productColors)
        handleGetAttributes()
        setSizeName("")
        setColorCode("")
        setFormData({
            variantId: variant.id || null,
            productsId: product.id,
            productColorsId: variant.productColorsId || null,
            productSizesId: variant.productSizesId || null,
            currenciesId: 92,
            sku: variant.sku || "",
            investedCost: variant.investedCost || "",
            skuFor: "Wholesale",
            wholesalePrice: variant.wholesalePrice || null,
            isDelete: false,
            publish: true
        })
        handleShow();
    }


    return (
        <>
            <section className="bg-card mb-4" >
                <section className="flex-between mb-4">
                    <h6>Wholesale variants</h6>
                    <section>
                        {(user?.userRole === "Owner" || user?.userRole === "Admin") && <>
                            <Button className="text-white bg-primary-grad ms-2" onClick={showForm}><IconAdd />  <span className="ms-2">{translate("createNew")}</span></Button>
                        </>}

                    </section>
                </section>

                {loading ?
                    <TableLoading rowCount={5} />
                    :
                    <>
                        {data.length > 0 ?
                            <Table responsive className="mb-4" striped>
                                <thead>
                                    <tr>
                                        <th className="ps-4">#</th>
                                        <th>SKU</th>
                                        {product.productTypes.id === 1 && <th>Size</th>}
                                        <th>{translate('qty')} ({translate('in')})</th>
                                        <th>{translate('qty')} ({translate('out')})</th>
                                        <th>{translate('qty')} ({translate('remain')})</th>
                                        <th><span className="flex-end">{translate('totalCost')}</span></th>
                                        {/* <th><span className="flex-end">{translate('retailPrice')}</span></th> */}
                                        <th><span className="flex-end">{translate('wholesalePrice')}</span></th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((variant: any, index: any) =>
                                        <tr key={index}>
                                            <td className="ps-4">{index + 1}</td>
                                            <td>{variant.sku}</td>
                                            {product.productTypes.id === 1 && <td>{variant.productSizes || "-"}</td>}
                                            <td>{numberFormat(variant.stockIn)}</td>
                                            <td>{numberFormat(variant.stockOut)}</td>
                                            <td>{numberFormat(variant.stockRemain)}</td>
                                            {/* <td>600 available of 2 locations <Button className="btn-sm ms-2" onClick={handleShowInfo}><IconFile /></Button></td> */}
                                            <td><span className="flex-end">{numberFormat(variant.investedCost)}</span></td>
                                            {/* <td><span className="flex-end">{numberFormat(variant.retailPrice)}</span></td> */}
                                            <td><span className="flex-end">{numberFormat(variant.wholesalePrice)}</span></td>
                                            <td>
                                                {(user?.userRole === "Owner" || user?.userRole === "Admin") && <span className="flex-end">
                                                    <Button className="btn-sm ms-2" onClick={() => showForm(variant)}><IconEdit /></Button>
                                                    <Button className="btn-sm ms-2" onClick={() => history.push({
                                                        pathname: '/variant-detail',
                                                        state: { variant: variant, product: product }
                                                    })}><IconFile /></Button>
                                                    {product.productTypes.id !== 1 && <Button className="btn-sm ms-2" onClick={() => showForm(variant)}><IconEdit /></Button>}
                                                    <Button className="btn-danger btn-sm ms-2" onClick={() => handleDelete(variant)}>
                                                        <IconDelete />
                                                    </Button>
                                                </span>}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                            :
                            <NoData noImage />
                        }
                    </>
                }
            </section >


            <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{product.productName} (#{product.productCode})</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-4">
                    {formData?.variantId && <section className="d-flex">
                        <small className="text-muted d-block mb-3 me-4">Size : <span className="fw-bold">{size}</span></small>
                        <small className="text-muted d-block mb-3">Color : <span className="fw-bold">{color}</span></small>
                    </section>}
                    <Row>
                        {!formData?.variantId && <>

                            {product.productTypes.id === 1 && <Col md={6}>
                                <Form.Group className="mb-4 input-form-control">
                                    <Form.Label>Size</Form.Label>
                                    <Form.Control as="select" aria-label="Select" id="productSizesId" onChange={handleSizeChange} value={formData?.productSizesId || ""} >
                                        <option value="">Select</option>
                                        {productSizes.map((item: any, index: number) =>
                                            <option value={item.id} key={index}>{item.attrValue}</option>
                                        )}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            }
                            <Col md={6}>
                                <Form.Group className="mb-4 input-form-control">
                                    <Form.Label>{translate('color')}</Form.Label>
                                    <Form.Control as="select" aria-label="Select" id="productColorsId" onChange={handleColorChange} value={formData?.productColorsId || ""} >
                                        <option value="">Select</option>
                                        {productColors.map((item: any, index: number) =>
                                            <option value={item.id} key={index}>{item.attrValue}</option>
                                        )}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </>}

                        <Col md={6}>
                            <Form.Group className="mb-4 input-form-control" controlId="investedCost">
                                <Form.Label>{translate('totalCost')}</Form.Label>
                                <Form.Control type="number" min="0" onWheel={disableScroll} onChange={(evt) => handleChange(evt, true)} value={formData?.investedCost} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-4 input-form-control" controlId="wholesalePrice">
                                <Form.Label>{translate('wholesalePrice')} *</Form.Label>
                                <Form.Control type="number" min="0" onWheel={disableScroll} onChange={(evt) => handleChange(evt, true)} value={formData?.wholesalePrice || ""} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={formData.variantId ? handleEdit : handleSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default WholesaleVariants

