import React, { useContext, useRef, useState, useEffect } from 'react';
import { Badge, Button, Col, Row, Table } from 'react-bootstrap';
import { useHistory, useLocation } from "react-router-dom"
import { IconEdit, IconImage, IconLeftArrow } from '../../components/Icons';
import { Layout, OrderDetailLoading, Receipt } from '../../components';
import { LangContext, OrderContext, PageContext } from '../../context';
import { exportComponentAsPNG } from 'react-component-export-image';
import { actionData, draftOrderRoute, getData, getDraftOrderDetailByIdRoute, numberFormat, putMethod, } from '../../services';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import Payments from './cpn/Payments';


const Detail = () => {
    const history = useHistory()
    const { dispatch: { translate } } = useContext(LangContext);
    const location: any = useLocation();
    const id = location.state.id
    const routeFrom = location.state.routeFrom
    const [draftOrder, setDraftOrder] = useState<any>()
    const [loading, setLoading] = useState(false)
    const [, setOrderItems] = useContext(OrderContext);
    const tempUser: any = localStorage.getItem("USER")
    const user = tempUser && JSON.parse(tempUser)
    const [page, setPage] = useContext(PageContext)

    //Save Image and Pdf
    const exportComponentRef = useRef(null);

    useEffect(() => {
        handleGetData()
        // eslint-disable-next-line
    }, [])

    const handleGetData = async () => {
        setLoading(true)
        getData(getDraftOrderDetailByIdRoute(id)).then((result) => {
            setDraftOrder(result.data)
            setTimeout(() => {
                setLoading(false)
            }, 500)
        })
    }

    const ComponentToPrint = React.forwardRef((props, ref: any) => (
        <div ref={ref}>
            <section className="bg-card bg-white border">
                <Receipt order={draftOrder} draftOrder />
            </section>
        </div>
    ));

    const handleCancel = () => {
        swal({
            text: `Are you sure to cancel this order?`,
            icon: 'warning',
            buttons: [true, true],
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                // let tempItems: any = [];
                // draftOrder?.orderItems.forEach((item: any) => {
                //     tempItems.push({
                //         productVariantsId: item.productVariantsId,
                //         quantity: item.quantity
                //     })
                // })

                let _formData: any = {
                    draftOrdersId: draftOrder?.id
                }

                actionData(putMethod, `${draftOrderRoute}/cancel`, _formData).then((result) => {
                    if (result.success) {
                        swal(result.message, {
                            icon: 'success',
                            buttons: [false],
                            timer: 1500,
                        })
                        history.push("/orders")
                    } else {
                        toast.error(result.message)
                    }
                })
            }
        })
    }

    const handleConfirm = () => {
        swal({
            text: `Are you sure to confirm this order?`,
            icon: 'warning',
            buttons: [true, true],
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                if (draftOrder?.shippingMethods === "Delivery") {
                    if (draftOrder?.deliveryServiceId && draftOrder?.deliveryPriceId && draftOrder?.deliveryFee) {
                        handleConfirmAction()
                    } else {
                        toast.error("Please fill delivery info to edit order form!")
                    }
                } else {
                    handleConfirmAction()
                }
                // if (draftOrder?.paymentName) {
                // } else {
                //     toast.error("Please fill payment type in edit order form!")
                // }
            }
        })
    }

    const handleConfirmAction = () => {
        let _formData: any = {
            draftOrdersId: draftOrder?.id
        }

        actionData(putMethod, `${draftOrderRoute}/confirm`, _formData).then((result) => {
            if (result.success) {
                swal(result.message, {
                    icon: 'success',
                    buttons: [false],
                    timer: 1500,
                })
                setPage({ current: 0, total: 0, sorting: page.sorting })
                history.push("/sales")
            } else {
                toast.error(result.message)
            }
        })
    }



    return (
        <Layout title="Order detail">
            {loading ? <OrderDetailLoading /> :
                <>
                    <section className="flex-between mb-4 ">
                        <section className="cursor" onClick={() => routeFrom === "customer" ? history.goBack() : history.push("/orders")}>
                            <p className="mb-0"><IconLeftArrow /> {translate('orders')}</p>
                        </section>
                        {!(draftOrder?.orderStatus === "Canceled" || draftOrder?.orderStatus === "Confirmed") && <section>
                            {(user?.userRole === "Owner" || user?.userRole === "Admin" || user?.userRole === "Manager") && <Button className="text-white me-2"
                                onClick={() => {
                                    history.push({
                                        pathname: draftOrder?.orderType === "Retail" ? '/edit-order' : '/edit-wholesale-order',
                                        state: { order: draftOrder, routeFrom: "detail" }
                                    }); setOrderItems([])
                                }}><IconEdit /> <span className="ms-2">Edit order</span></Button>}
                            <Button onClick={() => exportComponentAsPNG(exportComponentRef, { fileName: `${draftOrder?.draftOrdersCode}.png`, })}><IconImage /> <span className="ms-2">Export as image</span></Button>
                            <Button className="text-white ms-2" onClick={handleConfirm}>Order confirm</Button>
                            {(user?.userRole === "Owner" || user?.userRole === "Admin" || user?.userRole === "Manager") && <Button className="text-white ms-2 bg-danger border-none" onClick={handleCancel}>Order cancel</Button>}
                        </section>}
                    </section>

                    <Row className='mb-4'>
                        <Col md={8}>
                            <ComponentToPrint ref={exportComponentRef} />
                        </Col>
                        <Col>
                            <Table responsive striped className="mb-0">
                                <tbody>
                                    <tr>
                                        <td className="ps-4 text-muted ">Employee</td>
                                        <td> <small>{draftOrder?.employeeName || "-"}</small></td>
                                    </tr>
                                    <tr>
                                        <td className="ps-4 text-muted ">Order type</td>
                                        <td> <small>{draftOrder?.orderType}</small></td>
                                    </tr>
                                    <tr>
                                        <td className="ps-4 text-muted ">{translate('orderStatus')}</td>
                                        <td><Badge className={draftOrder?.orderStatus === "Confirmed" ? "bg-primary" : draftOrder?.orderStatus === "Canceled" ? "bg-danger" : "bg-primary"}>{draftOrder?.orderStatus}</Badge></td>
                                    </tr>
                                    <tr>
                                        <td className="ps-4 text-muted ">Order from</td>
                                        <td> <small>{draftOrder?.orderFrom || "-"}</small></td>
                                    </tr>
                                    <tr>
                                        <td className="ps-4 text-muted ">Order note</td>
                                        <td> <small>{draftOrder?.orderNotes || "-"}</small></td>
                                    </tr>
                                    {/* <tr>
                                        <td className="ps-4 text-muted ">{translate('paymentStatus')}</td>
                                        <td><Badge className={draftOrder?.paymentStatus === "PAID" ? "bg-primary" : draftOrder?.paymentStatus === "UNPAID" ? "bg-danger" : "bg-success"}>{draftOrder?.paymentStatus}</Badge></td>
                                    </tr>
                                    <tr>
                                        <td className="ps-4 text-muted ">{translate('paymentType')}</td>
                                        <td> <small>{draftOrder?.paymentName || "-"}</small></td>
                                    </tr> */}
                                    <tr>
                                        <td className="ps-4 text-muted ">Shipping method</td>
                                        <td> <small>{draftOrder?.shippingMethods || "-"}</small></td>
                                    </tr>
                                    {draftOrder?.shippingMethods === "Delivery" &&
                                        <>
                                            <tr>
                                                <td className="ps-4 text-muted ">Delivery</td>
                                                <td> <small>{draftOrder?.deliveryService || "-"}</small></td>
                                            </tr>
                                            <tr>
                                                <td className="ps-4 text-muted ">{translate('location')}</td>
                                                <td> <small>{draftOrder?.locationName || "-"}</small></td>
                                            </tr>
                                            <tr>
                                                <td className="ps-4 text-muted ">{translate('deliveryFee')}</td>
                                                <td> <small>{draftOrder?.deliveryFee ? `${numberFormat(draftOrder?.deliveryFee)} MMK` : "-"}</small></td>
                                            </tr>
                                            <tr>
                                                <td className="ps-4 text-muted ">{translate('deliveryStatus')}</td>
                                                <td> <Badge className={draftOrder?.deliveryStatus === "Delivered" ? "bg-warning" : draftOrder?.deliveryStatus === "Canceled" ? "bg-danger" : "bg-primary"}>{draftOrder?.deliveryStatus}</Badge></td>
                                            </tr>

                                        </>
                                    }
                                    <tr>
                                        <td className="ps-4 text-muted ">{draftOrder?.shippingMethods} note</td>
                                        <td> <small>{draftOrder?.deliveryNotes || "-"}</small></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Payments detail={draftOrder} />
                </>
            }
        </Layout >
    );
};



export default Detail;
