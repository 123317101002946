import { useState } from "react";
import { Image } from "react-bootstrap";
import { IconDelete } from "../../../components/Icons";
import AddItemModal from "./AddItemModal";


const CartItem = ({ thumbnail, size, price, quantity, color }:
    { thumbnail: string, size: number, price: number, quantity: number, color: string }) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <section className="cart-item">
            <AddItemModal show={show} handleClose={handleClose} />
            <section className="d-flex align-items-center" onClick={handleShow}>
                <label className="text-danger cursor"><IconDelete /></label>
                <section className="cart-item-image ms-3">
                    <Image src={thumbnail} />
                </section>
                <section className="ms-3">
                    <small className="xs-label d-block fw-bold mb-2">ပိတ်ဖေါက်ဇာရှေ့ကွဲလက်တို</small>

                    <section className="d-flex mb-2">
                        <small className="xs-label text-muted d-block">#500</small>
                        <small className="xs-label text-muted d-block ms-3">{size}</small>
                        <small className="xs-label text-muted d-block ms-3">{color}</small>
                    </section>
                    <small className="xs-label text-muted d-block">{quantity} × 13,000</small>
                </section>

            </section>

            <section className="d-flex align-items-end" >
                <small className="d-block text-muted" onClick={handleShow}>{new Intl.NumberFormat().format(quantity * price)}</small>
            </section>
        </section>
    );
};

export default CartItem;
