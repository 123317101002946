import { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { Bar } from 'react-chartjs-2'
import { getData } from '../../services'

export default function BarChart() {
    const [labels, setLabels] = useState<any>([])
    const [values, setValues] = useState<any>([])

    useEffect(() => {
        handleGetData()
    }, [])

    const handleGetData = async () => {
        let _labels: any = []
        let _values: any = []
        getData("dashboard/brands/items").then(result => {
            if (result && result.length > 0) {
                result.forEach((item: any) => {
                    if (item.productCount > 0) {
                        _labels.push(item.brandName)
                        _values.push(item.productCount)
                    }
                })

                setLabels(_labels)
                setValues(_values)
            }
        })
    }

    return (
        <Col md={12}>
            <Bar
                data={{
                    labels: labels,
                    datasets: [
                        {
                            label: 'Product count',
                            data: values,
                            backgroundColor: [
                                '#0FCAF0',
                            ],
                            borderWidth: 1,
                        },
                        // {
                        //     label: 'Orders',
                        //     data: [10, 3, 5, 20, 10, 15, 5, 40, 30, 15, 5, 30,],
                        //     backgroundColor: [
                        //         '#27AE60',
                        //     ],
                        //     borderWidth: 1,
                        // },
                    ],
                }}
                height={280}
                width={400}
                options={{
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,

                        }
                    }
                }}
            />
        </Col>
    )
}

