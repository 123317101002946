
import React, { useContext } from "react";
import { Table } from "react-bootstrap";
import { LangContext, PageContext } from "../../../context";
import { handleDateFormat, numberFormat } from "../../../services";

const DailyReportSalePdf = React.forwardRef((props: any, ref: any) => {
    const { dispatch: { translate } } = useContext(LangContext);
    const data = props.data
    const [page] = useContext(PageContext)
    let orderType = props.orderType;
    let startDate = props.startDate;
    let endDate = props.endDate;
    let summaryTotal = props.summaryTotal;

    const paginate = 25
    let pageTotal = Math.ceil(data.length / paginate);
    const pages: any = [];

    for (let i = 0; i < pageTotal; i++) {
        pages.push({ id: i })
    }

    const DataLists = ({ current }: { current: number }) => {
        return (
            <Table className="mb-0 print-table center-table" bordered striped>
                <thead>
                    <tr>
                        <th>{translate('no.')}</th>
                        <th>{translate('date')}</th>
                        {!orderType && <th>{translate('type')}</th>}
                        <th>{translate('numberOfSale')}</th>
                        <th>{translate('productQuantity')}</th>
                        <th>{translate('total')}</th>
                    </tr>
                </thead>
                <tbody>
                    {data && data.slice(current * paginate, (current * paginate) + paginate).map((item: any, index: number) =>
                        <tr key={index}>
                            <td><span className='flex-center'>{paginate * current + index + 1 + page.current * 100}</span></td>
                            <td><span className='flex-center'>{handleDateFormat(item.orderedDate)}</span></td>
                            {!orderType && <td><span className='flex-center'>{item?.orderType}</span></td>}
                            <td><span className='flex-end'>{numberFormat(item?.numOfSales)}</span></td>
                            <td><span className='flex-end'>{numberFormat(item?.orderedQty)}</span></td>
                            <td><span className='flex-end'>{numberFormat(item?.totalAmount)}</span></td>
                        </tr>)}
                    {current === pages.length - 1 && <tr>
                        <th colSpan={2} className="ps-4"><span className='flex-center'>{translate('total')}</span></th>
                        {!orderType && <th />}
                        <th><span className="flex-end">{numberFormat(summaryTotal.numOfSales)}</span></th>
                        <th><span className="flex-end">{numberFormat(summaryTotal.orderedQty)}</span></th>
                        <th><span className="flex-end">{numberFormat(summaryTotal.totalAmount)}</span></th>
                    </tr>}
                </tbody>
            </Table>
        );
    }


    return (
        <section ref={ref} className="default-margin">
            <div className="d-flex flex-column align-items-center mb-1">
                <h6 className="mt-2 mb-1 fw-bold">{translate('dailySaleReport')}</h6>
            </div>

            <div className='flex-between mb-1'>
                <div>
                    <small className="print-label-xxs mb-1">{translate('type')} : {orderType ? orderType : "Retail , Wholesale"}</small>
                    {startDate && <small className="d-block print-label-xxs mb-1">{endDate ? "From date" : "Date"} : {startDate}</small>}
                    {endDate && <small className='d-block print-label-xxs mb-1'>End date : {endDate}</small>}
                </div>
                <div className='d-flex flex-column align-items-end'>
                    <small className="d-block print-label-xxs mb-1">{translate('numberOfSale')} : {numberFormat(summaryTotal?.numOfSales)}</small>
                    <small className="d-block print-label-xxs mb-1">{translate('productQuantity')} : {numberFormat(summaryTotal?.orderedQty)}</small>
                    <small className='d-block print-label-xxs mb-1'>{translate('total')} : {translate('mmk')} {numberFormat(summaryTotal?.totalAmount)}</small>
                </div>
            </div>

            {pages.map((item: number, index: number) => (
                <div key={index} className={index === 0 ? "" : "page-container"}>
                    <DataLists current={index} />
                    {item !== pages.length - 1 && <footer />}
                </div>
            ))}
        </section >
    );
});

export default DailyReportSalePdf