import { useState } from "react";
import { Col, Image, } from "react-bootstrap";
import AddItemModal from "./AddItemModal";

const ItemCard = ({ thumbnail, size, price, stock }: { thumbnail: string, size: number, price: string, stock: string }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Col md={4}>
            <section className="pos-item mb-4" onClick={handleShow}>
                <section className="pos-item-image">
                    <Image src={thumbnail} />
                </section>
                <section className="p-3">
                    <small className="d-block fw-bold mb-2">ပိတ်ဖေါက်ဇာရှေ့ကွဲလက်တို</small>
                    <section className="flex-between mb-3">
                        <section>
                            <small className="xs-label text-muted d-block">SKU</small>
                            <small className="d-block text-muted fw-bold">#500-{size}</small>
                        </section>
                        <section className="d-flex flex-column align-items-end">
                            <small className="xs-label text-muted d-block">Size</small>
                            <small className="d-block text-muted fw-bold">{size}</small>
                        </section>
                    </section>
                    <section className="flex-between">
                        <section>
                            <small className="xs-label text-muted d-block">Stock</small>
                            <small className="d-block text-muted fw-bold">{stock}</small>
                        </section>
                        <section className="d-flex flex-column align-items-end">
                            <small className="xs-label text-muted d-block">Price</small>
                            <small className="d-block text-muted fw-bold">{price}</small>
                        </section>
                    </section>
                </section>
            </section>

            <AddItemModal show={show} handleClose={handleClose} />
        </Col>
    );
};

export default ItemCard;
