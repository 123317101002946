import PieChart from "./PieChart"

function ProductInfo() {
    return (
        <section className="shadow-sm p-4 mb-4 rounded h-450">
            <h6 className="fw-bold text-primary text-center mb-4">Products by type</h6>
            <PieChart />
        </section>
    )
}

export default ProductInfo