
import React, { useContext, useEffect, useState } from "react";
import { Col, Image, Table } from "react-bootstrap";
import { LangContext } from "../../../context/Lang";
import { getAllQunatityAmount, getAllTotalAmount, groupByVariantService, handleDateTimeFormat, numberFormat } from "../../../services";
import QRCode from 'qrcode';
import { toast } from "react-toastify";
import { IconFacebook, IconPhone } from "../../../components/Icons";

const ReceiptPrint = React.forwardRef((props: any, ref: any) => {
    const { dispatch: { translate } } = useContext(LangContext);
    const sale = props.sale;
    const saleItems = sale?.orderItems && groupByVariantService(sale?.orderItems)


    const receiptPagiante = props.receiptPagiante || 12

    const [imageUrl, setImageUrl] = useState('');
    let pageTotal = Math.ceil(saleItems?.length / receiptPagiante);
    const pages: any = [];

    const totalPaidAmount = () => {
        let total = 0;
        if (sale?.paymentHistory && sale?.paymentHistory.length > 0) {
            sale?.paymentHistory.forEach((item: any) => {
                total += item.totalAmountPaid ? +item.totalAmountPaid : 0
            })
        }
        return total;
    }

    for (let i = 0; i < pageTotal; i++) {
        pages.push({ id: i })
    }

    useEffect(() => {
        generateQrCode()
        // eslint-disable-next-line
    }, [])

    const generateQrCode = async () => {
        try {
            const response = await QRCode.toDataURL(sale?.draftOrdersCode);
            setImageUrl(response);
        } catch (error: any) {
            toast.error(error);
        }
    }

    const getSubtotalAmount = () => {
        let total = 0;
        sale?.orderItems?.forEach((item: any) => {
            total += item.quantity * item.unitPrice
        });
        return total;
    }

    const getDiscountAmountByPercentage = () => {
        let amount: any = 0;
        if (sale?.discountValue) {
            let discountPercent = parseInt(sale?.discountValue)
            amount = ((getSubtotalAmount() + (sale?.deliveryFee ? parseInt(sale?.deliveryFee) : 0) + getBankCommissionAmount()) / 100) * discountPercent
            amount = parseInt(amount)
        } else {
            amount = 0
        }
        return amount;
    }

    const getBankCommissionAmount = () => {
        return getAllTotalAmount(sale?.orderItems, parseInt(sale?.deliveryFee)) / 100 * sale?.bankCommission
    }

    const ReceiptInfo = ({ current }: { current: number }) => {
        return (
            <section className="sale-receipt-print">

                <div>
                    <section className="flex-between">
                        <section style={{ width: "60%" }}>
                            <small className=" d-block print-label-xs"><span>{translate('name')}</span> : {sale?.customerName || "-"}</small>
                            <small className=" d-block print-label-xs"><span>{translate('phone')}</span> : {sale?.customerPhone || "-"}</small>
                            {sale?.customerAddress[0].address && <small className="d-block print-label-xs"><span>{translate('address')}</span> : <span className="print-label-xxs">{sale?.customerAddress[0].address || "-"}</span></small>}
                        </section>
                        <section className="d-flex flex-column align-items-end">
                            <small className="d-block print-label-xs">{sale?.saleOrdersCode}</small>
                            <small className=" d-block print-label-xs ">{translate('date')} : {handleDateTimeFormat(sale?.createdAt)}</small>
                        </section>
                    </section>
                    <div>
                        <Table responsive className="print-receipt-table mt-2 mb-0" bordered striped>
                            <thead>
                                <tr>
                                    <th><span className="d-flex justify-content-center">{translate('no.')}</span></th>
                                    <th><span className="d-flex justify-content-center">Code</span></th>
                                    <th><span className="d-flex justify-content-center">{translate('item')}</span></th>
                                    <th><span className="d-flex justify-content-end">{translate('quantity')}</span></th>
                                    <th><span className="d-flex justify-content-end">{translate('price')}</span></th>
                                    <th><span className="d-flex justify-content-end">{translate('amount')}</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {saleItems && saleItems.slice(current * receiptPagiante, (current * receiptPagiante) + receiptPagiante).map((item: any, index: number) => (
                                    <tr key={index}>
                                        <th><span className="d-flex justify-content-center">{index + 1 + current * receiptPagiante}</span></th>
                                        <th><span className="d-flex justify-content-center">{item.productCode}</span></th>
                                        <th>{item.productName} {item.productSize && `${item.productSize}`}  {item.colors[0].productColor !== "-" && item.colors.map((color: any, index: number) => (<span className="fw-bold" key={index}>{color.productColor}{item.colors.length - 1 !== index && " , "}</span>))}  {item.unitPrice === "0" ? `(${translate('gift')})` : ""}</th>
                                        <th><span className="flex-end">{numberFormat(getAllQunatityAmount(item.colors))} </span></th>
                                        <th><span className="flex-end">{item.unitPrice !== "0" ? numberFormat(item.unitPrice) : "Free"}</span></th>
                                        <th><span className="flex-end">{item.unitPrice !== "0" ? numberFormat(getAllQunatityAmount(item.colors) * item.unitPrice) : 0}</span></th>
                                    </tr>
                                ))}
                                {current === pages.length - 1 && <tr>
                                    <th />
                                    <th />
                                    <th />
                                    <th><span className="flex-end">{numberFormat(getAllQunatityAmount(sale?.orderItems))}</span></th>
                                    <th />
                                    <th><span className="flex-end">{numberFormat(getAllTotalAmount(sale?.orderItems))}</span></th>
                                </tr>}
                            </tbody>
                        </Table>
                        {current === pages.length - 1 && <>
                            {saleItems.slice(current * receiptPagiante, (current * receiptPagiante) + receiptPagiante).length > 11 ? <>
                                <footer />
                                <div className="print-margin-top" />
                                <div className=" margin-for-receipt" />
                                <section className="flex-between mb-4">
                                    <section style={{ width: "60%" }}>
                                        <small className=" d-block print-label-xs "><span>{translate('name')}</span> : {sale?.customerName || "-"}</small>
                                        <small className=" d-block print-label-xs "><span>{translate('phone')}</span> : {sale?.customerPhone || "-"}</small>
                                        {sale?.customerAddress[0].address && <small className="d-block print-label-xs mb-1"><span>{translate('address')}</span> : <span className="print-label-xxs">{sale?.customerAddress[0].address || "-"}</span></small>}
                                    </section>
                                    <section className="d-flex flex-column align-items-end">
                                        <small className="d-block print-label-xs ">{sale?.saleOrdersCode}</small>
                                        <small className=" d-block print-label-xs ">{translate('date')} : {handleDateTimeFormat(sale?.createdAt)}</small>
                                    </section>
                                </section>
                                <Table responsive className="print-receipt-table mt-2 mb-0" bordered striped>
                                    <thead>
                                        <tr>
                                            <th><span className="d-flex justify-content-center">{translate('no.')}</span></th>
                                            <th><span className="d-flex justify-content-center">Code</span></th>
                                            <th><span className="d-flex justify-content-center">{translate('item')}</span></th>
                                            <th><span className="d-flex justify-content-end">{translate('quantity')}</span></th>
                                            <th><span className="d-flex justify-content-end">{translate('price')}</span></th>
                                            <th><span className="d-flex justify-content-end">{translate('amount')}</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {current === pages.length - 1 && <tr>
                                            <th />
                                            <th />
                                            <th />
                                            <th><span className="flex-end">{numberFormat(getAllQunatityAmount(sale?.orderItems))}</span></th>
                                            <th />
                                            <th><span className="flex-end">{numberFormat(getAllTotalAmount(sale?.orderItems))}</span></th>
                                        </tr>}
                                    </tbody>
                                </Table>
                                <ReceiptSummary />
                            </> : <ReceiptSummary />}
                        </>}
                    </div>

                </div>

                <div>
                    {current === pages.length - 1 && <>
                        <small className=" d-block print-label-xxs my-1 d-flex align-items-center"><IconFacebook /> <span className="ms-2"> Cupid & Vision ကွန်ပျူတာပန်းထိုးလုံချည်နှင့်အမျိုးသမီးအင်္ကျီလက်လီ/လက်ကား</span></small>
                        <small className=" d-block print-label-xxs mb-1 d-flex align-items-center"><IconPhone /> <span className="ms-2">09-666669821, 09-796666397</span></small>
                        <small className="print-label-xxs text-center d-block">{translate('thankForYourPurchase')}</small>
                    </>}

                </div>

            </section>
        );
    }

    const ReceiptSummary = () => (
        <section className="d-flex justify-content-between pt-2">
            <div style={{ width: "40%" }}>
                {/* <small className="d-block print-label-xs mb-2">{translate('payment')} : {sale?.paymentStatus}</small> */}
                {sale?.deliveryNotes && <>
                    <small className="d-block mb-2 print-label-xs"><span>Note : </span> {sale?.deliveryNotes}</small>
                </>}
            </div>

            {imageUrl && <Image src={imageUrl} alt="no_found" width={50} height={50} />}


            <Col md={4} style={{ width: "40%" }} className="me-2">
                {sale?.deliveryFee && <section className="flex-between">
                    <small className="print-label-xs d-block">{translate('deliveryFee')}</small>
                    <small className="print-label-xs">{numberFormat(sale?.deliveryFee)}</small>
                </section>}
                {sale?.bankCommission && <section className="flex-between">
                    <small className="print-label-xs d-block">Bank ({sale?.bankCommission}%)</small>
                    <small className="print-label-xs">{numberFormat(getAllTotalAmount(sale?.orderItems, parseInt(sale?.deliveryFee)) / 100 * sale?.bankCommission)}</small>
                </section>}
                {sale?.discountValue && <section className="flex-between">
                    <small className="print-label-xs d-block">Discount {sale?.discountType === "By percentage" && `(${sale?.discountValue}%)`}</small>
                    <small className="print-label-xs">{sale?.discountValue && "-"} {numberFormat(sale?.discountType === "By percentage" ? getDiscountAmountByPercentage() : sale?.discountValue)}</small>
                </section>}

                {(sale?.deliveryFee || sale?.bankCommission || sale?.discountValue) && <section className="flex-between border-color-black pt-1 mt-1">
                </section>}

                <section className="flex-between">
                    <small className="print-label-xs d-block">{translate('totalAmount')}</small>
                    <small className="print-label-xs">{numberFormat(getAllTotalAmount(sale?.orderItems, parseInt(sale?.deliveryFee), parseFloat(sale?.bankCommission), sale?.discountType, parseFloat(sale?.discountValue)))}</small>
                </section>
                <section className="flex-between ">
                    <small className="print-label-xs d-block">{translate('paidAmount')}</small>
                    <small className="print-label-xs">{numberFormat(totalPaidAmount())}</small>
                </section>
                <section className="flex-between border-color-black pt-1 mt-1">
                    <small className="print-label-xs d-block">{translate('balance')} ({translate('mmk')})</small>
                    <small className="print-label-xs">{numberFormat(getAllTotalAmount(sale?.orderItems, parseInt(sale?.deliveryFee), parseFloat(sale?.bankCommission), sale?.discountType, parseFloat(sale?.discountValue)) - totalPaidAmount())}</small>
                </section>
            </Col>
        </section>
    )

    return (
        <div ref={ref} className="landscape" style={{ height: "92.5vh", width: "100%", }}>
            {pages.map((item: number, index: number) => (
                <div key={index}>
                    <section className="d-flex  margin-for-receipt" >
                        <ReceiptInfo current={index} />
                        <section className="print-line"></section>
                        <ReceiptInfo current={index} />
                    </section >

                    {item !== pages.length - 1 && <footer />}
                </div>
            ))}

        </div>
    );
});



export default ReceiptPrint