import React, { useContext, useEffect, useState } from 'react';
import { TableLayout } from '../../../components';
import { LangContext } from '../../../context';
import { getData, groupByIncomeReport, numberFormat } from '../../../services';

const IncomeByPayment = () => {
    const [data, setData] = useState([])
    const [summary, setSummary] = useState<any>()
    const [loading, setLoading] = useState(false)
    const { dispatch: { translate } } = useContext(LangContext);

    useEffect(() => {
        handleGetData()
        // eslint-disable-next-line
    }, [])


    const handleGetData = () => {
        setLoading(true)
        getData("total/orders/sale?orderStatus=Confirmed").then(result => {
            // let temp = result.data && groupByIncomeReport(result.data)
            setData(result.data); setSummary(result.summary); setLoading(false)
        })
    }

    return (
        <section className="bg-card mb-4">
            <div className='flex-between mb-3'>
                <h6 className="">Sales income by payment type</h6>
                <div className='d-flex flex-column align-items-end'>
                    {summary?.total ? <span className="d-block mb-2"><small className="text-muted">All total</small> : <small>{numberFormat(summary?.total)} {translate('mmk')}</small></span> : <></>}
                </div>
            </div>
            <TableLayout loading={loading} data={data} noBg>
                <thead>
                    <tr>
                        <th className='ps-4'>{translate("no.")}</th>
                        <th>Payment name</th>
                        <th>Payment status</th>
                        <th>No. of sale</th>
                        <th><span className='flex-end'>Amount</span></th>
                        <th><span className='flex-end'>{translate('deliveryFee')}</span></th>
                        <th><span className='flex-end'>Bank commission</span></th>
                        <th><span className='flex-end'>Discount</span></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item: any, index: any) =>
                        <tr key={index}>
                            <td className='ps-4'>{index + 1}</td>
                            <td>{item.paymentName}</td>
                            <td>{numberFormat(item.paymentStatus)}</td>
                            <td>{numberFormat(item.numOfSales || "-")}</td>
                            <td><span className='flex-end'>{numberFormat(item.amount || "-")}</span></td>
                            <td><span className='flex-end'>{numberFormat(item.deliveryFee || "-")}</span></td>
                            <td><span className='flex-end'>{numberFormat(item.bankCommission || "-")}</span></td>
                            <td><span className='flex-end'>{numberFormat(item.discountValue || "-")}</span></td>
                        </tr>
                    )}
                    <tr>
                        <th colSpan={4} className="ps-4"><span className='flex-center'>{translate('total')}</span></th>
                        <th><span className="flex-end">{numberFormat(summary?.amount)}</span></th>
                        <th><span className="flex-end">{numberFormat(summary?.deliveryFee)}</span></th>
                        <th><span className="flex-end">{numberFormat(summary?.bankCommission)}</span></th>
                        <th><span className="flex-end">{numberFormat(summary?.discount)}</span></th>
                    </tr>
                </tbody>
            </TableLayout>
        </section>
    );
};

export default IncomeByPayment;
