import { useContext, useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import { LangContext } from '../../context/Lang'
import { SubHeader, TableLayout, TableRowButtons, CustomModal, CustomInput } from '../../components'
import { dataService, deleteService, deliverySerivcesRoute, getDataService, handleClose, handleShow, inputChangeService, responseService } from '../../services'

const DeliveryServices = () => {
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false);
    const { dispatch: { translate } } = useContext(LangContext);
    const [data, setData] = useState([])
    const [formData, setFormData] = useState({
        serviceId: "",
        serviceName: "",
        allowed: true
    })
    const required = ["serviceName"]

    useEffect(() => {
        handleGetData()
    }, [])

    const handleGetData = () => {
        getDataService(deliverySerivcesRoute + "?list=all", setLoading, setData)
    }

    const handleChange = (evt: any) => {
        inputChangeService(evt, formData, setFormData);
    }

    const showForm = (item?: any) => {
        setFormData({
            serviceId: item.id,
            serviceName: item.deliveryService || "",
            allowed: true
        })
        handleShow(setShow);
    }


    const handleSubmit = (method: string) => {
        setLoading(true)
        dataService(method, formData, required, deliverySerivcesRoute).then(result => {
            handleResponse(result)
        })
    }

    const handleResponse = (result: any) => {
        responseService(result, handleGetData, handleClose(setShow), null, null, setLoading)
    }

    const handleDelete = (item: any) => {
        deleteService(item.deliveryService, { "serviceId": item.id }, deliverySerivcesRoute, handleResponse)
    }

    return (
        <Layout title="Delivery Services">
            <SubHeader title={translate("configuration")} showForm={showForm} />

            <TableLayout loading={loading} data={data}>
                <thead>
                    <tr>
                        <th>{translate("no.")}</th>
                        <th>Delivery name</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {data.map((item: any, index: any) =>
                        <tr key={index}>
                            <td><span className='flex-center'>{index + 1}</span></td>
                            <td>{item.deliveryService}</td>
                            <td><TableRowButtons item={item} showForm={showForm} handleDelete={handleDelete} flex="flex-center" /></td>
                        </tr>
                    )}
                </tbody>
            </TableLayout>

            <CustomModal show={show} handleClose={() => handleClose(setShow)} id={formData?.serviceId} handleSubmit={handleSubmit} loading={loading}>
                <CustomInput label="Delivery name" id="serviceName" handleChange={handleChange} value={formData?.serviceName} />
            </CustomModal>
        </Layout >
    )
}

export default DeliveryServices