import { useContext, useEffect, useState } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap"
import { useHistory } from "react-router";
import { IconCamera, IconPerson } from "../../components/Icons";
import Layout from "../../components/Layout";
import { toast } from "react-toastify";
import { LangContext } from "../../context/Lang";
import { dataService, deleteAvatar, getData, putMethod, regionRoute, townshipRoute, uploadAvatar } from "../../services";
import { FileWithPath, useDropzone } from "react-dropzone";
import { VariableContext } from "../../context";
import Skeleton from "react-loading-skeleton";
const RES_API_URL = process.env.REACT_APP_RES_API_URL

interface Target {
    id?: number
    fileName?: string
    colorName?: string
    image?: string
}

function Profile() {
    const history = useHistory();
    const { dispatch: { translate } } = useContext(LangContext);
    const [imageUploadLoading, setImageUploadLoading] = useState(false)
    const tempUser: any = localStorage.getItem("USER")
    const user = tempUser && JSON.parse(tempUser)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [regions, setRegions] = useState([])
    const [regionCode, setRegionCode] = useState("")
    const [townships, setTownships] = useState([])
    const [variable, setVariable] = useContext(VariableContext)
    const [fileName, setFileName] = useState("")
    const [target, setTarget] = useState<Target>();
    const [formData, setFormData] = useState({
        userId: user?.id,
        avatar: "",
        fullName: "",
        phone: "",
        addressId: "",
        address: "",
        subSubAreaCode: ""
    })
    const required = ["fullName"]

    useEffect(() => {
        handleGetData()
        handleGetRegions()
        // eslint-disable-next-line
    }, [])

    const handleGetData = () => {
        getData(`profile?userId=${user?.id}`).then(result => {
            let temp = result?.data
            setFormData({
                ...formData,
                avatar: temp?.avatar || "",
                fullName: temp?.fullName || "",
                phone: temp?.phone || "",
                addressId: temp?.addressId || "",
                address: temp?.address || "",
                subSubAreaCode: temp?.subSubAreaCode || ""
            });
            setRegionCode(temp?.areaCode || "")
            setFileName(temp?.avatar || "")
            handleGetTownships(temp?.areaCode || "")
            setVariable({ ...variable, username: temp?.fullName, avatar: temp?.avatar })
        })
    }


    const handleGetRegions = async () => {
        getData(regionRoute).then(result => {
            setRegions(result.data)
        })
    }

    const handleGetTownships = async (regionCode: string) => {
        getData(townshipRoute(regionCode)).then(result => {
            setTownships(result.data)
        })
    }

    //Avatar Function
    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/jpeg, image/png",
        multiple: false,
        onDrop: (acceptedFiles: FileWithPath[]) => {
            let temp: any = []
            setImageUploadLoading(true)
            temp = acceptedFiles.map(
                (file: any) => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
            )
            setTimeout(() => {
                let tempFormData = new FormData()
                temp && temp.map((file: any) => (tempFormData.append("files", file, file.name)))
                uploadAvatar(tempFormData).then(data => {

                    let _formData = {
                        userId: user?.id,
                        avatar: data[0].fileName,
                        fullName: formData?.fullName || "",
                        phone: formData?.phone || "",
                        addressId: formData?.addressId || "",
                        address: formData?.address || "",
                        subSubAreaCode: formData?.subSubAreaCode || "",
                    }

                    setFileName(data[0].fileName)

                    dataService(putMethod, _formData, [], "profile").then(result => {
                        if (result?.id || result?.success) {
                            toast.success("Successfully done!")
                            handleGetData()
                        }
                    })
                    setImageUploadLoading(false)
                })
            }, 1000)
        }
    })

    const handleDeleteUploaded = async (file: any, i: any) => {
        setImageUploadLoading(true)
        deleteAvatar({ file: file }).then(data => {
            setFileName("")
            let _formData = {
                userId: user?.id,
                avatar: "",
                fullName: formData?.fullName || "",
                phone: formData?.phone || "",
                addressId: formData?.addressId || "",
                address: formData?.address || "",
                subSubAreaCode: formData?.subSubAreaCode || "",
            }

            dataService(putMethod, _formData, [], "profile", true).then(result => {
                if (result?.id || result?.success) {
                    toast.success("Successfully done!")
                    handleGetData()
                }
            })
            setImageUploadLoading(false)
            handleClose()
        })
    }

    const handleChange = (evt: any) => {
        const value = evt.target.value
        const name = evt.target.id
        setFormData({ ...formData, [name]: value })
    }

    const handleSelectRegion = (evt: any) => {
        const value = evt.target.value
        setRegionCode(value)
        handleGetTownships(value)
    }

    const toggle = (fileName: string, id: number,) => {
        setTarget({ id: id, fileName: fileName });
        handleShow();
    };

    //Update Action
    const handleSubmit = (evt?: any) => {
        evt.preventDefault()

        dataService(putMethod, formData, required, "profile").then(result => {
            if (result?.success) {
                toast.success(result?.message)
                handleGetData()
            }
        })
    }


    return (
        <Layout title="Profile">
            <section className="bg-card">
                <div className="d-flex flex-column justify-content-center align-items-center my-4">

                    {!imageUploadLoading ? <div className="position-relative">
                        <div className="avatar">
                            {fileName ? <img src={`${RES_API_URL}/assets/avatar/${fileName}`} className="thumb-img" alt={fileName} />
                                :
                                <IconPerson size={80} />
                            }
                        </div>

                        {!fileName && <div className="btn-upload">
                            <div {...getRootProps({ className: " mb-3" })} >
                                <input {...getInputProps()} />
                                <IconCamera />
                            </div>
                        </div>}
                    </div> : <div>
                        <Skeleton height={150} width={150} borderRadius={100} />
                    </div>}


                    {fileName && <small className="mt-3 cursor text-danger" onClick={() => toggle(fileName, 0)}>Remove avatar</small>}
                </div>

                <Form>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="fullName" className="mb-4 input-form-control">
                                <Form.Label>{translate('name')} *</Form.Label>
                                <Form.Control type="text" onChange={handleChange} value={formData?.fullName} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="email" className="mb-4 input-form-control">
                                <Form.Label>{translate('email')}</Form.Label>
                                <Form.Control type="text" onChange={handleChange} value={user?.email} disabled />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="phone" className="mb-4 input-form-control">
                                <Form.Label>{translate('phone')}</Form.Label>
                                <Form.Control type="text" onChange={handleChange} value={formData?.phone} />
                            </Form.Group>
                        </Col>

                        <Col md={6}>
                            <Form.Group className="mb-4 input-form-control">
                                <Form.Label>{translate('region')}</Form.Label>
                                <Form.Control as="select" aria-label="Select" className="mb-4" id="areaCode" value={regionCode} onChange={handleSelectRegion}>
                                    <option value="">Select</option>
                                    {regions.map((item: any, index: number) =>
                                        <option value={item.areaCode} key={index}>{item.areaNameLocal}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-4 input-form-control">
                                <Form.Label>{translate('township')}</Form.Label>
                                <Form.Control as="select" aria-label="Select" className="mb-4" id="subSubAreaCode" value={formData?.subSubAreaCode} onChange={handleChange}>
                                    <option value="">Select</option>
                                    {townships.map((item: any, index: number) =>
                                        <option value={item.subSubAreaCode} key={index}>{item.subSubAreaNameLocal}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="address" className="mb-4 input-form-control">
                                <Form.Label>{translate('address')}</Form.Label>
                                <Form.Control type="text" onChange={handleChange} value={formData?.address} />
                            </Form.Group>
                        </Col>
                    </Row>

                    <section className="d-flex">
                        <Button variant="primary" type="submit" className="me-2" onClick={handleSubmit}>Update </Button>
                        <Button variant="success" onClick={() => history.goBack()}>Cancel </Button>
                    </section>
                </Form>
            </section>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Deleting Avatar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure to delete avatar?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => handleDeleteUploaded(target?.fileName, target?.id)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </Layout>
    )
}

export default Profile