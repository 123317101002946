import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Badge, Button, Col, Form, InputGroup, Offcanvas, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { IconClose, IconEdit, IconFilter, IconPrint, IconSearch } from '../../components/Icons';
import DatePicker from "react-datepicker";
import { useReactToPrint } from "react-to-print"
import { Count, Print } from './cpn';
import { actionData, dateFormat2, deliveryListRoute, deliverySerivcesRoute, getData, getDataService, getDeliveryListsRoute, getNumber, handleClose, handleDateFormat, handleShow, inputChangeService, numberFormat, postMethod, putMethod, regionRoute, townshipRoute } from '../../services';
import { PageContext, LangContext } from '../../context';
import { TableLayout, Layout, LoadingButtonLabel } from '../../components';
import { handleCloseSearch } from '../../utils';
import { toast } from 'react-toastify';
const limit: any = process.env.REACT_APP_PAGINATION_LIMIT

const Index = () => {
    const { dispatch: { translate } } = useContext(LangContext);
    const [isFilter, setIsFilter] = useState(false);
    const [show, setShow] = useState(false);
    const [showWayBill, setShowWayBill] = useState(false);
    const [selectedSaleId, setSelectedSaleId] = useState(null);
    const [wayBill, setWayBill] = useState("");
    const [regions, setRegions] = useState([])
    const [regionCode, setRegionCode] = useState("")
    const [deliveryServices, setDeliveryServices] = useState([])
    const [townships, setTownships] = useState([])
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [searchString, setSearchString] = useState("")
    const [page, setPage] = useContext(PageContext)
    const deliveryStatus = ["All", "Pending", "Ready to Ship", "Shipped", "Delivered", "Canceled", "Lost & Damaged", "Failed Delivery", "Returned"]

    useEffect(() => {
        handleGetData(page.current, page.sorting, "")
        handleGetDeliveryServices()
        // eslint-disable-next-line
    }, [])

    const handleGetData = (skip: number, sorting: string, keyword: string) => {
        getDataService(getDeliveryListsRoute(skip, sorting, keyword), setLoading, setData, setPage, skip, sorting)
    }


    const handleGetDeliveryServices = async () => {
        getData(deliverySerivcesRoute).then(result => {
            setDeliveryServices(result.data)
        })
    }

    const [formData, setFormData] = useState(
        {
            orderBy: "desc",
            // skip: 0,
            take: limit,
            deliveryServiceId: "",
            deliveryStatusId: null,
            deliveryStatus: "",
            areaCode: "",
            subSubAreaCode: "",
            startDate: "",
            endDate: "",
        }
    )
    const [filterData, setFilterData] = useState()

    //Deli Lists Print
    const componentRef = useRef(null);
    const reactToPrintContent = useCallback(() => {
        return componentRef.current;
    }, []);
    const PrintAction = useReactToPrint({
        content: reactToPrintContent,
    });

    //Date in Filter
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const onChange = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        start && setFormData({ ...formData, startDate: handleDateFormat(start, dateFormat2), endDate: "" })
        end && setFormData({ ...formData, endDate: handleDateFormat(end, dateFormat2) })

        if (!start && !end) {
            setFormData({ ...formData, startDate: "", endDate: "" })
        }
    };

    useEffect(() => {
        handleGetRegions()
    }, [])


    const handleGetRegions = async () => {
        getData(regionRoute).then(result => {
            setRegions(result.data)
        })
    }

    const handleGetTownships = async (regionCode: string) => {
        getData(townshipRoute(regionCode)).then(result => {
            setTownships(result.data)
        })
    }

    const handleSelectRegion = (evt: any) => {
        const value = evt.target.value
        setRegionCode(value)
        handleGetTownships(value)
        setFormData({ ...formData, areaCode: evt.target.value })
    }

    // const handleChangePage = (page: number, total: number) => {
    //     setPage({ current: page, total: total })
    //     window.scrollTo(0, 0)
    // }


    const handleEnterAction = (e: any) => {
        if (e.keyCode === 13) {
            setSearchString(e.target.value)
            handleGetData(0, page.sorting, e.target.value)
        }
    }
    const handleChange = (evt: any) => {
        inputChangeService(evt, formData, setFormData);
    }

    const handleFilterAction = () => {
        if (formData.deliveryStatusId) {
            formData["deliveryStatus"] = deliveryStatus[formData.deliveryStatusId];
        } else {
            formData["deliveryStatus"] = "";
        }

        let _data: any = {}

        for (let [key, value] of Object.entries(formData)) {
            if (value !== null && value !== '' && value !== undefined && value !== "All") {
                if (key !== "deliveryStatusId") {
                    _data[key] = value
                }
            }
        }

        setFilterData(_data)
        setIsFilter(true)
        //setVariable({ ...variable, isFilter: true })

        handleClose(setShow)
        handleGetDataByFilter(0, _data)
    }

    const handleGetDataByFilter = (skip: number, _formData: any) => {
        _formData["skip"] = skip * limit;
        setLoading(true)
        actionData(postMethod, deliveryListRoute, _formData).then((result) => {
            setData(result.data)
            setPage({
                current: skip,
                total: result.count,
                sorting: 'desc',
            })
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        })
    }

    const handleChangePage = (skip: number) => {
        isFilter ? handleGetDataByFilter(skip, filterData) : handleGetData(skip, page.sorting, searchString)
    }

    const handleClearFilter = () => {
        setFormData({
            ...formData,
            orderBy: "desc",
            take: limit,
            deliveryServiceId: "",
            deliveryStatusId: null,
            deliveryStatus: "",
            areaCode: "",
            subSubAreaCode: "",
            startDate: "",
            endDate: "",
        })
        handleClose(setShow)
        setStartDate(null)
        setEndDate(null)
        handleGetData(0, page.sorting, "")
    }

    const saveWayBill = () => {
        setLoading(true)
        let formData = {
            saleOrdersId: selectedSaleId,
            wayBillNumber: wayBill
        }
        actionData(putMethod, `delivery/waybill`, formData).then((result) => {
            if (result.data) {
                toast.success("Successfully updated!")
                handleGetData(page.current, page.sorting, "")
                handleClose(setShowWayBill)
            } else {
                toast.error("Something wrong!")
                setLoading(false)
            }
        })
    }


    return (
        <Layout title={translate('deliveryList')}>
            <Count />
            <section className="d-flex justify-content-between">
                <Col md={6} className="mb-4">
                    <div className='d-flex align-items-center'>
                        <Col md={5}>
                            <InputGroup className="search-input">
                                <InputGroup.Text className="bg-primary-grad text-white">
                                    <IconSearch />
                                </InputGroup.Text>
                                <Form.Control placeholder="Search" value={searchString} onChange={(e) => setSearchString(e.target.value)} onKeyDown={(e) => handleEnterAction(e)} />
                                {searchString && <OverlayTrigger overlay={<Tooltip>Clear Search</Tooltip>} >
                                    <InputGroup.Text className="bg-danger cursor" onClick={() => handleCloseSearch(setSearchString, handleGetData)}>
                                        <IconClose />
                                    </InputGroup.Text>
                                </OverlayTrigger>}
                            </InputGroup>
                        </Col>
                        {page.total ? <span><small className='text-muted ms-3'>Result : </small>{numberFormat(page.total)}</span> : <></>}
                    </div>
                </Col>
                <section>
                    <Button variant="primary" onClick={() => handleShow(setShow)} className="me-3 bg-primary-grad">
                        <IconFilter />
                        <span className="ms-2">Filter</span>
                    </Button>
                    <section className="d-none"><Print ref={componentRef} data={data} /></section>
                    <Button onClick={PrintAction} className="bg-primary-grad"><IconPrint /> <span className="ms-2">Print</span></Button>
                </section>
            </section>

            <TableLayout loading={loading} data={data} page={page} setPage={setPage} handleGetData={handleGetData} isFilter handleChangePage={handleChangePage} noBg>
                <thead>
                    <tr>
                        <th>{translate('no.')}</th>
                        <th>Sale receipt</th>
                        {/* <th>{translate('saleNumber')}</th>
                        <th>{translate('customer')}</th> */}
                        <th>Delivery</th>
                        <th>{translate('location')}</th>
                        <th>{translate('status')}</th>
                        <th>{translate('quantity')}</th>
                        <th>Subtotal</th>
                        <th>{translate('deliveryFee')}</th>
                        <th>{translate('total')}</th>
                        <th>{translate('date')}</th>
                        <th>Way bill</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {data.map((item: any, index: number) =>
                        <tr key={index}>
                            <td className='ps-4'>{getNumber(index, page?.current)}</td>
                            {/* <td>{item.saleOrdersCode}</td>
                            <td>{item.customer.fullName}</td> */}
                            <td>
                                <span className="d-flex align-items-center">
                                    <div>
                                        <div>{item.customer.fullName}</div>
                                        <small className='text-muted my-2 d-block'>{item.saleOrdersCode}</small>
                                    </div>
                                </span>
                            </td>
                            <td>{item.deliveryService || "-"}</td>
                            <td>{item.locationName || "-"}</td>
                            <td><span className="flex-center"><Badge className={item.deliveryStatus === "Delivered" ? "bg-warning" : "bg-primary"}>{item.deliveryStatus}</Badge></span></td>
                            <td><span className="flex-end">{item.totalQty}</span></td>
                            <td><span className="flex-end">{numberFormat(item.totalAmount - item.deliveryFee)}</span></td>
                            <td><span className="flex-end">{numberFormat(item.deliveryFee)}</span></td>
                            <td><span className="flex-end">{numberFormat(item.totalAmount)}</span></td>
                            <td>{handleDateFormat(item.createdAt)}</td>
                            <td><span className="flex-center">{item.wayBillNumber || "-"}</span></td>
                            <td><span className="flex-center"> <Button className="btn-sm" onClick={() => { setSelectedSaleId(item.id); setWayBill(item.wayBillNumber || ""); handleShow(setShowWayBill) }}><IconEdit /></Button></span></td>
                        </tr>)}
                </tbody>
            </TableLayout>

            <Offcanvas show={show} onHide={() => handleClose(setShow)} placement={"end"}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Filter</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>
                        <Form.Label>{translate("date")}</Form.Label>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            onChange={onChange}
                            startDate={startDate}
                            placeholderText="Select Date"
                            className="form-control mb-4"
                            endDate={endDate}
                            selectsRange
                        />

                        <Form.Label>Delivery</Form.Label>
                        <Form.Control as="select" aria-label="Select" className='mb-4' id="deliveryServiceId" value={formData?.deliveryServiceId} onChange={handleChange}>
                            <option value="">Select</option>
                            {deliveryServices.map((item: any, index: number) =>
                                <option value={item.id} key={index}>{item.deliveryService}</option>
                            )}
                        </Form.Control>

                        <Form.Label>{translate("region")}</Form.Label>
                        <Form.Control as="select" aria-label="Select" className="mb-4" id="areaCode" value={regionCode} onChange={handleSelectRegion}>
                            <option value="">{translate("allRegion")}</option>
                            {regions.map((item: any, index: number) =>
                                <option value={item.areaCode} key={index}>{item.areaNameLocal}</option>
                            )}
                        </Form.Control>
                        {/* value={formData?.subSubAreaCode} onChange={handleChange} */}
                        <Form.Label>{translate("township")}</Form.Label>
                        <Form.Control as="select" aria-label="Select" className="mb-4" id="subSubAreaCode" value={formData?.subSubAreaCode} onChange={(e) => setFormData({ ...formData, subSubAreaCode: e.target.value })}>
                            <option value="">{translate("allTownship")}</option>
                            {townships.map((item: any, index: number) =>
                                <option value={item.subSubAreaCode} key={index}>{item.subSubAreaNameLocal}</option>
                            )}
                        </Form.Control>

                        <Form.Label>Status</Form.Label>
                        <Form.Control as="select" aria-label="Select" id="deliveryStatusId" value={formData?.deliveryStatusId || ""} onChange={handleChange} >
                            {deliveryStatus.map((item: string, index: number) => (
                                <option value={index} key={index}>{item}</option>
                            ))}
                        </Form.Control>
                    </Form>
                    <div className='d-flex  mt-4'>
                        <Button className='w-50 py-2 bg-primary-grad me-2' onClick={handleFilterAction}>Search</Button>
                        <Button className='w-50 py-2 bg-danger' onClick={handleClearFilter}>Clear filter</Button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>

            <Modal show={showWayBill} onHide={() => handleClose(setShowWayBill)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Way bill</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <Form.Group className="mb-4 input-form-control" controlId="quantity">
                        <Form.Label>Way bill number</Form.Label>
                        <Form.Control value={wayBill} onChange={(e) => setWayBill(e.target.value)} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => handleClose(setShowWayBill)}>
                        Cancel
                    </Button>
                    <Button variant="primary" disabled={loading} onClick={saveWayBill}>
                        <LoadingButtonLabel label="Save" disabled={loading} />
                    </Button>
                </Modal.Footer>
            </Modal>
        </Layout>
    );
};



export default Index;




