import { useContext, useEffect, useState } from 'react'
import { Layout, SubHeader, TableLayout, TableRowButtons, CustomModal, CustomInput } from '../../components'
import { LangContext } from '../../context/Lang'
import { dataService, deleteService, getDataService, inputChangeService, productTypesRoute, responseService } from '../../services'

const ProductTypes = () => {
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [data, setData] = useState([])
    const { dispatch: { translate } } = useContext(LangContext);
    const [formData, setFormData] = useState({
        productTypesId: "",
        langId: 68,
        publish: true,
        shippable: true,
        productType: "",
    })
    const required = ["productType"]

    useEffect(() => {
        handleGetData()
    }, [])


    const handleGetData = () => {
        getDataService(productTypesRoute, setLoading, setData)
    }

    const handleSubmit = (method: string) => {
        setLoading(true)
        dataService(method, formData, required, productTypesRoute).then(result => {
            handleResponse(result)
        })
    }

    const handleDelete = (item: any) => {
        deleteService(item.productType, { "productTypesId": item.id }, productTypesRoute, handleResponse)
    }

    const handleResponse = (result: any) => {
        responseService(result, handleGetData, handleClose, null, null, setLoading)
    }

    const handleChange = (evt: any) => {
        inputChangeService(evt, formData, setFormData);
    }


    const showForm = (item?: any) => {
        setFormData({
            productTypesId: item.id,
            langId: 68,
            publish: item.publish || true,
            shippable: item.shippable || true,
            productType: item.productType,
        })
        handleShow();
    }


    return (
        <Layout title={translate("productTypes")}>
            <SubHeader title={translate("configuration")} showForm={showForm} />

            <TableLayout loading={loading} data={data}>
                <thead>
                    <tr>
                        <th>{translate("no.")}</th>
                        <th>{translate("productType")}</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {data.map((item: any, index: any) =>
                        <tr key={index}>
                            <td><span className='flex-center'>{index + 1}</span></td>
                            <td>{item.productType}</td>
                            <td><TableRowButtons item={item} showForm={showForm} handleDelete={handleDelete} flex="flex-center" /></td>
                        </tr>
                    )}
                </tbody>
            </TableLayout>

            <CustomModal show={show} handleClose={handleClose} id={formData?.productTypesId} handleSubmit={handleSubmit} loading={loading}>
                <CustomInput label={translate("productType")} id="productType" handleChange={handleChange} value={formData?.productType} />
            </CustomModal>
        </Layout >
    )
}

export default ProductTypes