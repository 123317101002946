import swal from 'sweetalert'

export const handleDelete = () => {
  swal({
    title: 'Are you sure to delete?',
    text: 'Once deleted, you will not be able to recover this imaginary product!',
    icon: 'warning',
    buttons: [true, true],
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      swal('Poof! Your product has been deleted!', {
        icon: 'success',
        buttons: [false],
        timer: 1500,
      })
    }
  })
}

export const openUrl = (link: string) => {
  // window.open('https://facebook.com/mgmg', '_blank');
  window.open(link, '_blank')
}

//For Number Input
export const disableScroll = (e: any) => {
  e.currentTarget.blur()
}

export const handleCloseSearch = (setSearchString: any, handleGetData: any) => {
  setSearchString('')
  handleGetData(0, 'desc')
}
