import { useContext, useEffect, useState } from 'react'
import { LangContext } from '../../context/Lang'
import { dataService, deleteService, factoriesRoute, getDataService, inputChangeService, responseService } from '../../services'
import { Layout, SubHeader, CustomInput, CustomModal, TableLayout, TableRowButtons } from '../../components'

const Factories = () => {
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { dispatch: { translate } } = useContext(LangContext);
    const [data, setData] = useState([])
    const [formData, setFormData] = useState({
        factoryId: "",
        factory: "",
        location: ""
    })
    const required = ["factory"]

    useEffect(() => {
        handleGetData()
    }, [])


    const handleGetData = () => {
        getDataService(factoriesRoute, setLoading, setData)
    }

    const handleSubmit = (method: string) => {
        setLoading(true)
        dataService(method, formData, required, factoriesRoute).then(result => {
            handleResponse(result)
        })
    }

    const handleDelete = (item: any) => {
        deleteService(item.factory, { "factoryId": item.id }, factoriesRoute, handleResponse)
    }

    const handleResponse = (result: any) => {
        responseService(result, handleGetData, handleClose, null, null, setLoading)
    }

    const handleChange = (evt: any) => {
        inputChangeService(evt, formData, setFormData);
    }

    const showForm = (item?: any) => {
        setFormData({
            factoryId: item.id,
            factory: item.factory || "",
            location: item.location
        })
        handleShow();
    }

    return (
        <Layout title="Factories">
            <SubHeader title={translate("configuration")} showForm={showForm} />

            <TableLayout loading={loading} data={data}>
                <thead>
                    <tr>
                        <th>{translate("no.")}</th>
                        <th>Factory Name</th>
                        <th>{translate("location")}</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {data.map((item: any, index: any) =>
                        <tr key={index}>
                            <td><span>{index + 1}</span></td>
                            <td>{item.factory}</td>
                            <td>{item.location}</td>
                            <td><TableRowButtons item={item} showForm={showForm} handleDelete={handleDelete} flex="flex-center" /></td>
                        </tr>
                    )}
                </tbody>
            </TableLayout>

            <CustomModal show={show} handleClose={handleClose} id={formData?.factoryId} handleSubmit={handleSubmit} loading={loading}>
                <CustomInput label="Factory Name" id="factory" handleChange={handleChange} value={formData?.factory} />
                <CustomInput label="Location" id="location" handleChange={handleChange} value={formData?.location} />
            </CustomModal>
        </Layout >
    )
}

export default Factories