import { useContext } from "react";
import { Col, Row } from "react-bootstrap"
import { Link, useLocation } from "react-router-dom"
import { LangContext } from "../../../context/Lang";
import { openUrl } from "../../../utils"

function CustomerDetailInfo() {
    const { dispatch: { translate } } = useContext(LangContext);
    const location: any = useLocation();
    const data = location.state.customer

    return (
        <div>
            <section className="bg-card mb-4">
                <Row>
                    <Col md={12}>
                        <section>
                            <section className="d-flex">
                                <Col>
                                    <small className="text-muted d-block mb-1">{translate('name')}</small>
                                    <h6 className="mb-4">{data?.fullName}</h6>
                                </Col>
                                <Col>
                                    <small className="text-muted d-block mb-1">{translate('phone')}</small>
                                    <h6 className="mb-4">{data?.phone}</h6>
                                </Col>
                            </section>
                            <section className="d-flex">
                                <Col>
                                    <small className="text-muted d-block mb-1">Other phone</small>
                                    <h6 className="mb-4">{data?.phoneOther || "-"}</h6>
                                </Col>
                                <Col>
                                    <small className="text-muted d-block mb-1">Facebook Link</small>
                                    {data?.facebookUrl ? <Link to='#' onClick={() => openUrl(data?.facebookUrl)}>{data?.facebookUrl}</Link> : <h6>-</h6>}
                                </Col>
                                {/* <Col>
                                <small className="text-muted d-block mb-1">No. of Orders</small>
                                <h6 className="mb-4">10</h6>
                            </Col> */}
                            </section>
                        </section>
                    </Col>
                </Row>
            </section >
            <Row className="mb-4">
                <Col>
                    <h6>Billing address</h6>
                    <div className="bg-card">
                        <small className="text-muted d-block mb-1">{translate('region')}</small>
                        <h6 className="mb-4">{data?.billingAddresses && data?.billingAddresses.length > 0 ? data?.billingAddresses[0]?.areaNameLocal || "-" : "-"}</h6>
                        <small className="text-muted d-block mb-1">{translate('township')}</small>
                        <h6 className="mb-4">{data?.billingAddresses && data?.billingAddresses.length > 0 ? data?.billingAddresses[0]?.subSubAreaNameLocal || "-" : "-"}</h6>
                        <small className="text-muted d-block mb-1">{translate('address')}</small>
                        <h6 className="mb-4">{data?.billingAddresses && data?.billingAddresses.length > 0 ? data?.billingAddresses[0]?.address || "-" : "-"}</h6>
                        <small className="text-muted d-block mb-1">Note</small>
                        <h6 className="mb-4">{data?.billingAddresses && data?.billingAddresses.length > 0 ? data?.billingAddresses[0]?.note || "-" : "-"}</h6>
                    </div>
                </Col>
                <Col>
                    <h6>Shipping address</h6>
                    <div className="bg-card">
                        <small className="text-muted d-block mb-1">{translate('region')}</small>
                        <h6 className="mb-4">{data?.shippingAddresses && data?.shippingAddresses.length > 0 ? data?.shippingAddresses[0]?.areaNameLocal || "-" : "-"}</h6>
                        <small className="text-muted d-block mb-1">{translate('township')}</small>
                        <h6 className="mb-4">{data?.shippingAddresses && data?.shippingAddresses.length > 0 ? data?.shippingAddresses[0]?.subSubAreaNameLocal || "-" : "-"}</h6>
                        <small className="text-muted d-block mb-1">{translate('address')}</small>
                        <h6 className="mb-4">{data?.shippingAddresses && data?.shippingAddresses.length > 0 ? data?.shippingAddresses[0]?.address || "-" : "-"}</h6>
                        <small className="text-muted d-block mb-1">Note</small>
                        <h6 className="mb-4">{data?.shippingAddresses && data?.shippingAddresses.length > 0 ? data?.shippingAddresses[0]?.note || "-" : "-"}</h6>
                    </div>
                </Col>

            </Row>

        </div>
    )
}

export default CustomerDetailInfo