import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Image, Modal, Table } from "react-bootstrap";
import { IconAdd, IconClose, IconDelete, IconImage, IconUpload } from "../../../../components/Icons";
import { FileWithPath, useDropzone } from "react-dropzone"
import { actionData, deleteMethod, colorRoute, dataService, deleteUpload, getData, getDataService, getProductColorRoute, inputChangeService, postMethod, productColorRoute, putMethod, responseService, setUpload, numberFormat } from "../../../../services";
import { useLocation } from "react-router-dom";
import { ImageLoading, LoadingButtonLabel, NoData, ShowLessButton, TableLoading } from "../../../../components";
import { LangContext } from "../../../../context/Lang";
import Lightbox from 'react-image-lightbox';
import { toast } from 'react-toastify'
import swal from 'sweetalert'
const RES_API_URL = process.env.REACT_APP_RES_API_URL

interface Target {
    id?: number
    fileName?: string
    colorName?: string
    image?: string
}

const Colors = ({ handleRefresh }: { handleRefresh: any }) => {
    const [colors, setColors] = useState([])
    const { dispatch: { translate } } = useContext(LangContext);
    const [productColors, setProductColors] = useState([])
    const [loading, setLoading] = useState(false)
    const [imageUploadLoading, setImageUploadLoading] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [target, setTarget] = useState<Target>();
    const [showAddColor, setShowAddColor] = useState(false);
    const handleCloseAddColor = () => setShowAddColor(false);
    const handleShowAddColor = () => setShowAddColor(true);
    const [showImageModal, setShowImageModel] = useState(false);
    const handleCloseImageModal = () => setShowImageModel(false);
    const handleShowImageModal = () => setShowImageModel(true);
    // const [files, setFiles] = useState<any[]>([])
    const [uploadedFiles, setUploadedFiles] = useState([])
    const location: any = useLocation();
    const product = location.state.item
    const [itemsToShow, setItemsToShow] = useState(5);
    const tempUser: any = localStorage.getItem("USER")
    const user = tempUser && JSON.parse(tempUser)
    const [formData, setFormData] = useState({
        colorId: null,
        productsId: product.id,
        attrValuesId: null,
        attrValue: "",
        imageUrl: ""
    })

    const required = ["attrValuesId"]

    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/jpeg, image/png",
        multiple: false,
        onDrop: (acceptedFiles: FileWithPath[]) => {
            let temp: any = []
            setImageUploadLoading(true)
            temp = acceptedFiles.map(
                (file: any) => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
            )
            setTimeout(() => {
                let tempFormData = new FormData()
                temp && temp.map((file: any) => (tempFormData.append("files", file, file.name)))
                setUpload(tempFormData).then(data => {
                    //setFiles(temp);
                    setUploadedFiles(data)
                    setFormData({ ...formData, imageUrl: JSON.stringify(data) })
                    setImageUploadLoading(false)
                    // let uploadedFilesArray = data.concat(uploadedFiles)
                    // setUploadedFiles(uploadedFilesArray)
                    // setAddedFiles(uploadedFilesArray)
                    // setLoading(false)
                })
            }, 1000)
        }
    })



    useEffect(() => {
        handleGetAttributes()
        handleGetData()
        // eslint-disable-next-line
    }, [])

    const handleDeleteUploaded = async (file: any, i: any) => {
        deleteUpload({ file: file }).then(data => {
            setUploadedFiles([])
            setFormData({ ...formData, imageUrl: "" })
            handleClose()

            if (formData.colorId) {
                let tempFormData = {
                    colorId: formData.colorId,
                    productsId: product.id,
                    attrValuesId: formData.attrValuesId,
                    imageUrl: ""
                }

                dataService(putMethod, tempFormData, required, productColorRoute, true).then(result => {
                    handleResponse(result)
                })
            }
        })
    }


    const handleGetAttributes = async () => {
        getData(colorRoute).then(result => {
            setColors(result.data.values)
        })
    }

    const handleGetData = () => {
        getDataService(getProductColorRoute(product.id), setLoading, setProductColors)
    }

    const toggle = (fileName: string, id: number,) => {
        setTarget({ id: id, fileName: fileName });
        handleShow();
    };


    const uploadedThumbs = uploadedFiles.map((file: any, i) => (
        <div className="image-area rounded" key={file.fileName} >
            <img src={`${RES_API_URL}${file.url}`} className="thumb-img"
                alt={file.fileName}
                key={file.fileName} />
            <button className="btn remove-image" onClick={() => toggle(file.fileName, i)} type="button"><IconClose /></button>
        </div>
    ))


    const handleChange = (evt: any) => {
        inputChangeService(evt, formData, setFormData);
    }

    const handleSubmit = (method: string) => {
        setLoading(true)
        dataService(method, formData, required, productColorRoute, method === putMethod).then(result => {
            handleResponse(result)
        })
    }

    const handleDelete = (item: any) => {
        const fileName = item.imageUrl ? item.imageUrl[0].fileName : item.imageUrl;
        swal({
            title: `Are you sure to delete?`,
            text: `Once deleted, you will not be able to recover ${item.attrValue}!`,
            icon: 'warning',
            buttons: [true, true],
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                response(await actionData(deleteMethod, productColorRoute, { "colorId": item.id }), fileName)
            }
        })
    }

    const response = (result: any, fileName?: string) => {
        if (result?.success) {
            toast.success(result?.message)
            fileName && deleteUpload({ file: fileName });
            handleRefresh()
            handleGetData()
        } else {
            toast.error(result?.message)
        }
    }


    const handleResponse = (result: any) => {
        setUploadedFiles([])
        handleRefresh()
        responseService(result, handleGetData, handleCloseAddColor, null, null, setLoading)
    }

    const showForm = (item?: any) => {
        if (item.id && item.imageUrl) {
            let tempImages = item.imageUrl
            if (tempImages.length > 0) {
                setUploadedFiles(tempImages)
            }
        } else {
            setUploadedFiles([])
        }

        setFormData({
            colorId: item.id || null,
            productsId: product.id,
            attrValue: item.attrValue || null,
            attrValuesId: item.attrValuesId || null,
            imageUrl: item.imageUrl || ""
        })
        handleShowAddColor();
    }

    const colorImage = (item: any) => {
        let tempImages = item.imageUrl
        let tempDiv: any = "-";
        let imageUrl = tempImages[0].url;

        if (tempImages.length > 0) {
            tempDiv = <section className="product-card-small-image cursor">
                <Image src={`${RES_API_URL}${imageUrl}`}
                    onClick={() => {
                        setTarget({ colorName: item.attrValue, image: tempImages[0].url });
                        handleShowImageModal()
                    }} />
            </section>
        }

        return tempDiv;
    }


    return (
        <Col md={12}>
            {showImageModal &&
                <Lightbox
                    mainSrc={`${RES_API_URL}${target?.image}`}
                    imageTitle={product.productName}
                    imageCaption={`${translate('color')} - ${target?.colorName}`}
                    onCloseRequest={handleCloseImageModal}
                />}

            <section className="bg-card mb-5">
                <section className="flex-between mb-4">
                    <h6>{translate('colors')} {productColors.length > 0 && `(${productColors.length})`}</h6>
                    {(user?.userRole === "Owner" || user?.userRole === "Admin") && <Button className="text-white bg-primary-grad" onClick={showForm}><IconAdd />  <span className="ms-2">{translate('createNew')}</span></Button>}
                </section>

                {loading ?
                    <TableLoading rowCount={5} />
                    :
                    <>
                        {productColors.length > 0 ?
                            <>
                                <Table responsive className="mb-4" striped>
                                    <thead>
                                        <tr>
                                            <th className="ps-4">#</th>
                                            <th>Code</th>
                                            <th>{translate('color')}</th>
                                            <th>{translate('qty')} ({translate('in')})</th>
                                            <th>{translate('qty')} ({translate('out')})</th>
                                            <th>{translate('qty')} ({translate('remain')})</th>
                                            <th>Image</th>
                                            {/* <th>Detail</th>
                                            <th><span className="d-flex justify-content-end">Stock</span></th> */}
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {productColors.slice(0, itemsToShow).map((item: any, index: any) =>
                                            <tr key={index}>
                                                <td className="ps-4">{index + 1}</td>
                                                <td>C{item.attrValuesId}</td>
                                                <td>
                                                    <section className="d-flex align-items-center">
                                                        {item.attrValue}
                                                    </section>
                                                </td>
                                                <td>{numberFormat(item.stockIn)}</td>
                                                <td>{numberFormat(item.stockOut)}</td>
                                                <td>{numberFormat(item.stockRemain)}</td>
                                                <td>
                                                    {item.imageUrl ? colorImage(item) : "-"}
                                                </td>
                                                {/* <td><Button className="btn-sm ms-2" onClick={handleShowWarehouse}><IconFile /></Button></td>
                                                <td><span className="d-flex justify-content-end">900 </span></td> */}
                                                <td>
                                                    {(user?.userRole === "Owner" || user?.userRole === "Admin") && <span className="d-flex justify-content-end">
                                                        <Button className="btn-sm ms-2" onClick={() => showForm(item)}><IconImage /></Button>
                                                        <Button className="btn-danger btn-sm ms-2" onClick={() => handleDelete(item)}>
                                                            <IconDelete />
                                                        </Button>
                                                    </span>}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                                {productColors.length > 5 && <ShowLessButton length={productColors.length} itemsToShow={itemsToShow} setItemsToShow={setItemsToShow} />}
                            </>
                            :
                            <NoData noImage />
                        }
                    </>
                }
                {/* <section className="d-flex justify-content-end mt-4 me-1">
                        <h6 >Total Stock : 1,800</h6>
                    </section> */}
            </section>



            <Modal show={showAddColor} onHide={handleCloseAddColor} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{product.productName} (#{product.productCode})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {formData.colorId && <small>{translate('color')} : {formData.attrValue}</small>}

                    {!formData.colorId && <Form.Group className="input-form-control" controlId="attrValuesId">
                        <Form.Label>{translate('color')} *</Form.Label>
                        <Form.Control as="select" aria-label="Select" onChange={handleChange} value={formData?.attrValuesId || ""}>
                            <option value="">Select</option>
                            {colors.map((item: any, index: number) =>
                                <option value={item.id} key={index}>{item.attrValue} (C{item.id})</option>
                            )}
                        </Form.Control>
                    </Form.Group>}


                    {uploadedFiles.length > 0 ? (<div className="d-flex justify-content-center  mt-4">
                        {uploadedThumbs}
                    </div>) : <div {...getRootProps({ className: " mb-3" })} >
                        <input {...getInputProps()} />
                        <div className="d-flex justify-content-center">
                            {imageUploadLoading ? <ImageLoading /> : <Button className="text-white mt-3"><IconUpload /> Upload Image</Button>}
                        </div>
                    </div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleCloseAddColor}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => handleSubmit(formData.colorId ? putMethod : postMethod)} disabled={loading}>
                        <LoadingButtonLabel label="Save" disabled={loading} />
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Deleting Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure to delete image?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => handleDeleteUploaded(target?.fileName, target?.id)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </Col>

    );
};

export default Colors;
