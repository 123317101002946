import { useContext, } from 'react';
import { useHistory, } from 'react-router-dom';
import { IconLeftArrow } from '../../components/Icons';
import Layout from '../../components/Layout'
import { LangContext } from '../../context/Lang';
import CustomerDetailInfo from './components/CustomerDetailInfo';
import CustomerOrders from './components/CustomerOrders';
import CustomerSales from './components/CustomerSales';

const Detail = () => {
    const history = useHistory();
    const { dispatch: { translate } } = useContext(LangContext);


    return (
        <Layout title='Cusomer Detail'>
            <section className="mb-4 cursor" onClick={() => history.goBack()}>
                <p className="mb-0"><IconLeftArrow /> {translate('customers')}</p>
            </section>

            <CustomerDetailInfo />
            <CustomerOrders />
            <CustomerSales />
        </Layout>
    )
}

export default Detail