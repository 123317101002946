import { Button, Col, Image, Navbar, NavDropdown, Table } from 'react-bootstrap'
import { IconAdd, IconDelete, IconEdit, IconLeftArrow, IconLock, IconMenu, IconPerson, IconSignOut, IconSortDown, IconSortUp } from './Icons'
import { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { VariableContext, LangContext } from '../context'
import Sidebar from './Sidebar'
import { ToastContainer } from "react-toastify"
import { Route, Redirect } from 'react-router-dom'
import { sortingService, isLogin, removeLocalForUser, getData } from '../services'
import QRCode from 'qrcode';
import Receipt from './Receipt';
import { TableLoading, ImageLoading, CustomerLoading, DetailLoading, OrderDetailLoading, EditOrderDetailLoading, ProductGridLoading, AddOrderLoading, ProductListLoading } from "./Loading"
import { PgComponent } from "./Pagination"
import { CustomInput, CustomSelect, CustomModal } from "./Custom"
var cn = require('classnames');
const limit: any = process.env.REACT_APP_PAGINATION_LIMIT
const RES_API_URL = process.env.REACT_APP_RES_API_URL

export { Receipt, TableLoading, ImageLoading, CustomerLoading, DetailLoading, OrderDetailLoading, EditOrderDetailLoading, ProductGridLoading, AddOrderLoading, ProductListLoading, PgComponent, CustomInput, CustomSelect, CustomModal }

export const OwnerRoute = ({ component: Component, ...rest }: any) => {
    const tempUser: any = localStorage.getItem("USER")
    const user = tempUser && JSON.parse(tempUser)

    return (
        <Route {...rest} render={props => (
            isLogin() ?
                (user?.userRole === "Owner" || user?.userRole === "Admin") ?
                    <Component {...props} /> :
                    <Redirect to="/not-found" />
                : <Redirect to="/sign-in" />
        )} />
    )
}

export const PublicRoute = ({ component: Component, restricted, ...rest }: any) => {
    const tempUser: any = localStorage.getItem("USER")
    const user = tempUser && JSON.parse(tempUser)

    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route {...rest} render={props => (
            isLogin() ?
                <Redirect to={user?.userRole === "Owner" || user?.userRole === "Admin" ? "/dashboard" : "/orders"} />
                : <Component {...props} />
        )} />
    )
}

export const PrivateRoute = ({ component: Component, ...rest }: any) => {
    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            isLogin() ?
                <Component {...props} />
                : <Redirect to="/" />
        )} />
    )
}

export function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, [pathname]);
    return null;
}

export function Layout({ children, title, noPadding = false }: { children: any, title: string, noPadding?: boolean }) {
    return (
        <section className="d-flex">
            <ToastContainer autoClose={1000} />
            <Sidebar />

            <section className="w-100">
                <Header title={title} />
                <section className={!noPadding ? "p-4" : ""}>
                    {children}
                </section>
            </section>
        </section>
    )
}

export function Header({ title }: { title: string }) {
    const [variable, setVariable] = useContext(VariableContext)
    const { state: { language }, dispatch: { setLanguage } } = useContext(LangContext);

    const chooseLanguageHandler = (value: string) => {
        setLanguage(value);
    }

    const toggle = () => {
        setVariable({ ...variable, isSidebarActive: !variable?.isSidebarActive })
    }

    return (
        <Navbar className="header bg-white shadow-sm px-4  d-flex justify-content-between">
            <div className="d-flex align-items-center">
                <span className="primary-text fs-10 me-3 cursor" onClick={toggle}><IconMenu /></span>
                <h5 className="m-0 fw-bold">{title}</h5>
            </div>
            <div className="d-flex align-items-center">
                {language === "EN" ? <label className="cursor" onClick={() => chooseLanguageHandler('MM')}><Image src={"images/lang/mm.svg"} width="25" className="me-2" /></label>
                    :
                    <label className="cursor" onClick={() => chooseLanguageHandler('EN')}><Image src={"images/lang/eng.svg"} width="25" className="me-2" /></label>
                }

                <AvatarDropdown />
            </div>
        </Navbar>
    );
}

export const SubHeader = ({ title, showForm, }: { title: string, showForm: any }) => {
    const history = useHistory()
    const { dispatch: { translate } } = useContext(LangContext);

    return (
        <section className="flex-between mb-4">
            <section className="cursor" onClick={() => history.goBack()}>
                <p className="mb-0"><IconLeftArrow /> {title}</p>
            </section>
            <Button className="text-white bg-primary-grad" onClick={showForm}><IconAdd /> <span className="ms-2">{translate("createNew")}</span></Button>
        </section>
    )
}

export function AvatarDropdown() {
    const [showmenu, setShowmenu] = useState(false)
    const history = useHistory()
    const tempUser: any = localStorage.getItem("USER")
    const user = tempUser && JSON.parse(tempUser)
    const [variable, setVariable] = useContext(VariableContext)

    useEffect(() => {
        handleGetData()
        // eslint-disable-next-line
    }, [])

    const handleGetData = () => {
        getData(`profile?userId=${user?.id}`).then(result => {
            let temp = result?.data
            setVariable({ ...variable, username: temp?.fullName, avatar: temp?.avatar })
        })
    }

    const handleLogout = () => {
        removeLocalForUser();
        history.push('/');
    }

    return (
        <section className="avatar-dropdown ms-2">
            <NavDropdown
                title={
                    <section className="d-flex">
                        <span className="header-avatar">  <Image src={variable?.avatar ? `${RES_API_URL}/assets/avatar/${variable?.avatar}` : "images/avatar.jpeg"} /> </span>
                        <section className="ms-3">
                            <p className="fw-bold mb-1">{variable?.username}</p>
                            <small className="text-muted mb-2">{user?.userRole}</small>
                        </section>
                    </section>}
                show={showmenu}
                onMouseEnter={() => setShowmenu(true)}
                onMouseLeave={() => setShowmenu(false)}
            >
                <section className="p-2 d-flex align-items-center flex-column">
                    <Image src={variable?.avatar ? `${RES_API_URL}/assets/avatar/${variable?.avatar}` : "images/avatar.jpeg"} className="mt-2" />
                    <p className="fw-bold mt-2 mb-1">{variable?.username}</p>
                    <small className="text-muted mb-2">{user?.userRole}</small>
                </section>
                <NavDropdown.Item href="/profile" ><span className="text-primary me-2"><IconPerson size={16} /></span>Profile</NavDropdown.Item>
                <NavDropdown.Item href="/change-password"><span className="text-primary me-2"><IconLock /></span>Change Password</NavDropdown.Item>
                <NavDropdown.Item className="last-item" onClick={handleLogout}><span className="text-primary me-2"><IconSignOut /></span>Logout</NavDropdown.Item>
            </NavDropdown>
        </section>
    )
}

export const NoData = ({ title = "Data", noImage = false }: { title?: string, noImage?: boolean }) => {
    return (
        <section className='d-flex align-items-center flex-column '>
            {!noImage && <Image src={"images/no_data.png"} width={200} className="mt-5" />}
            <p className="fs-6 mb-0 text-success">No {title} Found!</p>
        </section>
    )
}


export const TableLayout = ({ loading, data, children, page, setPage, handleGetData, isFilter = false, handleChangePage, noBg = false, paginationLimit = 30 }: { loading: boolean, data: any, children: any, page?: any, setPage?: any, handleGetData?: any, isFilter?: boolean, handleChangePage?: any, noBg?: boolean, paginationLimit?: number }) => {
    return (
        <>
            {loading ?
                <TableLoading />
                :
                <>
                    {data.length > 0 ?
                        <section className={noBg ? "" : "bg-card mb-4"}>
                            <Table responsive className={(page && page.total > limit) ? "mb-4 center-table" : "mb-0 center-table"} striped bordered>
                                {children}
                            </Table>

                            {(page && page.total > limit) && <PgComponent
                                currentPage={page.current + 1 || 0}
                                fromCount={page.current || 0}
                                total={page.total || 0}
                                paginationLimit={paginationLimit || limit}
                                pageInfo={page}
                                setPage={setPage}
                                handleGetData={handleGetData}
                                isFilter={isFilter}
                                handleChangePage={handleChangePage}
                            />}
                        </section>
                        :
                        <NoData noImage />
                    }
                </>
            }
        </>
    )
}

export const TableRowButtons = ({ item, showForm, handleDelete, flex = "flex-end" }: { item: any, showForm: any, handleDelete: any, flex?: string }) => (
    <span className={flex}>
        <Button className="btn-sm bg-grad ms-2" onClick={() => showForm(item)}><IconEdit /></Button>
        <Button className="bg-danger btn-sm ms-2" onClick={() => handleDelete(item)}> <IconDelete /> </Button>
    </span>
)


export function NotFound() {
    return (
        <div className="center-container">
            <h1 className="text-success">
                Page not found!
            </h1>
        </div>
    )
}

export const SortingButton = ({ page, setPage, handleGetData, handleGetDataByFilter, filterActionData }: { page: any, setPage: any, handleGetData: any, handleGetDataByFilter?: any, filterActionData?: any }) => {
    return <Button className="text-white bg-primary-grad" onClick={() => sortingService(page, setPage, handleGetData, handleGetDataByFilter, filterActionData)}>{page.sorting === "asc" ? <IconSortDown /> : <IconSortUp />}</Button>
}

export const ShowLessButton = ({ length, itemsToShow, setItemsToShow }: { length: number, itemsToShow: number, setItemsToShow: any }) => {
    const showmore = () => {
        setItemsToShow(length)
    }

    const showless = () => {
        setItemsToShow(5)
    }

    return <>{(itemsToShow === 5) ? <Button className='bg-primary-grad' onClick={showmore}>Show More</Button> : <Button className='bg-primary-grad' onClick={showless}>Show Less</Button>}</>
}

export const LoadingButtonLabel = ({ label, disabled }: { label: string, disabled: boolean }) => {
    return (
        <>
            {!disabled ? label : <div> <div className="spinner-grow spinner-grow-sm text-white" />  Processing . . .</div>}
        </>
    )
}

export const Checkbox = ({ label, handleClick, isChecked }: { label: string, handleClick: any, isChecked: any }) => {
    return (
        <div className="form-check">
            <input type="checkbox" className="form-check-input cursor" id={label} onChange={handleClick} checked={isChecked} />
            <label className="form-check-label cursor" htmlFor={label}>{label}</label>
        </div>
    );
};

export const QRView = ({ text, size }: { text: string, size: number }) => {
    let tempDiv = <></>
    QRCode.toDataURL(text, function (err, url) {
        if (err) console.log('error: ' + err)
        tempDiv = <Image src={url} alt="no_found" width={size} height={size} />
    })
    return tempDiv
}

export const Lang = ({ label }: { label: string }) => {
    const { dispatch: { translate } } = useContext(LangContext)
    return <>{translate(label)}</>
}

export const InfoCard = ({ id, label, value, icon, color, state, cardAction, colCount = 3 }: { id: string, label: string, value: number, icon: any, color: string, state?: string, cardAction?: any, colCount?: number }) => (
    <section className={cn("info-count-count mb-4", state === id && "count-active")} onClick={cardAction}>
        <div className={cn("avatar-lg rounded border", `bg-soft-${color}`, `border-${color}`)}>
            {icon}
        </div>

        <div className="text-end">
            <h4 className="fw-bold mb-1"><span>{value || "-"}</span></h4>
            <small className="text-muted">{label}</small>
        </div>
    </section>
)

export const InfoTable = ({ data, notes }: { data: any, notes?: string }) => (
    <Col>
        <Table responsive striped className="mb-0 border">
            <tbody>
                {data.map((item: any, index: number) => (!item.hide) && (
                    <tr key={index}>
                        <td className="text-muted" style={{ width: "40%" }}>{item?.label}</td>
                        <td style={{ width: "60%" }}>{item?.value}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    </Col>
)
















