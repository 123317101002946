import { useContext, useState } from "react";
import { Button, Col, Modal, Form, Image, ButtonGroup, Table, Row, Container, } from "react-bootstrap";
import { toast } from "react-toastify";
import { AddOrderLoading, CustomSelect } from "../../../../components";
import { LangContext, OrderContext, VariableContext } from "../../../../context";
import { disableScroll, getData, getProductSKUSuggestionRoute, getProductSuggestionRoute, getSubtotolInOrderItem, getVariantByCode, getVariantBySKU, handleChangeQuantity, numberFormat } from "../../../../services";
import AddFreeOrderByProduct from "./AddFreeOrderByProduct";
const RES_API_URL = process.env.REACT_APP_RES_API_URL

const AddFreeOrderItemModal = ({ show, handleClose }: { show: boolean, handleClose: any }) => {
    const [variable, setVariable] = useContext(VariableContext)
    const [orderItems, setOrderItems] = useContext(OrderContext)
    const { dispatch: { translate } } = useContext(LangContext);
    const [variant, setVariant] = useState<any>()
    const [variants, setVariants] = useState([])
    const [product, setProduct] = useState<any>()
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(false)
    const [isRetail, setChangeRetail] = useState(true)
    const [tempOrderItems, setTempOrderItems] = useState([])
    const [tempOrderItemsByProduct, setTempOrderItemsByProduct] = useState([])
    const [finalOrderItemsByProduct, setFinalOrderItemsByProduct] = useState([])


    let changeActionSKU = (isSKU: boolean) => {
        setVariant(null)
        setProduct(null)
        setTempOrderItems([])
        setTempOrderItemsByProduct([])
        setVariable({ ...variable, isSKU: isSKU })
    }

    const variantInputChange = (keyword: string) => {
        getData(getProductSKUSuggestionRoute(keyword, "Wholesale")).then(result => {
            let temp: any = []

            result.data.forEach((item: any) => {
                temp.push({
                    value: item.sku,
                    label: `${item.sku} (${item.productName})`,
                    sku: item.sku,
                })
            })
            setVariants(temp)
        })
    }

    const getVariantDetail = (data: any) => {
        if (data) {
            setLoading(true)
            setChangeRetail(true)
            getData(getVariantBySKU(data.sku)).then(result => {
                let temp: any = []
                let tempVariant = result.data
                setVariant(result.data);
                temp.push({
                    productVariantsId: tempVariant.productVariant.id,
                    productName: tempVariant.productName,
                    productSKU: tempVariant.productVariant.sku,
                    productSize: tempVariant.productVariant.productSizes,
                    productColor: tempVariant.productVariant.productColors,
                    stockCount: tempVariant.productVariant.stockCount,
                    quantity: tempVariant.productVariant.stockCount > 0 ? 1 : "",
                    unitPrice: 0,
                    retailPrice: tempVariant.productVariant.retailPrice,
                    wholesalePrice: tempVariant.productVariant.wholesalePrice,
                    orderType: "Retail"
                })
                setTempOrderItems(temp)
                setTimeout(() => {
                    setLoading(false)
                }, 500)
            })
        } else {
            setVariant(null)
        }
    }

    const productInputChange = (keyword: string) => {
        getData(getProductSuggestionRoute(keyword)).then(result => {
            let temp: any = []

            result.data.forEach((item: any) => {
                temp.push({
                    value: item.productCode,
                    label: `${item.productCode} (${item.productName})`,
                    productCode: item.productCode,
                })
            })
            setProducts(temp)
        })
    }

    const getProductDetail = (data: any) => {
        if (data) {
            setLoading(true)
            data && getData(getVariantByCode(data.productCode, "Wholesale")).then(result => {
                let temp: any = []
                let variants = result.data.productVariants
                setProduct(result.data);

                if (variants.length > 0) {
                    variants.forEach((item: any) => {
                        temp.push({
                            productVariantsId: item.id,
                            productName: result.data.productName,
                            productSKU: item.sku,
                            productSize: item.productSizes,
                            productColor: item.productColors,
                            stockCount: item.stockCount,
                            quantity: "",
                            unitPrice: 0,
                            retailPrice: item.retailPrice,
                            wholesalePrice: item.wholesalePrice,
                            isCheck: false,
                            orderType: "Retail"
                        })
                    })
                }

                setTempOrderItemsByProduct(temp)
                setTimeout(() => {
                    setLoading(false)
                }, 500)
            })
        } else {
            setVariant(null)
        }
    }

    const variantProductImage = (imageUrl: any) => {
        let tempDiv = <Image src={'images/noimage.png'} width={240} height={240} rounded />;
        if (imageUrl && imageUrl.length > 0) {
            tempDiv = <Image src={`${RES_API_URL}${imageUrl[0].url}`} alt={imageUrl[0].url} width={240} height={240} rounded />
        }
        return tempDiv;
    }


    const changeRetailPrice = (price: number, isRetail: boolean) => {
        let temp: any = tempOrderItems;
        temp.forEach((data: any, index: number) => {
            temp[index].unitPrice = price
            temp[index].orderType = isRetail ? "Retail" : "Wholesale"
        });
        setTempOrderItems(temp)
    }

    const finalAddOrderItemsAction = () => {
        if (variable?.isSKU) {
            if (variant) {
                let isExist = false

                tempOrderItems.forEach((item: any) => {
                    orderItems.forEach((subItem: any) => {
                        if (item.productSKU === subItem.productSKU) isExist = true
                    })
                })

                if (!isExist) {
                    if (tempOrderItems[0]['quantity'] > 0) {
                        let temp: any = [];
                        orderItems?.forEach((item: any) => temp.push(item));
                        tempOrderItems?.forEach((item: any) => temp.push(item));
                        setOrderItems(temp);
                        setVariant(null)
                        setTempOrderItems([])
                        handleClose()
                    } else {
                        toast.error("Please add quantity at least!");
                    }
                } else {
                    toast.error("Already added order item!");
                }
            } else {
                setTempOrderItems([])
                toast.error("Choose variant at least!");
            }
        } else {
            if (finalOrderItemsByProduct.length > 0) {
                let isExist = false
                let findEmptyQuantity = false

                finalOrderItemsByProduct.forEach((item: any) => {
                    orderItems.forEach((subItem: any) => {
                        if (item.productSKU === subItem.productSKU) isExist = true
                    })

                    if (item.quantity <= 0) findEmptyQuantity = true
                })


                if (!isExist) {
                    if (!findEmptyQuantity) {
                        let temp: any = [];
                        orderItems?.forEach((item: any) => temp.push(item));
                        finalOrderItemsByProduct?.forEach((item: any) => temp.push(item));
                        setOrderItems(temp);
                        setFinalOrderItemsByProduct([])
                        setTempOrderItemsByProduct([])
                        setProduct(null)
                        handleClose()
                    } else {
                        toast.error("Empty quantity found!");
                    }
                } else {
                    toast.error("Already added order item!");
                }
            } else {
                toast.error("Choose variant at least one!");
            }
        }
    }

    const addFinalItemByProdyct = (items: any) => {
        setFinalOrderItemsByProduct(items)
    }

    const closeModal = () => {
        handleClose()
        setVariant(null)
        setProduct(null)
    }


    return (
        <Modal show={show} onHide={closeModal} fullscreen>
            <Modal.Header closeButton>
                <Modal.Title>Add free item ({translate('wholesale')})</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <section className="d-flex mb-4 mt-3 justify-content-center">
                    <Col md={6}>
                        {variable?.isSKU ?
                            <CustomSelect label="Search by sku" data={variants} selectAction={getVariantDetail} handleSelectInputChange={variantInputChange} />
                            :
                            <CustomSelect label="Search by product code" data={products} selectAction={getProductDetail} handleSelectInputChange={productInputChange} />
                        }
                    </Col>
                    <ButtonGroup>
                        <Button
                            onClick={() => changeActionSKU(true)}
                            className={variable?.isSKU ? "text-white" : "bg-white text-primary"}>SKU</Button>
                        <Button
                            onClick={() => changeActionSKU(false)}
                            className={variable?.isSKU ? "bg-white text-primary" : "text-white"}
                        >Code</Button>
                    </ButtonGroup>
                </section>

                {loading ? <AddOrderLoading /> : <>
                    {
                        variable?.isSKU ?
                            <>
                                {variant ?
                                    <>
                                        <Container>
                                            <Row className="d-flex align-items-center mb-2">
                                                <Col md={3} className="d-flex justify-content-end">
                                                    {variantProductImage(variant.productVariant.imageUrl)}
                                                </Col>
                                                <Col md={4}>
                                                    <Table responsive className="mb-0" striped>
                                                        <tbody>
                                                            <tr>
                                                                <td className="ps-4 text-muted">Product name</td>
                                                                <td>{variant.productName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="ps-4 text-muted">SKU</td>
                                                                <td>{variant.productVariant.sku}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="ps-4 text-muted">Size</td>
                                                                <td>{variant.productVariant.productSizes}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="ps-4 text-muted">{translate('color')}</td>
                                                                <td>{variant.productVariant.productColors}</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                                <Col md={4}>
                                                    <Table responsive className="mb-0" striped>
                                                        <tbody>
                                                            <tr>
                                                                <td className="ps-4 text-muted">Quantity in Package</td>
                                                                <td>{variant.qtyInPackage || "-"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="ps-4 text-muted">Minimum wholesale quantity</td>
                                                                <td>{variant.minWholesaleMoq || "-"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="ps-4 text-muted">{translate('retailPrice')}</td>
                                                                <td>{numberFormat(variant.productVariant.retailPrice)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="ps-4 text-muted">{translate('wholesalePrice')}</td>
                                                                <td>{numberFormat(variant.productVariant.wholesalePrice)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>

                                            <Table responsive className="mb-4" striped>
                                                <thead>
                                                    <tr>
                                                        <th className="ps-4">Size</th>
                                                        <th>{translate('color')}</th>
                                                        <th><span className="d-flex justify-content-end">{translate('stock')}</span></th>
                                                        <th><span className="d-flex justify-content-end">{translate('quantity')}</span></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* <tr className="cursor">
                                                    <td className="ps-4">{variant.productVariant.productSizes || "-"}</td>
                                                    <td>{variant.productVariant.productColors || "-"}</td>
                                                    <td><span className="d-flex justify-content-end">{numberFormat(variant.productVariant.stockCount) || "0"}</span></td>
                                                    <td>
                                                        <span className="d-flex justify-content-center align-items-center"><Col md={5} className="me-2"><Form.Control type="number" min="0" onWheel={disableScroll} /></Col>
                                                            * {isRetail ? numberFormat(variant.productVariant.retailPrice) : numberFormat(variant.productVariant.wholesalePrice)}</span></td>
                                                    <td><span className="d-flex justify-content-end">0</span></td>
                                                </tr> */}
                                                    {tempOrderItems.length > 0 && tempOrderItems.map((item: any, index: number) => (
                                                        <tr key={index}>
                                                            <td className="ps-4">{item.productSize || "-"}</td>
                                                            <td>{item.productColor || "-"}</td>
                                                            <td><span className="d-flex justify-content-end">{numberFormat(item.stockCount) || "0"}</span></td>
                                                            <td>
                                                                <span className="d-flex justify-content-end align-items-center">
                                                                    <Col md={6}>
                                                                        <Form.Control
                                                                            type="number"
                                                                            min="0"
                                                                            onWheel={disableScroll}
                                                                            value={item.quantity}
                                                                            onChange={(e: any) => handleChangeQuantity(e.target.value, item.productSKU, item.stockCount, tempOrderItems, setTempOrderItems)}
                                                                            max={item.stockCount} />
                                                                    </Col>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Container>


                                    </> :
                                    <div className="d-flex flex-column align-items-center mt-5">
                                        <Image src="images/search.png" width={200} />
                                        <p className="text-muted">Please find variant by sku code or scan qr!</p>
                                    </div>
                                }
                            </>
                            :
                            <>
                                {product ? <AddFreeOrderByProduct product={product} variants={tempOrderItemsByProduct} handleAdd={addFinalItemByProdyct} /> : <div className="d-flex flex-column align-items-center mt-5">
                                    <Image src="images/search.png" width={200} />
                                    <p className="text-muted">Please find product by prduct code!</p>
                                </div>}
                            </>
                    }
                </>}
            </Modal.Body>

            <Modal.Footer>
                <Button variant="success" onClick={closeModal}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={finalAddOrderItemsAction}>
                    Add
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddFreeOrderItemModal;
