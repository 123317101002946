import { useContext, useEffect, useState } from "react"
import { Layout } from "../../components"
import { LangContext, PageContext, VariableContext } from "../../context"
import { actionData, getDataService, getProductsRoute, postMethod, productFilterRoute, responseService } from "../../services"
import { ProductContent, ProductHeader } from "./components"
const limit: any = process.env.REACT_APP_PAGINATION_LIMIT


function Products() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const { dispatch: { translate } } = useContext(LangContext);
    const [page, setPage] = useContext(PageContext)
    const [variable] = useContext(VariableContext)

    useEffect(() => {
        let productCode = variable?.productSearchType === "productCode" ? variable?.searchString : null
        let productName = variable?.productSearchType !== "productCode" ? variable?.searchString : null

        variable?.isProductFilter ? handleGetDataByFilter(page.current, variable?.productFilterActionData) : handleGetData(page.current, page.sorting, productName, productCode)
        // eslint-disable-next-line
    }, [])

    const handleGetData = (skip: number, sorting: string, productName?: string, productCode?: string) => {
        getDataService(getProductsRoute(skip, sorting, productName, productCode), setLoading, setData, setPage, skip, sorting)
    }


    const handleGetDataByFilter = (skip: number, _formData: any) => {
        _formData["skip"] = skip * limit;
        setLoading(true)
        actionData(postMethod, productFilterRoute, _formData).then((result) => {
            setData(result.data)
            setPage({
                current: skip,
                total: result.count,
                sorting: _formData.orderBy,
            })
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        })
    }

    const handleResponse = (result: any) => {
        responseService(result, handleGetData, null, page.current)
    }

    return (
        <Layout title={translate('products')}>
            <ProductHeader page={page} setPage={setPage} handleGetData={handleGetData} handleGetDataByFilter={handleGetDataByFilter} />
            <ProductContent loading={loading} data={data} page={page} setPage={setPage} handleResponse={handleResponse} handleGetData={handleGetData} handleGetDataByFilter={handleGetDataByFilter} />
        </Layout >
    )
}

export default Products