import { useContext } from 'react';
import { Badge, Button, Col, Form, InputGroup, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { IconClose, IconDelete, IconEdit, IconFile, IconSearch } from '../../components/Icons';
import Layout from '../../components/Layout';
import { LangContext } from '../../context/Lang';
import { handleDelete } from '../../utils';

const BadDebt = () => {
    const { dispatch: { translate } } = useContext(LangContext);

    return (
        <Layout title={translate('badDebt')}>
            <section className="d-flex justify-content-between mb-4">
                <Col md={4}>
                    <InputGroup className="search-input">
                        <InputGroup.Text className="bg-primary-grad text-white">
                            <IconSearch />
                        </InputGroup.Text>
                        <Form.Control placeholder="Search" />
                        {false && <OverlayTrigger overlay={<Tooltip>Clear Search</Tooltip>} >
                            <InputGroup.Text className="bg-danger cursor">
                                <IconClose />
                            </InputGroup.Text>
                        </OverlayTrigger>}
                    </InputGroup>
                </Col>
            </section>

            <section className="bg-card mb-4">
                <Table responsive className="mb-0">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Sale ID</th>
                            <th><span className="d-flex justify-content-end">Amount (MMK)</span></th>
                            <th>Status</th>
                            <th>Date</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Mg Mg</td>
                            <td>SID-020222-00002</td>
                            <td><span className="d-flex justify-content-end">200,000</span></td>
                            <td><Badge className="bg-danger">Bad Debt</Badge></td>
                            <td>01-02-22</td>
                            <td>
                                <Button className="btn-sm ms-2"><IconFile /></Button>
                                <Button className="btn-sm ms-2"><IconEdit /></Button>
                                <Button className="btn-danger btn-sm ms-2" onClick={handleDelete}>
                                    <IconDelete />
                                </Button>
                            </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Aung Aung</td>
                            <td>SID-020222-00003</td>
                            <td><span className="d-flex justify-content-end">100,000</span></td>
                            <td><Badge>Bad Debt Relief</Badge></td>
                            <td>01-02-22</td>
                            <td>
                                <Button className="btn-sm ms-2"><IconFile /></Button>
                                <Button className="btn-sm ms-2"><IconEdit /></Button>
                                <Button className="btn-danger btn-sm ms-2" onClick={handleDelete}>
                                    <IconDelete />
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </section >
        </Layout>
    );
};

export default BadDebt;
