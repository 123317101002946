import { useCallback, useContext, useRef, useState } from 'react';
import { Button, Col, Form, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { IconLeftArrow, IconPrint } from '../../components/Icons';
import Layout from '../../components/Layout'
import { LangContext } from '../../context/Lang';
import Select from 'react-select';
import { useReactToPrint } from 'react-to-print';
import { PrintLabelComponent } from './components';
import { getData, getProductSKURoute, numberFormat } from '../../services';
import { toast } from 'react-toastify';
import QRCode from 'qrcode';


const PrintLabel = () => {
    const history = useHistory();
    const { dispatch: { translate } } = useContext(LangContext);
    const [data, setData] = useState([])
    const [variant, setVariant] = useState<any>()
    const [imageUrl, setImageUrl] = useState('');

    const labelSizes = [
        { id: 0, labelName: "32mm * 25 mm", labelSize: "for-32-25" },
    ]

    const [printInfo, setPrintInfo] = useState({
        qrcode: true,
        name: false,
        code: true,
        price: false,
        labelSize: labelSizes[0].labelSize,
        numOfRow: 3
    })
    // const [printCount, setPrintCount] = useState(1)

    //Print Labels
    const componentRef = useRef(null);
    const reactToPrintContent = useCallback(() => {
        return componentRef.current;
    }, []);
    const PrintAction = useReactToPrint({
        content: reactToPrintContent,
    });

    // const handlePrintLabelsChange = (evt: any) => {
    //     const target = evt.target
    //     const value = target.checked
    //     const name = target.id
    //     setPrintLabels({ ...printLabels, [name]: value })
    // }

    const handleGetVariansts = async (keyword: string) => {
        getData(getProductSKURoute(keyword)).then(result => {
            let temp: any = []

            result.data.forEach((item: any) => {
                temp.push({
                    value: item.sku,
                    label: `${item.sku} (${item.productName})`,
                    sku: item.sku,
                    productCode: item.productCode,
                    productName: item.productName,
                    color: item.color,
                    retailPrice: item.retailPrice,
                })


            })

            setData(temp)
        })
    }

    const handleInputChange = (newValue: string) => {
        handleGetVariansts(newValue)
    };

    const generateQrCode = async (sku: string) => {
        try {
            const response = await QRCode.toDataURL(sku);
            setImageUrl(response);
        } catch (error: any) {
            toast.error(error);
        }
    }

    const handlePrintAction = () => {
        PrintAction()
    }

    return (
        <Layout title="Print Labels">
            <section className="mb-4 cursor" onClick={() => history.goBack()}>
                <p className="mb-0"><IconLeftArrow /> {translate('products')}</p>
            </section>

            <Col md={12} className="d-flex justify-content-center">
                <Col>
                    <section className="shadow-sm p-4 rounded bg-card">
                        <section className="flex-between mb-4 mt-3">
                            <Col md={5}>
                                <Select
                                    isClearable
                                    options={data}
                                    onInputChange={handleInputChange}
                                    onChange={(value: any) => { setVariant(value); generateQrCode(value.sku) }}
                                    placeholder={`Search by SKU`}
                                    classNamePrefix="react-select"
                                    className="react-select-container"
                                    styles={{
                                        control: (base: any, state: any) => ({
                                            ...base,
                                            boxShadow: state.isFocused ? 0 : 0
                                        })
                                    }}
                                    theme={(theme: any) => ({
                                        ...theme,
                                        borderRadius: 4,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#efefef',
                                            primary: '#e9edef',
                                            neutral0: '#212529',
                                            primary50: '#e9edef'
                                        },
                                    })}
                                />
                            </Col>
                        </section>


                        <Table responsive className="mb-4">
                            <thead>
                                <tr>
                                    {/* <th /> */}
                                    <th>SKU</th>
                                    <th>Product name</th>
                                    <th>{translate('color')}</th>
                                    <th><span className='flex-end'>{translate('retailPrice')}</span></th>
                                    <th><span className='flex-end'>Label size</span></th>
                                    <th><span className='flex-end'>Number of row</span></th>
                                    {/* <th><span className='flex-end'>Quantity</span></th> */}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {/* <td><span className="text-danger cursor"><IconDelete /></span></td> */}
                                    <td>{variant?.sku || "-"}</td>
                                    <td>{variant?.productName || "-"}</td>
                                    <td>{variant?.color || "-"}</td>
                                    <td><span className='flex-end'>{numberFormat(variant?.retailPrice)}</span></td>
                                    <td>
                                        <span className='flex-end'>
                                            {variant ? <Col md={8} >
                                                <Form.Control
                                                    as="select"
                                                    aria-label="Select"
                                                    id="bootstrapName"
                                                    onChange={(e) => setPrintInfo({ ...printInfo, labelSize: e.target.value })}
                                                    value={printInfo.labelSize} >
                                                    {labelSizes.map((item: any, index: number) =>
                                                        <option value={item.labelSize} key={index}>{item.labelName}</option>
                                                    )}
                                                </Form.Control>
                                            </Col> : "-"}
                                        </span>
                                    </td>
                                    <td>
                                        <span className='flex-end'>
                                            {variant ? <Col md={8} >
                                                <Form.Control
                                                    as="select"
                                                    aria-label="Select"
                                                    id="numOfRow"
                                                    onChange={(e) => setPrintInfo({ ...printInfo, numOfRow: parseInt(e.target.value) })}
                                                    value={printInfo.numOfRow} >
                                                    <option value={3}>3</option>
                                                    <option value={2}>2</option>
                                                    <option value={1}>1</option>
                                                </Form.Control>
                                            </Col> : "-"}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>

                        {/* <section className='d-flex my-4'>
                            <Form.Check type="checkbox" label="QR code" id="qrcode" className='me-4 cursor' checked={printLabels.qrcode} onChange={handlePrintLabelsChange} />
                            <Form.Check type="checkbox" label="Product name" id="name" className='me-4 cursor' checked={printLabels.name} onChange={handlePrintLabelsChange} />
                            <Form.Check type="checkbox" label="SKU" id="code" className='me-4 cursor' checked={printLabels.code} onChange={handlePrintLabelsChange} />
                            <Form.Check type="checkbox" label="Retail price" id="price" className='cursor' checked={printLabels.price} onChange={handlePrintLabelsChange} />
                        </section> */}

                        <section className='d-flex'>
                            <section className="d-none page-auto"><PrintLabelComponent ref={componentRef} printInfo={printInfo} variant={variant} qrImageURL={imageUrl} /></section>
                            <Button className="text-white " onClick={handlePrintAction}><span className='me-2'><IconPrint /></span>Print</Button>
                            <Button className="btn-success text-white ms-3" onClick={() => history.goBack()}>Cancel</Button>
                        </section>
                    </section>
                </Col>
            </Col>
        </Layout >
    )
}

export default PrintLabel