import { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { IconAdd, IconDelete, IconEdit } from "../../../components/Icons";
import { dataService, deleteService, getData, getDataService, getProductSizeRoute, inputChangeService, measurementRoute, numberFormat, postMethod, productSizeRoute, putMethod, responseService, standardSizeRoute } from "../../../services";
import { useLocation } from "react-router-dom";
import { NoData, ShowLessButton, TableLoading } from "../../../components";
import { LangContext } from "../../../context/Lang";
import { disableScroll } from "../../../utils";


const ProductSizes = ({ handleFreshVariant }: { handleFreshVariant: any }) => {
    const [data, setData] = useState([])
    const [measurements, setMeasurements] = useState([])
    const [standardSizes, setStandardSize] = useState([])
    const [isMeasurement, changeMeasurement] = useState(true);
    const { dispatch: { translate } } = useContext(LangContext);
    const [loading, setLoading] = useState(false)
    const [sizeName, setSizeName] = useState("")
    const [itemsToShow, setItemsToShow] = useState(5);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const location: any = useLocation();
    const product = location.state.item

    const tempUser: any = localStorage.getItem("USER")
    const user = tempUser && JSON.parse(tempUser)


    const [formData, setFormData] = useState({
        sizeId: null,
        productsId: product.id,
        currenciesId: 91,
        attrValuesId: null,
        investedCost: null,
        retailPrice: null,
        wholesalePrice: null,
    })

    const required = ["attrValuesId", "investedCost", "retailPrice"]



    useEffect(() => {
        handleGetAttributes()
        handleGetData()
        // eslint-disable-next-line
    }, [])

    const handleGetAttributes = async () => {
        getData(measurementRoute).then(result => { setMeasurements(result.data.values) })
        getData(standardSizeRoute).then(result => { setStandardSize(result.data.values) })
    }

    const handleGetData = () => {
        getDataService(getProductSizeRoute(product.id), setLoading, setData)
    }

    const handleChange = (evt: any, wantString?: boolean) => {
        inputChangeService(evt, formData, setFormData, wantString);
    }

    const handleSubmit = (method: string) => {
        dataService(method, formData, required, productSizeRoute).then(result => {
            handleResponse(result)
            if (method === putMethod && result?.success) {
                handleFreshVariant()
            }
        })
    }

    const handleDelete = (item: any) => {
        deleteService(item.attrValue, { "sizeId": item.id }, productSizeRoute, handleResponse)
    }

    const handleResponse = (result: any) => {
        responseService(result, handleGetData, handleClose)
    }

    const showForm = (item?: any) => {
        item && setSizeName(item.attrValue)
        setFormData({
            sizeId: item.id || null,
            productsId: product.id,
            currenciesId: 91,
            attrValuesId: item.attrValuesId || null,
            investedCost: item.investedCost || null,
            retailPrice: item.retailPrice || null,
            wholesalePrice: item.wholesalePrice || null,
        })
        handleShow();
    }


    return (
        <Col md={12}>
            <section className="bg-card mb-5">
                <section className="flex-between mb-4">
                    <h6>Sizes {data.length > 0 && `(${data.length})`}</h6>
                    {(user?.userRole === "Owner" || user?.userRole === "Admin") && <Button className="text-white bg-primary-grad" onClick={showForm}><IconAdd />  <span className="ms-2">{translate('createNew')}</span></Button>}
                </section>

                {loading ?
                    <TableLoading rowCount={5} />
                    :
                    <>
                        {data.length > 0 ?
                            <>
                                <Table responsive className="mb-4" striped>
                                    <thead>
                                        <tr>
                                            <th className="ps-4">#</th>
                                            <th>Size</th>
                                            <th>{translate('qty')} ({translate('in')})</th>
                                            <th>{translate('qty')} ({translate('out')})</th>
                                            <th>{translate('qty')} ({translate('remain')})</th>
                                            <th><span className="flex-end">{translate('totalCost')}</span></th>
                                            <th><span className="flex-end">{translate('retailPrice')}</span></th>
                                            <th><span className="flex-end">{translate('wholesalePrice')}</span></th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.slice(0, itemsToShow).map((item: any, index: any) =>
                                            <tr key={index}>
                                                <td className="ps-4">{index + 1}</td>
                                                <td>
                                                    <section className="d-flex align-items-center">
                                                        {item.attrValue}
                                                    </section>
                                                </td>
                                                <td>{numberFormat(item.stockIn)}</td>
                                                <td>{numberFormat(item.stockOut)}</td>
                                                <td>{numberFormat(item.stockRemain)}</td>
                                                <td><span className="flex-end">{numberFormat(item.investedCost)}</span></td>
                                                <td><span className="flex-end">{numberFormat(item.retailPrice)}</span></td>
                                                <td><span className="flex-end">{numberFormat(item.wholesalePrice)}</span></td>
                                                <td>
                                                    {(user?.userRole === "Owner" || user?.userRole === "Admin") && <span className="d-flex justify-content-end">
                                                        <Button className="btn-sm ms-2" onClick={() => showForm(item)}><IconEdit /></Button>
                                                        <Button className="btn-danger btn-sm ms-2" onClick={() => handleDelete(item)}>
                                                            <IconDelete />
                                                        </Button>
                                                    </span>}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                                {data.length > 5 && <ShowLessButton length={data.length} itemsToShow={itemsToShow} setItemsToShow={setItemsToShow} />}
                            </>
                            :
                            <NoData noImage />
                        }
                    </>
                }
            </section>

            <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{product.productName} (#{product.productCode})</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-4">
                    {formData?.sizeId && <><section className="d-flex">
                        <small className="text-muted d-block mb-4 me-4">Size : <span className="fw-bold">{sizeName}</span></small>

                    </section>
                    </>}


                    <Form.Group className="mb-4 input-form-control">
                        {!formData?.sizeId && <ButtonGroup className="mb-4">
                            <Button className={isMeasurement ? "text-white" : "bg-white text-primary"} onClick={() => changeMeasurement(true)}>Measurement Size</Button>
                            <Button className={!isMeasurement ? "text-white" : "bg-white text-primary"} onClick={() => changeMeasurement(false)}>Standard Size</Button>
                        </ButtonGroup>}

                        <Row>
                            {!formData?.sizeId &&
                                <Col md={6}>
                                    <Form.Label>Size *</Form.Label>
                                    {isMeasurement ?
                                        <Form.Control as="select" aria-label="Select" id="attrValuesId" onChange={handleChange} value={formData?.attrValuesId || ""} >
                                            <option value="">Select</option>
                                            {measurements.map((item: any, index: number) =>
                                                <option value={item.id} key={index}>{item.attrValue}</option>
                                            )}
                                        </Form.Control>
                                        :
                                        <Form.Control as="select" aria-label="Select" id="attrValuesId" onChange={handleChange} value={formData?.attrValuesId || ""} >
                                            <option value="">Select</option>
                                            {standardSizes.map((item: any, index: number) =>
                                                <option value={item.id} key={index}>{item.attrValue}</option>
                                            )}
                                        </Form.Control>}
                                </Col>
                            }

                            <Col md={6}>
                                <Form.Group className="mb-4 input-form-control" controlId="investedCost">
                                    <Form.Label>{translate('totalCost')} *</Form.Label>
                                    <Form.Control type="number" min="0" onWheel={disableScroll} onChange={(evt) => handleChange(evt, true)} value={formData?.investedCost || ""} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-4 input-form-control" controlId="retailPrice">
                                    <Form.Label>{translate('retailPrice')} *</Form.Label>
                                    <Form.Control type="number" min="0" onWheel={disableScroll} onChange={(evt) => handleChange(evt, true)} value={formData?.retailPrice || ""} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-4 input-form-control" controlId="wholesalePrice">
                                    <Form.Label>{translate('wholesalePrice')}</Form.Label>
                                    <Form.Control type="number" min="0" onWheel={disableScroll} onChange={(evt) => handleChange(evt, true)} value={formData?.wholesalePrice || ""} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => handleSubmit(formData.sizeId ? putMethod : postMethod)}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </Col>
    );
};

export default ProductSizes;
