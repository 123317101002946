import { LangContext, LangProvider } from "./Lang"
import { PageContext, PageProvider, SecondPageContext, SecondPageProvider, ThirdPageContext, ThirdPageProvider } from "./Page"
import { VariableContext, VariableProvider } from "./Variable"
import { OrderContext, OrderProvider } from "./Order"
import { CartContext, CartProvider } from "./Cart"
import { FilterContext, FilterProvider } from "./Filter"

export function Context({ children }: { children: any }) {
    return (
        <LangProvider>
            <PageProvider>
                <SecondPageProvider>
                    <ThirdPageProvider>
                        <VariableProvider>
                            <OrderProvider>
                                <CartProvider>
                                    <FilterProvider>
                                        {children}
                                    </FilterProvider>
                                </CartProvider>
                            </OrderProvider>
                        </VariableProvider>
                    </ThirdPageProvider>
                </SecondPageProvider>
            </PageProvider>
        </LangProvider>
    )
}

export {
    LangContext,
    LangProvider,
    PageContext,
    PageProvider,
    SecondPageContext,
    SecondPageProvider,
    ThirdPageContext,
    ThirdPageProvider,
    VariableContext,
    VariableProvider,
    OrderContext,
    OrderProvider,
    CartContext,
    CartProvider,
    FilterContext
}

