import React, { useContext } from 'react';
import { Image } from 'react-bootstrap';
import {
    ProSidebar,
    Menu,
    MenuItem,
    SidebarHeader,
    SidebarContent,
    SubMenu,
} from 'react-pro-sidebar';
import { useHistory, useLocation } from 'react-router-dom';
import { LangContext, PageContext, VariableContext } from '../context';
import { IconAccounting, IconDashboard, IconOrder, IconPeople, IconInventory, IconReport, IconSale, IconGear, IconDelivery, IconBoxs } from './Icons';

const Sidebar = () => {
    const history = useHistory()
    const [variable, setVariable] = useContext(VariableContext)
    const { dispatch: { translate } } = useContext(LangContext);
    const { pathname } = useLocation()
    const splitLocation = pathname.split('/')[1]
    const tempUser: any = localStorage.getItem("USER")
    const user = tempUser && JSON.parse(tempUser)
    const [page, setPage] = useContext(PageContext)

    const goPage = (route: string) => {
        //Reset page
        if (`/${splitLocation}` !== route) {
            setPage({ current: 0, total: 0, sorting: page.sorting })

            if (route === "/products") {
                setVariable({
                    ...variable,
                    searchString: "",
                    productSearchType: "productCode"
                })
            }
        }

        history.push(route);
    }

    return (
        <div className="sidebar">
            <ProSidebar collapsed={variable?.isSidebarActive} breakPoint="xs" >
                <SidebarHeader>
                    <div
                        style={{
                            padding: '16px',
                            fontWeight: 'bold',
                            fontSize: 16,
                            letterSpacing: '1px',
                            overflow: 'hidden',
                            flexGrow: 1,
                            flexShrink: 1,
                            whiteSpace: 'nowrap',
                        }}
                    >
                        <Image src={"logo.png"} width={42} height={42} className="me-4" />  Cupid & Vision
                    </div>
                </SidebarHeader>

                <SidebarContent>
                    {(user?.userRole === "Owner" || user?.userRole === "Admin") && <Menu iconShape="round">
                        <MenuItem icon={<IconDashboard />} active={splitLocation === 'dashboard'} onClick={() => goPage(`/dashboard`)}>{translate('dashboard')}</MenuItem>
                        <MenuItem icon={<IconInventory />} active={splitLocation === 'products'} onClick={() => goPage(`/products`)}>{translate('products')}</MenuItem>
                        <SubMenu title={translate('inventory')} icon={<IconBoxs />} >
                            <MenuItem active={splitLocation === 'retail-inventories'} onClick={() => goPage(`/retail-inventories`)}>{translate('retail')}</MenuItem>
                            <MenuItem active={splitLocation === 'wholesale-inventories'} onClick={() => goPage(`/wholesale-inventories`)}>{translate('wholesale')}</MenuItem>
                        </SubMenu>
                        {/* <MenuItem icon={<IconBoxs />} active={splitLocation === 'inventories'} onClick={() => goPage(`/inventories`)}>{translate('inventory')}</MenuItem> */}
                        {/* <MenuItem icon={<IconPointOfSale />} active={splitLocation === 'point-of-sale'} onClick={() => goPage(`/point-of-sale`)}>{translate('pointOfSale')}</MenuItem> */}
                        <MenuItem icon={<IconOrder />} active={splitLocation === 'orders'} onClick={() => goPage(`/orders`)}>{translate('orders')}</MenuItem>
                        <MenuItem icon={<IconSale size={16} />} active={splitLocation === 'sales'} onClick={() => goPage(`/sales`)}>{translate('sales')}</MenuItem>
                        <MenuItem icon={<IconDelivery size={16} />} active={splitLocation === 'delivery-lists'} onClick={() => goPage(`/delivery-lists`)}>{translate('deliveryList')}</MenuItem>

                        <SubMenu title={translate('accounting')} icon={<IconAccounting />} >
                            <MenuItem active={splitLocation === 'bookkeeping'} onClick={() => goPage(`/bookkeeping`)}>{translate('bookkeeping')}</MenuItem>
                            {/* <MenuItem active={splitLocation === 'account-payable'} onClick={() => goPage(`/account-payable`)}>{translate('accountsPayable')}</MenuItem>
                            <MenuItem active={splitLocation === 'account-receivable'} onClick={() => goPage(`/account-receivable`)}>{translate('accountsReceivable')}</MenuItem>
                            <MenuItem active={splitLocation === 'bad-debt'} onClick={() => goPage(`/bad-debt`)}>{translate('badDebt')}</MenuItem> */}
                            {/* <MenuItem active={splitLocation === 'bad-debt'} onClick={() => goPage(`/bad-debt`)}>{translate('income')}</MenuItem> */}
                            {/* <MenuItem active={splitLocation === 'invoices'} onClick={() => goPage(`/invoices`)}>{translate('invoices')}</MenuItem>
                            <MenuItem active={splitLocation === 'receipts'} onClick={() => goPage(`/receipts`)}>{translate('receipts')}</MenuItem> */}
                        </SubMenu>
                        <SubMenu title={translate('reports')} icon={<IconReport />} >
                            <MenuItem active={splitLocation === 'sale-reports'} onClick={() => goPage(`/sale-reports`)}>{translate('saleReports')}</MenuItem>
                            <MenuItem active={splitLocation === 'income-reports'} onClick={() => goPage(`/income-reports`)}>{translate('incomeReports')}</MenuItem>
                            <MenuItem active={splitLocation === 'daily-sale-reports'} onClick={() => goPage(`/daily-sale-reports`)}>{translate('dailySaleReport')}</MenuItem>
                        </SubMenu>
                        <MenuItem icon={<IconPeople size={16} />} active={splitLocation === 'customers'} onClick={() => goPage(`/customers`)}>{translate('customers')}</MenuItem>
                        <MenuItem icon={<IconGear />} active={splitLocation === 'configuration'} onClick={() => goPage(`/configuration`)}>{translate('configuration')}</MenuItem>
                    </Menu>}

                    {user?.userRole === "Manager" && <Menu iconShape="round">
                        <MenuItem icon={<IconInventory />} active={splitLocation === 'products'} onClick={() => goPage(`/products`)}>{translate('products')}</MenuItem>
                        <MenuItem icon={<IconBoxs />} active={splitLocation === 'inventories'} onClick={() => goPage(`/inventories`)}>Inventories</MenuItem>
                        <MenuItem icon={<IconOrder />} active={splitLocation === 'orders'} onClick={() => goPage(`/orders`)}>{translate('orders')}</MenuItem>
                        {/* <MenuItem icon={<IconPointOfSale />} active={splitLocation === 'point-of-sale'} onClick={() => goPage(`/point-of-sale`)}>{translate('pointOfSale')}</MenuItem> */}
                        <MenuItem icon={<IconSale size={16} />} active={splitLocation === 'sales'} onClick={() => goPage(`/sales`)}>{translate('sales')}</MenuItem>
                        <MenuItem icon={<IconDelivery size={16} />} active={splitLocation === 'delivery-lists'} onClick={() => goPage(`/delivery-lists`)}>{translate('deliveryList')}</MenuItem>
                    </Menu>}

                    {user?.userRole === "Operator" && <Menu iconShape="round">
                        <MenuItem icon={<IconInventory />} active={splitLocation === 'products'} onClick={() => goPage(`/products`)}>{translate('products')}</MenuItem>
                        <MenuItem icon={<IconBoxs />} active={splitLocation === 'inventories'} onClick={() => goPage(`/inventories`)}>Inventories</MenuItem>
                        <MenuItem icon={<IconOrder />} active={splitLocation === 'orders'} onClick={() => goPage(`/orders`)}>{translate('orders')}</MenuItem>
                        {/* <MenuItem icon={<IconPointOfSale />} active={splitLocation === 'point-of-sale'} onClick={() => goPage(`/point-of-sale`)}>{translate('pointOfSale')}</MenuItem> */}
                        <MenuItem icon={<IconSale size={16} />} active={splitLocation === 'sales'} onClick={() => goPage(`/sales`)}>{translate('sales')}</MenuItem>
                        <MenuItem icon={<IconDelivery size={16} />} active={splitLocation === 'delivery-lists'} onClick={() => goPage(`/delivery-lists`)}>{translate('deliveryList')}</MenuItem>
                    </Menu>}
                </SidebarContent>
            </ProSidebar>
        </div >
    );
};

export default Sidebar;
