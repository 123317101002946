import { Badge, Button, Col, Form, Table } from "react-bootstrap";
import { CustomModal, Lang, NoData, TableRowButtons, } from "../../../components";
import { useEffect, useState } from "react";
import { dateFormat2, disableScroll, getData, handleClose, handleDateFormat, handleShow, inputChangeService, numberFormat, paymentRoute, putMethod, } from "../../../services";
import { dataService, deleteService, responseService } from "../../../services";
import { IconAdd } from "../../../components/Icons";
import { FormFieldService } from "../../../components/services";
import { toast } from "react-toastify";


const Payments = ({ detail, handleGetData }: { detail: any, handleGetData: any }) => {
    const data = detail?.paymentHistory;
    // const { dispatch: { translate } } = useContext(LangContext);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false)
    const paymentStatus = ["UNPAID", "PARTIAL PAID", "PAID"]
    const [payments, setPayments] = useState([])
    const [formData, setFormData] = useState<any>({
        saleOrdersId: null,
        paymentHistoryId: null,
        paymentTypeId: "",
        paymentStatusId: 1,
        totalAmountPaid: "",
        paymentDate: new Date(),
    })

    const fields = [
        { id: "paymentDate", label: "Payment date", value: handleDateFormat(formData.paymentDate, dateFormat2), type: "date", },
    ]

    const required = ["totalAmountPaid"]

    useEffect(() => {
        handleGetPayments()
        // eslint-disable-next-line
    }, [])

    const handleChange = (evt: any, wantString?: boolean) => {
        inputChangeService(evt, formData, setFormData, wantString);
    }

    const handleGetPayments = async () => {
        getData(paymentRoute).then(result => {
            setPayments(result.data)
        })
    }

    const showForm = (item?: any) => {
        setFormData({
            saleOrdersId: detail.id,
            paymentHistoryId: item?.id || "",
            paymentTypeId: item?.paymentTypeId || "",
            paymentStatusId: item?.paymentStatus ? paymentStatus.indexOf(item?.paymentStatus) : 1,
            paymentDate: item?.paymentDate || new Date(),
            totalAmountPaid: item?.totalAmountPaid || "",
        })
        handleShow(setShow);
    }

    const handleSubmit = (method: string) => {
        let _formData: any = {
            saleOrdersId: formData.saleOrdersId,
            paymentTypeId: formData.paymentTypeId || null,
            totalAmountPaid: formData.totalAmountPaid || null,
            paymentDate: formData.paymentDate && new Date(formData.paymentDate).toISOString()
        }

        if (method === putMethod) {
            _formData["paymentHistoryId"] = formData.paymentHistoryId;
        }

        _formData["paymentStatus"] = paymentStatus[formData.paymentStatusId];

        setLoading(true)

        if (formData.paymentStatusId === 0) {
            handleAction(method, _formData)
        } else {
            if (formData.paymentTypeId) {
                handleAction(method, _formData)
            } else {
                setLoading(false)
                toast.error("Payment type is required!")
            }
        }
    }

    const handleAction = (method: string, submitData: any) => {
        dataService(method, submitData, required, `payment/transactions`, method === putMethod).then(result => {
            responseService(result, handleGetData, handleClose(setShow), null, null, setLoading)
        })
    }

    const handleResponse = (result: any) => {
        responseService(result, handleGetData, handleClose(setShow), null, null, setLoading)
    }

    const handleDelete = (item: any) => {
        deleteService("this payment", { paymentHistoryId: item.id }, `payment/transactions`, handleResponse)
    }

    return (
        <Col md={12}>
            <section className="bg-card mb-4">
                <section className="flex-between mb-3">
                    <h6 className="fw-bold">Payment history</h6>
                    {!detail?.isCancel ? <Button className="text-white bg-grad" onClick={showForm}><IconAdd />  <span className="ms-2"><Lang label="createNew" /></span></Button> : <></>}
                </section>

                {data && data.length > 0 ?
                    <>
                        <Table responsive striped bordered className="center-table">
                            <thead>
                                <tr>
                                    <th>Paid amount</th>
                                    <th>Payment type</th>
                                    <th>Payment status</th>
                                    <th>Payment date</th>
                                    {!detail?.isCancel && <th />}
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item: any, index: number) => (
                                    <tr key={index}>
                                        <td><span className="flex-end">MMK {item?.totalAmountPaid ? numberFormat(item?.totalAmountPaid) : 0}</span></td>
                                        <td><span className="flex-center">{item?.paymentName || "-"}</span></td>
                                        <td><span className="flex-center"><Badge className={item?.paymentStatus === "PAID" ? "bg-primary" : item?.paymentStatus === "UNPAID" ? "bg-danger" : "bg-success"}>{item?.paymentStatus}</Badge></span></td>
                                        <td><span className="flex-center">{handleDateFormat(item?.paymentDate)}</span></td>
                                        {!detail?.isCancel && <td> <TableRowButtons item={item} showForm={showForm} handleDelete={handleDelete} flex="flex-center" /></td>}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </>
                    :
                    <NoData noImage />
                }
            </section>

            <CustomModal show={show} handleClose={() => handleClose(setShow)} id={formData?.paymentHistoryId} handleSubmit={handleSubmit} loading={loading}>


                <Form.Group className="input-form-control mb-4" >
                    <Form.Label>Payment status</Form.Label>
                    <Form.Control as="select" aria-label="Select" id="paymentStatusId" value={formData?.paymentStatusId}
                        onChange={(e) => {
                            setFormData({ ...formData, paymentStatusId: parseInt(e.target.value), paymentTypeId: e.target.value === "0" ? "" : formData.paymentTypeId, totalAmountPaid: e.target.value === "0" ? 0 : formData.totalAmountPaid })
                        }}
                    >
                        {paymentStatus.map((item: string, index: number) => (
                            <option value={index} key={index}>{item}</option>
                        ))}
                    </Form.Control>
                </Form.Group>

                {formData.paymentStatusId !== 0 && <Form.Group className="input-form-control mb-4" >
                    <Form.Label>Payment type</Form.Label>
                    <Form.Control as="select" aria-label="Select" id="paymentTypeId" value={formData?.paymentTypeId} onChange={handleChange} >
                        <option value="">Select</option>
                        {payments.length > 0 && payments.map((item: any, index: number) => (
                            <option value={item.id} key={index}>{item.paymentName}</option>
                        ))}
                    </Form.Control>
                </Form.Group>}

                {formData.paymentStatusId !== 0 && <Form.Group className="input-form-control mb-4" >
                    <Form.Label>Paid amount *</Form.Label>
                    <Form.Control type="number" value={formData?.totalAmountPaid || ""} id="totalAmountPaid" onChange={handleChange} onWheel={disableScroll} />
                </Form.Group>}

                <FormFieldService fields={fields} handleChange={handleChange} formData={formData} setFormData={setFormData} colCount={12} />
            </CustomModal>
        </Col >

    );
};

export default Payments;
