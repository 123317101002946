import { useContext, useEffect, useState } from 'react'
import { Button, Form, Modal, Table, } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { IconAdd, IconDelete, IconEdit, IconLeftArrow } from '../../components/Icons'
import Layout from '../../components/Layout'
import { TableLoading, NoData } from '../../components'
import { LangContext } from '../../context/Lang'
import { dataService, deleteService, getData, postMethod, putMethod, responseService, warehousesRoute } from '../../services'


const Warehoures = () => {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { dispatch: { translate } } = useContext(LangContext);
    const [data, setData] = useState([])
    const [formData, setFormData] = useState({
        warehouseId: "",
        warehouse: "",
        location: ""
    })
    const required = ["warehouse"]

    useEffect(() => {
        handleGetData()
    }, [])


    const handleGetData = async () => {
        setLoading(true)
        getData(warehousesRoute).then(result => {
            setData(result.data)
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        })
    }

    const handleChange = (evt: any) => {
        const value = evt.target.value
        const name = evt.target.id
        setFormData({
            ...formData, [name]: value,
        })
    }

    const showAddForm = () => {
        setFormData({
            warehouseId: "",
            warehouse: "",
            location: ""
        })
        handleShow();
    }

    const showEditForm = (item: any) => {
        setFormData({
            warehouseId: item.id,
            warehouse: item.warehouse,
            location: item.location
        })
        handleShow();
    }

    const responseAction = (result: any) => {
        responseService(result, handleGetData, handleClose)
    }

    const handleCreate = () => {
        dataService(postMethod, formData, required, warehousesRoute).then(result => {
            responseAction(result)
        })
    }

    const handleEdit = () => {
        dataService(putMethod, formData, required, warehousesRoute).then(result => {
            responseAction(result)
        })
    }

    const handleDelete = (item: any) => {
        const _formData = {
            "warehouseId": item.id
        }

        deleteService(item.warehouse, _formData, warehousesRoute, responseAction)
    }

    return (
        <Layout title="Warehoures">
            <section className="flex-between mb-4">
                <section className="cursor" onClick={() => history.goBack()}>
                    <p className="mb-0"><IconLeftArrow /> {translate("configuration")}</p>
                </section>
                <Button className="text-white bg-primary-grad" onClick={showAddForm}><IconAdd /> <span className="ms-2">{translate("createNew")}</span></Button>
            </section>

            {loading ?
                <TableLoading />
                :
                <>
                    {data.length > 0 ?
                        <section className="bg-card mb-4">
                            <Table responsive className="mb-0 border center-table" bordered>
                                <thead>
                                    <tr>
                                        <th>{translate("no.")}</th>
                                        <th>Warehoure Name</th>
                                        <th>{translate("location")}</th>
                                        {/* <th><span className="flex-end">{translate("quantity")}</span></th> */}
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item: any, index: any) =>
                                        <tr key={index}>
                                            <td><span className='flex-center'>{index + 1}</span></td>
                                            <td>{item.warehouse}</td>
                                            <td>{item.location}</td>
                                            <td>
                                                <span className="flex-center">
                                                    <Button className="btn-sm ms-2" onClick={() => showEditForm(item)}><IconEdit /></Button>
                                                    <Button className="btn-danger btn-sm ms-2" onClick={() => handleDelete(item)}> <IconDelete /> </Button>
                                                </span>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </section>
                        :
                        <NoData />
                    }
                </>
            }


            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{formData?.warehouseId ? translate("editInfo") : translate("createNew")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>Warehoure Name</Form.Label>
                    <Form.Control type="text" className="mb-4" id="warehouse" onChange={handleChange} value={formData?.warehouse} />

                    <Form.Label>{translate("location")}</Form.Label>
                    <Form.Control type="text" className="mb-4" id="location" onChange={handleChange} value={formData?.location} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={formData?.warehouseId ? handleEdit : handleCreate}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </Layout >
    )
}

export default Warehoures