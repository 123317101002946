
import React from "react";
import { Image } from "react-bootstrap";

const ReceiptHeaderPrint = React.forwardRef((props: any, ref: any) => {
    const logoSize = 70;

    const ReceiptHeaderContent = () => (
        <section className="receipt-header-print">
            <section className="receipt-header">
                <section className="flex-between">
                    <Image src="logo.png" width={logoSize} height={logoSize} />
                    <section className="text-center">
                        <Image src="images/bawgawadi_text.png" width={"200"} />
                        <h5 className="mt-1 mb-1 fw-bold">Cupid & Vision</h5>
                    </section>
                    <Image src="images/bawgawadi.png" width={logoSize} height={logoSize} />
                </section>
                <section className="d-flex justify-content-center">
                    <small className="print-label">ကွန်ပျူတာပန်းထိုးလုံချည် နှင့် အမျိုးသမီးအင်္ကျီ လက်လီ / လက်ကား (ပင်ရင်း)</small>
                </section>
            </section>
        </section>
    )

    return (
        <section ref={ref} className="d-flex margin-for-receipt">
            <ReceiptHeaderContent />
            <section className="receipt-header-print-line"></section>
            <ReceiptHeaderContent />
        </section >
    );
});

export default ReceiptHeaderPrint