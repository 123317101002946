import { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, InputGroup, Table, } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { IconAdd, IconLeftArrow } from '../../components/Icons'
import Layout from '../../components/Layout'
import { LangContext } from '../../context/Lang'
import { TableLoading, NoData, CustomModal, TableRowButtons, CustomInput } from '../../components'
import { dataService, deleteService, deliveryFeesRoute, deliveryPricesRoute, deliverySerivcesRoute, getData, handleClose, handleShow, inputChangeService, numberFormat, regionRoute, responseService, townshipRoute } from '../../services'
import { disableScroll } from '../../utils'

const DeliveryFees = () => {
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(false);

    const { dispatch: { translate } } = useContext(LangContext);
    const [regions, setRegions] = useState([])
    const [regionCode, setRegionCode] = useState("")
    const [townships, setTownships] = useState([])
    const [deliveryServices, setDeliveryServices] = useState([])
    const [deliveryFees, setDeliveryFees] = useState([])
    const [formData, setFormData] = useState({
        servicePriceId: "",
        deliveryServicesId: "",
        subSubAreaCode: "",
        deliveryPrice: ""
    })
    const [locationName, setLocationName] = useState("")
    const required = ["subSubAreaCode"]


    useEffect(() => {
        getData(deliverySerivcesRoute + "?list=all").then(result => {
            setDeliveryServices(result.data)
            setFormData({ ...formData, deliveryServicesId: result.data[0].id })
            handleGetDeliveryFees(result.data[0].id)
        })
        handleGetRegions()
        // eslint-disable-next-line 
    }, [])


    const handleGetDeliveryFees = async (serviceId: any) => {
        setLoading(true)
        getData(deliveryFeesRoute(serviceId)).then(result => {
            setDeliveryFees(result.data.servicePrices)
            setTimeout(() => {
                setLoading(false)
            }, 1500)
        })
    }


    const handleGetRegions = async () => {
        getData(regionRoute).then(result => {
            setRegions(result.data)
        })
    }

    const handleGetTownships = async (regionCode: string) => {
        getData(townshipRoute(regionCode)).then(result => {
            setTownships(result.data)
        })
    }

    const handleChange = (evt: any, wantString?: boolean) => {
        inputChangeService(evt, formData, setFormData, wantString);
    }

    const handleSelectRegion = (evt: any) => {
        const value = evt.target.value
        setRegionCode(value)
        handleGetTownships(value)
    }

    const handleSelectDelivery = (evt: any) => {
        const value = evt.target.value
        setFormData({ ...formData, deliveryServicesId: value })
        handleGetDeliveryFees(value)
    }

    const showForm = (item?: any) => {
        setFormData({
            ...formData,
            servicePriceId: item?.id,
            subSubAreaCode: item?.subSubAreaCode,
            deliveryPrice: item?.deliveryPrice
        })

        if (item?.areaCode) {
            setRegionCode(item?.areaCode)
            handleGetTownships(item?.areaCode)
        } else {
            setRegionCode("")
        }

        setLocationName(item?.locationName)
        handleShow(setShow);
    }

    const handleSubmit = (method: string) => {
        setLoading(true)

        let _formData = {
            servicePriceId: formData.servicePriceId || null,
            deliveryServicesId: formData.deliveryServicesId ? parseInt(formData.deliveryServicesId) : null,
            subSubAreaCode: formData.subSubAreaCode,
            locationName: locationName,
            deliveryPrice: formData.deliveryPrice
        }

        dataService(method, _formData, required, deliveryPricesRoute).then(result => {
            handleResponse(result)
        })
    }

    const handleResponse = (result: any) => {
        responseService(result, handleGetDeliveryFees(formData.deliveryServicesId), handleClose(setShow), null, null, setLoading)
    }

    const handleDelete = (item: any) => {
        deleteService(item.locationName, { "servicePriceId": item.id }, deliveryPricesRoute, handleResponse)
    }

    return (
        <Layout title={translate("deliveryFees")}>
            <section className="flex-between mb-4">
                <section className="cursor" onClick={() => history.goBack()}>
                    <p className="mb-0"><IconLeftArrow /> {translate("configuration")}</p>
                </section>

                <Col md={3}>
                    <InputGroup>
                        <InputGroup.Text className="bg-primary-grad text-white">
                            Delivery Service
                        </InputGroup.Text>
                        <Form.Control as="select" aria-label="Select" onChange={handleSelectDelivery}>
                            {deliveryServices.map((item: any, index: number) =>
                                <option value={item.id} key={index}>{item.deliveryService}</option>
                            )}
                        </Form.Control>
                    </InputGroup>
                </Col>

                <Button className="text-white bg-primary-grad" onClick={showForm}><IconAdd /> <span className="ms-2">{translate("createNew")}</span></Button>
            </section>

            {loading ?
                <TableLoading />
                :
                <>
                    {deliveryFees.length > 0 ?
                        <section className="bg-card mb-4">
                            <Table responsive className="mb-0 border center-table" bordered>
                                <thead>
                                    <tr>
                                        <th>{translate("no.")}</th>
                                        <th>{translate("region")}</th>
                                        <th>{translate("township")}</th>
                                        <th>{translate("location")}</th>
                                        <th><span className="flex-end">{translate("price")} (MMK)</span></th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {deliveryFees.map((item: any, index: any) =>
                                        <tr key={index}>
                                            <td><span className='flex-center'>{index + 1}</span></td>
                                            <td>{item.areaNameLocal}</td>
                                            <td>{item.subSubAreaNameLocal}</td>
                                            <td>{item.locationName}</td>
                                            <th><span className="flex-end">{numberFormat(item.deliveryPrice)}</span></th>
                                            <td><TableRowButtons item={item} showForm={showForm} handleDelete={handleDelete} flex="flex-center" /></td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </section>
                        :
                        <NoData />
                    }
                </>
            }

            <CustomModal show={show} handleClose={() => handleClose(setShow)} id={formData?.servicePriceId} handleSubmit={handleSubmit} loading={loading}>
                <Form.Group className="mb-4 input-form-control">
                    <Form.Label>{translate("region")}</Form.Label>
                    <Form.Control as="select" aria-label="Select" id="areaCode" value={regionCode} onChange={handleSelectRegion}>
                        <option value="">Select</option>
                        {regions.map((item: any, index: number) =>
                            <option value={item.areaCode} key={index}>{item.areaNameLocal}</option>
                        )}
                    </Form.Control>
                </Form.Group>

                <Form.Group className="mb-4 input-form-control">
                    <Form.Label>{translate("township")}</Form.Label>
                    <Form.Control as="select" aria-label="Select" id="subSubAreaCode" value={formData?.subSubAreaCode} onChange={(evt: any) => {
                        const index = evt.nativeEvent.target.selectedIndex;
                        const label = evt.target.value === "" ? "" : evt.nativeEvent.target[index].text
                        handleChange(evt, true);
                        setLocationName(label)
                    }} >
                        <option value="">Select</option>
                        {townships.map((item: any, index: number) =>
                            <option value={item.subSubAreaCode} key={index}>{item.subSubAreaNameLocal}</option>
                        )}
                    </Form.Control>
                </Form.Group>

                <CustomInput label={translate("location")} id="locationName" handleChange={(evt: any) => setLocationName(evt.target.value)} value={locationName} />

                <Form.Group className="mb-4 input-form-control">
                    <Form.Label>{translate("price")}</Form.Label>
                    <Form.Control type="number" min="0" id="deliveryPrice" value={formData?.deliveryPrice} onWheel={disableScroll} onChange={(evt) => handleChange(evt, true)} />
                </Form.Group>
            </CustomModal>

        </Layout >
    )
}

export default DeliveryFees