import { ToastContainer } from "react-toastify"
import { Header } from "."
import Sidebar from "./Sidebar"

function Layout({ children, title, noPadding = false }: { children: any, title: string, noPadding?: boolean }) {
    return (
        <section className="d-flex">
            <ToastContainer autoClose={1000} />
            <Sidebar />

            <section className="w-100">
                <Header title={title} />
                <section className={!noPadding ? "p-4" : ""}>
                    {children}
                </section>
            </section>
        </section>
    )
}


export default Layout