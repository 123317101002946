import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, FormCheck, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify";
import { CustomInput } from "../../components";
import { IconLeftArrow, } from "../../components/Icons";
import Layout from "../../components/Layout"
import { LangContext } from "../../context/Lang";
import { brandRoute, dataService, factoriesRoute, getData, materialRoute, postMethod, productCodeExitRoute, productsRoute, productTypesRoute, sleeveRoute, styleRoute, widthRoute } from "../../services";
import { disableScroll } from "../../utils";
import swal from 'sweetalert'

function Add() {
    const history = useHistory();
    const { dispatch: { translate } } = useContext(LangContext);
    const [isCustomText, setCustomText] = useState(false)
    const [formData, setFormData] = useState({
        productTypesId: 1,
        brandsId: "",
        factoriesId: "",
        productCode: "",
        productName: "",
        description: "",
        shippable: true,
        soldBy: "Each",
        lowStockQty: undefined,
        minWholesaleMoq: undefined,
        qtyInPackage: undefined,
        isDelete: false,
        publish: false,
        attributes: {
            style: {
                id: "",
                attrValue: ""
            },
            material: {
                id: "",
                attrValue: ""
            },
            sleeve: {
                id: "",
                attrValue: ""
            },
            width: {
                id: "",
                attrValue: ""
            }
        }
    })

    const [productAttrubutes, setProductAttrubutes] = useState({
        material: "",
        materialId: "",
        style: "",
        styleId: "",
        sleeve: "",
        sleeveId: "",
        width: "",
        widthId: ""
    })

    const required = ["productCode", "productName"]

    const [productTypes, setProductTypes] = useState([])
    const [brands, setBrands] = useState([])
    const [materials, setMaterials] = useState([])
    const [filterMaterials, setFilterMaterials] = useState([])
    const [styles, setStyles] = useState([])
    const [filterStyles, setFilterStyles] = useState([])
    const [sleeves, setSleeves] = useState([])
    const [widths, setWidths] = useState([])
    const [factories, setFactories] = useState([])

    useEffect(() => {
        handleGetAttributes()
    }, [])

    const handleGetAttributes = async () => {
        getData(productTypesRoute).then(result => { setProductTypes(result.data) })
        getData(brandRoute).then(result => { setBrands(result.data) })
        getData(materialRoute).then(result => {
            setMaterials(result.data.values);
            setFilterMaterials(result.data.values.filter((item: any) => item?.productTypesId === 1))
        })
        getData(styleRoute).then(result => {
            setStyles(result.data.values);
            setFilterStyles(result.data.values.filter((item: any) => item?.productTypesId === 1))
        })
        getData(sleeveRoute).then(result => { setSleeves(result.data.values) })
        getData(widthRoute).then(result => { setWidths(result.data.values) })
        getData(factoriesRoute).then(result => { setFactories(result.data) })
    }

    const handleChange = (evt: any, productTypesId?: boolean) => {
        const target = evt.target
        const value = target.type === "checkbox" ? target.checked : (target.type === "number" || target.type === "select-one") ? target.value === "" ? "" : parseInt(target.value) : target.value
        const name = target.id

        if (productTypesId) {
            setFormData({
                ...formData, [name]: value,
                productName: "",
                attributes: {
                    style: {
                        id: "",
                        attrValue: ""
                    },
                    material: {
                        id: "",
                        attrValue: ""
                    },
                    sleeve: {
                        id: "",
                        attrValue: ""
                    },
                    width: {
                        id: "",
                        attrValue: ""
                    }
                }
            })

            setProductAttrubutes({
                material: "",
                materialId: "",
                style: "",
                styleId: "",
                sleeve: "",
                sleeveId: "",
                width: "",
                widthId: ""
            })
        } else {
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleAttibuteChange = (evt: any) => {
        const target = evt.target
        const value = target.value
        const name = target.id
        const index = evt.nativeEvent.target.selectedIndex;
        const label = value === "" ? "" : evt.nativeEvent.target[index].text

        let tempAttributes: any = formData?.attributes;

        let attiabuteValueId: any = value ? parseInt(value) : ""

        if (name === 'material') {
            if (tempAttributes.material) {
                tempAttributes.material.id = attiabuteValueId
                tempAttributes.material.attrValue = value ? label : ""
            } else {
                tempAttributes = {
                    ...tempAttributes, material: {
                        id: attiabuteValueId,
                        attrValue: value ? label : ""
                    },
                }
            }
        } else if (name === 'style') {
            if (tempAttributes.style) {
                tempAttributes.style.id = attiabuteValueId
                tempAttributes.style.attrValue = value ? label : ""
            } else {
                tempAttributes = {
                    ...tempAttributes, style: {
                        id: attiabuteValueId,
                        attrValue: value ? label : ""
                    },
                }
            }
        } else if (name === 'sleeve') {

            if (tempAttributes.sleeve) {
                tempAttributes.sleeve.id = attiabuteValueId
                tempAttributes.sleeve.attrValue = value ? label : ""
            } else {
                tempAttributes = {
                    ...tempAttributes, sleeve: {
                        id: attiabuteValueId,
                        attrValue: value ? label : ""
                    },
                }
            }
        } else {
            if (tempAttributes.width) {
                tempAttributes.width.id = attiabuteValueId
                tempAttributes.width.attrValue = value ? label : ""
            } else {
                tempAttributes = {
                    ...tempAttributes, width: {
                        id: attiabuteValueId,
                        attrValue: value ? label : ""
                    },
                }
            }
        }

        let productName = "";
        if (isCustomText) {
            productName = formData?.productName
        } else {
            let material = name === "material" ? label : productAttrubutes?.material;
            let style = name === "style" ? label : productAttrubutes?.style;
            let sleeve = name === "sleeve" ? label : productAttrubutes?.sleeve;
            let width = name === "width" ? label : productAttrubutes?.width;

            productName = material + style;
            if (formData.productTypesId === 1) {
                productName = productName + sleeve
            } else {
                productName = productName + width
            }
        }

        setProductAttrubutes({ ...productAttrubutes, [name]: label, [`${name}Id`]: value })
        setFormData({ ...formData, productName: productName, attributes: tempAttributes })

    }

    const handleProductTypeChange = (evt: any) => {
        setFilterMaterials(materials.filter((item: any) => item?.productTypesId === parseInt(evt.target.value)))
        setFilterStyles(styles.filter((item: any) => item?.productTypesId === parseInt(evt.target.value)))
        handleChange(evt, true)
    }


    const handleSubmit = () => {
        getData(productCodeExitRoute(formData?.productCode)).then((result) => {
            if (result.exist) {
                toast.error(result.message)
            } else {
                let data: any = {}

                for (let [key, value] of Object.entries(formData?.attributes)) {
                    if (value.id !== null && value.id !== '') {
                        data[key] = value
                    }
                }

                let tempformData: any = formData;
                tempformData.attributes = data

                dataService(postMethod, tempformData, required, productsRoute).then(result => {
                    if (result?.success) {
                        swal(result.message, {
                            icon: 'success',
                            buttons: [false],
                            timer: 1500,
                        })
                        history.push("/products")
                    } else {
                        toast.error(result?.message)
                    }
                })
            }
        })
    }


    return (
        <Layout title={translate('createNew')}>
            <section className="mb-4 cursor" onClick={() => history.goBack()}>
                <p className="mb-0"><IconLeftArrow /> {translate('products')}</p>
            </section>

            <section className="bg-card">
                <Row>
                    <Col md={4}>
                        <CustomInput label="Product code *" id="productCode" handleChange={handleChange} value={formData?.productCode} />
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label>{translate('productType')}</Form.Label>
                            <Form.Control
                                as="select"
                                aria-label="Select"
                                id="productTypesId"
                                onChange={handleProductTypeChange}
                                value={formData?.productTypesId} >
                                {productTypes.map((item: any, index: number) =>
                                    <option value={item.id} key={index}>{item.productType}</option>
                                )}
                            </Form.Control>
                        </Form.Group>

                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label>{translate('brand')}</Form.Label>
                            <Form.Control as="select" aria-label="Select" id="brandsId" onChange={handleChange} value={formData?.brandsId} >
                                <option value="">Select</option>
                                {brands.map((item: any, index: number) =>
                                    <option value={item.id} key={index}>{item.brandName}</option>
                                )}
                            </Form.Control>
                        </Form.Group>

                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label>{translate('material')}</Form.Label>
                            <Form.Control as="select" aria-label="Select" id="material" onChange={handleAttibuteChange} value={productAttrubutes?.materialId}>
                                <option value="">Select</option>
                                {filterMaterials.map((item: any, index: number) =>
                                    <option value={item.id} key={index}>{item.attrValue}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label>{translate('style')}</Form.Label>
                            <Form.Control as="select" aria-label="Select" id="style" onChange={handleAttibuteChange} value={productAttrubutes?.styleId} >
                                <option value="">Select</option>
                                {filterStyles.map((item: any, index: number) =>
                                    <option value={item.id} key={index}>{item.attrValue}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    {formData.productTypesId === 1 ?
                        <Col md={4}>
                            <Form.Group className="mb-4 input-form-control" >
                                <Form.Label>{translate('sleeve')}</Form.Label>
                                <Form.Control as="select" aria-label="Select" id="sleeve" onChange={handleAttibuteChange} value={productAttrubutes?.sleeveId} >
                                    <option value="">Select</option>
                                    {sleeves.map((item: any, index: number) =>
                                        <option value={item.id} key={index}>{item.attrValue}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        :
                        <Col md={4}>
                            <Form.Group className="mb-4 input-form-control" >
                                <Form.Label>{translate('width')}</Form.Label>
                                <Form.Control as="select" aria-label="Select" id="width" onChange={handleAttibuteChange} value={productAttrubutes?.widthId} >
                                    <option value="">Select</option>
                                    {widths.map((item: any, index: number) =>
                                        <option value={item.id} key={index}>{item.attrValue}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    }
                    <Col md={4}>
                        <Form.Group className="mb-4 input-form-control">
                            <section className="flex-between">
                                <Form.Label>Product Name *</Form.Label>
                                <FormCheck
                                    id="switch"
                                    className="switch"
                                    type="switch"
                                    label={"Custom Name"}
                                    checked={isCustomText}
                                    onChange={() => setCustomText(!isCustomText)}
                                />
                            </section>

                            <Form.Control type="text" id="productName" onChange={handleChange} value={formData?.productName} disabled={!isCustomText} />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label>Factory</Form.Label>
                            <Form.Control as="select" aria-label="Select" id="factoriesId" onChange={handleChange} value={formData?.factoriesId}>
                                <option value="">Select</option>
                                {factories.map((item: any, index: number) =>
                                    <option value={item.id} key={index}>{item.factory}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label>Quantity in Package</Form.Label>
                            <Form.Control type="number" min="0" id="qtyInPackage" onWheel={disableScroll} onChange={handleChange} value={formData?.qtyInPackage} />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label>Low Stock Quantity</Form.Label>
                            <Form.Control type="number" min="0" id="lowStockQty" onWheel={disableScroll} onChange={handleChange} value={formData?.lowStockQty} />
                            <Form.Text className="text-muted">
                                Product quantity at which you will be notified about low stock
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label>Minimum Wholesale Quantity</Form.Label>
                            <Form.Control type="number" min="0" id="minWholesaleMoq" onWheel={disableScroll} onChange={handleChange} value={formData?.minWholesaleMoq} />
                        </Form.Group>
                    </Col>

                    <section className="d-flex align-items-center justify-content-between">
                        <Form.Group className="mb-4" >
                            <Form.Check type="checkbox" label="Publish Product" id="publish" checked={formData?.publish} onChange={handleChange} />
                        </Form.Group>
                    </section>

                    <Col md={12}>
                        <Form.Group className="mb-4">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={2} id="description" onChange={handleChange} value={formData?.description} />
                        </Form.Group>
                    </Col>

                    <section className="d-flex">
                        <Button className="text-white" onClick={handleSubmit}>Save</Button>
                        <Button className="btn-success text-white ms-2" onClick={() => history.goBack()}>Cancel</Button>
                    </section>
                </Row>
            </section>
        </Layout >
    )
}

export default Add