import { useState } from "react"
import { Button, Col, Form, Row, Image } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify";
import { LoadingButtonLabel } from "../../components";
import { actionData, inputChangeService, postMethod, saveLocalForUser, signInRoute } from "../../services";

function Login() {
    const history = useHistory()
    const [disabled, setDisabled] = useState(false);
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    })

    const handleChange = (evt: any) => {
        inputChangeService(evt, formData, setFormData);
    }

    const handleEnterAction = (event: any) => {
        if (event.keyCode === 13) {
            onSubmit()
        }
    }

    const onSubmit = () => {
        if (formData.email !== "" && formData.password !== "") {
            setDisabled(true);
            setTimeout(() => {
                handleLogin();
            }, 500);

        } else {
            toast.error('Please fill email or password!')
        }

    }

    const handleLogin = () => {
        actionData(postMethod, signInRoute, formData).then(user => {
            if (user?.data) {
                saveLocalForUser(JSON.stringify(user?.data))
                history.push((user?.data.userRole === "Owner" || user?.data.userRole === "Admin") ? "/dashboard" : "/orders")
            } else {
                toast.error('Wrong email or password!')
                setDisabled(false);
            }
        })
    }

    return (
        <Row className="login-container">
            <Col className="login-container-image" md={7}>
                <section className="text-center login-container-image-child">
                    <Image src={"logo.png"} width={100} height={100} className="mb-4" />
                    <h1 className="fw-bold text-white mb-2">Cupid & Vision</h1>
                    <h4 className="text-white mb-5">POS System</h4>

                    <Image src={"images/onlineStore.svg"} width={600} />
                </section>
            </Col>
            <Col className="login-container-field" md={5}>
                <Col md={8}>
                    <section className="text-center">
                        <Image src={"images/onlineShop.svg"} width={300} />
                        <h3 className="fw-bold text-primary my-4 text-center">LOGIN</h3>
                    </section>
                    <Form>
                        <Form.Group className="mb-4 input-form-control" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" onChange={handleChange} value={formData?.email} onKeyDown={(e) => handleEnterAction(e)} />
                        </Form.Group>

                        <Form.Group className="mb-4 input-form-control" controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" onChange={handleChange} value={formData?.password} autoComplete="off" onKeyDown={(e) => handleEnterAction(e)} />
                        </Form.Group>
                    </Form>
                    <ToastContainer autoClose={1500} />
                    <Button className="w-100 btn-wh bg-primary-grad text-white fw-bold" onClick={onSubmit} disabled={disabled}>
                        <LoadingButtonLabel label={"Login"} disabled={disabled} />
                    </Button>
                </Col>
            </Col>
        </Row >
    )
}

export default Login