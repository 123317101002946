import { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, FormCheck, InputGroup, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Layout, SortingButton, TableLayout, } from '../../components';
import { IconAdd, IconClose, IconDelete, IconEdit, IconFile, IconSearch } from '../../components/Icons';
import { LangContext } from '../../context/Lang';
import { PageContext } from '../../context/Page';
import { customerRoute, dataService, deleteService, getCustomerRoute, getData, getDataService, putMethod, regionRoute, responseService, townshipRoute, inputChangeService, getNumber } from '../../services';
import AddCustomerModal from './components/AddCustomerModal';

const Index = () => {
    const history = useHistory()
    const { dispatch: { translate } } = useContext(LangContext)
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); setShowEdit(false) };
    const handleShow = () => setShow(true);
    const [showEdit, setShowEdit] = useState(false);
    const handleShowEdit = () => setShowEdit(true);
    const [data, setData] = useState([])
    const [isDifferentAddress, changeDifferentAddress] = useState(false)
    const [regions, setRegions] = useState([])
    const [regionCode, setRegionCode] = useState("")
    const [shippingRegionCode, setShippingRegionCode] = useState("")
    const [townships, setTownships] = useState([])
    const [shippingTownships, setShippingTownships] = useState([])
    const [formData, setFormData] = useState({
        personId: "",
        fullName: "",
        email: "",
        facebookUrl: "",
        phone: "",
        phoneOther: "",
        billingAddress: "",
        billingSubSubAreaCode: "",
        billingNote: "",
        shippingAddress: "",
        shippingSubSubAreaCode: "",
        shippingNote: "",
    })
    const required = ["fullName", "phone"]
    const [page, setPage] = useContext(PageContext)

    useEffect(() => {
        handleGetData(page.current, page.sorting, "")
        // eslint-disable-next-line
    }, [])

    const handleGetData = (skip: number, sorting: string, keyword: string) => {
        getDataService(getCustomerRoute(skip, sorting, keyword), setLoading, setData, setPage, skip, sorting)
    }

    const responseAction = (result: any) => {
        responseService(result, handleGetData, handleClose, page.current)
    }

    const handleGetRegions = async () => {
        getData(regionRoute).then(result => {
            setRegions(result.data)
        })
    }

    const handleGetTownships = async (regionCode: string) => {
        getData(townshipRoute(regionCode)).then(result => {
            setTownships(result.data)
        })
    }

    const handleSelectRegion = (evt: any) => {
        const value = evt.target.value
        setRegionCode(value)
        handleGetTownships(value)
    }

    const handleGetShippingTownships = async (regionCode: string) => {
        getData(townshipRoute(regionCode)).then(result => {
            setShippingTownships(result.data)
        })
    }

    const handleSelectShippingRegion = (evt: any) => {
        const value = evt.target.value
        setShippingRegionCode(value)
        handleGetShippingTownships(value)
    }

    const handleChange = (evt: any, wantString?: boolean) => {
        inputChangeService(evt, formData, setFormData, wantString);
    }

    const showEditForm = (item: any) => {
        handleGetRegions()
        if (item?.billingAddresses && item?.billingAddresses.length > 0) {
            setRegionCode(item?.billingAddresses[0].areaCode)
            handleGetTownships(item?.billingAddresses[0].areaCode)
        } else {
            setRegionCode("")
        }

        if (item?.shippingAddresses && item?.shippingAddresses.length > 0) {
            setShippingRegionCode(item?.shippingAddresses[0].areaCode)
            handleGetShippingTownships(item?.shippingAddresses[0].areaCode)
        }

        if (item?.billingAddresses && item?.billingAddresses.length > 0 && item?.shippingAddresses && item?.shippingAddresses.length > 0) {
            if (item?.billingAddresses[0].areaCode === item?.shippingAddresses[0].areaCode && item?.billingAddresses[0].address === item?.shippingAddresses[0].address && item?.billingAddresses[0].note === item?.shippingAddresses[0].note) {
                changeDifferentAddress(false)
            } else {
                changeDifferentAddress(true)
            }
        } else {
            changeDifferentAddress(true)
        }



        setFormData({
            personId: item?.id,
            fullName: item?.fullName,
            email: item?.email,
            facebookUrl: item?.facebookUrl,
            phone: item?.phone,
            phoneOther: item?.phoneOther,
            billingAddress: item?.billingAddresses && item?.billingAddresses.length > 0 ? item?.billingAddresses[0].address : "",
            billingSubSubAreaCode: item?.billingAddresses && item?.billingAddresses.length > 0 ? item?.billingAddresses[0].subSubAreaCode : "",
            billingNote: item?.billingAddresses && item?.billingAddresses.length > 0 ? item?.billingAddresses[0].note : "",
            shippingAddress: item?.shippingAddresses && item?.shippingAddresses.length > 0 ? item?.shippingAddresses[0].address : "",
            shippingSubSubAreaCode: item?.shippingAddresses && item?.shippingAddresses.length > 0 ? item?.shippingAddresses[0].subSubAreaCode : "",
            shippingNote: item?.shippingAddresses && item?.shippingAddresses.length > 0 ? item?.shippingAddresses[0].note : "",
        })
        handleShowEdit();
    }

    const handleSubmit = () => {
        let shippingAddress = {
            address: formData.shippingAddress || "",
            subSubAreaCode: formData.shippingSubSubAreaCode || null,
            note: formData.shippingNote || "",
        }

        let billingAddress = {
            address: formData.billingAddress || "",
            subSubAreaCode: formData.billingSubSubAreaCode || null,
            note: formData.billingNote || "",
        }


        let _formData = {
            personId: formData.personId,
            fullName: formData.fullName,
            email: formData.email,
            phone: formData.phone,
            phoneOther: formData.phoneOther,
            facebookUrl: formData.facebookUrl,
            shippingAddress: isDifferentAddress ? shippingAddress : billingAddress,
            billingAddress: billingAddress,
        }

        dataService(putMethod, _formData, required, customerRoute).then(result => {
            responseAction(result)
        })
    }

    const handleEnterAction = (e: any) => {
        if (e.keyCode === 13) {
            handleGetData(page.current, page.sorting, e.target.value)
        }
    }


    return (
        <Layout title={translate("customers")}>
            <section className="d-flex justify-content-between mb-4">
                <AddCustomerModal show={show} handleClose={handleClose} responseAction={responseAction} />
                <div>
                    <Button className="text-white bg-primary-grad me-2" onClick={handleShow}><IconAdd /> <span className="ms-2">{translate('createNew')}</span></Button>
                    <SortingButton page={page} setPage={setPage} handleGetData={handleGetData} />
                </div>


                <Col md={4}>
                    <InputGroup className="search-input">
                        <InputGroup.Text className="bg-primary-grad text-white">
                            <IconSearch />
                        </InputGroup.Text>
                        <Form.Control placeholder="Search" onKeyDown={(e) => handleEnterAction(e)} />
                        {false && <OverlayTrigger overlay={<Tooltip>Clear Search</Tooltip>} >
                            <InputGroup.Text className="bg-danger cursor">
                                <IconClose />
                            </InputGroup.Text>
                        </OverlayTrigger>}
                    </InputGroup>
                </Col>
            </section>

            <TableLayout loading={loading} data={data} page={page} setPage={setPage} handleGetData={handleGetData}>
                <thead>
                    <tr>
                        <th className='ps-4'>#</th>
                        <th>Code</th>
                        <th>{translate('name')}</th>
                        <th>{translate('phone')}</th>
                        <th>{translate('region')}</th>
                        <th>{translate('township')}</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {data.map((item: any, index: any) =>
                        <tr key={index}>
                            <td className='ps-4'>{getNumber(index, page?.current)}</td>
                            <td>{item.uniqueCode}</td>
                            <td>{item.fullName}</td>
                            <td>{item.phone}</td>
                            <td>{item.shippingAddresses && item.shippingAddresses.length > 0 ? item.shippingAddresses[0]?.areaNameLocal || "-" : "-"}</td>
                            <td>{item.shippingAddresses && item.shippingAddresses.length > 0 ? item.shippingAddresses[0].subSubAreaNameLocal || "-" : "-"}</td>
                            <td>
                                <span className="flex-center">
                                    <Button className="btn-sm ms-2" onClick={() => history.push({
                                        pathname: '/customer-detail',
                                        state: { customer: item }
                                    })}><IconFile /></Button>

                                    <Button className="btn-sm ms-2" onClick={() => showEditForm(item)}><IconEdit /></Button>
                                    <Button className="btn-danger btn-sm ms-2"
                                        onClick={() =>
                                            deleteService(
                                                item.fullName,
                                                {
                                                    "personId": item.id
                                                },
                                                customerRoute,
                                                responseAction)}><IconDelete />
                                    </Button>
                                </span>
                            </td>

                        </tr>
                    )}
                </tbody>
            </TableLayout>


            <Modal show={showEdit} onHide={handleClose} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{translate("editInfo")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-4 input-form-control" >
                                <Form.Label className="text-muted">{translate("name")} *</Form.Label>
                                <Form.Control type="text" id="fullName" onChange={handleChange} value={formData?.fullName} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-4 input-form-control" >
                                <Form.Label className="text-muted">{translate("phone")} *</Form.Label>
                                <Form.Control type="text" id="phone" onChange={handleChange} value={formData?.phone} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-4 input-form-control" >
                                <Form.Label className="text-muted">Other phone</Form.Label>
                                <Form.Control type="text" id="phoneOther" onChange={handleChange} value={formData?.phoneOther} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-4 input-form-control" >
                                <Form.Label className="text-muted">{translate("email")}</Form.Label>
                                <Form.Control type="text" id="email" onChange={handleChange} value={formData?.email} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-4 input-form-control" >
                                <Form.Label className="text-muted">Facebook URL</Form.Label>
                                <Form.Control type="text" id="facebookUrl" onChange={handleChange} value={formData?.facebookUrl} />
                            </Form.Group>
                        </Col>
                        {/* <Col md={6}>
                            <Form.Group className="mb-4 input-form-control" >
                                <Form.Label className="text-muted">Note</Form.Label>
                                <Form.Control type="text" id="note" onChange={handleChange} value={formData?.note} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-4 input-form-control" >
                                <Form.Label className="text-muted">{translate("region")}</Form.Label>
                                <Form.Control as="select" aria-label="Select" className="mb-4" id="areaCode" value={regionCode} onChange={handleSelectRegion}>
                                    <option value="">Select</option>
                                    {regions.map((item: any, index: number) =>
                                        <option value={item.areaCode} key={index}>{item.areaNameLocal}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-4 input-form-control" >
                                <Form.Label className="text-muted">{translate("township")}</Form.Label>
                                <Form.Control as="select" aria-label="Select" className="mb-4" id="subSubAreaCode" value={formData?.subSubAreaCode} onChange={(evt) => handleChange(evt, true)}>
                                    <option value="">Select</option>
                                    {townships.map((item: any, index: number) =>
                                        <option value={item.subSubAreaCode} key={index}>{item.subSubAreaNameLocal}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-4 input-form-control" >
                                <Form.Label className="text-muted">{translate("address")}</Form.Label>
                                <Form.Control type="text" id="address" onChange={handleChange} value={formData?.address} />
                            </Form.Group>
                        </Col> */}
                    </Row>
                    <Row className='align-items-center'>
                        <Col>
                            <h6 className='mb-0'>Billing address</h6>
                        </Col>
                        <Col>
                            <div className='flex-between align-items-center'>

                                <h6 className='mb-0'>Shipping address</h6>

                                <FormCheck
                                    id="switch"
                                    className="switch"
                                    type="switch"
                                    label={"Different address"}
                                    checked={isDifferentAddress}
                                    onChange={() => changeDifferentAddress(!isDifferentAddress)}
                                />

                                {/* <Button className='bg-grad btn-sm'  onClick={() => {
                                if (shippingRegionCode) {
                                    handleGetTownships(shippingRegionCode)
                                }

                                setRegionCode(shippingRegionCode)

                                setFormData({
                                    ...formData,
                                    billingAddress: formData.shippingAddress || "",
                                    billingSubSubAreaCode:formData.shippingSubSubAreaCode || "",
                                    billingNote: formData.shippingNote || "",
                                })
                            }}>Same as shipping address</Button> */}
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className='p-3 rounded bg-light mt-3'>

                                <Row>
                                    <Col md={12}>
                                        <Form.Group className="mb-4 input-form-control" >
                                            <Form.Label className="text-muted">{translate("region")}</Form.Label>
                                            <Form.Control as="select" aria-label="Select" className="mb-4" id="areaCode" value={regionCode} onChange={handleSelectRegion}>
                                                <option value="">Select</option>
                                                {regions.map((item: any, index: number) =>
                                                    <option value={item.areaCode} key={index}>{item.areaNameLocal}</option>
                                                )}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="mb-4 input-form-control" >
                                            <Form.Label className="text-muted">{translate("township")}</Form.Label>
                                            <Form.Control as="select" aria-label="Select" className="mb-4" id="billingSubSubAreaCode" value={formData?.billingSubSubAreaCode} onChange={(evt) => handleChange(evt, true)}>
                                                <option value="">Select</option>
                                                {townships.map((item: any, index: number) =>
                                                    <option value={item.subSubAreaCode} key={index}>{item.subSubAreaNameLocal}</option>
                                                )}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="mb-4  input-form-control" >
                                            <Form.Label className="text-muted">{translate("address")}</Form.Label>
                                            <Form.Control type="text" id="billingAddress" onChange={handleChange} value={formData?.billingAddress} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="input-form-control" >
                                            <Form.Label className="text-muted">Note</Form.Label>
                                            <Form.Control type="text" id="billingNote" onChange={handleChange} value={formData?.billingNote} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col>
                            <div className='p-3 rounded bg-light mt-3'>
                                {isDifferentAddress ? <Row>
                                    <Col md={12}>
                                        <Form.Group className="mb-4 input-form-control" >
                                            <Form.Label className="text-muted">{translate("region")}</Form.Label>
                                            <Form.Control as="select" aria-label="Select" className="mb-4" id="areaCode" value={shippingRegionCode} onChange={handleSelectShippingRegion}>
                                                <option value="">Select</option>
                                                {regions.map((item: any, index: number) =>
                                                    <option value={item.areaCode} key={index}>{item.areaNameLocal}</option>
                                                )}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="mb-4 input-form-control" >
                                            <Form.Label className="text-muted">{translate("township")}</Form.Label>
                                            <Form.Control as="select" aria-label="Select" className="mb-4" id="shippingSubSubAreaCode" value={formData?.shippingSubSubAreaCode} onChange={(evt) => handleChange(evt, true)}>
                                                <option value="">Select</option>
                                                {shippingTownships.map((item: any, index: number) =>
                                                    <option value={item.subSubAreaCode} key={index}>{item.subSubAreaNameLocal}</option>
                                                )}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="mb-4  input-form-control" >
                                            <Form.Label className="text-muted">{translate("address")}</Form.Label>
                                            <Form.Control type="text" id="shippingAddress" onChange={handleChange} value={formData?.shippingAddress} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="input-form-control" >
                                            <Form.Label className="text-muted">Note</Form.Label>
                                            <Form.Control type="text" id="shippingNote" onChange={handleChange} value={formData?.shippingNote} />
                                        </Form.Group>
                                    </Col>
                                </Row> : <Row>
                                    <Col md={12}>
                                        <Form.Group className="mb-4 input-form-control" >
                                            <Form.Label className="text-muted">{translate("region")}</Form.Label>
                                            <Form.Control as="select" aria-label="Select" className="mb-4" id="areaCode" value={regionCode} onChange={handleSelectRegion} disabled>
                                                <option value="">Select</option>
                                                {regions.map((item: any, index: number) =>
                                                    <option value={item.areaCode} key={index}>{item.areaNameLocal}</option>
                                                )}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="mb-4 input-form-control" >
                                            <Form.Label className="text-muted">{translate("township")}</Form.Label>
                                            <Form.Control as="select" aria-label="Select" className="mb-4" id="billingSubSubAreaCode" value={formData?.billingSubSubAreaCode} onChange={(evt) => handleChange(evt, true)} disabled>
                                                <option value="">Select</option>
                                                {townships.map((item: any, index: number) =>
                                                    <option value={item.subSubAreaCode} key={index}>{item.subSubAreaNameLocal}</option>
                                                )}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="mb-4  input-form-control" >
                                            <Form.Label className="text-muted">{translate("address")}</Form.Label>
                                            <Form.Control type="text" id="billingAddress" onChange={handleChange} value={formData?.billingAddress} disabled />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="input-form-control" >
                                            <Form.Label className="text-muted">Note</Form.Label>
                                            <Form.Control type="text" id="billingNote" onChange={handleChange} value={formData?.billingNote} disabled />
                                        </Form.Group>
                                    </Col>
                                </Row>}
                            </div>
                        </Col>

                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </Layout>
    );
};

export default Index;


