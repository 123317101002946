
import React, { useContext } from "react";
import { Table } from "react-bootstrap";
import { LangContext, PageContext } from "../../../context";
import { getNumber, handleDateFormat, numberFormat } from "../../../services";


const Print = React.forwardRef((props: any, ref: any) => {
    const { dispatch: { translate } } = useContext(LangContext);
    const data = props.data
    const [page] = useContext(PageContext)

    return (
        <section ref={ref} className="default-margin">
            <div className="d-flex flex-column align-items-center mb-2">
                <h6 className="mt-2 mb-1 fw-bold">{translate('deliveryList')}</h6>
            </div>

            <Table className="mb-0 print-table" bordered striped>
                <thead>
                    <tr>
                        <th><span className='flex-center'>{translate('no.')}</span></th>
                        <th><span className='flex-center'>{translate('saleNumber')}</span></th>
                        <th><span className='flex-center'>{translate('customer')}</span></th>
                        <th><span className='flex-center'>Delivery</span></th>
                        <th><span className='flex-center'>{translate('location')}</span></th>
                        <th><span className='flex-center'>{translate('quantity')}</span></th>
                        <th><span className='flex-center'>Subtotal</span></th>
                        <th><span className='flex-center'>{translate('deliveryFee')}</span></th>
                        <th><span className='flex-center'>{translate('total')}</span></th>
                        <th><span className='flex-center'>{translate('date')}</span></th>
                    </tr>
                </thead>
                <tbody>
                    {data && data.length > 0 && data.map((item: any, index: number) =>
                        <tr key={index}>
                            <td><span className="flex-center">{getNumber(index, page?.current)}</span></td>
                            <td>{item.saleOrdersCode}</td>
                            <td>{item.customer.fullName}</td>
                            <td>{item.deliveryService || "-"}</td>
                            <td>{item.locationName || "-"}</td>
                            <td><span className="flex-end">{item.totalQty}</span></td>
                            <td><span className="flex-end">{numberFormat(item.totalAmount - item.deliveryFee)}</span></td>
                            <td><span className="flex-end">{numberFormat(item.deliveryFee)}</span></td>
                            <td><span className="flex-end">{numberFormat(item.totalAmount)}</span></td>
                            <td>{handleDateFormat(item.createdAt)}</td>
                        </tr>)}
                    {/* <tr>
                        <th />
                        <th />
                        <th />
                        <th />
                        <th />
                        <th><span className="flex-end">123</span></th>
                        <th><span className="flex-end">23,343,32</span></th>
                        <th><span className="flex-end">23,343,32</span></th>
                        <th><span className="flex-end">23,343,32</span></th>
                        <th />
                    </tr> */}
                </tbody>
            </Table>
        </section >
    );
});

export default Print