import { useContext } from "react";
import { Col, Form, InputGroup, Pagination } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { VariableContext } from "../context";

export const PgComponent = ({
    currentPage,
    fromCount,
    total,
    paginationLimit,
    pageInfo,
    setPage,
    handleGetData,
    disableScrollTop,
    disablePageInput,
    isFilter,
    handleChangePage
}: {
    currentPage: number,
    fromCount: number,
    total: number,
    paginationLimit: number,
    pageInfo: any,
    setPage: any,
    handleGetData: any,
    disableScrollTop?: boolean,
    disablePageInput?: boolean,
    isFilter?: boolean,
    handleChangePage?: any
}) => {

    const pagination = [];
    const { pathname } = useLocation()
    const splitLocation = pathname.split('/')[1]
    const [variable] = useContext(VariableContext)
    let pageTotal = Math.ceil(total / paginationLimit);
    let ellipsisLeft = false
    let ellipsisRight = false

    for (let i = 1; i <= pageTotal; i++) {
        if (i === currentPage) {
            pagination.push({ id: i, current: true, ellipsis: false })
        } else {
            if (
                i < 2 ||
                i > pageTotal - 1 ||
                i === currentPage - 1 ||
                i === currentPage + 1
            ) {
                pagination.push({ id: i, current: false, ellipsis: false })
            } else if (i > 1 && i < currentPage && !ellipsisLeft) {
                pagination.push({ id: i, current: false, ellipsis: true })
                ellipsisLeft = true
            } else if (i < pageTotal && i > currentPage && !ellipsisRight) {
                pagination.push({ id: i, current: false, ellipsis: true })
                ellipsisRight = true
            }
        }
    }

    const handlePage = (skip: number) => {
        setPage({ ...pageInfo, current: skip, total: total })
        !disableScrollTop && window.scrollTo(0, 0)

        if (splitLocation === "products") {
            let productCode = variable?.productSearchType === "productCode" ? variable?.searchString : null
            let productName = variable?.productSearchType !== "productCode" ? variable?.searchString : null

            if (isFilter) {
                handleChangePage(skip)
            } else {
                handleGetData(skip, pageInfo.sorting, productName, productCode)
            }
        } else {
            if (isFilter) {
                handleChangePage(skip)
            } else {
                handleGetData(skip, pageInfo.sorting)
            }
        }

    };

    const handleEnterAction = (event: any) => {
        if (event.keyCode === 13) {
            handlePage((event.target.value ? event.target.value : currentPage) - 1)
        }
    }

    return (
        <Pagination>

            <Pagination.Prev disabled={fromCount === 0}
                onClick={() => handlePage(fromCount - 1)} />

            {pagination.map((page, index) => {
                if (!page.ellipsis) {
                    return <Pagination.Item onClick={() => handlePage(page.id - 1)} key={index} active={page.current}>{page.id}</Pagination.Item>;
                } else {
                    return <Pagination.Ellipsis key={index} disabled />
                }
            }
            )}

            <Pagination.Next disabled={pageTotal === currentPage}
                onClick={() => handlePage(fromCount + 1)} className="pagination-last-item" />

            {!disablePageInput && <Col className="ms-4 align-items-center" md={2}>
                <InputGroup>
                    <InputGroup.Text className="bg-primary-grad text-white">
                        Page
                    </InputGroup.Text>

                    <Form.Control
                        placeholder={currentPage.toString()}
                        type="number"
                        max={pageTotal}
                        min={1}
                        className="justify-content-center text-center"
                        onKeyDown={(e) => handleEnterAction(e)}
                    />

                    <InputGroup.Text className="bg-primary-grad text-white">
                        of {pageTotal}
                    </InputGroup.Text>
                </InputGroup>
            </Col>}

        </Pagination >
    );
};