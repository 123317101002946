import { useEffect, useState } from "react"
import { Row, Col } from "react-bootstrap"
import { deliveryCountRoute, getDataService, numberFormat } from "../../../services"

function Count() {
    const [data, setData] = useState([])
    const [, setLoading] = useState(false)

    useEffect(() => {
        handleGetData()
        // eslint-disable-next-line
    }, [])

    const handleGetData = () => {
        getDataService(deliveryCountRoute, setLoading, setData)
    }

    const getCount = (delivery: string) => {
        let count = 0;
        data.forEach((item: any) => {
            if (item.deliveryStatus === delivery) {
                count = item.count;
            }
        })
        return numberFormat(count);
    }

    return (
        <Row className="mb-2">
            <Col md={6} xl={3}>
                <section className="widget-content bg-arielle-smile">
                    <section>All</section>
                    <section className="widget-numbers"><span>{getCount("All")}</span></section>
                </section>
            </Col>
            <Col md={6} xl={3}>
                <section className="widget-content bg-arielle-smile">
                    <section>Pending</section>
                    <section className="widget-numbers"><span>{getCount("Pending")}</span></section>
                </section>
            </Col>
            <Col md={6} xl={3}>
                <section className="widget-content bg-arielle-smile">
                    <section>Ready to Ship</section>
                    <section className="widget-numbers"><span>{getCount("Ready to Ship")}</span></section>
                </section>
            </Col>
            <Col md={6} xl={3}>
                <section className="widget-content bg-night-fade">
                    <section>Delivered</section>
                    <section className="widget-numbers"><span>{getCount("Delivered")}</span></section>
                </section>
            </Col>
        </Row>
    )
}

export default Count