import { Col, Container, Row } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"

export const TableLoading = ({ rowCount = 15 }: { rowCount?: number }) => {
    return (
        <Skeleton count={rowCount} height={50} className="mb-2" borderRadius={10} />
    )
}

export const ImageLoading = () => {
    return (
        <Skeleton count={1} height={180} width={180} className="mt-3" borderRadius={10} />
    )
}

export const CustomerLoading = () => {
    return (
        <Skeleton count={1} height={300} className="mt-2" borderRadius={10} />
    )
}

export const DetailLoading = () => {
    return (
        <>
            <Skeleton count={1} height={400} className="mb-4" borderRadius={10} />
            <Row>
                <Col>
                    <Skeleton count={1} height={100} borderRadius={10} />
                </Col>
                <Col>
                    <Skeleton count={1} height={100} borderRadius={10} />
                </Col>
                <Col>
                    <Skeleton count={1} height={100} borderRadius={10} />
                </Col>
                <Col>
                    <Skeleton count={1} height={100} borderRadius={10} />
                </Col>
            </Row>
            <Skeleton count={1} height={400} className="mt-4" borderRadius={10} />
        </>
    )
}

export const OrderDetailLoading = () => {
    return (
        <Row>
            <Col md={8}>
                <Skeleton count={1} height={700} borderRadius={10} />
            </Col>
            <Col>
                <Skeleton count={1} height={500} borderRadius={10} />
            </Col>
        </Row>
    )
}

export const EditOrderDetailLoading = () => {
    return (
        <>
            <Skeleton count={1} height={300} className="mb-2" borderRadius={10} />
            <Skeleton count={1} height={150} className="mb-2" borderRadius={10} />
            <Skeleton count={1} height={150} className="mb-2" borderRadius={10} />
        </>
    )
}


export const ProductGridLoading = () => {
    return (
        <>
            <Row>
                <Col>
                    <Skeleton count={1} height={250} className="mb-4" borderRadius={10} />
                </Col>
                <Col>
                    <Skeleton count={1} height={250} className="mb-4" borderRadius={10} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Skeleton count={1} height={250} className="mb-4" borderRadius={10} />
                </Col>
                <Col>
                    <Skeleton count={1} height={250} className="mb-4" borderRadius={10} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Skeleton count={1} height={250} className="mb-4" borderRadius={10} />
                </Col>
                <Col>
                    <Skeleton count={1} height={250} className="mb-4" borderRadius={10} />
                </Col>
            </Row>
        </>
    )
}

export const AddOrderLoading = () => {
    return (
        <Container>
            <Container className='mb-4'>
                <Row>
                    <Col md={3} className="d-flex justify-content-end">
                        <Skeleton count={1} width={240} height={240} borderRadius={10} />
                    </Col>
                    <Col md={4}>
                        <Skeleton count={1} height={240} borderRadius={10} />
                    </Col>
                    <Col md={4}>
                        <Skeleton count={1} height={240} borderRadius={10} />
                    </Col>
                </Row>
            </Container>
            <Skeleton count={5} height={50} className="mb-2" borderRadius={10} />
        </Container>
    )
}

export const ProductListLoading = () => {
    return (
        <Skeleton count={5} height={195} className="mb-3" borderRadius={10} />
    )
}