import { useContext, useEffect, useState } from "react";
import { Badge, Button, Col, Form, Modal, Row, Table, } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom"
import swal from "sweetalert";
import { LoadingButtonLabel, NoData, PgComponent, SortingButton, TableLoading } from "../../../components";
import { IconAdd, IconDelete, IconEdit, IconFile, IconLeftArrow, IconLeftRightArrow } from "../../../components/Icons";
import Layout from "../../../components/Layout"
import { LangContext, ThirdPageContext } from "../../../context";
import { actionData, dataService, deleteService, getData, getInventoryRoute, getSKUSuggForTransferRoute, handleClose, handleDateFormat, handleDateTimeFormat, handleShow, inputChangeService, inventoryRoute, numberFormat, postMethod, putMethod, responseService, } from "../../../services";
import { disableScroll } from "../../../utils";
import Select from 'react-select';
import { toast } from "react-toastify";
const limit: any = process.env.REACT_APP_PAGINATION_LIMIT

function Detail() {
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [showTransfer, setShowTransfer] = useState(false);
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    const { dispatch: { translate } } = useContext(LangContext);
    const location: any = useLocation();
    // const product = location.state.product
    const variant = location.state.variant
    const [variants, setVariants] = useState([])
    const [selectedVariant, setSelectedVariant] = useState<any>()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useContext(ThirdPageContext)
    const tempUser: any = localStorage.getItem("USER")
    const user = tempUser && JSON.parse(tempUser)
    const [totalStock, setTotalStock] = useState(0);
    const [formData, setFormData] = useState({
        inventoryId: "",
        productUnitsId: 1,
        // warehousesId: "",
        productVariantsId: variant.id,
        quantity: "",
        stockEntry: "In",
        trxTypes: "Receive",
        notes: ""
    })

    const [transferQuantity, setTransferQuantity] = useState("")

    const required = ["quantity"]

    useEffect(() => {
        handleGetData(page.current, page.sorting)
        // eslint-disable-next-line
    }, [])

    const handleGetData = (skip: number, sorting: string) => {
        setLoading(true)
        getData(getInventoryRoute(variant.id, skip, sorting)).then((result) => {
            setData(result.data)
            let total = 0;

            if (result.data && result.data.length > 0) {
                result.data.forEach((item: any) => {
                    if (item.stockEntry === "In") {
                        total += item.quantity
                    }
                    else {
                        total -= item.quantity
                    }
                })

                setTotalStock(total)
            }

            setPage &&
                setPage({
                    current: skip,
                    total: result.count,
                    sorting: sorting || 'desc',
                })
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        })
    }

    const variantInputChange = (keyword?: string) => {
        getData(getSKUSuggForTransferRoute({ productId: variant.productsId, keyword: keyword, skuFor: variant.skuFor === "Retail" ? "Wholesale" : "Retail" })).then(result => {
            let temp: any = []

            result.data.forEach((item: any) => {
                temp.push({
                    value: item.sku,
                    label: `${item.sku} (${item.productName})`,
                    sku: item.sku,
                })
            })
            setVariants(temp)
        })
    }


    const handleChange = (evt: any, wantString?: boolean) => {
        inputChangeService(evt, formData, setFormData, wantString);
    }

    const handleSubmit = () => {
        setLoading(true)
        dataService(formData.inventoryId ? putMethod : postMethod, formData, required, inventoryRoute).then(result => {
            handleResponse(result)
        })
    }

    const handleResponse = (result: any) => {
        responseService(result, handleGetData, () => handleClose(setShow), page.current, null, setLoading)
    }

    const showForm = (item?: any) => {
        setFormData({
            productUnitsId: 1,
            // warehousesId: "",
            inventoryId: item?.inventoryId || "",
            productVariantsId: variant.id,
            quantity: item?.quantity || "",
            stockEntry: item?.stockEntry || "In",
            trxTypes: item?.trxTypes || "Receive",
            notes: item?.notes || ""
        })
        handleShow(setShow);
    }

    const handleDelete = (item: any) => {
        deleteService('this inventory', { "inventoryId": item.inventoryId }, 'inventories', handleResponse)
    }

    const handleSubmitTransfer = () => {
        if (selectedVariant) {
            if (transferQuantity) {
                if (parseInt(transferQuantity) <= totalStock) {
                    let _formData = {
                        quantity: parseInt(transferQuantity),
                        fromSKU: variant?.sku,
                        toSKU: selectedVariant.sku
                    }
                    actionData(putMethod, `inventory/transfer`, _formData).then((result) => {
                        if (result.success) {
                            toast.success("Successfully transfer!")
                            handleGetData(page.current, page.sorting)
                            handleClose(setShowTransfer)
                        } else {
                            toast.error("Something wrong!")
                            setLoading(false)
                        }
                    })
                } else {
                    toast.error("Transfer quantity is greater than existing quantity")
                }
            } else {
                toast.error("Please, fill transfer quantity")
            }
        } else {
            toast.error("Please, select variant")
        }
    }

    return (
        <Layout title={'Variant Detail'}>
            <section className="cursor mb-4" onClick={() => history.goBack()}>
                <p className="mb-0"><IconLeftArrow /> {translate('productDetail')}</p>
            </section>

            <section className="bg-card mb-4">
                <Row>
                    <Col md={6}>
                        <small className="text-muted d-block mb-1">Product Name</small>
                        <h6 className="mb-4">{variant.productName || ""}</h6>
                    </Col>
                    <Col md={6}>
                        <small className="text-muted d-block mb-1">Product Code</small>
                        <h6 className="mb-4">{variant.productCode || ""}</h6>
                    </Col>
                    <Col md={6}>
                        <small className="text-muted d-block mb-1">SKU</small>
                        <h6 className="mb-4">{variant.sku}</h6>
                    </Col>
                    <Col md={6}>
                        <small className="text-muted d-block mb-1">Size</small>
                        <h6 className="mb-4">{variant.productSizes || "-"}</h6>
                    </Col>
                    <Col md={6}>
                        <small className="text-muted d-block mb-1">{translate('color')}</small>
                        <h6 className="mb-4">{variant.productColors || "-"}</h6>
                    </Col>
                    <Col md={6}>
                        <small className="text-muted d-block mb-1">Stock</small>
                        <h6 className="mb-4">{numberFormat(totalStock)}</h6>
                    </Col>
                    <Col md={6}>
                        <small className="text-muted d-block mb-1">Total cost</small>
                        <h6 className="mb-4">{numberFormat(variant.investedCost)} {variant.investedCost && "MMK"}</h6>
                    </Col>
                    {variant.skuFor === "Retail" && <Col md={6}>
                        <small className="text-muted d-block mb-1">Retail price</small>
                        <h6 className="mb-4">{numberFormat(variant.retailPrice)} {variant.retailPrice && "MMK"}</h6>
                    </Col>}
                    <Col md={6}>
                        <small className="text-muted d-block mb-1">Wholesale price</small>
                        <h6 className="mb-4">{numberFormat(variant.wholesalePrice)} {variant.wholesalePrice && "MMK"}</h6>
                    </Col>
                    <Col md={6}>
                        <small className="text-muted d-block mb-1">Created Date</small>
                        <h6 className="mb-4">{handleDateFormat(variant.createdAt)}</h6>
                    </Col>
                </Row>
            </section>

            <section className="bg-card mb-4">
                <section className="flex-between mb-4 align-items-center">
                    <Form.Label>Inventory</Form.Label>
                    <section>
                        <SortingButton page={page} setPage={setPage} handleGetData={handleGetData} />
                        <Button className="text-white bg-primary-grad ms-2" onClick={() => { handleShow(setShowTransfer); setSelectedVariant(null); setTransferQuantity(""); variantInputChange() }}><IconLeftRightArrow /> <span className="ms-2">Transfer</span></Button>
                        <Button className="text-white bg-primary-grad ms-2" onClick={showForm}><IconAdd /> <span className="ms-2">{translate("createNew")}</span></Button>
                    </section>
                </section>

                {loading ?
                    <TableLoading rowCount={5} />
                    :
                    <>
                        {data.length > 0 ?
                            <Table responsive className="mb-0 border center-table" bordered>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        {/* <th>Warehouse</th> */}
                                        <th>{translate('date')}</th>
                                        <th>Stock entry</th>
                                        <th>Transition type</th>
                                        <th>Note</th>
                                        <th><span className="flex-end">{translate("quantity")}</span></th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item: any, index: any) =>
                                        <tr key={index}>
                                            <td><span className="flex-center">{index + 1}</span></td>
                                            {/* <td>{item.warehouse}</td> */}
                                            <td><span className="flex-center">{handleDateTimeFormat(item.createdAt)}</span></td>
                                            <td><span className="flex-center"><Badge className={item.stockEntry === "In" ? "" : "bg-danger"}>{item.stockEntry}</Badge></span></td>
                                            <td><span className="flex-center">{item.trxTypes}</span></td>
                                            <td><span className="flex-center">{item.notes ? <Button className="btn-sm" onClick={() => {
                                                swal(item.notes);
                                            }}><IconFile /></Button> : "-"}</span></td>
                                            <td><span className="flex-end">{item.quantity}</span></td>
                                            <td>{item.trxTypes !== "Transfer" && <span>{(user?.userRole === "Owner" || user?.userRole === "Admin") && <span className="flex-center">
                                                <Button className="btn-sm" onClick={() => showForm(item)}><IconEdit /></Button>
                                                <Button className="btn-danger btn-sm ms-2" onClick={() => handleDelete(item)}>
                                                    <IconDelete />
                                                </Button>
                                            </span>}</span>}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                            :
                            <NoData noImage />
                        }


                        {(page && page.total > limit) && <PgComponent
                            currentPage={page.current + 1 || 0}
                            fromCount={page.current || 0}
                            total={page.total || 0}
                            paginationLimit={limit}
                            pageInfo={page}
                            setPage={setPage}
                            handleGetData={handleGetData}
                        />}
                    </>
                }
            </section>

            <Modal show={show} onHide={() => handleClose(setShow)} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{variant.productName} ({variant.productCode})</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-4">

                    <section className="bg-light mb-3 rounded p-3">
                        <section className="d-flex flex-between">
                            <small className="text-muted d-block "><span className="fw-bold">{variant.sku}</span></small>
                            {/* {product.productTypes.id === 1 && <small className="text-muted d-block ">Size : <span className="fw-bold">{variant.productSizes}</span></small>} */}
                            {variant.productSizes && <small className="text-muted d-block ">Size : <span className="fw-bold">{variant.productSizes || "-"}</span></small>}
                            {variant.productColors && <small className="text-muted d-block ">{translate('color')} : <span className="fw-bold">{variant.productColors}</span></small>}
                        </section>
                    </section>

                    <Row>
                        {/* <Col md={6}>
                            <Form.Group className="mb-4 input-form-control" controlId="warehousesId">
                                <Form.Label>Warehouse *</Form.Label>
                                <Form.Control as="select" aria-label="Select" onChange={handleChange} value={formData?.warehousesId}>
                                    <option value="">Select</option>
                                    {warehouses.map((item: any, index: number) =>
                                        <option value={item.id} key={index}>{item.warehouse}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col> */}
                        <Col md={6}>
                            <Form.Group className="mb-4 input-form-control" controlId="stockEntry">
                                <Form.Label>Stock entry</Form.Label>
                                <Form.Control as="select" aria-label="Select"
                                    onChange={(evt) => {
                                        if (evt.target.value === "In") {
                                            setFormData({ ...formData, trxTypes: "Receive", stockEntry: evt.target.value })
                                        } else {
                                            setFormData({ ...formData, trxTypes: "Goods Issue", stockEntry: evt.target.value })
                                        }
                                        //handleChange(evt, true);
                                    }}
                                    value={formData?.stockEntry}
                                >
                                    <option value="In">In</option>
                                    <option value="Out">Out</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            {formData.stockEntry === "In" ? <Form.Group className="mb-4 input-form-control" controlId="trxTypes">
                                <Form.Label>Transition type</Form.Label>
                                <Form.Control as="select" aria-label="Select" onChange={(evt) => handleChange(evt, true)} value={formData?.trxTypes}>
                                    <option value="Receive">Receive</option>
                                    <option value="Purchase">Purchase</option>
                                    <option value="Retain">Retain</option>
                                    <option value="Return">Return</option>
                                    <option value="Others">Others</option>
                                </Form.Control>
                            </Form.Group> :
                                <Form.Group className="mb-4 input-form-control" controlId="trxTypes">
                                    <Form.Label>Transition type</Form.Label>
                                    <Form.Control as="select" aria-label="Select" onChange={(evt) => handleChange(evt, true)} value={formData?.trxTypes}>
                                        <option value="Goods Issue">Goods Issue</option>
                                        <option value="Hold">Hold</option>
                                        <option value="Lost">Lost</option>
                                        <option value="Damage">Damage</option>
                                        <option value="Retire">Retire</option>
                                        <option value="Others">Others</option>
                                    </Form.Control>
                                </Form.Group>}
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-4 input-form-control" controlId="quantity">
                                <Form.Label>Quantity *</Form.Label>
                                <Form.Control type="number" min="0" onWheel={disableScroll} onChange={handleChange} value={formData?.quantity} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-4 input-form-control" controlId="notes">
                                <Form.Label>Note</Form.Label>
                                <Form.Control as="textarea" rows={1} onChange={handleChange} value={formData?.notes} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmit} disabled={loading}>
                        <LoadingButtonLabel label="Save" disabled={loading} />
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showTransfer} onHide={() => handleClose(setShowTransfer)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Inventory Transfer</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <Form.Group className="mb-4 input-form-control" controlId="quantity">
                        <section className="bg-light mb-3 rounded p-3">
                            <small className="text-muted d-block mb-3">Product name : <span className="fw-bold">{variant.productName}</span></small>
                            <section className="d-flex flex-between">
                                <small className="text-muted d-block ">From : <span className="fw-bold">{variant.sku}</span></small>
                                <small className="text-muted d-block ">Stock : <span className="fw-bold">{totalStock}</span></small>
                            </section>
                        </section>

                        <Form.Label>To</Form.Label>
                        <Select
                            isClearable
                            options={variants}
                            onInputChange={(newValue: string) => variantInputChange(newValue)}
                            onChange={(value: any) => setSelectedVariant(value)}
                            placeholder={'Search by sku code'}
                            classNamePrefix="react-select"
                            className="react-select-container"
                            styles={{
                                control: (base: any, state: any) => ({
                                    ...base,
                                    boxShadow: state.isFocused ? 0 : 0
                                })
                            }}
                            theme={(theme: any) => ({
                                ...theme,
                                borderRadius: 4,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#efefef',
                                    primary: '#e9edef',
                                    neutral0: '#212529',
                                    primary50: '#e9edef'
                                },
                            })}
                        />
                    </Form.Group>
                    <Form.Group className="mb-4 input-form-control" controlId="quantity">
                        <Form.Label>Stock Quantity *</Form.Label>
                        <Form.Control type="number" min="0" onWheel={disableScroll} onChange={(e) => setTransferQuantity(e.target.value)} value={transferQuantity} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => handleClose(setShowTransfer)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmitTransfer} disabled={loading}>
                        <LoadingButtonLabel label="Save" disabled={loading} />
                    </Button>
                </Modal.Footer>
            </Modal>
        </Layout>
    )
}

export default Detail


