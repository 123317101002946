import { useContext, useEffect, useState } from 'react';
import { Badge, Button, Col, Form, InputGroup, Offcanvas, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { useHistory } from 'react-router-dom';
import { Layout, SortingButton, TableLayout } from '../../components';
import { IconAdd, IconClose, IconEdit, IconFile, IconFilter, IconSearch } from '../../components/Icons';
import { OrderContext, PageContext, VariableContext } from '../../context';
import { LangContext } from '../../context/Lang';
import { actionData, dateFormat2, deliverySerivcesRoute, getData, getDataService, getDraftOrderRouteRoute, getNumber, handleClose, handleDateFormat, handleDateTimeFormat, handleShow, inputChangeService, numberFormat, orderFilterRoute, paymentRoute, postMethod } from '../../services';
import { handleCloseSearch } from '../../utils';
const limit: any = process.env.REACT_APP_PAGINATION_LIMIT

const Index = () => {
    const history = useHistory()
    const { dispatch: { translate } } = useContext(LangContext);
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [page, setPage] = useContext(PageContext)
    const [, setOrderItems] = useContext(OrderContext);
    const tempUser: any = localStorage.getItem("USER")
    const user = tempUser && JSON.parse(tempUser)
    const [variable, setVariable] = useContext(VariableContext)
    const [show, setShow] = useState(false);
    const [payments, setPayments] = useState([])
    const [searchString, setSearchString] = useState("")
    const [deliveryServices, setDeliveryServices] = useState([])

    //Date in Filter
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const onChange = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        start && setFormData({ ...formData, startDate: handleDateFormat(start, dateFormat2), endDate: "" })
        end && setFormData({ ...formData, endDate: handleDateFormat(end, dateFormat2) })

        if (!start && !end) {
            setFormData({ ...formData, startDate: "", endDate: "" })
        }
    };
    const deliveryStatus = ["All", "Pending", "Ready to Ship", "Shipped", "Delivered", "Canceled", "Lost & Damaged", "Failed Delivery", "Returned"]
    const shippingMethodData = ["All", "Delivery", "Local Pickup"]
    const paymentStatus = ["All", "UNPAID", "PAID"]
    const orderTypes = ["All", "Retail", "Wholesale"]
    const orderStatusData = ["All", "Confirmed", "Pending", "Canceled"]
    const [formData, setFormData] = useState(
        {
            orderBy: "desc",
            // skip: 0,
            take: limit,
            deliveryServiceId: "",
            deliveryStatusId: null,
            deliveryStatus: "",
            shippingMethods: "",
            shippingMethodId: null,
            orderType: "",
            orderTypeId: null,
            paymentTypeId: "",
            paymentStatus: "",
            paymentStatusId: null,
            orderStatusId: null,
            orderStatus: "",
            startDate: "",
            endDate: "",
        }
    )

    useEffect(() => {
        variable?.isOrderFilter ? handleGetDataByFilter(page.current, variable?.orderFilterActionData) : handleGetData(page.current, page.sorting, "")
        if (variable?.orderFilterData) {
            let _data = variable?.orderFilterData;
            setFormData({
                ...formData, orderBy: "desc",
                take: limit,
                deliveryServiceId: _data?.deliveryServiceId,
                deliveryStatusId: _data?.deliveryStatusId,
                deliveryStatus: _data?.deliveryStatus,
                shippingMethods: _data?.shippingMethods,
                shippingMethodId: _data?.shippingMethodId,
                orderType: _data?.orderType,
                orderTypeId: _data?.orderTypeId,
                paymentTypeId: _data?.paymentTypeId,
                paymentStatus: _data?.paymentStatus,
                paymentStatusId: _data?.paymentStatusId,
                orderStatusId: _data?.orderStatusId,
                orderStatus: _data?.orderStatus,
                startDate: _data?.startDate,
                endDate: _data?.endDate,
            })
        }

        handleGetPayments()
        handleGetDeliveryServices()
        // eslint-disable-next-line
    }, [])


    const handleGetDeliveryServices = async () => {
        getData(deliverySerivcesRoute).then(result => {
            setDeliveryServices(result.data)
        })
    }

    const handleGetPayments = async () => {
        getData(paymentRoute).then(result => {
            setPayments(result.data)
        })
    }

    const handleGetData = (skip: number, sorting: string, keyword: string) => {
        getDataService(getDraftOrderRouteRoute(skip, sorting, keyword), setLoading, setData, setPage, skip, sorting)
    }


    const handleEnterAction = (e: any) => {
        if (e.keyCode === 13) {
            setSearchString(e.target.value)
            handleGetData(0, page.sorting, e.target.value)
        }
    }

    const handleChange = (evt: any) => {
        inputChangeService(evt, formData, setFormData);
    }

    const handleFilterAction = () => {
        if (formData.shippingMethodId) {
            formData["shippingMethods"] = shippingMethodData[formData.shippingMethodId];
        } else {
            formData["shippingMethods"] = "";
        }

        if (formData.paymentStatusId) {
            formData["paymentStatus"] = paymentStatus[formData.paymentStatusId];
        } else {
            formData["paymentStatus"] = "";
        }

        if (formData.orderTypeId) {
            formData["orderType"] = orderTypes[formData.orderTypeId];
        } else {
            formData["orderType"] = "";
        }

        if (formData.deliveryStatusId) {
            formData["deliveryStatus"] = deliveryStatus[formData.deliveryStatusId];
        } else {
            formData["deliveryStatus"] = "";
        }

        if (formData.orderStatusId) {
            formData["orderStatus"] = orderStatusData[formData.orderStatusId];
        } else {
            formData["orderStatus"] = "";
        }

        let _data: any = {}


        for (let [key, value] of Object.entries(formData)) {
            if (value !== null && value !== '' && value !== undefined && value !== "All") {
                if (key !== "deliveryStatusId" && key !== "orderStatusId" && key !== "shippingMethodId" && key !== "paymentStatusId") {
                    _data[key] = value
                }
            }
        }

        setVariable({ ...variable, orderFilterData: formData, orderFilterActionData: _data, isOrderFilter: true })
        handleClose(setShow)
        handleGetDataByFilter(0, _data)
    }

    const handleGetDataByFilter = (skip: number, _formData: any) => {
        _formData["skip"] = skip * limit;
        setLoading(true)
        actionData(postMethod, orderFilterRoute, _formData).then((result) => {
            setData(result.data)
            setPage({
                current: skip,
                total: result.count,
                sorting: _formData.orderBy,
            })
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        })
    }

    const handleClearFilter = () => {
        setFormData({
            ...formData, orderBy: "desc",
            // skip: 0,
            take: limit,
            deliveryServiceId: "",
            deliveryStatusId: null,
            deliveryStatus: "",
            shippingMethods: "",
            shippingMethodId: null,
            orderType: "",
            orderTypeId: null,
            paymentTypeId: "",
            paymentStatus: "",
            paymentStatusId: null,
            orderStatusId: null,
            orderStatus: "",
            startDate: "",
            endDate: "",
        })
        setStartDate(null)
        setEndDate(null)
        handleClose(setShow)
        handleGetData(page.current, page.sorting, "")
        setVariable({ ...variable, orderFilterData: {}, orderFilterActionData: {}, isOrderFilter: false })
    }

    const handleChangePage = (skip: number) => {
        variable?.isOrderFilter ? handleGetDataByFilter(skip, variable?.orderFilterActionData) : handleGetData(skip, page.sorting, searchString)
    }


    return (
        <Layout title={translate('orders')}>
            <section className="d-flex justify-content-between mb-4">
                <Col md={6}>
                    <div className='d-flex align-items-center'>
                        <Col md={5}>
                            <InputGroup className="search-input">
                                <InputGroup.Text className="bg-primary-grad text-white">
                                    <IconSearch />
                                </InputGroup.Text>
                                <Form.Control placeholder="Search" value={searchString} onChange={(e) => setSearchString(e.target.value)} onKeyDown={(e) => handleEnterAction(e)} />
                                {searchString && <OverlayTrigger overlay={<Tooltip>Clear Search</Tooltip>} >
                                    <InputGroup.Text className="bg-danger cursor" onClick={() => handleCloseSearch(setSearchString, handleGetData)}>
                                        <IconClose />
                                    </InputGroup.Text>
                                </OverlayTrigger>}
                            </InputGroup>
                        </Col>
                        {page.total ? <span><small className='text-muted ms-3'>Result : </small>{numberFormat(page.total)}</span> : <></>}
                    </div>
                </Col>


                <div className='d-flex'>
                    <Button className="text-white bg-primary-grad me-2" onClick={() => { history.push("/add-order"); setOrderItems([]) }}><IconAdd /> <span className="ms-2">{translate('retail')}</span></Button>
                    <Button className="text-white bg-primary-grad me-2" onClick={() => { history.push("/add-wholesale-order"); setOrderItems([]) }}><IconAdd /> <span className="ms-2">{translate('wholesale')}</span></Button>
                    <Button variant="primary" onClick={() => handleShow(setShow)} className="me-2 bg-primary-grad">
                        <IconFilter />
                        <span className="ms-2">Filter</span>
                    </Button>
                    <SortingButton page={page} setPage={setPage} handleGetData={handleGetData} />
                </div>


                {/* <Col md={3}>
                    <InputGroup className="search-input">
                        <InputGroup.Text className="bg-primary-grad text-white">
                            <IconSearch />
                        </InputGroup.Text>
                        <Form.Control placeholder="Search" onKeyDown={(e) => handleEnterAction(e)} />
                        {false && <OverlayTrigger overlay={<Tooltip>Clear Search</Tooltip>} >
                            <InputGroup.Text className="bg-danger cursor">
                                <IconClose />
                            </InputGroup.Text>
                        </OverlayTrigger>}
                    </InputGroup>
                </Col> */}
            </section>

            <TableLayout loading={loading} data={data} page={page} setPage={setPage} handleGetData={handleGetData} isFilter handleChangePage={handleChangePage} noBg>
                <thead>
                    <tr>
                        <th>{translate('no.')}</th>
                        <th>{translate('orderNumber')}</th>
                        <th>Order type</th>
                        <th>{translate('customer')}</th>
                        <th>{translate('status')}</th>
                        <th>{translate('date')}</th>
                        <th><span className="d-flex justify-content-end">{translate('total')} ({translate('mmk')})</span></th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {data.map((item: any, index: any) =>
                        <tr key={index}>
                            <td className='ps-4'>{getNumber(index, page?.current)}</td>
                            <td><span className='flex-center'>{item.draftOrdersCode}</span></td>
                            <td><span className='flex-center'>{item.orderType}</span></td>
                            <td>{item.customerName}</td>
                            <td><span className='flex-center'><Badge className={item.orderStatus === "Confirmed" ? "bg-primary" : item.orderStatus === "Canceled" ? "bg-danger" : "bg-info"}>{item.orderStatus}</Badge></span></td>
                            <td><span className='flex-center'>{handleDateTimeFormat(item.createdAt)}</span></td>
                            <td><span className="flex-end">{numberFormat(item.totalAmount)}</span></td>
                            <td>
                                <span className="flex-center">
                                    {(user?.userRole === "Owner" || user?.userRole === "Admin" || user?.userRole === "Manager") && <>
                                        {!(item.orderStatus === "Canceled" || item.orderStatus === "Confirmed") && <OverlayTrigger overlay={<Tooltip>Edit order</Tooltip>}>
                                            <Button className="btn-sm ms-2" onClick={() => {
                                                history.push({
                                                    pathname: item.orderType === "Retail" ? '/edit-order' : '/edit-wholesale-order',
                                                    state: { order: item }
                                                }); setOrderItems([])
                                            }}><IconEdit /></Button>
                                        </OverlayTrigger>}
                                    </>}


                                    <OverlayTrigger overlay={<Tooltip>{translate('viewDetail')}</Tooltip>}>
                                        <Button className="btn-sm ms-2 border-none" onClick={() => history.push({
                                            pathname: '/order-detail',
                                            state: { id: item.id }
                                        })}><IconFile /></Button>
                                    </OverlayTrigger>
                                </span>
                            </td>

                        </tr>
                    )}
                </tbody>
            </TableLayout>



            <Offcanvas show={show} onHide={() => handleClose(setShow)} placement={"end"}>
                {/* <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Filter</Offcanvas.Title>
                </Offcanvas.Header> */}
                <Offcanvas.Body>
                    <Form>
                        <Form.Label>{translate("date")}</Form.Label>
                        <ReactDatePicker
                            dateFormat="dd/MM/yyyy"
                            onChange={onChange}
                            startDate={startDate}
                            placeholderText="Select Date"
                            className="form-control mb-4"
                            endDate={endDate}
                            selectsRange
                        />

                        <Form.Label>Order type</Form.Label>
                        <Form.Control as="select" aria-label="Select" className='mb-4' id="orderTypeId" value={formData?.orderTypeId || ""} onChange={handleChange}>
                            {orderTypes.map((item: any, index: number) =>
                                <option value={index} key={index}>{item}</option>
                            )}
                        </Form.Control>

                        <Form.Label>Shipping methods</Form.Label>
                        <Form.Control as="select" aria-label="Select" className='mb-4' id="shippingMethodId" value={formData?.shippingMethodId || ""} onChange={handleChange}>
                            {shippingMethodData.map((item: any, index: number) =>
                                <option value={index} key={index}>{item}</option>
                            )}
                        </Form.Control>

                        <Form.Label>Payment types</Form.Label>
                        <Form.Control as="select" aria-label="Select" className='mb-4' id="paymentTypeId" value={formData?.paymentTypeId} onChange={handleChange}>
                            <option value="">Select</option>
                            {payments.map((item: any, index: number) =>
                                <option value={item.id} key={index}>{item.paymentName}</option>
                            )}
                        </Form.Control>

                        <Form.Label>Payment status</Form.Label>
                        <Form.Control as="select" aria-label="Select" className='mb-4' id="paymentStatusId" value={formData?.paymentStatusId || ""} onChange={handleChange}>
                            {paymentStatus.map((item: any, index: number) =>
                                <option value={index} key={index}>{item}</option>
                            )}
                        </Form.Control>

                        <Form.Label>Delivery</Form.Label>
                        <Form.Control as="select" aria-label="Select" className='mb-4' id="deliveryServiceId" value={formData?.deliveryServiceId} onChange={handleChange}>
                            <option value="">Select</option>
                            {deliveryServices.map((item: any, index: number) =>
                                <option value={item.id} key={index}>{item.deliveryService}</option>
                            )}
                        </Form.Control>


                        <Form.Label>Delivery status</Form.Label>
                        <Form.Control as="select" aria-label="Select" className='mb-4' id="deliveryStatusId" value={formData?.deliveryStatusId || ""} onChange={handleChange} >
                            {deliveryStatus.map((item: string, index: number) => (
                                <option value={index} key={index}>{item}</option>
                            ))}
                        </Form.Control>

                        <Form.Label>Order status</Form.Label>
                        <Form.Control as="select" aria-label="Select" id="orderStatusId" value={formData?.orderStatusId || ""} onChange={handleChange}>
                            {orderStatusData.map((item: any, index: number) =>
                                <option value={index} key={index}>{item}</option>
                            )}
                        </Form.Control>
                    </Form>
                    <div className='d-flex  mt-4'>
                        <Button className='w-50 py-2 bg-primary-grad me-2' onClick={handleFilterAction}>Search</Button>
                        <Button className='w-50 py-2 bg-danger' onClick={handleClearFilter}>Clear filter</Button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </Layout>
    );
};

export default Index;

