import React, { useRef, useState, useEffect, useCallback, useContext } from 'react';
import { Badge, Button, Col, Row, Table } from 'react-bootstrap';
import { useHistory, useLocation } from "react-router-dom"
import { IconEdit, IconImage, IconLeftArrow, IconPrint } from '../../components/Icons';
import { Layout, OrderDetailLoading } from '../../components';
import { exportComponentAsPNG } from 'react-component-export-image';
import { actionData, getData, getSaleOrderDetailByIdRoute, numberFormat, putMethod, saleOrderRoute } from '../../services';
import { useReactToPrint } from 'react-to-print';
import ReceiptPrint from './cpn/ReceiptPrint';
import { LangContext } from '../../context';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import Payments from './cpn/Payments';
import SaleReceipt from '../../components/SaleReceipt';

const Detail = () => {
    const history = useHistory()
    const { dispatch: { translate } } = useContext(LangContext);
    const location: any = useLocation();
    const id = location.state.id
    const routeFrom = location.state.routeFrom
    const [draftOrder, setDraftOrder] = useState<any>()
    const [loading, setLoading] = useState(false)
    // const [show, setShow] = useState(false);
    // const [payments, setPayments] = useState([])
    // const paymentStatus = ["UNPAID", "PAID"]
    // const deliveryStatus = ["Pending", "Ready to Ship", "Shipped", "Delivered", "Canceled", "Lost & Damaged", "Failed Delivery", "Collect in Person", "Returned"]
    // const [formData, setFormData] = useState({ paymentStatusId: 0, deliveryStatusId: 0, paymentTypeId: "", })
    const tempUser: any = localStorage.getItem("USER")
    const user = tempUser && JSON.parse(tempUser)

    //Save Image and Pdf
    const exportComponentRef = useRef(null);

    useEffect(() => {
        handleGetData()
        // handleGetPayments()
        // eslint-disable-next-line
    }, [])

    // const handleGetPayments = async () => {
    //     getData(paymentRoute).then(result => {
    //         setPayments(result.data)
    //     })
    // }

    const handleGetData = async () => {
        setLoading(true)
        getData(getSaleOrderDetailByIdRoute(id)).then((result) => {
            setDraftOrder(result.data)
            // let tempOrder = result.data

            // setFormData({
            //     paymentStatusId: tempOrder.paymentStatus ? paymentStatus.indexOf(tempOrder.paymentStatus) : 0,
            //     paymentTypeId: tempOrder.paymentTypeId || "",
            //     deliveryStatusId: tempOrder.deliveryStatus ? deliveryStatus.indexOf(tempOrder.deliveryStatus) : 0,
            // })

            setTimeout(() => {
                setLoading(false)
            }, 500)
        })
    }

    // const handleChange = (evt: any) => {
    //     inputChangeService(evt, formData, setFormData);
    // }


    const ComponentToPrint = React.forwardRef((props, ref: any) => (
        <div ref={ref}>
            <section className="bg-card bg-white border">
                <SaleReceipt order={draftOrder} />
            </section>

        </div>
    ));

    //Sale Receipt Print
    const componentRef = useRef(null);
    const reactToPrintContent = useCallback(() => {
        return componentRef.current;
    }, []);
    const PrintAction = useReactToPrint({
        documentTitle: draftOrder?.saleOrdersCode,
        content: reactToPrintContent,
    });

    // const handleSubmit = () => {
    //     let _formData: any = {
    //         ordersId: id,
    //         paymentTypeId: formData.paymentTypeId,
    //     }
    //     _formData["paymentStatus"] = paymentStatus[formData.paymentStatusId];
    //     _formData["deliveryStatus"] = deliveryStatus[formData.deliveryStatusId];

    //     actionData(putMethod, saleOrderRoute, _formData).then((result) => {
    //         if (result.success) {
    //             swal(result.message, {
    //                 icon: 'success',
    //                 buttons: [false],
    //                 timer: 1500,
    //             })
    //             handleGetData()
    //             handleClose(setShow)
    //         } else {
    //             toast.error(result.message)
    //         }
    //     })
    // }

    const handleCancel = () => {
        swal({
            text: `Are you sure to cancel this sale?`,
            icon: 'warning',
            buttons: [true, true],
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                let _formData: any = {
                    ordersId: draftOrder?.id
                }

                actionData(putMethod, `${saleOrderRoute}/cancel`, _formData).then((result) => {
                    if (result.success) {
                        swal(result.message, {
                            icon: 'success',
                            buttons: [false],
                            timer: 1500,
                        })
                        history.push("/sales")
                    } else {
                        toast.error(result.message)
                    }
                })
            }
        })
    }

    return (
        <Layout title="Sale detail">
            <style type="text/css" media="print">{"@page { size: Legal landscape; }"}</style>
            {loading ? <OrderDetailLoading /> :
                <>
                    <section className="flex-between mb-4 ">
                        <section className="cursor" onClick={() => routeFrom === "customer" ? history.goBack() : history.push("/sales")}>
                            <p className="mb-0"><IconLeftArrow /> Sale orders</p>
                        </section>
                        <section className="d-none"><ReceiptPrint ref={componentRef} sale={draftOrder} receiptPagiante={draftOrder?.orderType === "Retail" ? 15 : 15} /></section>

                        {draftOrder?.orderStatus !== "Canceled" && <section>
                            {(user?.userRole === "Owner" || user?.userRole === "Admin" || user?.userRole === "Manager") && <Button className="text-white me-2" onClick={() => history.push({
                                pathname: draftOrder?.orderType === "Retail" ? '/edit-sale' : '/edit-wholesale',
                                state: { order: draftOrder, routeFrom: "detail" }
                            })}><IconEdit /> <span className="ms-2">Edit sale</span></Button>}
                            <Button onClick={() => exportComponentAsPNG(exportComponentRef, { fileName: `${draftOrder?.saleOrdersCode}.png`, })}><IconImage /> <span className="ms-2">Export as image</span></Button>
                            <Button onClick={PrintAction} className="ms-2"><IconPrint /> <span className="ms-2">Print receipt</span></Button>
                            {(user?.userRole === "Owner" || user?.userRole === "Admin" || user?.userRole === "Manager") && <Button className="text-white ms-2 bg-danger border-none" onClick={handleCancel}>Sale cancel</Button>}
                        </section>}
                    </section>

                    <Row className='mb-4'>
                        <Col md={8}>
                            <ComponentToPrint ref={exportComponentRef} />
                        </Col>
                        <Col>
                            <Table responsive striped className="mb-0">
                                <tbody>
                                    <tr>
                                        <td className="ps-4 text-muted ">Employee</td>
                                        <td> <small>{draftOrder?.employeeName || "-"}</small></td>
                                    </tr>
                                    <tr>
                                        <td className="ps-4 text-muted ">Sale type</td>
                                        <td> <small>{draftOrder?.orderType}</small></td>
                                    </tr>
                                    <tr>
                                        <td className="ps-4 text-muted ">Draft order number</td>
                                        <td> <small>{draftOrder?.draftOrdersCode || "-"}</small></td>
                                    </tr>
                                    <tr>
                                        <td className="ps-4 text-muted ">{translate('orderStatus')}</td>
                                        <td><Badge className={draftOrder?.orderStatus === "Confirmed" ? "bg-primary" : draftOrder?.orderStatus === "Canceled" ? "bg-danger" : "bg-info"}>{draftOrder?.orderStatus}</Badge></td>
                                    </tr>
                                    <tr>
                                        <td className="ps-4 text-muted ">Order from</td>
                                        <td> <small>{draftOrder?.orderFrom || "-"}</small></td>
                                    </tr>
                                    <tr>
                                        <td className="ps-4 text-muted ">Order note</td>
                                        <td> <small>{draftOrder?.orderNotes || "-"}</small></td>
                                    </tr>
                                    {/* <tr>
                                        <td className="ps-4 text-muted ">{translate('paymentStatus')}</td>
                                        <td><Badge className={draftOrder?.paymentStatus === "PAID" ? "bg-warning" : draftOrder?.paymentStatus === "UNPAID" ? "bg-danger" : "bg-success"}>{draftOrder?.paymentStatus}</Badge></td>
                                    </tr>
                                    <tr>
                                        <td className="ps-4 text-muted ">{translate('paymentType')}</td>
                                        <td> <small>{draftOrder?.paymentName || "-"}</small></td>
                                    </tr> */}
                                    <tr>
                                        <td className="ps-4 text-muted ">Shipping method</td>
                                        <td> <small>{draftOrder?.shippingMethods || "-"}</small></td>
                                    </tr>
                                    {draftOrder?.shippingMethods === "Delivery" &&
                                        <>
                                            <tr>
                                                <td className="ps-4 text-muted ">Delivery</td>
                                                <td> <small>{draftOrder?.deliveryService || "-"}</small></td>
                                            </tr>
                                            <tr>
                                                <td className="ps-4 text-muted ">{translate('location')}</td>
                                                <td> <small>{draftOrder?.locationName || "-"}</small></td>
                                            </tr>
                                            <tr>
                                                <td className="ps-4 text-muted ">{translate('deliveryFee')}</td>
                                                <td> <small>{draftOrder?.deliveryFee ? `${numberFormat(draftOrder?.deliveryFee)} MMK` : "-"}</small></td>
                                            </tr>
                                            <tr>
                                                <td className="ps-4 text-muted ">{translate('deliveryStatus')}</td>
                                                <td> <Badge className={draftOrder?.deliveryStatus === "Delivered" ? "bg-warning" : draftOrder?.deliveryStatus === "Canceled" ? "bg-danger" : "bg-primary"}>{draftOrder?.deliveryStatus}</Badge></td>
                                            </tr>
                                        </>
                                    }

                                    <tr>
                                        <td className="ps-4 text-muted ">{draftOrder?.shippingMethods} note</td>
                                        <td> <small>{draftOrder?.deliveryNotes || "-"}</small></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Payments detail={draftOrder} handleGetData={handleGetData} />
                </>
            }

            {/* <Modal show={show} onHide={() => handleClose(setShow)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{translate("editInfo")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="input-form-control mb-4" >
                        <Form.Label>Payment status</Form.Label>
                        <Form.Control as="select" aria-label="Select" id="paymentStatusId" value={formData?.paymentStatusId} onChange={handleChange} >
                            {paymentStatus.map((item: string, index: number) => (
                                <option value={index} key={index}>{item}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group className="input-form-control mb-4" >
                        <Form.Label>Payment type</Form.Label>
                        <Form.Control as="select" aria-label="Select" id="paymentTypeId" value={formData?.paymentTypeId} onChange={handleChange} >
                            <option value="">Select</option>
                            {payments.length > 0 && payments.map((item: any, index: number) => (
                                <option value={item.id} key={index}>{item.paymentName}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group className="mb-4 input-form-control" >
                        <Form.Label>Delivery status</Form.Label>
                        <Form.Control as="select" aria-label="Select" id="deliveryStatusId" value={formData?.deliveryStatusId} onChange={handleChange} >
                            {deliveryStatus.map((item: string, index: number) => (
                                <option value={index} key={index}>{item}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => handleClose(setShow)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmit} disabled={loading}>
                        <LoadingButtonLabel label='Save' disabled={loading} />
                    </Button>
                </Modal.Footer>
            </Modal> */}
        </Layout >
    );
};



export default Detail;
