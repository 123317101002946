import { useContext, useEffect, useState } from 'react';
import { Badge, Button, OverlayTrigger, Tooltip, } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { TableLayout } from '../../../components';
import { IconFile, } from '../../../components/Icons';
import { LangContext, ThirdPageContext } from '../../../context';
import { getDataService, getNumber, getOrdersTotal, getSaleOrderByCustomerRouteRoute, handleDateTimeFormat, numberFormat } from '../../../services';

const CustomerSales = () => {
    const history = useHistory()
    const { dispatch: { translate } } = useContext(LangContext);
    const location: any = useLocation();
    const [loading, setLoading] = useState(false)
    const customer = location.state.customer
    const [page, setPage] = useContext(ThirdPageContext)
    // const [, setOrderItems] = useContext(OrderContext);
    const [data, setData] = useState([])
    // const tempUser: any = localStorage.getItem("USER")
    // const user = tempUser && JSON.parse(tempUser)

    useEffect(() => {
        handleGetData(page.current, page.sorting, "")
        // eslint-disable-next-line
    }, [])

    const handleGetData = (skip: number, sorting: string, keyword: string) => {
        getDataService(getSaleOrderByCustomerRouteRoute(customer?.id, "sales", skip, sorting, keyword), setLoading, setData, setPage, skip, sorting)
    }

    return (
        <section className="bg-card mb-4">
            <section className="d-flex justify-content-between mb-2">
                <h6 className='text-muted'>{translate('sales')}</h6>
                {data.length > 0 && <h6 className='fw-bold'><span className='text-muted'>Total amount :</span> {numberFormat(getOrdersTotal(data))} MMK</h6>}
            </section>

            <TableLayout loading={loading} data={data} page={page} setPage={setPage} handleGetData={handleGetData} noBg>
                <thead>
                    <tr>
                        <th className='ps-4'>{translate('no.')}</th>
                        <th>{translate('saleNumber')}</th>
                        <th>{translate('paymentStatus')}</th>
                        <th>{translate('orderStatus')}</th>
                        <th>{translate('date')}</th>
                        <th><span className="d-flex justify-content-end">{translate('total')} (MMK)</span></th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {data.map((item: any, index: any) =>
                        <tr key={index}>
                            <td className='ps-4'>{getNumber(index, page?.current)}</td>
                            <td>{item.saleOrdersCode}</td>
                            <td><Badge className={item.paymentStatus === "PAID" ? "bg-primary" : "bg-danger"}>{item.paymentStatus}</Badge></td>
                            <td><Badge className={item.orderStatus === "Confirmed" ? "bg-primary" : item.orderStatus === "Canceled" ? "bg-danger" : "bg-info"}>{item.orderStatus}</Badge></td>
                            <td>{handleDateTimeFormat(item.createdAt)}</td>
                            <td><span className="flex-end">{numberFormat(item.totalAmount)}</span></td>
                            <td>
                                <span className="flex-end">
                                    {/* {(user?.userRole === "Owner" || user?.userRole === "Admin" || user?.userRole === "Manager") && <>
                                        {item.orderStatus !== "Canceled" && <OverlayTrigger overlay={<Tooltip>Edit sale</Tooltip>}>
                                            <Button className="btn-sm ms-2" onClick={() => {
                                                history.push({
                                                    pathname: '/edit-sale',
                                                    state: { order: item }
                                                }); setOrderItems([])
                                            }}><IconEdit /></Button>
                                        </OverlayTrigger>}
                                    </>} */}


                                    <OverlayTrigger overlay={<Tooltip>{translate('viewDetail')}</Tooltip>}>
                                        <Button className="btn-sm ms-2 border-none" onClick={() => history.push({
                                            pathname: '/sale-detail',
                                            state: { id: item.id, routeFrom: "customer" }
                                        })}><IconFile /></Button>
                                    </OverlayTrigger>
                                </span>
                            </td>
                        </tr>
                    )}
                </tbody>
            </TableLayout>

        </section>
    );
};

export default CustomerSales;

