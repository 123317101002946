import { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { Pie } from 'react-chartjs-2'
import { getData } from '../../services'


export default function PieChart() {
  const [labels, setLabels] = useState<any>([])
  const [values, setValues] = useState<any>([])

  useEffect(() => {
    handleGetData()
  }, [])

  const handleGetData = async () => {
    let _labels: any = []
    let _values: any = []
    getData("dashboard/items").then(result => {
      if (result && result.length > 0) {
        result.forEach((item: any) => {
          if (item.productCount > 0) {
            _labels.push(item.productType)
            _values.push(item.productCount)
          }
        })

        setLabels(_labels)
        setValues(_values)
      }
    })
  }

  return (
    <Col md={12} >
      <Pie
        data={{
          //labels: ['Enquiry', 'Schedule', 'Price information', 'Rates & Fees',],
          labels: labels,
          // labels: ['ပိတ်ဖေါက်ဇာ', 'ဝါးသား', 'ထိုင်းပိုး', 'ချည်ပိုး',],
          datasets: [
            {
              label: 'Product count',
              data: values,
              // backgroundColor: [
              //   '#E3A232',
              //   '#00acfb',
              //   '#27AE60',
              //   '#dbdbdb',
              //   '#F1C40F',
              // ],
              backgroundColor: ['#0FCAF0', '#E3A232', '#FFC007', '#E3A232', '#00acfb', '#dbdbdb'],
              borderWidth: 1,
            },
            // {
            //   label: 'Quantity',
            //   data: [47, 52, 67, 58, 9, 50],
            //   backgroundColor: 'orange',
            //   borderColor: 'red',
            // },
          ],
        }}
        height={350}
        width={300}
        options={{
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
            }
          }
        }}
      />
    </Col>
  )
}

