import React, { useEffect, useState } from "react";
import { useContext } from "react"
import { Button, ButtonGroup, Col, Dropdown, DropdownButton, Form, Image, InputGroup, Offcanvas, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import { NoData, PgComponent, ProductGridLoading, SortingButton, TableLoading } from "../../../components"
import { IconAdd, IconClose, IconDelete, IconEdit, IconFile, IconFilter, IconGrid, IconList, IconPrint } from "../../../components/Icons"
import { VariableContext } from "../../../context/Variable"
import { brandRoute, deleteService, factoriesRoute, getData, getNumber, handleClose, handleShow, inputChangeService, numberFormat, productsRoute, productTypesRoute } from "../../../services"
import { LangContext, SecondPageContext } from "../../../context";
const limit: any = process.env.REACT_APP_PAGINATION_LIMIT
const RES_API_URL = process.env.REACT_APP_RES_API_URL


export const ProductHeader = ({ page, setPage, handleGetData, handleGetDataByFilter }: { page: any, setPage: any, handleGetData: any, handleGetDataByFilter: any }) => {
    const history = useHistory()
    const [variable, setVariable] = useContext(VariableContext)
    const { dispatch: { translate } } = useContext(LangContext);
    const tempUser: any = localStorage.getItem("USER")
    const user = tempUser && JSON.parse(tempUser)
    const [show, setShow] = useState(false);
    const [productTypes, setProductTypes] = useState([])
    const [brands, setBrands] = useState([])
    const [factories, setFactories] = useState([])

    const [formData, setFormData] = useState(
        {
            orderBy: "desc",
            take: limit,
            productTypesId: "",
            brandsId: "",
            factoriesId: "",
        }
    )

    useEffect(() => {
        handleGetAttributes()

        if (variable?.productFilterData) {
            let _data = variable?.productFilterData;
            setFormData({
                ...formData, orderBy: "desc",
                take: limit,
                productTypesId: _data?.productTypesId,
                brandsId: _data?.brandsId,
                factoriesId: _data?.factoriesId,
            })
        }

        // eslint-disable-next-line
    }, [])


    const handleGetAttributes = async () => {
        getData(productTypesRoute).then(result => { setProductTypes(result.data) })
        getData(brandRoute).then(result => { setBrands(result.data) })
        getData(factoriesRoute).then(result => { setFactories(result.data) })
    }

    const handleEnterAction = (e: any) => {
        if (e.keyCode === 13) {
            let productCode = variable?.productSearchType === "productCode" ? e.target.value : null
            let productName = variable?.productSearchType !== "productCode" ? e.target.value : null

            setVariable({ ...variable, searchString: e.target.value })
            handleGetData(null, page.sorting, productName, productCode)
        }
    }

    const handleCloseSearch = () => {
        setVariable({
            ...variable,
            searchString: "",
            productSearchType: "productCode"
        });

        setPage({ ...page, current: 0 })

        handleGetData(0, page.sorting)
    }

    const handleChange = (evt: any) => {
        inputChangeService(evt, formData, setFormData);
    }
    const handleFilterAction = () => {
        let _data: any = {}

        for (let [key, value] of Object.entries(formData)) {
            if (value !== null && value !== '' && value !== undefined && value !== translate('all')) {
                if (key !== "deliveryStatusId" && key !== "orderStatusId" && key !== "shippingMethodId" && key !== "paymentStatusId") {
                    _data[key] = value
                }
            }
        }

        setVariable({ ...variable, productFilterData: formData, productFilterActionData: _data, isProductFilter: true })
        handleClose(setShow)
        handleGetDataByFilter(0, _data)
    }


    const handleClearFilter = () => {
        setFormData({
            ...formData, orderBy: "desc",
            take: limit,
            productTypesId: "",
            brandsId: "",
            factoriesId: "",
        })
        handleClose(setShow)
        handleGetData(0, page.sorting)
        setVariable({ ...variable, productFilterData: {}, productFilterActionData: {}, isProductFilter: false })
    }

    const handleChangePage = (skip: number) => {
        variable?.isProductFilter ? handleGetDataByFilter(skip, variable?.productFilterActionData) : handleGetData(skip, page.sorting, "")
    }

    return (
        <section className="d-flex justify-content-between mb-4">
            <Col md={5}>
                <div className='d-flex align-items-center'>
                    <Col md={8}>
                        <InputGroup className="search-input">
                            <DropdownButton
                                title={variable?.productSearchType === "productCode" ? "Code" : "Name"}
                                className="bg-primary-grad"
                            >
                                <Dropdown.Item href="#" onClick={() => setVariable({ ...variable, productSearchType: "productCode" })}>Code</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => setVariable({ ...variable, productSearchType: "productName" })}>Name</Dropdown.Item>
                            </DropdownButton>
                            <Form.Control placeholder="Search" onChange={(e) => setVariable({ ...variable, searchString: e.target.value })} onKeyDown={(e) => handleEnterAction(e)} value={variable?.searchString} />
                            {variable?.searchString && <OverlayTrigger overlay={<Tooltip>Clear Search</Tooltip>} >
                                <InputGroup.Text className="bg-danger cursor" onClick={handleCloseSearch}>
                                    <IconClose />
                                </InputGroup.Text>
                            </OverlayTrigger>}
                        </InputGroup>
                    </Col>
                    {page.total ? <span><small className='text-muted ms-3'>Result : </small>{numberFormat(page.total)}</span> : <></>}
                </div>
            </Col>

            <section className="d-flex">
                {(page && page.total > limit) && <PgComponent
                    currentPage={page.current + 1 || 0}
                    fromCount={page.current || 0}
                    total={page.total || 0}
                    paginationLimit={limit}
                    pageInfo={page}
                    setPage={setPage}
                    handleGetData={handleGetData}
                    isFilter={variable?.isProductFilter}
                    handleChangePage={handleChangePage}
                    disablePageInput
                />}
                <div className="me-2" />

                {(user?.userRole === "Owner" || user?.userRole === "Admin") &&
                    <OverlayTrigger overlay={<Tooltip>{translate('createNew')}</Tooltip>}>
                        <Button className="text-white bg-primary-grad me-2" onClick={() => history.push("/add-product")}><IconAdd /></Button>
                    </OverlayTrigger>}

                <OverlayTrigger overlay={<Tooltip>Print labels</Tooltip>}>
                    <Button className="text-white bg-primary-grad " onClick={() => history.push("/product-print-label")}><IconPrint /></Button>
                </OverlayTrigger>

                <Button className="text-white bg-primary-grad ms-2" onClick={() => handleShow(setShow)} ><IconFilter /></Button>

                <ButtonGroup className="mx-2">
                    <Button
                        onClick={() => setVariable({ ...variable, isList: false })}
                        className={variable?.isList ? "bg-white text-primary" : "text-white bg-primary-grad"}
                    ><IconGrid /></Button>
                    <Button
                        onClick={() => setVariable({ ...variable, isList: true })}
                        className={variable?.isList ? "text-white bg-primary-grad" : "bg-white text-primary"}><IconList /></Button>
                </ButtonGroup>

                <SortingButton page={page} setPage={setPage} handleGetData={handleGetData} handleGetDataByFilter={handleGetDataByFilter} filterActionData={variable?.productFilterActionData} />
            </section>

            {/* <Col md={4}>
                <InputGroup className="search-input">
                    <DropdownButton
                        title={variable?.productSearchType === "productCode" ? "Code" : "Name"}
                        className="bg-primary-grad"
                    >
                        <Dropdown.Item href="#" onClick={() => setVariable({ ...variable, productSearchType: "productCode" })}>Code</Dropdown.Item>
                        <Dropdown.Item href="#" onClick={() => setVariable({ ...variable, productSearchType: "productName" })}>Name</Dropdown.Item>
                    </DropdownButton>
                    <Form.Control placeholder="Search" onChange={(e) => setVariable({ ...variable, searchString: e.target.value })} onKeyDown={(e) => handleEnterAction(e)} value={variable?.searchString} />
                    {variable?.searchString && <OverlayTrigger overlay={<Tooltip>Clear Search</Tooltip>} >
                        <InputGroup.Text className="bg-danger cursor" onClick={handleCloseSearch}>
                            <IconClose />
                        </InputGroup.Text>
                    </OverlayTrigger>}
                </InputGroup>
            </Col> */}
            <Offcanvas show={show} onHide={() => handleClose(setShow)} placement={"end"}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Filter</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>
                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label>{translate('productType')}</Form.Label>
                            <Form.Control as="select" aria-label="Select" id="productTypesId" value={formData?.productTypesId} onChange={handleChange}>
                                <option value="">{translate('all')}</option>
                                {productTypes.map((item: any, index: number) =>
                                    <option value={item.id} key={index}>{item.productType}</option>
                                )}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label>{translate('brand')}</Form.Label>
                            <Form.Control as="select" aria-label="Select" id="brandsId" value={formData?.brandsId} onChange={handleChange}>
                                <option value="">{translate('all')}</option>
                                {brands.map((item: any, index: number) =>
                                    <option value={item.id} key={index}>{item.brandName}</option>
                                )}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label>Factory</Form.Label>
                            <Form.Control as="select" aria-label="Select" id="factoriesId" value={formData?.factoriesId} onChange={handleChange}>
                                <option value="">{translate('all')}</option>
                                {factories.map((item: any, index: number) =>
                                    <option value={item.id} key={index}>{item.factory}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                    <div className='d-flex  mt-4'>
                        <Button className='w-50 py-2 bg-primary-grad me-2' onClick={handleFilterAction}>Search</Button>
                        <Button className='w-50 py-2 bg-danger' onClick={handleClearFilter}>Clear filter</Button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </section>
    )
}

export const ProductContent = ({ loading, data, page, setPage, handleResponse, handleGetData, handleGetDataByFilter }: { loading: boolean, data: any, page: any, setPage: any, handleResponse: any, handleGetData: any, handleGetDataByFilter: any }) => {
    const [variable] = useContext(VariableContext)
    const { dispatch: { translate } } = useContext(LangContext);

    const handleChangePage = (skip: number) => {
        variable?.isProductFilter ? handleGetDataByFilter(skip, variable?.productFilterActionData) : handleGetData(skip, page.sorting, "")
    }


    return (
        <div>
            {variable?.isList ?
                <>
                    {loading ?
                        <TableLoading />
                        :
                        <>
                            {data.length > 0 ?
                                <>
                                    {/* {data.map((item: any, index: number) => (
                                        <Col md={12} key={index}>
                                            <ListCard item={item} handleResponse={handleResponse} />
                                        </Col>
                                    ))} */}
                                    <Table responsive className="mb-4 border center-table" striped bordered>
                                        <thead>
                                            <tr>
                                                <th>{translate('no.')}</th>
                                                <th>Product name</th>
                                                <th>Code</th>
                                                <th>Stock</th>
                                                <th>{translate('material')}</th>
                                                <th>{translate('style')}</th>
                                                <th>{translate('sleeve')}</th>
                                                <th>{translate('brand')}</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((item: any, index: any) =>
                                                <tr key={index} >
                                                    <TableCard item={item} handleResponse={handleResponse} index={index} pageCurrent={page.current} />
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </> : <NoData />}
                        </>
                    }
                </>
                :
                <Row>
                    {loading ?
                        <ProductGridLoading />
                        :
                        <>
                            {data.length > 0 ?
                                <>
                                    {data.map((item: any, index: number) => (
                                        <Col md={6} key={index}>
                                            <GridCard item={item} handleResponse={handleResponse} />
                                        </Col>
                                    ))}
                                </> : <NoData />}
                        </>
                    }
                </Row>}

            {(page && page.total > limit) && <PgComponent
                currentPage={page.current + 1 || 0}
                fromCount={page.current || 0}
                total={page.total || 0}
                paginationLimit={limit}
                pageInfo={page}
                setPage={setPage}
                handleGetData={handleGetData}
                isFilter={variable?.isProductFilter}
                handleChangePage={handleChangePage}
            />}
        </div>
    )
}

export const GridCard = ({ item, handleResponse }: { item: any, handleResponse: any }) => {
    const history = useHistory()
    const { dispatch: { translate } } = useContext(LangContext);
    const [page, setPage] = useContext(SecondPageContext)
    const tempUser: any = localStorage.getItem("USER")
    const user = tempUser && JSON.parse(tempUser)
    const goDetail = () => {
        history.push({
            pathname: '/product-detail',
            state: { item: item }
        })

        setPage({ current: 0, total: 0, sorting: page.sorting })
    }

    const handleDelete = (item: any) => {
        deleteService(item.productName, { "productId": item.id }, productsRoute, handleResponse)
    }

    const productImage = () => {
        let tempImages = item.productColors
        let tempDiv = <Image src={'images/noimage.png'} />;
        if (tempImages.length > 0) {
            tempDiv = <Image src={`${RES_API_URL}${tempImages[0]}`} alt={tempImages[0].url} />
        }
        return tempDiv;
    }


    return (
        <section className="product-card" >
            <section className="product-card-image" onClick={goDetail}>
                {productImage()}
            </section>
            <section className="product-card-info">
                <section onClick={goDetail}>
                    <h6 className="fw-bold mb-3">{item?.productName}</h6>
                    <section className="d-flex justify-content-between mb-2">
                        <small className="text-muted d-block mb-1">Code : {item?.productCode}</small>
                        {item?.brands && <small className="text-muted d-block">{translate('brand')} : {item?.brands?.brandName}</small>}
                    </section>
                    {item?.attributes.material && <small className="text-muted d-block mb-2">{translate('material')} : {item?.attributes.material?.attrValue}</small>}
                    {item?.attributes.style && <small className="text-muted d-block mb-2">{translate('style')} : {item?.attributes.style?.attrValue}</small>}
                    {item?.attributes.sleeve && <small className="text-muted d-block mb-2">{translate('sleeve')} : {item?.attributes.sleeve?.attrValue}</small>}
                    {item?.attributes.width && <small className="text-muted d-block mb-2">{translate('width')} : {item?.attributes.width?.attrValue}</small>}
                </section>
                <section className="d-flex justify-content-between align-items-center mt-4">
                    <section onClick={goDetail}>
                        <small className="text-muted d-block mb-2">Stock : {item?.stockCount}</small>
                    </section>
                    {(user?.userRole === "Owner" || user?.userRole === "Admin") && <section>
                        <Button className="text-white btn-sm bg-primary-grad" onClick={() => history.push({
                            pathname: '/edit-product',
                            state: { item: item }
                        })}><IconEdit /></Button>
                        <Button className="ms-2 btn-danger btn-sm" onClick={() => handleDelete(item)}><IconDelete /></Button>
                    </section>}
                </section>
            </section>
        </section>
    )
}

export const TableCard = ({ item, handleResponse, index, pageCurrent }: { item: any, handleResponse: any, index: number, pageCurrent: number }) => {
    const history = useHistory()
    const [page, setPage] = useContext(SecondPageContext)
    const tempUser: any = localStorage.getItem("USER")
    const user = tempUser && JSON.parse(tempUser)
    const goDetail = () => {
        history.push({
            pathname: '/product-detail',
            state: { item: item }
        })

        setPage({ current: 0, total: 0, sorting: page.sorting })
    }

    const handleDelete = (item: any) => {
        deleteService(item.productName, { "productId": item.id }, productsRoute, handleResponse)
    }

    return (
        <>
            <td><span className="flex-center">{getNumber(index, pageCurrent)}</span></td>
            <td>{item.productName}</td>
            <td><span className="flex-center">{item.productCode} </span></td>
            <td><span className="flex-end">{numberFormat(item.stockCount)} </span></td>
            <td><span className="flex-center">{item?.attributes.material?.attrValue || "-"} </span></td>
            <td><span className="flex-center">{item?.attributes.style?.attrValue || "-"} </span></td>
            <td><span className="flex-center">{item?.attributes.sleeve?.attrValue || "-"} </span></td>
            <td><span className="flex-center">{item?.brands?.brandName || "-"} </span></td>
            <td>
                <span className="flex-center">
                    <Button className="text-white btn-sm bg-primary-grad" onClick={goDetail}><IconFile /></Button>
                    {(user?.userRole === "Owner" || user?.userRole === "Admin") && <>
                        <Button className="text-white btn-sm bg-primary-grad mx-2" onClick={() => history.push({
                            pathname: '/edit-product',
                            state: { item: item }
                        })}><IconEdit /></Button>
                        <Button className=" btn-danger btn-sm" onClick={() => handleDelete(item)}><IconDelete /></Button>
                    </>}
                </span>
            </td>
        </>
    )
}

export const ListCard = ({ item, handleResponse }: { item: any, handleResponse: any }) => {
    const history = useHistory()
    const { dispatch: { translate } } = useContext(LangContext);
    const [page, setPage] = useContext(SecondPageContext)
    const goDetail = () => {
        history.push({
            pathname: '/product-detail',
            state: { item: item }
        })

        setPage({ current: 0, total: 0, sorting: page.sorting })
    }

    const handleDelete = (item: any) => {
        deleteService(item.productName, { "productId": item.id }, productsRoute, handleResponse)
    }

    return (
        <section className="product-card-list-view" >
            <section className="product-card-image" onClick={goDetail}>
                <Image src={'images/noimage.png'} />
            </section>
            <section className="product-card-info">
                <section onClick={goDetail}>
                    <h6 className="fw-bold mb-3">{item?.productName}</h6>

                    <section className="d-flex align-items-end">
                        <section>
                            <small className="text-muted d-block mb-1">#{item?.productCode}</small>
                            {item?.brands && <small className="text-muted d-block mb-1">{translate('brand')} : {item?.brands?.brandName}</small>}
                            {item?.attributes.material && <small className="text-muted d-block mb-1">{translate('material')} : {item?.attributes.material?.attrValue}</small>}
                            {item?.attributes.style && <small className="text-muted d-block mb-1">{translate('style')} : {item?.attributes.style?.attrValue}</small>}
                            {item?.attributes.sleeve && <small className="text-muted d-block">{translate('sleeve')} : {item?.attributes.sleeve?.attrValue}</small>}
                            {item?.attributes.width && <small className="text-muted d-block ">{translate('width')} : {item?.attributes.width?.attrValue}</small>}
                            {/* <small className="text-muted d-block">Stock : 3,000</small> */}
                        </section>
                        {/* <section className="ms-5">
                                <small className="text-muted d-block">40-44 : 13,000 Ks</small>
                                <small className="text-muted d-block">46-48 : 14,000 Ks</small>
                            </section> */}
                    </section>
                </section>
                <section className="d-flex align-items-center">
                    <Button className="text-white btn-sm bg-primary-grad" onClick={() => history.push({
                        pathname: '/edit-product',
                        state: { item: item }
                    })}><IconEdit /></Button>
                    <Button className="ms-2 btn-danger btn-sm" onClick={() => handleDelete(item)}><IconDelete /></Button>
                </section>
            </section>
        </section>
    )
}

export const PrintLabelComponent = React.forwardRef((props: any, ref: any) => {
    let printInfo = props.printInfo;
    let variant = props.variant;
    let imageUrl = props.qrImageURL;
    // const [imageUrl, setImageUrl] = useState('');
    //const labelSize = `label-card-${props.printInfo.labelSize}`

    // useEffect(() => {
    //     generateQrCode()
    //     // eslint-disable-next-line
    // }, [])

    // const generateQrCode = async () => {
    //     try {
    //         const response = await QRCode.toDataURL(variant?.sku);
    //         setImageUrl(response);
    //     } catch (error: any) {
    //         toast.error(error);
    //     }
    // }


    return (
        <section className="print-labels" ref={ref}>
            {printInfo.numOfRow ? [...Array(printInfo.numOfRow)].map((item: any, index: number) => (
                <div className="label-card-for-32-25" key={index}>
                    {imageUrl && <Image src={imageUrl} alt="no_found" width={60} />}
                    <h6 className="card-label">{variant?.sku}</h6>
                </div>
            )
            ) : <section></section>}
        </section>
        // <section className="print-labels" ref={ref}>
        //     {props.printCount ? [...Array(props.printCount)].map((item: any, index: number) => (
        //         <div className="print-card">
        //             {printLabels?.qrcode && <QRCode value={variant?.sku || ""} className="qr-code" size={40} />}

        //             <div className="ms-2 card-label-container">
        //                 {printLabels?.name && <h6 className="card-label">{variant?.productName}</h6>}
        //                 {printLabels?.code && <h6 className="card-label">{variant?.sku}</h6>}
        //                 {printLabels?.price && <h6 className="card-label">{numberFormat(variant?.retailPrice)} MMK</h6>}
        //             </div>
        //         </div>
        //     )
        //     ) : <section></section>}
        // </section>
        // <section className="print-labels" ref={ref}>
        //     {props.printCount ? [...Array(props.printCount)].map((item: any, index: number) => (
        //         <div className="print-card-for-32-25">
        //             {printLabels?.name && <h6 className="card-label">{variant?.productName}</h6>}
        //             {printLabels?.qrcode && <QRCode value={variant?.sku || ""} size={40} />}
        //             {printLabels?.code && <h6 className="card-label-small">{variant?.sku} {printLabels?.price && <span className="ms-2">{numberFormat(variant?.retailPrice)} MMK</span>}</h6>}
        //         </div>
        //     )
        //     ) : <section></section>}
        // </section>
    );
});