import { useHistory } from "react-router-dom";
import CartItem from "./components/CartItem";
import CustomerInfo from "./components/CustomerInfo";

const PosCart = () => {
    const history = useHistory()

    return (
        <section className="pos-cart">
            <section className="p-4">
                <CustomerInfo />

                <section>
                    <CartItem thumbnail="temp/temp1.jpeg" size={40} price={13000} quantity={5} color="Red" />
                    {/* <CartItem thumbnail="temp/temp1.jpeg" size={44} price={13000} quantity={2} color="Pink" /> */}
                </section>

                {/* <section className="flex-between mb-2">
                    <small className="xs-label text-muted d-block">Subtotal</small>
                    <small className="d-block text-muted">65,000</small>
                </section>
                <section className="flex-between border-bottom pb-3 mb-3">
                    <small className="xs-label text-muted d-block">Discount</small>
                    <small className="d-block text-muted">-</small>
                </section> */}
                <section className="flex-between">
                    <h6 className="text-muted">Total</h6>
                    <h6 className="text-muted">65,000</h6>
                </section>
            </section>
            <section className="pos-sale-btn" onClick={() => history.push("/sale-info")}>
                <label>Sale</label>
                <label>MMK 65,000</label>
            </section>
        </section>
    );
};

export default PosCart;
