import { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { LoadingButtonLabel } from "..";
import { LangContext } from "../../context/Lang";
import { postMethod, putMethod } from "../../services";

import FormFieldService from "./FormFieldService";

export const CustomModalService = ({
    show,
    handleSubmit,
    handleClose,
    id,
    loading,
    lg = false,
    setShow,
    fields,
    handleChange,
    handleInputChange,
    formData,
    setFormData,
    customTitle = "" }:
    {
        show: boolean,
        handleSubmit: any,
        handleClose: any,
        id: any,
        loading: boolean,
        lg?: boolean,
        setShow: any,
        fields: any,
        handleChange: any,
        handleInputChange?: any,
        formData?: any,
        setFormData?: any,
        customTitle?: string
    }) => {
    return (
        <CustomModal show={show} handleClose={() => handleClose(setShow)} customTitle={customTitle} id={id} handleSubmit={handleSubmit} loading={loading} lg={lg}>
            <FormFieldService fields={fields} handleChange={handleChange} handleInputChange={handleInputChange} formData={formData} setFormData={setFormData} />
        </CustomModal>
    )
}

export const CustomModal = ({
    show,
    handleSubmit,
    handleClose,
    id,
    loading,
    lg,
    xl,
    children,
    customTitle = "" }:
    {
        show: boolean,
        handleSubmit: any,
        handleClose: any,
        id: any,
        loading: boolean,
        lg?: boolean,
        xl?: boolean,
        children: any,
        customTitle?: string
    }) => {
    const { dispatch: { translate } } = useContext(LangContext);

    return (
        <Modal show={show} onHide={handleClose} centered size={lg ? 'lg' : xl ? 'xl' : undefined}>
            <Modal.Header closeButton>
                <Modal.Title>{customTitle ? customTitle : id ? translate("editInfo") : translate("createNew")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <Button className='bg-success' onClick={handleClose}>
                    Cancel
                </Button>
                <Button className='bg-grad' onClick={() => handleSubmit(id ? putMethod : postMethod)} disabled={loading}>
                    <LoadingButtonLabel label='Save' disabled={false} />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
