import { useState } from "react";
import { InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { IconAdd } from "../../../components/Icons";
import AddCustomerModal from "../../customers/components/AddCustomerModal";
import CustomerInfoAccordion from "./CustomerInfoAccordion";


const CustomerInfo = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <section className="mb-4">
            <AddCustomerModal show={show} handleClose={handleClose} />
            <InputGroup className="mb-4">
                <section style={{ width: "85%" }}>
                    <Select
                        isClearable
                        // options={buyers}
                        // defaultValue={variable?.buyer && { label: variable?.buyer.label! }}
                        // onInputChange={handleBuyerInputChange}
                        // onChange={(value: any) => setVariable({ ...variable, buyer: value })}
                        placeholder={'Select Customer'}
                        classNamePrefix="react-select"
                        className="react-select-container-half-radius"
                        styles={{
                            control: (base: any, state: any) => ({
                                ...base,
                                boxShadow: state.isFocused ? 0 : 0
                            })
                        }}

                    />
                </section>
                <InputGroup.Text className="bg-primary text-white d-flex justify-content-center" style={{ width: "15%" }}>
                    <OverlayTrigger
                        overlay={
                            <Tooltip>
                                Add New Customer
                            </Tooltip>
                        }
                    >
                        <Link to="#" className="btn btn-grad btn-sm" onClick={handleShow}><IconAdd /></Link>
                    </OverlayTrigger>
                </InputGroup.Text>
            </InputGroup>

            <CustomerInfoAccordion />
        </section>
    );
};

export default CustomerInfo;
