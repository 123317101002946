import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, FormCheck, Modal, Row } from "react-bootstrap";
import { LangContext } from "../../../context/Lang";
import { customerRoute, dataService, getData, inputChangeService, postMethod, regionRoute, townshipRoute } from "../../../services";

const AddCustomerModal = ({ show, handleClose, responseAction }: { show: boolean, handleClose: any, responseAction?: any, }) => {
    const { dispatch: { translate } } = useContext(LangContext);
    const [regions, setRegions] = useState([])
    const [regionCode, setRegionCode] = useState("")
    const [shippingRegionCode, setShippingRegionCode] = useState("")
    const [townships, setTownships] = useState([])
    const [shippingTownships, setShippingTownships] = useState([])
    const [isDifferentAddress, changeDifferentAddress] = useState(false)
    const [formData, setFormData] = useState({
        userRoleId: 5,
        personId: "",
        fullName: "",
        email: "",
        facebookUrl: "",
        phone: "",
        phoneOther: "",
        billingAddress: "",
        billingSubSubAreaCode: "",
        billingNote: "",
        shippingAddress: "",
        shippingSubSubAreaCode: "",
        shippingNote: "",
    })
    const required = ["fullName", "phone"]

    useEffect(() => {
        handleGetRegions()
        clearData()
    }, [])

    const handleGetRegions = async () => {
        getData(regionRoute).then(result => {
            setRegions(result.data)
        })
    }

    const handleGetTownships = async (regionCode: string) => {
        getData(townshipRoute(regionCode)).then(result => {
            setTownships(result.data)
        })
    }

    const handleSelectRegion = (evt: any) => {
        const value = evt.target.value
        setRegionCode(value)
        handleGetTownships(value)
    }

    const handleGetShippingTownships = async (regionCode: string) => {
        getData(townshipRoute(regionCode)).then(result => {
            setShippingTownships(result.data)
        })
    }

    const handleSelectShippingRegion = (evt: any) => {
        const value = evt.target.value
        setShippingRegionCode(value)
        handleGetShippingTownships(value)
    }

    const handleChange = (evt: any, wantString?: boolean) => {
        inputChangeService(evt, formData, setFormData, wantString);
    }

    const clearData = () => {
        setRegionCode("")
        setFormData({
            userRoleId: 5,
            personId: "",
            fullName: "",
            email: "",
            facebookUrl: "",
            phone: "",
            phoneOther: "",
            billingAddress: "",
            billingSubSubAreaCode: "",
            billingNote: "",
            shippingAddress: "",
            shippingSubSubAreaCode: "",
            shippingNote: "",
        })
    }


    //Add Action
    const handleSubmit = () => {

        let shippingAddress = {
            address: formData.shippingAddress || "",
            subSubAreaCode: formData.shippingSubSubAreaCode || null,
            note: formData.shippingNote || "",
        }

        let billingAddress = {
            address: formData.billingAddress || "",
            subSubAreaCode: formData.billingSubSubAreaCode || null,
            note: formData.billingNote || "",
        }

        let _formData = {
            fullName: formData.fullName,
            email: formData.email,
            phone: formData.phone,
            phoneOther: formData.phoneOther,
            facebookUrl: formData.facebookUrl,
            shippingAddress: isDifferentAddress ? shippingAddress : billingAddress,
            billingAddress: billingAddress,
        }

        dataService(postMethod, _formData, required, customerRoute).then(result => {
            responseAction(result)
            result.success && clearData()
        })
    }

    return (
        <Modal show={show} onHide={handleClose} centered size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{translate("createNew")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={4}>
                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label className="text-muted">{translate("name")} *</Form.Label>
                            <Form.Control type="text" id="fullName" onChange={handleChange} value={formData?.fullName} />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label className="text-muted">{translate("phone")} *</Form.Label>
                            <Form.Control type="text" id="phone" onChange={handleChange} value={formData?.phone} />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label className="text-muted">Other phone</Form.Label>
                            <Form.Control type="text" id="phoneOther" onChange={handleChange} value={formData?.phoneOther} />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label className="text-muted">{translate("email")}</Form.Label>
                            <Form.Control type="text" id="email" onChange={handleChange} value={formData?.email} />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label className="text-muted">Facebook URL</Form.Label>
                            <Form.Control type="text" id="facebookUrl" onChange={handleChange} value={formData?.facebookUrl} />
                        </Form.Group>
                    </Col>

                </Row>

                <Row className='align-items-center'>
                    <Col>
                        <h6 className='mb-0'>Billing address</h6>
                    </Col>
                    <Col>
                        <div className='flex-between align-items-center'>

                            <h6 className='mb-0'>Shipping address</h6>

                            <FormCheck
                                id="switch"
                                className="switch"
                                type="switch"
                                label={"Different address"}
                                checked={isDifferentAddress}
                                onChange={() => changeDifferentAddress(!isDifferentAddress)}
                            />
                            {/*                            
                            <Button className='bg-grad btn-sm'  onClick={() => {
                                if (shippingRegionCode) {
                                    handleGetTownships(shippingRegionCode)
                                }

                                setRegionCode(shippingRegionCode)

                                setFormData({
                                    ...formData,
                                    billingAddress: formData.shippingAddress || "",
                                    billingSubSubAreaCode:formData.shippingSubSubAreaCode || "",
                                    billingNote: formData.shippingNote || "",
                                })
                            }}>Same as shipping address</Button> */}
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className='p-3 rounded bg-light mt-3'>
                            <Row>
                                <Col md={12}>
                                    <Form.Group className="mb-4 input-form-control" >
                                        <Form.Label className="text-muted">{translate("region")}</Form.Label>
                                        <Form.Control as="select" aria-label="Select" className="mb-4" id="areaCode" value={regionCode} onChange={handleSelectRegion}>
                                            <option value="">Select</option>
                                            {regions.map((item: any, index: number) =>
                                                <option value={item.areaCode} key={index}>{item.areaNameLocal}</option>
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="mb-4 input-form-control" >
                                        <Form.Label className="text-muted">{translate("township")}</Form.Label>
                                        <Form.Control as="select" aria-label="Select" className="mb-4" id="billingSubSubAreaCode" value={formData?.billingSubSubAreaCode} onChange={(evt) => handleChange(evt, true)}>
                                            <option value="">Select</option>
                                            {townships.map((item: any, index: number) =>
                                                <option value={item.subSubAreaCode} key={index}>{item.subSubAreaNameLocal}</option>
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="mb-4  input-form-control" >
                                        <Form.Label className="text-muted">{translate("address")}</Form.Label>
                                        <Form.Control type="text" id="billingAddress" onChange={handleChange} value={formData?.billingAddress} />
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="input-form-control" >
                                        <Form.Label className="text-muted">Note</Form.Label>
                                        <Form.Control type="text" id="billingNote" onChange={handleChange} value={formData?.billingNote} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col md={12}>
                                    <Form.Group className="mb-4 input-form-control" >
                                        <Form.Label className="text-muted">{translate("region")}</Form.Label>
                                        <Form.Control as="select" aria-label="Select" className="mb-4" id="areaCode" value={shippingRegionCode} onChange={handleSelectShippingRegion}>
                                            <option value="">Select</option>
                                            {regions.map((item: any, index: number) =>
                                                <option value={item.areaCode} key={index}>{item.areaNameLocal}</option>
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="mb-4 input-form-control" >
                                        <Form.Label className="text-muted">{translate("township")}</Form.Label>
                                        <Form.Control as="select" aria-label="Select" className="mb-4" id="shippingSubSubAreaCode" value={formData?.shippingSubSubAreaCode} onChange={(evt) => handleChange(evt, true)}>
                                            <option value="">Select</option>
                                            {shippingTownships.map((item: any, index: number) =>
                                                <option value={item.subSubAreaCode} key={index}>{item.subSubAreaNameLocal}</option>
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="mb-4  input-form-control" >
                                        <Form.Label className="text-muted">{translate("address")}</Form.Label>
                                        <Form.Control type="text" id="shippingAddress" onChange={handleChange} value={formData?.shippingAddress} />
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="input-form-control" >
                                        <Form.Label className="text-muted">Note</Form.Label>
                                        <Form.Control type="text" id="shippingNote" onChange={handleChange} value={formData?.shippingNote} />
                                    </Form.Group>
                                </Col>
                            </Row> */}
                        </div>
                    </Col>
                    <Col>
                        <div className='p-3 rounded bg-light mt-3'>
                            {isDifferentAddress ? <Row>
                                <Col md={12}>
                                    <Form.Group className="mb-4 input-form-control" >
                                        <Form.Label className="text-muted">{translate("region")}</Form.Label>
                                        <Form.Control as="select" aria-label="Select" className="mb-4" id="areaCode" value={shippingRegionCode} onChange={handleSelectShippingRegion}>
                                            <option value="">Select</option>
                                            {regions.map((item: any, index: number) =>
                                                <option value={item.areaCode} key={index}>{item.areaNameLocal}</option>
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="mb-4 input-form-control" >
                                        <Form.Label className="text-muted">{translate("township")}</Form.Label>
                                        <Form.Control as="select" aria-label="Select" className="mb-4" id="shippingSubSubAreaCode" value={formData?.shippingSubSubAreaCode} onChange={(evt) => handleChange(evt, true)}>
                                            <option value="">Select</option>
                                            {shippingTownships.map((item: any, index: number) =>
                                                <option value={item.subSubAreaCode} key={index}>{item.subSubAreaNameLocal}</option>
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="mb-4  input-form-control" >
                                        <Form.Label className="text-muted">{translate("address")}</Form.Label>
                                        <Form.Control type="text" id="shippingAddress" onChange={handleChange} value={formData?.shippingAddress} />
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="input-form-control" >
                                        <Form.Label className="text-muted">Note</Form.Label>
                                        <Form.Control type="text" id="shippingNote" onChange={handleChange} value={formData?.shippingNote} />
                                    </Form.Group>
                                </Col>
                            </Row> : <Row>
                                <Col md={12}>
                                    <Form.Group className="mb-4 input-form-control" >
                                        <Form.Label className="text-muted">{translate("region")}</Form.Label>
                                        <Form.Control as="select" aria-label="Select" className="mb-4" id="areaCode" value={regionCode} onChange={handleSelectRegion} disabled>
                                            <option value="">Select</option>
                                            {regions.map((item: any, index: number) =>
                                                <option value={item.areaCode} key={index}>{item.areaNameLocal}</option>
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="mb-4 input-form-control" >
                                        <Form.Label className="text-muted">{translate("township")}</Form.Label>
                                        <Form.Control as="select" aria-label="Select" className="mb-4" id="billingSubSubAreaCode" value={formData?.billingSubSubAreaCode} onChange={(evt) => handleChange(evt, true)} disabled>
                                            <option value="">Select</option>
                                            {townships.map((item: any, index: number) =>
                                                <option value={item.subSubAreaCode} key={index}>{item.subSubAreaNameLocal}</option>
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="mb-4  input-form-control" >
                                        <Form.Label className="text-muted">{translate("address")}</Form.Label>
                                        <Form.Control type="text" id="billingAddress" onChange={handleChange} value={formData?.billingAddress} disabled />
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="input-form-control" >
                                        <Form.Label className="text-muted">Note</Form.Label>
                                        <Form.Control type="text" id="billingNote" onChange={handleChange} value={formData?.billingNote} disabled />
                                    </Form.Group>
                                </Col>
                            </Row>}

                        </div>
                    </Col>

                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddCustomerModal;
