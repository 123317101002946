import { useContext, useEffect, useState } from 'react'
import { LangContext } from '../../../context/Lang'
import { attributeDetailRoute, dataService, deleteService, getData, inputChangeService, productTypesRoute, responseService, } from '../../../services'
import { Layout, SubHeader, CustomInput, CustomModal, TableLayout, TableRowButtons } from '../../../components'
import { Form } from 'react-bootstrap'

const AttributeDetailComponent = ({ attributes, route, title, label, showCode, dependProductType }: { attributes: string, route: string, title: string, label: string, showCode?: boolean, dependProductType?: boolean }) => {
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { dispatch: { translate } } = useContext(LangContext);
    const [data, setData] = useState([])
    const [productTypes, setProductTypes] = useState([])
    const [formData, setFormData] = useState({
        attribute: attributes,
        attrValuesId: '',
        attrValue: "",
        productTypesId: dependProductType ? 1 : ""
    })
    const required = ["attrValue"]

    useEffect(() => {
        handleGetData()
        handleGetAttributes()
        // eslint-disable-next-line
    }, [])


    const handleGetData = async () => {
        setLoading(true)
        getData(route).then(result => {
            setData(result.data.values)
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        })
    }

    const handleGetAttributes = async () => {
        getData(productTypesRoute).then(result => { setProductTypes(result.data) })
    }


    const handleSubmit = (method: string) => {
        setLoading(true)
        dataService(method, formData, required, attributeDetailRoute).then(result => {
            handleResponse(result)
        })
    }

    const handleDelete = (item: any) => {
        deleteService(item.attrValue, { "attrValuesId": item.id }, attributeDetailRoute, handleResponse)
    }

    const handleResponse = (result: any) => {
        responseService(result, handleGetData, handleClose, null, null, setLoading)
    }

    const handleChange = (evt: any) => {
        inputChangeService(evt, formData, setFormData);
    }

    const showForm = (item?: any) => {
        setFormData({
            attribute: item.id ? "" : attributes,
            attrValuesId: item.id,
            attrValue: item.attrValue || "",
            productTypesId: dependProductType ? item.productTypesId || 1 : ""
        })
        handleShow();
    }

    return (
        <Layout title={title}>
            <SubHeader title={translate("configuration")} showForm={showForm} />

            <TableLayout loading={loading} data={data}>
                <thead>
                    <tr>
                        <th className='ps-4'>{translate("no.")}</th>
                        {showCode && <th>Code</th>}
                        <th>{label}</th>
                        {dependProductType && <th>{translate('productType')}</th>}
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {data.map((item: any, index: any) =>
                        <tr key={index}>
                            <td><span className='flex-center'>{index + 1}</span></td>
                            {showCode && <td><span className='flex-center'>C{item.id}</span></td>}
                            <td>{item.attrValue}</td>
                            {dependProductType && <td><span className='flex-center'>{item.productType || "-"}</span></td>}
                            <td><TableRowButtons item={item} showForm={showForm} handleDelete={handleDelete} flex={"flex-center"} /></td>
                        </tr>
                    )}
                </tbody>
            </TableLayout>

            <CustomModal show={show} handleClose={handleClose} id={formData?.attrValuesId} handleSubmit={handleSubmit} loading={loading}>
                <CustomInput label={label} id="attrValue" handleChange={handleChange} value={formData?.attrValue} />

                {dependProductType && <Form.Group className="mb-4 input-form-control" >
                    <Form.Label>{translate('productType')}</Form.Label>
                    <Form.Control
                        as="select"
                        aria-label="Select"
                        id="productTypesId"
                        onChange={handleChange}
                        value={formData?.productTypesId} >
                        {productTypes.map((item: any, index: number) =>
                            <option value={item.id} key={index}>{item.productType}</option>
                        )}
                    </Form.Control>
                </Form.Group>}
            </CustomModal>
        </Layout >
    )
}

export default AttributeDetailComponent