import Login from './auth/Login'
import Dashboard from './dashboard'
import Profile from './account/Profile'
import ChangePassword from './account/ChangePassword'

// Product
import Products from './products'
import ProductDetail from './products/detail'
import ProductPrintLabel from './products/PrintLabel'
import VariantDetail from './products/varients/Detail'
import AddProduct from './products/Add'
import EditProduct from './products/Edit'
import RetailInventories from './inventories/Retail'
import WholeInventories from './inventories/Wholesale'

// Orders and Sales
import Orders from './orders'
import OrderDetail from './orders/Detail'
import AddOrder from './orders/retail/Add'
import EditOrder from './orders/retail/Edit'
import AddWholesaleOrder from './orders/wholesale/Add'
import EditWholesaleOrder from './orders/wholesale/Edit'
import PointOfSale from './pos'
import SaleInfo from './sales/SaleInfo'
import Sales from './sales'
import SaleDetail from './sales/Detail'
import EditSale from './sales/Edit'
import DeliveryLists from './delivery'

//Accounting
import Bookkeeping from './accounting/Bookkeeping'
import AccountReceivable from './accounting/Receivable'
import AccountPayable from './accounting/Payable'
import BadDebt from './accounting/BadDebt'

//Report
import SaleReport from './reports/SaleReport'
import IncomeReport from './reports/IncomeReport'
import DailySaleReport from './reports/DailySaleReport'

//Customer
import Customers from './customers'
import CustomerDetail from './customers/Detail'

// Configutation
import Configuration from './configuration'
import ProductTypes from './configuration/ProductTypes'
import Brands from './configuration/Brands'
import {
  Materials,
  Styles,
  Colors,
  Sleeves,
  Widths,
  Measurement,
  StandardSize,
} from './configuration/AttributeDetails'
import Warehouses from './configuration/Warehouses'
import Factories from './configuration/Factories'
import DeliveryServices from './configuration/DeliveryServices'
import DeliveryFees from './configuration/DeliveryFees'
import Payments from './configuration/Payments'
import Staffs from './configuration/Staffs'
import ReceiptHeader from './configuration/ReceiptHeader'
import WholesaleEdit from './sales/WholesaleEdit'
import ChangePrices from './configuration/ChangePrices'

export { Login }

export const ownerRoutes = [
  { route: 'dashboard', component: Dashboard },
  { route: 'add-product', component: AddProduct },
  { route: 'edit-product', component: EditProduct },
  { route: 'account-payable', component: AccountPayable },
  { route: 'account-receivable', component: AccountReceivable },
  { route: 'bad-debt', component: BadDebt },
  { route: 'bookkeeping', component: Bookkeeping },
  { route: 'sale-reports', component: SaleReport },
  { route: 'income-reports', component: IncomeReport },
  { route: 'daily-sale-reports', component: DailySaleReport },
  { route: 'configuration', component: Configuration },
  { route: 'product-types', component: ProductTypes },
  { route: 'brands', component: Brands },
  { route: 'materials', component: Materials },
  { route: 'styles', component: Styles },
  { route: 'sleeves', component: Sleeves },
  { route: 'widths', component: Widths },
  { route: 'colors', component: Colors },
  { route: 'measurements', component: Measurement },
  { route: 'standard-size', component: StandardSize },
  { route: 'warehouses', component: Warehouses },
  { route: 'factories', component: Factories },
  { route: 'delivery-services', component: DeliveryServices },
  { route: 'delivery-fees', component: DeliveryFees },
  { route: 'payments', component: Payments },
  { route: 'staff-members', component: Staffs },
  { route: 'receipt-header', component: ReceiptHeader },
  { route: 'change-prices', component: ChangePrices },
]

export const privateRoutes = [
  { route: 'profile', component: Profile },
  { route: 'change-password', component: ChangePassword },
  { route: 'products', component: Products },
  { route: 'product-print-label', component: ProductPrintLabel },
  { route: 'product-detail', component: ProductDetail },
  { route: 'variant-detail', component: VariantDetail },
  { route: 'retail-inventories', component: RetailInventories },
  { route: 'wholesale-inventories', component: WholeInventories },
  { route: 'orders', component: Orders },
  { route: 'order-detail', component: OrderDetail },
  { route: 'add-order', component: AddOrder },
  { route: 'edit-order', component: EditOrder },
  { route: 'add-wholesale-order', component: AddWholesaleOrder },
  { route: 'edit-wholesale-order', component: EditWholesaleOrder },
  { route: 'point-of-sale', component: PointOfSale },
  { route: 'sale-info', component: SaleInfo },
  { route: 'sales', component: Sales },
  { route: 'sale-detail', component: SaleDetail },
  { route: 'edit-sale', component: EditSale },
  { route: 'edit-wholesale', component: WholesaleEdit },
  { route: 'delivery-lists', component: DeliveryLists },
  { route: 'customers', component: Customers },
  { route: 'customer-detail', component: CustomerDetail },
]
