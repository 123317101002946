import { useContext } from "react";
import { Button, ButtonGroup, Col, Form, InputGroup, Nav, Row, Tab } from "react-bootstrap";
import { IconSearch } from "../../components/Icons";
import { VariableContext } from "../../context/Variable";
import ItemCard from "./components/ItemCard";
import ItemCardByCode from "./components/ItemCardByCode";

const PosItem = () => {
    const [variable, setVariable] = useContext(VariableContext)

    return (
        <section className="pt-4 px-4">
            <section className="flex-between mb-3">
                <Col md={8}>
                    <InputGroup className="input-form-control">
                        <InputGroup.Text className="bg-primary-grad text-white">
                            <IconSearch />
                        </InputGroup.Text>
                        <Form.Control placeholder="Search" autoFocus />
                    </InputGroup>
                </Col>

                <ButtonGroup>
                    <Button
                        onClick={() => setVariable({ ...variable, isSKU: false })}
                        className={variable?.isSKU ? "bg-white text-primary" : "text-white bg-primary-grad"}
                    >Code</Button>
                    <Button
                        onClick={() => setVariable({ ...variable, isSKU: true })}
                        className={variable?.isSKU ? "text-white bg-primary-grad" : "bg-white text-primary"}>SKU</Button>
                </ButtonGroup>
            </section>

            <Col md={6} className="mb-4">
                <Tab.Container defaultActiveKey="all">
                    <Nav className="" activeKey="all">
                        <Nav.Item>
                            <Nav.Link eventKey="all">All</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="အင်္ကျီ">အင်္ကျီ</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="လုံချည်">လုံချည်</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Tab.Container>
            </Col>
            {variable?.isSKU ?
                <Row>
                    <ItemCard thumbnail="temp/temp1.jpeg" size={40} price="13,000" stock="900" />
                    <ItemCard thumbnail="temp/temp1.jpeg" size={42} price="13,000" stock="500" />
                    <ItemCard thumbnail="temp/temp1.jpeg" size={44} price="14,000" stock="450" />
                    <ItemCard thumbnail="temp/temp1.jpeg" size={46} price="14,000" stock="300" />
                    <ItemCard thumbnail="temp/temp1.jpeg" size={48} price="14,000" stock="200" />
                    <ItemCard thumbnail="temp/temp1.jpeg" size={50} price="14,000" stock="130" />
                </Row> :
                <Row>
                    <ItemCardByCode thumbnail="temp/temp1.jpeg" name="ပိတ်ဖေါက်ဇာရှေ့ကွဲလက်တို" code="#500" size={40} price="13,000" stock="900" />
                    <ItemCardByCode thumbnail="temp/temp6.jpeg" name="ဝါးသားရှေ့ကွဲလက်တို" code="#501" size={44} price="13,000" stock="500" />
                    <ItemCardByCode thumbnail="temp/temp7.jpeg" name="ပိတ်ဖေါက်ဇာနောက်ဆွဲလက်စက" code="#502" size={40} price="13,000" stock="400" />
                    <ItemCardByCode thumbnail="temp/temp8.jpeg" name="ဝါးသားနောက်ဆွဲလက်တို" code="#503" size={44} price="13,000" stock="300" />
                    <ItemCardByCode thumbnail="temp/temp9.jpeg" name="ဝါးသားခေါင်းစွပ်လက်တို" code="#504" size={40} price="13,000" stock="200" />
                    <ItemCardByCode thumbnail="temp/temp10.jpeg" name="ပိတ်ဖေါက်ဇာရှေ့ကွဲလက်စက" code="#505" size={44} price="13,000" stock="600" />
                </Row>
            }
        </section >
    );
};

export default PosItem;
