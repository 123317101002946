
import React, { useContext } from "react";
import { Table } from "react-bootstrap";
import { LangContext, PageContext } from "../../../context";
import { getNumber, handleDateFormat, numberFormat } from "../../../services";


const Print = React.forwardRef((props: any, ref: any) => {
    const { dispatch: { translate } } = useContext(LangContext);
    const data = props.data
    const [page] = useContext(PageContext)
    let saleCount = props.saleCount;
    let amount = props.amount;
    let deliveryFee = props.deliveryFee;
    let discount = props.discount;
    let total = props.total;
    let orderType = props.orderType;
    let totalQty = props.totalQty;
    let startDate = props.startDate;
    let endDate = props.endDate;
    let summarySaleCount = props.summarySaleCount;
    let summaryTotalQty = props.summaryTotalQty;
    // let summaryTotal = props.summaryTotal;

    return (
        <section ref={ref} className="default-margin">
            <div className="d-flex flex-column align-items-center mb-1">
                <h6 className="mt-2 mb-1 fw-bold">{translate('saleReports')}</h6>
            </div>
            {/* <div className="d-flex flex-between mb-2">
                <small className="print-label-xxs">Order type : <span className="fw-bold">{orderType ? orderType : "Retail , Wholesale"}</span></small>
                <small className="print-label-xxs">
                    {startDate && <span>{endDate ? "From date" : "Date"} : <span className="fw-bold">{startDate}</span></span>}
                    {endDate && <span className='ms-3'>End date : <span className="fw-bold">{endDate}</span></span>}
                </small>
            </div> */}

            <div className='flex-between mb-1'>
                <div>
                    <small className="print-label-xxs mb-1">{translate('type')} : {orderType ? orderType : "Retail , Wholesale"}</small>
                    {startDate && <small className="d-block print-label-xxs mb-1">{endDate ? "From date" : "Date"} : {startDate}</small>}
                    {endDate && <small className='d-block print-label-xxs mb-1'>End date : {endDate}</small>}
                </div>
                <div className='d-flex flex-column align-items-end'>
                    <small className="d-block print-label-xxs mb-1">{translate('numberOfSale')} : {numberFormat(summarySaleCount)}</small>
                    <small className="d-block print-label-xxs mb-1">{translate('productQuantity')} : {numberFormat(summaryTotalQty)}</small>
                    {/* <small className='d-block print-label-xxs mb-1'>{translate('total')} : {numberFormat(summaryTotal)} {translate('mmk')}</small> */}
                </div>
            </div>

            <Table className="mb-0 print-table" bordered striped>
                <thead>
                    <tr>
                        <th>{translate('no.')}</th>
                        <th><span className='flex-center'>{translate('date')}</span></th>
                        <th><span className='flex-center'>Source</span></th>
                        {!orderType && <th>Type</th>}
                        <th>Sales</th>
                        <th>Qty</th>
                        <th>Amount</th>
                        <th>{translate('deliveryFee')}</th>
                        <th>Bank (%)</th>
                        <th>Discount</th>
                        <th>{translate('total')}</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item: any, index: number) =>
                        <tr key={index}>
                            <td><span className="flex-center">{getNumber(index, page?.current, 25)}</span></td>
                            <td>{item.dateOfSale !== data[index - 1]?.dateOfSale && handleDateFormat(item.dateOfSale)}</td>
                            <td>{(item.dateOfSale !== data[index - 1]?.dateOfSale || item.orderFrom !== data[index - 1]?.orderFrom) && item.orderFrom}</td>
                            {!orderType && <td>{item.orderType}</td>}
                            <td><span className='flex-end'>{numberFormat(item.numOfSales)}</span></td>
                            <td><span className='flex-end'>{numberFormat(item.productQty)}</span></td>
                            <td><span className='flex-end'>{numberFormat(item.amount)}</span></td>
                            <td><span className='flex-end'>{numberFormat(item.deliveryTotal)}</span></td>
                            <td><span className='flex-end'>{item.bankCommission ? `${numberFormat(item.bankCommission)} %` : "-"}</span></td>
                            <td><span className='flex-end'>{numberFormat(item.discount)}</span></td>
                            <td><span className='flex-end'>{numberFormat(item.grandTotal)}</span></td>
                        </tr>)}
                    <tr>
                        <th colSpan={orderType ? 3 : 4} className="ps-2"><span className="flex-center">{translate('total')}</span></th>
                        <th><span className="flex-end">{numberFormat(saleCount)}</span></th>
                        <th><span className="flex-end">{numberFormat(totalQty)}</span></th>
                        <th><span className="flex-end">{numberFormat(amount)}</span></th>
                        <th><span className="flex-end">{numberFormat(deliveryFee)}</span></th>
                        <th />
                        <th><span className="flex-end">{numberFormat(discount)}</span></th>
                        <th><span className="flex-end">{numberFormat(total)}</span></th>
                    </tr>
                </tbody>
            </Table>
        </section >
    );
});

export default Print