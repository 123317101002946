import { useContext, useEffect, useState } from 'react'
import { Badge, Button, Col, Form, Modal, Row, Table, } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { IconAdd, IconDelete, IconEdit, IconLeftArrow } from '../../components/Icons'
import Layout from '../../components/Layout'
import { TableLoading } from '../../components'
import { LangContext } from '../../context/Lang'
import { actionData, dataService, deleteService, getData, postMethod, putMethod, regionRoute, responseService, staffInviteRoute, staffRoute, townshipRoute, userRolesRoute } from '../../services'

const Staffs = () => {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const { dispatch: { translate } } = useContext(LangContext);
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); setShowEditForm(false) };
    const handleShow = () => setShow(true);
    const [showEditForm, setShowEditForm] = useState(false);
    const handleShowEditForm = () => setShowEditForm(true);
    const [data, setData] = useState([])
    const [staffRoles, setStaffRoles] = useState([])
    const [regions, setRegions] = useState([])
    const [regionCode, setRegionCode] = useState("")
    const [townships, setTownships] = useState([])
    const [confirmPassword, setConfirmPassword] = useState("")
    const [formData, setFormData] = useState({
        staffId: "",
        fullName: "",
        email: "",
        phone: "",
        password: "",
        userRoleId: "",
        address: "",
        subSubAreaCode: "",
        active: false
    })
    const required = ["fullName", "email", "phone", "userRoleId", "password"]
    const editRequired = ["fullName", "email", "phone", "userRoleId"]
    const tempUser: any = localStorage.getItem("USER")
    const user = tempUser && JSON.parse(tempUser)

    useEffect(() => {
        handleGetData()
        handleGetStaffRoles()
        handleGetRegions()
    }, [])

    const handleGetData = async () => {
        setLoading(true)
        getData(staffRoute).then(result => {
            setData(result.data)
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        })
    }

    const handleGetStaffRoles = async () => {
        getData(userRolesRoute).then(result => {
            setStaffRoles(result.data)
        })
    }

    const handleGetRegions = async () => {
        getData(regionRoute).then(result => {
            setRegions(result.data)
        })
    }

    const handleGetTownships = async (regionCode: string) => {
        getData(townshipRoute(regionCode)).then(result => {
            setTownships(result.data)
        })
    }

    const handleChange = (evt: any) => {
        const target = evt.target
        const value = target.type === "checkbox" || target.type === "radio" ? target.checked : target.value
        const name = target.id
        setFormData({ ...formData, [name]: value })
    }

    const handleSelectRegion = (evt: any) => {
        const value = evt.target.value
        setRegionCode(value)
        handleGetTownships(value)
    }

    const clearData = () => {
        setConfirmPassword("")
        setRegionCode("")
        setFormData({
            staffId: "",
            fullName: "",
            email: "",
            phone: "",
            password: "",
            userRoleId: "",
            address: "",
            subSubAreaCode: "",
            active: false
        })
    }

    const showAddStaffForm = () => {
        handleShow();
        clearData()
    }


    //Add Action
    const handleSubmit = (evt?: any) => {
        evt.preventDefault()

        let inputObject: any = {};

        for (let [key, value] of Object.entries(formData)) {
            if (value !== null && value !== "") {
                inputObject[key] = value;
            }
        }

        if (inputObject.userRoleId) {
            inputObject.userRoleId = parseInt(inputObject.userRoleId);
        }

        for (const field of required) {
            if (inputObject.hasOwnProperty(field) === false) {
                return toast.error('Field name mark as * cannot be empty!');
            }
        }

        if (inputObject?.password.length < 6) {
            return toast.error('Password length must be 6!');
        }

        if (inputObject?.password !== confirmPassword) {
            return toast.error('Password does not match!');
        }

        actionData(postMethod, staffInviteRoute, inputObject).then(result => {
            if (result?.data) {
                toast.success('Successful added!');
                handleGetData()
                handleClose()
            } else {
                toast.error('Something wrong!');
            }
        });
    }


    //Edit Action
    const showEditStaffForm = (item: any) => {
        handleShowEditForm();
        clearData()
        setRegionCode(item?.areaCode)
        handleGetTownships(item?.areaCode)
        setFormData({
            ...formData,
            staffId: item?.id,
            fullName: item?.fullName,
            email: item?.email,
            phone: item?.phone,
            userRoleId: item?.rolesId,
            address: item?.address,
            subSubAreaCode: item?.subSubAreaCode,
            active: item?.active
        })
    }

    const responseAction = (result: any) => {
        responseService(result, handleGetData, handleClose)
    }

    const handleEditSubmit = () => {
        dataService(putMethod, formData, editRequired, staffRoute).then(result => {
            responseAction(result)
        })
    }


    return (
        <Layout title={translate("staff")}>
            <section className="flex-between mb-4">
                <section className="cursor" onClick={() => history.goBack()}>
                    <p className="mb-0"><IconLeftArrow /> {translate("configuration")}</p>
                </section>
                <Button className="text-white bg-primary-grad" onClick={showAddStaffForm}><IconAdd /> <span className="ms-2">{translate("createNew")}</span></Button>
            </section>

            <section className="bg-card mb-4">
                {loading ?
                    <TableLoading /> :
                    <Table responsive className="mb-0">
                        <thead>
                            <tr>
                                <th>{translate("no.")}</th>
                                <th>{translate("name")}</th>
                                <th>{translate("email")}</th>
                                <th>{translate("phone")}</th>
                                <th>Role</th>
                                <th>Status</th>
                                {/* <th>   <span className="flex-end">Number of sale</span></th> */}
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item: any, index: any) =>
                                <tr key={index}>
                                    <td><span>{index + 1}</span></td>
                                    <td>{item.fullName}</td>
                                    <td>{item.email}</td>
                                    <td>{item.phone}</td>
                                    <td>{item.roles}</td>
                                    <td><Badge>Active</Badge></td>
                                    {/* <td><span className="flex-end">15</span></td> */}
                                    <td>
                                        <span className="flex-center">
                                            {(user?.userRole === "Owner" && item.roles === "Owner") && <Button className="btn-sm ms-2 bg-primary-grad" onClick={() => showEditStaffForm(item)}><IconEdit /></Button>}
                                            {(user?.userRole === "Owner" && item.roles === "Admin") &&
                                                <>
                                                    <Button className="btn-sm ms-2 bg-primary-grad" onClick={() => showEditStaffForm(item)}><IconEdit /></Button>
                                                    <Button className="btn-danger btn-sm ms-2"
                                                        onClick={() =>
                                                            deleteService(
                                                                item.fullName,
                                                                {
                                                                    "staffId": item.id
                                                                },
                                                                staffRoute,
                                                                responseAction)}><IconDelete />
                                                    </Button>
                                                </>
                                            }
                                            {(item.roles !== "Owner" && item.roles !== "Admin") &&
                                                <>
                                                    <Button className="btn-sm ms-2 bg-primary-grad" onClick={() => showEditStaffForm(item)}><IconEdit /></Button>
                                                    <Button className="btn-danger btn-sm ms-2"
                                                        onClick={() =>
                                                            deleteService(
                                                                item.fullName,
                                                                {
                                                                    "staffId": item.id
                                                                },
                                                                staffRoute,
                                                                responseAction)}><IconDelete />
                                                    </Button>
                                                </>
                                            }
                                        </span>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>}
            </section>

            <Modal show={show} onHide={handleClose} centered size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>{translate("createNew")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Label>{translate("name")} *</Form.Label>
                                <Form.Control type="text" className="mb-4" id="fullName" onChange={handleChange} value={formData?.fullName} />
                            </Col>
                            <Col>
                                <Form.Label>{translate("email")} *</Form.Label>
                                <Form.Control type="text" className="mb-4" id="email" onChange={handleChange} value={formData?.email} />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Label>{translate("phone")} *</Form.Label>
                                <Form.Control type="text" className="mb-4" id="phone" onChange={handleChange} value={formData?.phone} />
                            </Col>
                            <Col>
                                <Form.Label>Role *</Form.Label>
                                <Form.Control as="select" aria-label="Select" className="mb-4" id="userRoleId" value={formData?.userRoleId} onChange={handleChange}>
                                    <option value="">Select</option>
                                    {staffRoles.map((item: any, index: number) =>
                                        <option value={item.id} key={index}>{item.roles}</option>
                                    )}
                                </Form.Control>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Label>{translate("region")}</Form.Label>
                                <Form.Control as="select" aria-label="Select" className="mb-4" id="areaCode" value={regionCode} onChange={handleSelectRegion}>
                                    <option value="">Select</option>
                                    {regions.map((item: any, index: number) =>
                                        <option value={item.areaCode} key={index}>{item.areaNameLocal}</option>
                                    )}
                                </Form.Control>
                            </Col>
                            <Col>
                                <Form.Label>{translate("township")}</Form.Label>
                                <Form.Control as="select" aria-label="Select" className="mb-4" id="subSubAreaCode" value={formData?.subSubAreaCode} onChange={handleChange}>
                                    <option value="">Select</option>
                                    {townships.map((item: any, index: number) =>
                                        <option value={item.subSubAreaCode} key={index}>{item.subSubAreaNameLocal}</option>
                                    )}
                                </Form.Control>
                            </Col>
                        </Row>

                        <Form.Label>{translate("address")}</Form.Label>
                        <Form.Control type="text" className="mb-4" id="address" onChange={handleChange} value={formData?.address} />

                        <Row>
                            <Col>
                                <Form.Label>Password *</Form.Label>
                                <Form.Control type="password" className="mb-4" id="password" onChange={handleChange} value={formData?.password} autoComplete="off" />
                            </Col>
                            <Col>
                                <Form.Label>Confirm password *</Form.Label>
                                <Form.Control type="password" className="mb-4" value={confirmPassword} onChange={(event) => { setConfirmPassword(event.target.value) }} autoComplete="off" />
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditForm} onHide={handleClose} centered size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>{translate("editInfo")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={6}>
                                <Form.Label>{translate("name")} *</Form.Label>
                                <Form.Control type="text" className="mb-4" id="fullName" onChange={handleChange} value={formData?.fullName} />
                            </Col>

                            <Col md={6}>
                                <Form.Label>{translate("email")} *</Form.Label>
                                <Form.Control type="text" className="mb-4" id="email" onChange={handleChange} value={formData?.email} />
                            </Col>

                            <Col md={6}>
                                <Form.Label>{translate("phone")} *</Form.Label>
                                <Form.Control type="text" className="mb-4" id="phone" onChange={handleChange} value={formData?.phone} />
                            </Col>

                            {user?.userRoleId !== formData?.userRoleId &&
                                <Col md={6}>
                                    <Form.Label>Role *</Form.Label>
                                    <Form.Control as="select" aria-label="Select" className="mb-4" id="userRoleId" value={formData?.userRoleId} onChange={handleChange}>
                                        <option value="">Select</option>
                                        {staffRoles.map((item: any, index: number) =>
                                            <option value={item.id} key={index}>{item.roles}</option>
                                        )}
                                    </Form.Control>
                                </Col>
                            }

                            <Col md={6}>
                                <Form.Label>{translate("region")}</Form.Label>
                                <Form.Control as="select" aria-label="Select" className="mb-4" id="areaCode" value={regionCode} onChange={handleSelectRegion}>
                                    <option value="">Select</option>
                                    {regions.map((item: any, index: number) =>
                                        <option value={item.areaCode} key={index}>{item.areaNameLocal}</option>
                                    )}
                                </Form.Control>
                            </Col>

                            <Col md={6}>
                                <Form.Label>{translate("township")}</Form.Label>
                                <Form.Control as="select" aria-label="Select" className="mb-4" id="subSubAreaCode" value={formData?.subSubAreaCode} onChange={handleChange}>
                                    <option value="">Select</option>
                                    {townships.map((item: any, index: number) =>
                                        <option value={item.subSubAreaCode} key={index}>{item.subSubAreaNameLocal}</option>
                                    )}
                                </Form.Control>
                            </Col>


                            <Col md={user?.userRoleId === formData?.userRoleId ? 6 : 12}>
                                <Form.Label>{translate("address")}</Form.Label>
                                <Form.Control type="text" className="mb-4" id="address" onChange={handleChange} value={formData?.address} />
                            </Col>
                        </Row>

                        {/* {user?.userRoleId !== formData?.userRoleId &&
                            <section onChange={handleChange} id="active">
                                <Form.Check
                                    inline
                                    label="Active"
                                    checked={formData?.active}
                                    name="active"
                                    type={"radio"}
                                    id={`active`}
                                />
                                <Form.Check
                                    inline
                                    label="Inactive"
                                    checked={!formData?.active}
                                    name="active"
                                    type={"radio"}
                                    id={`active`}
                                />
                            </section>} */}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleEditSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </Layout >
    )
}

export default Staffs