import { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Layout, CustomInput, CustomModal, SubHeader, TableLayout, TableRowButtons } from '../../components'
import { LangContext } from '../../context/Lang'
import { dataService, deleteService, getDataService, inputChangeService, paymentRoute, responseService } from '../../services'


const Payments = () => {
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { dispatch: { translate } } = useContext(LangContext);
    const [data, setData] = useState([])
    const [formData, setFormData] = useState({
        paymentId: "",
        paymentType: "",
        paymentName: "",
        bankAccount: "",
        phoneNumber: "",
        accountHolder: "",
        nrcNumber: "",
    })
    const required = ["paymentType", "paymentName", "accountHolder"]

    useEffect(() => {
        handleGetData()
    }, [])

    const handleGetData = () => {
        getDataService(paymentRoute, setLoading, setData)
    }

    const handleChange = (evt: any) => {
        inputChangeService(evt, formData, setFormData);
    }

    const showForm = (item?: any) => {
        setFormData({
            paymentId: item.id,
            paymentType: item.paymentType || "",
            paymentName: item.paymentName || "",
            bankAccount: item.bankAccount || "",
            phoneNumber: item.phoneNumber || "",
            accountHolder: item.accountHolder || "",
            nrcNumber: item.nrcNumber || "",
        })
        handleShow();
    }


    const handleSubmit = (method: string) => {
        setLoading(true)
        dataService(method, formData, required, paymentRoute).then(result => {
            handleResponse(result)
        })
    }

    const handleResponse = (result: any) => {
        responseService(result, handleGetData, handleClose, null, null, setLoading)
    }

    const handleDelete = (item: any) => {
        deleteService(item.paymentName, { "paymentId": item.id }, paymentRoute, handleResponse)
    }

    return (
        <Layout title="Payments">
            <SubHeader title={translate("configuration")} showForm={showForm} />

            <TableLayout loading={loading} data={data}>
                <thead>
                    <tr>
                        <th>{translate("no.")}</th>
                        <th>Payment type</th>
                        <th>Payment name</th>
                        <th>Bank account</th>
                        <th>Phone number</th>
                        <th>Account holder</th>
                        <th>NRC number</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {data.map((item: any, index: any) =>
                        <tr key={index}>
                            <td><span className='flex-center'>{index + 1}</span></td>
                            <td>{item.paymentType || "-"}</td>
                            <td>{item.paymentName || "-"}</td>
                            <td>{item.bankAccount || "-"}</td>
                            <td>{item.phoneNumber || "-"}</td>
                            <td>{item.accountHolder || "-"}</td>
                            <td>{item.nrcNumber || "-"}</td>
                            <td><TableRowButtons item={item} showForm={showForm} handleDelete={handleDelete} flex="flex-center" /></td>
                        </tr>
                    )}
                </tbody>
            </TableLayout>

            <CustomModal show={show} handleClose={handleClose} id={formData?.paymentId} handleSubmit={handleSubmit} loading={loading} lg>
                <Row>
                    <Col md={6}>
                        <CustomInput label="Payment type *" id="paymentType" handleChange={handleChange} value={formData?.paymentType} />
                    </Col>
                    <Col md={6}>
                        <CustomInput label="Payment name *" id="paymentName" handleChange={handleChange} value={formData?.paymentName} />
                    </Col>
                    <Col md={6}>
                        <CustomInput label="Bank account" id="bankAccount" handleChange={handleChange} value={formData?.bankAccount} />
                    </Col>
                    <Col md={6}>
                        <CustomInput label="Phone number" id="phoneNumber" handleChange={handleChange} value={formData?.phoneNumber} />
                    </Col>
                    <Col md={6}>
                        <CustomInput label="Account holder *" id="accountHolder" handleChange={handleChange} value={formData?.accountHolder} />
                    </Col>
                    <Col md={6}>
                        <CustomInput label="NRC number" id="nrcNumber" handleChange={handleChange} value={formData?.nrcNumber} />
                    </Col>
                </Row>
            </CustomModal>
        </Layout >
    )
}

export default Payments