import { toast } from 'react-toastify'
import swal from 'sweetalert'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)

//local storage name for login user token
const USER = 'USER'

//Date Variable
const defaultTimeZone = 'Asia/Yangon'

//const dateTimeFormat = 'hh:mm A - DD-MM-YYYY'
const dateTimeFormat = 'YYYY-MM-DD hh:mm A'
const dateFormat = 'DD-MM-YYYY'
const dateFormat2 = 'YYYY-MM-DD'

//Env Variable
const API_URL = process.env.REACT_APP_API_URL
const RES_API_URL = process.env.REACT_APP_RES_API_URL
const limit: any = process.env.REACT_APP_PAGINATION_LIMIT

//Method
const getMethod = 'GET'
const postMethod = 'POST'
const putMethod = 'PUT'
const deleteMethod = 'DELETE'

//Api Route Names
const signInRoute = 'sign-in'
const productsRoute = 'products'
const variantRoute = 'variants'
const variantBulkRoute = 'variants/bulk'
const productColorRoute = 'colors'
const productSizeRoute = 'sizes'
const inventoryRoute = 'inventories'
const draftOrderRoute = 'orders/draft'
const saleOrderRoute = 'sales/order'
const orderFilterRoute = 'orders/filter'
const productFilterRoute = 'products/filter'
const customerRoute = 'customers'
const regionRoute = 'areas?skip=0&orderBy=asc&lang=mm'
const productTypesRoute = 'product/types'
const brandRoute = 'brands'
const warehousesRoute = 'warehouses'
const factoriesRoute = 'factories'
const deliverySerivcesRoute = 'delivery/services'
const deliveryPricesRoute = 'delivery/prices'
const deliveryCountRoute = 'delivery/count'
const deliveryListRoute = 'delivery/list'
const paymentRoute = 'payments'
const staffRoute = 'staff'
const staffInviteRoute = 'invite'
const userRolesRoute = 'user-roles?roleType=staff'
const attributeDetailRoute = 'attr/detail'
const materialRoute = `${attributeDetailRoute}?attribute=material`
const styleRoute = `${attributeDetailRoute}?attribute=style`
const sleeveRoute = `${attributeDetailRoute}?attribute=sleeve`
const widthRoute = `${attributeDetailRoute}?attribute=width`
const colorRoute = `${attributeDetailRoute}?attribute=color`
const measurementRoute = `${attributeDetailRoute}?attribute=measurement_size`
const standardSizeRoute = `${attributeDetailRoute}?attribute=standard_size`

export const getProductsRoute = (
  skip: number,
  sorting?: string,
  productName?: string,
  productCode?: string
) => {
  const productNameQuery = productName ? `productName=${productName}&` : ''
  const productCodeQuery = productCode ? `productCode=${productCode}&` : ''

  return `${productsRoute}?${productNameQuery}${productCodeQuery}skip=${
    skip * limit
  }&take=${limit}&orderBy=${sorting || 'desc'}`
}

export const getProductDetailById = (id: number) => {
  return `product/details?productId=${id}`
}

export const getVariantRoute = (
  id: number,
  skip: number,
  sorting?: string,
  skuFor?: string
) => {
  return `${variantRoute}?productsId=${id}&skip=${
    skip * limit
  }&take=${limit}&orderBy=${sorting || 'desc'}&skuFor=${skuFor || ''}`
}

export const getAllVariantRoute = (
  skip: number,
  sorting?: string,
  sku?: string,
  skuFor?: string
) => {
  return `${variantRoute}?&skip=${skip * limit}&take=${limit}&orderBy=${
    sorting || 'desc'
  }&sku=${sku}&skuFor=${skuFor || ''}`
}

export const getVariantByCode = (productCode: string, skuFor?: string) => {
  return `product/variants?productCode=${productCode}&skuFor=${
    skuFor || 'Retail'
  }`
}

export const getVariantBySKU = (sku: string) => {
  return `sku/variants?sku=${sku}`
}

export const getProductSKURoute = (sku: string) => {
  return `sku?sku=${sku}&skip=0&take=25`
}

export const getProductSuggestionRoute = (productCode: string) => {
  return `product/suggestion?productCode=${productCode}&skip=0&take=25`
}

export const getProductSKUSuggestionRoute = (sku: string, skuFor?: string) => {
  return `sku/suggestion?sku=${sku}&skip=0&take=25&skuFor=${skuFor || 'Retail'}`
}

export const customRoute = (route: string, skip: number) => {
  return `${route}/${skip * limit}/${limit}?sort=desc`
}

export const getSKUSuggForTransferRoute = ({
  productId,
  keyword,
  skuFor,
}: {
  productId: string
  keyword?: string
  skuFor?: string
}) => {
  return `inventory/sku/suggestion?productsId=${productId}&skuFor=${
    skuFor || 'Retail'
  }&sku=${keyword}&skip=0&take=25&orderBy=asc`
}

export const getCustomerSuggestionRoute = (searchString: string) => {
  return `customers/suggestion?searchString=${searchString}`
}

export const getDraftOrderRouteRoute = (
  skip: number,
  sorting: string,
  keyword: string
) => {
  return `${draftOrderRoute}?skip=${skip * limit}&take=${limit}&orderBy=${
    sorting || 'desc'
  }&keyword=${keyword || ''}`
}

export const getSaleOrderByCustomerRouteRoute = (
  id: number,
  type: string,
  skip: number,
  sorting: string,
  keyword: string
) => {
  return `customers/${id}/${type}?skip=${skip * limit}&take=${limit}&orderBy=${
    sorting || 'desc'
  }&keyword=${keyword || ''}`
}

export const getSaleOrderRouteRoute = (
  skip: number,
  sorting: string,
  keyword: string
) => {
  return `${saleOrderRoute}?skip=${skip * limit}&take=${limit}&orderBy=${
    sorting || 'desc'
  }&keyword=${keyword || ''}`
}

export const getBookkeepingRoute = (
  skip: number,
  accountType: string,
  accountName: string,
  startDate: string,
  endDate: string,
  particular?: string
) => {
  const accountTypeString = accountType ? `accountType=${accountType}&` : ''
  const accountNameString = accountName ? `accountName=${accountName}&` : ''
  const startDateString = startDate ? `startDate=${startDate}&` : ''
  const endDateString = endDate ? `endDate=${endDate}&` : ''
  const particularString = particular ? `particular=${particular}&` : ''
  return `accounts/bookkeeping?skip=${
    skip * limit
  }&take=${limit}&orderBy=desc&${accountTypeString}${accountNameString}${startDateString}${endDateString}${particularString}`
}

export const getDeliveryListsRoute = (
  skip: number,
  sorting: string,
  keyword: string
) => {
  return `delivery/list?skip=${skip * limit}&take=${limit}&orderBy=${
    sorting || 'desc'
  }&keyword=${keyword || ''}`
}

export const getSaleReportRoute = (
  skip: number,
  orderType?: string,
  fromDate?: string,
  toDate?: string
) => {
  return `report/sales?skip=${skip * 25}&take=${25}&orderType=${
    orderType || ''
  }&fromDate=${fromDate || ''}&toDate=${toDate || ''}`
}

export const getDailySaleReportRoute = (
  skip: number,
  orderType?: string,
  fromDate?: string,
  toDate?: string
) => {
  return `report/daily/sales?skip=${skip * 100}&take=${100}&orderType=${
    orderType || ''
  }&fromDate=${fromDate || ''}&toDate=${toDate || ''}&orderBy=desc`
}

export const getDraftOrderDetailByIdRoute = (id: number) => {
  return `orders/draft/detail?orderId=${id}`
}

export const getSaleOrderDetailByIdRoute = (id: number) => {
  return `sales/order/detail?orderId=${id}`
}

export const getCustomerDetailByIdRoute = (id: number) => {
  return `customers/detail?customerId=${id}`
}

export const getProductColorRoute = (id: number) => {
  return `${productColorRoute}?productsId=${id}`
}

export const getProductSizeRoute = (id: number) => {
  return `${productSizeRoute}?productsId=${id}`
}

export const getCustomerRoute = (
  skip: number,
  sorting?: string,
  keyword?: string
) => {
  return `${customerRoute}?skip=${
    skip * limit
  }&take=${limit}&userRoleId=5&orderBy=${sorting || 'desc'}&keyword=${
    keyword || ''
  }`
}

export const townshipRoute = (regionCode: string) => {
  return `sub-sub-areas?skip=0&orderBy=asc&lang=mm&areaCode=${regionCode}`
}

export const deliveryFeesRoute = (id: string) => {
  return `delivery/prices?serviceId=${id}`
}

export const productCodeExitRoute = (code: string) => {
  return `product/exist?productCode=${code}`
}

export const getInventoryRoute = (
  id: number,
  skip: number,
  sorting?: string
) => {
  return `${inventoryRoute}?productVariantsId=${id}&skip=${
    skip * limit
  }&take=${limit}&orderBy=${sorting || 'desc'}`
}

export {
  getMethod,
  postMethod,
  putMethod,
  deleteMethod,
  dateFormat,
  dateFormat2,
  signInRoute,
  productsRoute,
  productSizeRoute,
  variantRoute,
  variantBulkRoute,
  inventoryRoute,
  productColorRoute,
  draftOrderRoute,
  saleOrderRoute,
  orderFilterRoute,
  productFilterRoute,
  customerRoute,
  regionRoute,
  productTypesRoute,
  brandRoute,
  attributeDetailRoute,
  materialRoute,
  styleRoute,
  sleeveRoute,
  widthRoute,
  colorRoute,
  measurementRoute,
  standardSizeRoute,
  warehousesRoute,
  factoriesRoute,
  deliverySerivcesRoute,
  deliveryPricesRoute,
  deliveryCountRoute,
  deliveryListRoute,
  paymentRoute,
  staffRoute,
  staffInviteRoute,
  userRolesRoute,
}

export const getDataService = async (
  route: string,
  setLoading: any,
  setData: any,
  setPage?: any,
  skip?: number,
  sorting?: string,
  noResultData?: boolean
) => {
  setLoading(true)
  getData(route).then((result) => {
    setData(noResultData ? result : result.data)
    setPage &&
      setPage({
        current: skip,
        total: result.count,
        sorting: sorting || 'desc',
      })
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  })
}

export const getData = async (route: string) => {
  try {
    const response = await fetch(`${API_URL}/${route}`, {
      method: 'GET',
      headers: { 'Content-type': 'application/json' },
      mode: 'cors',
      cache: 'default',
    })
    const json = await response.json()
    return json
  } catch (err) {
    return toast.error('Something Wrong!')
  }
}

export const actionData = async (method: string, route: string, data: any) => {
  try {
    const response = await fetch(`${API_URL}/${route}`, {
      method: method,
      headers: { 'Content-type': 'application/json' },
      mode: 'cors',
      cache: 'default',
      body: JSON.stringify(data),
    })
    const json = await response.json()
    return json
  } catch (error) {
    return toast.error('Something Wrong!')
  }
}

export const dataService = async (
  method: any,
  formData: any,
  required: any,
  route: string,
  sendNull?: boolean
) => {
  let data: any = {}

  for (let [key, value] of Object.entries(formData)) {
    if (sendNull) {
      data[key] = value
    } else {
      if (value !== null && value !== '') {
        data[key] = value
      }
    }
  }

  for (const field of required) {
    if (data.hasOwnProperty(field) === false) {
      return toast.error('Please fill data!')
    }
  }
  return actionData(method, route, data)
}

export const deleteService = async (
  name: string,
  data: any,
  route: string,
  responseAction: any
) => {
  swal({
    title: `Are you sure to delete?`,
    text: `Once deleted, you will not be able to recover ${name}!`,
    icon: 'warning',
    buttons: [true, true],
    dangerMode: true,
  }).then(async (willDelete) => {
    if (willDelete) {
      responseAction(await actionData(deleteMethod, route, data))
    }
  })
}

export const responseService = (
  result: any,
  handleGetData: any,
  handleClose?: any,
  skip?: number | null,
  sorting?: number | null,
  setLoading?: any
) => {
  if (result?.success) {
    toast.success(result?.message)
    handleGetData(skip, sorting)
    handleClose && handleClose()
  } else {
    toast.error(result?.message)
    setLoading && setLoading(false)
  }
}

export const inputChangeService = (
  evt: any,
  formData: any,
  setFormData: any,
  wantString?: boolean
) => {
  const target = evt.target
  const name = target.id
  let value

  if (target.type === 'checkbox') {
    value = target.checked
  } else if (target.type === 'number' || target.type === 'select-one') {
    if (target.value === '') {
      value = ''
    } else {
      if (wantString) {
        value = target.value
      } else {
        value = parseFloat(target.value)
      }
    }
  } else {
    value = target.value
  }

  setFormData({
    ...formData,
    [name]: value,
  })
}

export const getNumber = (
  index: number,
  skip: number,
  paginationLimit?: number
) => {
  let pglimit = paginationLimit || limit
  return index + 1 + skip * pglimit
}

export const handleDateFormat = (date: any, format?: string) => {
  return dayjs(date)
    .tz(defaultTimeZone)
    .format(format || dateFormat)
}

export const handleDateTimeFormat = (date: any) => {
  return dayjs(date).tz(defaultTimeZone).format(dateTimeFormat)
}

export function numberFormat(x: any) {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '-'
}

export const getDiscountAmountByPercentage = (
  netPrice: string,
  formData: any
) => {
  let amount: any = 0
  if (formData.discountValue) {
    let discountPercent = parseFloat(formData.discountValue)
    if (netPrice) {
      amount = (parseInt(netPrice) / 100) * discountPercent
    }
    amount = parseInt(amount)
  } else {
    amount = 0
  }
  return amount
}

export const totalPaidAmount = (data: any) => {
  let amount: any = 0
  if (data && data.length > 0) {
    data.forEach((item: any) => {
      amount += parseInt(item.amount || 0)
    })
  }
  return amount
}

export const totalAmount = (netPrice: string, formData: any) => {
  let amount: any = parseInt(netPrice)
  if (netPrice && formData.discountValue) {
    amount =
      amount -
      (formData.discountType === 'by_percentage'
        ? getDiscountAmountByPercentage(netPrice, formData)
        : parseInt(formData.discountValue))
  }
  return amount
}

export const totalBalance = (data: any) => {
  return totalAmount(data?.netPrice, data) - totalPaidAmount(data?.paymentTrx)
}

export const sortingService = (
  page: any,
  setPage: any,
  handleGetData: any,
  handleGetDataByFilter?: any,
  filterActionData?: any
) => {
  if (page.sorting === 'asc') {
    if (handleGetDataByFilter) {
      filterActionData['orderBy'] = 'desc'
      handleGetDataByFilter(page.current, filterActionData)
    } else {
      handleGetData(page.current, 'desc')
      setPage({ ...page, sorting: 'desc' })
    }
  } else {
    if (handleGetDataByFilter) {
      filterActionData['orderBy'] = 'asc'
      handleGetDataByFilter(page.current, filterActionData)
    } else {
      handleGetData(page.current, 'asc')
      setPage({ ...page, sorting: 'asc' })
    }
  }
}

export const saveLocalForUser = (user: any) => {
  localStorage.setItem(USER, user)
}

export const removeLocalForUser = () => {
  localStorage.removeItem(USER)
}

export const isLogin = () => {
  if (localStorage.getItem(USER)) {
    return true
  }
  return false
}

//For Number Input
export const disableScroll = (e: any) => {
  e.currentTarget.blur()
}

export const handleKeyDown = (event: any) => {
  if (event.key.toLowerCase() === 'enter') {
    const form = event.target.form
    const index = [...form].indexOf(event.target)
    form.elements[index + 1].focus()
    event.preventDefault()
  }
}

export const setUpload = async (data: any) => {
  const response = await fetch(`${RES_API_URL}/upload/products`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: data,
  })

  return response.json()
}

export const deleteUpload = async (data: any) => {
  const response = await fetch(`${RES_API_URL}/media/products`, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
  })

  return response.json()
}

export const uploadAvatar = async (data: any) => {
  const response = await fetch(`${RES_API_URL}/upload/avatar`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: data,
  })

  return response.json()
}

export const deleteAvatar = async (data: any) => {
  const response = await fetch(`${RES_API_URL}/media/avatar`, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
  })

  return response.json()
}

export const handleChangeQuantity = (
  quantity: number,
  sku: string,
  stock: number,
  items: any,
  setItem: any
) => {
  if (quantity <= stock) {
    let temp: any = []
    items?.forEach((item: any) => temp.push(item))
    temp.forEach((data: any, index: number) => {
      if (data.productSKU === sku) {
        temp[index].quantity = parseInt(quantity.toString())
      }
    })
    setItem(temp)
  } else {
    toast.error('Order quantity is greater than existing stock.')
  }
}

export const getSubtotolInOrderItem = (items: any) => {
  let total = 0
  items?.forEach((item: any) => {
    total += item.quantity * item.unitPrice
  })
  return numberFormat(total)
}

export const getAllTotalAmount = (
  orderItems: any,
  deliveryFee?: number,
  bankCommission?: any,
  discountType?: string,
  discountValue?: number
) => {
  let total: any = 0

  //For order items
  orderItems?.forEach((item: any) => {
    total += item.quantity * item.unitPrice
  })
  // orderItems?.reduce((p: any, c: any) => {
  //   total += p.quantity * p.unitPrice + c.quantity * c.unitPrice
  //   return null
  // })

  //For delivery fee
  total += deliveryFee || 0

  //For bank commission
  total += bankCommission ? (total / 100) * bankCommission : 0

  //For discount
  total = discountValue
    ? discountType === 'By amount'
      ? total - discountValue
      : total - (total / 100) * discountValue
    : total
  total = parseInt(total)

  return total
}

export const getAllQunatityAmount = (orderItems: any) => {
  let quantity = 0

  //For order items
  orderItems?.forEach((item: any) => {
    quantity += item.quantity
  })

  return quantity
}

export const getOrdersTotal = (items: any) => {
  let total = 0
  items.forEach((item: any) => {
    total = total + item.totalAmount
  })
  return total
}

export const handleClose = (setShow: any) => setShow(false)
export const handleShow = (setShow: any) => setShow(true)

export const groupByVariantService = (orderItems: any) => {
  let finalArray: any = []
  let tempArray: any = []

  orderItems.forEach((product: any) => {
    const { productCode, productName, productSize, unitPrice } = product

    let exist = false

    if (tempArray.length > 0) {
      tempArray.forEach((item: any) => {
        if (
          item.productCode === productCode &&
          item.productSize === productSize &&
          item.unitPrice === unitPrice
        ) {
          exist = true
        }
      })
    }

    if (!exist) {
      let tempObject = {
        productCode: productCode,
        productName: productName,
        productSize: productSize,
        unitPrice: unitPrice,
      }
      tempArray.push(tempObject)
    }
  })

  tempArray.length > 0 &&
    tempArray?.forEach((item: any) => {
      let tempObject = {
        productCode: item.productCode,
        productName: item.productName,
        productSize: item.productSize,
        unitPrice: item.unitPrice,
        colors: orderItems.filter(
          (product: any) =>
            item.productCode === product.productCode &&
            item.productSize === product.productSize &&
            item.unitPrice === product.unitPrice
        ),
      }
      finalArray.push(tempObject)
    })

  // if (Array.isArray(groupByCategory)) {

  // } else {
  //   let tempObject = {
  //     productCode: groupByCategory.productCode,
  //     productName: groupByCategory.productName,
  //     productSize: groupByCategory.productSize,
  //     unitPrice: groupByCategory.unitPrice,
  //     colors: orderItems.filter(
  //       (product: any) =>
  //         groupByCategory.productCode === product.productCode &&
  //         groupByCategory.productSize === product.productSize &&
  //         groupByCategory.unitPrice === product.unitPrice
  //     ),
  //   }
  //   finalArray.push(tempObject)
  // }

  return finalArray
}

export const groupByIncomeReport = (items: any) => {
  let finalArray: any = []
  let tempArray: any = []

  items.forEach((item: any) => {
    const { paymentName, paymentType, paymentStatus, orderStatus } = item

    let exist = false

    if (tempArray.length > 0) {
      tempArray.forEach((item: any) => {
        if (
          item.paymentName === paymentName &&
          item.paymentType === paymentType &&
          item.paymentStatus === paymentStatus &&
          item.orderStatus === orderStatus
        ) {
          exist = true
        }
      })
    }

    if (!exist) {
      let tempObject = {
        paymentName: paymentName,
        paymentType: paymentType,
        paymentStatus: paymentStatus,
        orderStatus: orderStatus,
      }
      tempArray.push(tempObject)
    }
  })

  tempArray.length > 0 &&
    tempArray?.forEach((item: any) => {
      let numOfSales = 0
      let amount = 0
      let deliveryFee = 0
      let bankCommission = 0
      let discountValue = 0

      items
        .filter(
          (subItem: any) =>
            item.paymentName === subItem.paymentName &&
            item.paymentType === subItem.paymentType &&
            item.paymentStatus === subItem.paymentStatus &&
            item.orderStatus === subItem.orderStatus
        )
        .forEach((element: any) => {
          numOfSales += element.numOfSales
          amount += element.amount
          deliveryFee += element.deliveryFee
          bankCommission += element.bankCommission
          discountValue += element.discountValue
        })

      let tempObject = {
        paymentName: item.paymentName,
        paymentType: item.paymentType,
        paymentStatus: item.paymentStatus,
        orderStatus: item.orderStatus,
        numOfSales: numOfSales,
        amount: amount,
        deliveryFee: deliveryFee,
        bankCommission: bankCommission,
        discountValue: discountValue,
      }
      finalArray.push(tempObject)
    })
  return finalArray
}

export function diffDays(d1: any, d2: any) {
  var ndays
  var tv1 = d1.valueOf() // msec since 1970
  var tv2 = d2.valueOf()

  ndays = (tv2 - tv1) / 1000 / 86400
  ndays = Math.round(ndays - 0.5)
  return ndays
}
