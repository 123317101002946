import { useContext, useEffect, useState } from 'react'
import { Layout, CustomInput, CustomModal, SubHeader, TableLayout, TableRowButtons } from '../../components'
import { LangContext } from '../../context/Lang'
import { brandRoute, dataService, deleteService, getDataService, handleClose, handleShow, inputChangeService, responseService } from '../../services'


const Brands = () => {
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false);
    const { dispatch: { translate } } = useContext(LangContext);
    const [data, setData] = useState([])
    const [formData, setFormData] = useState({
        brandId: "",
        brandName: "",
    })
    const required = ["brandName"]

    useEffect(() => {
        handleGetData()
    }, [])

    const handleGetData = () => {
        getDataService(brandRoute, setLoading, setData)
    }

    const handleChange = (evt: any) => {
        inputChangeService(evt, formData, setFormData);
    }

    const showForm = (item?: any) => {
        setFormData({
            brandId: item.id,
            brandName: item.brandName || ""
        })
        handleShow(setShow);
    }


    const handleSubmit = (method: string) => {
        setLoading(true)
        dataService(method, formData, required, brandRoute).then(result => {
            handleResponse(result)
        })
    }

    const handleResponse = (result: any) => {
        responseService(result, handleGetData, handleClose(setShow), null, null, setLoading)
    }

    const handleDelete = (item: any) => {
        deleteService(item.brandName, { "brandId": item.id }, brandRoute, handleResponse)
    }

    return (
        <Layout title={translate("brands")}>
            <SubHeader title={translate("configuration")} showForm={showForm} />

            <TableLayout loading={loading} data={data}>
                <thead>
                    <tr>
                        <th>{translate("no.")}</th>
                        <th>{translate("brand")}</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {data.map((item: any, index: any) =>
                        <tr key={index}>
                            <td><span className='flex-center'>{index + 1}</span></td>
                            <td>{item.brandName}</td>
                            <td><TableRowButtons item={item} showForm={showForm} handleDelete={handleDelete} flex="flex-center" /></td>
                        </tr>
                    )}
                </tbody>
            </TableLayout>

            <CustomModal show={show} handleClose={() => handleClose(setShow)} id={formData?.brandId} handleSubmit={handleSubmit} loading={loading}>
                <CustomInput label={translate("brand")} id="brandName" handleChange={handleChange} value={formData?.brandName} />
            </CustomModal>
        </Layout >
    )
}

export default Brands