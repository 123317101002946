import { useState, createContext } from 'react';

const contextDefaultValues: any = {
    orderItems: [],
    setOrderItems: () => { },
}

const OrderContext = createContext<any>(contextDefaultValues);

function OrderProvider(props: any) {

    const [orderItems, setOrderItems] = useState([])

    return (
        <OrderContext.Provider value={[orderItems, setOrderItems]}>
            {props.children}
        </OrderContext.Provider>
    );
}

export { OrderContext, OrderProvider };