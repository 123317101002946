import { useContext, useEffect, useState } from 'react';
import { Accordion, Button, Col, Form, FormCheck, InputGroup, Modal, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { IconAdd, IconDelete, IconLeftArrow, IconScanQR } from '../../../components/Icons';
import { LangContext } from '../../../context/Lang';
import Select from 'react-select';
import { disableScroll, openUrl } from '../../../utils';
import AddCustomerModal from '../../customers/components/AddCustomerModal';
import AddOrderItemModal from './cpn/AddOrderItemModal';
import { actionData, deliveryFeesRoute, deliverySerivcesRoute, draftOrderRoute, getAllTotalAmount, getCustomerDetailByIdRoute, getCustomerSuggestionRoute, getData, getDraftOrderDetailByIdRoute, getVariantBySKU, inputChangeService, numberFormat, paymentRoute, putMethod } from '../../../services';
import { OrderContext } from '../../../context';
import { Layout, Checkbox, CustomerLoading, EditOrderDetailLoading, NoData, } from '../../../components';
import { toast } from 'react-toastify';
import swal from 'sweetalert'
import AddFreeOrderItemModal from './cpn/AddFreeOrderItemModal';

const Edit = () => {
    const history = useHistory()
    const { dispatch: { translate } } = useContext(LangContext);
    const [orderItems, setOrderItems] = useContext(OrderContext);
    const location: any = useLocation();
    const order = location.state.order
    const routeFrom = location.state.routeFrom
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showCustomerModal, setShowCustomerModal] = useState(false);
    const handleCloseCustomerModal = () => setShowCustomerModal(false);
    const handleShowCustomerModal = () => setShowCustomerModal(true);
    const [showOrderItemModal, setShowOrderItemModal] = useState(false);
    const handleCloseOrderItemModal = () => setShowOrderItemModal(false);
    const handleShowOrderItemModal = () => setShowOrderItemModal(true);
    const [showFreeOrderItemModal, setFreeShowOrderItemModal] = useState(false);
    const handleCloseFreeOrderItemModal = () => setFreeShowOrderItemModal(false);
    const handleShowFreeOrderItemModal = () => setFreeShowOrderItemModal(true);
    // const [isDeliveryFee, setIsDeliveryFee] = useState(false);
    const [isDiscount, setIsDiscount] = useState(false);
    const [isBankPercentage, setIsBankPercentage] = useState(false);
    const [deliveryServices, setDeliveryServices] = useState([])
    const [deliveryFees, setDeliveryFees] = useState([])
    const [deliveryFee, setDeliveryFee] = useState(0)
    const [isCustomDeliyFee, setCustomDeliyFee] = useState(false)
    const [customers, setCustomers] = useState([])
    const [payments, setPayments] = useState([])
    const [customer, setCustomer] = useState<any>()
    const [loading, setLoading] = useState(false)
    const [customerLoading, setCustomerLoading] = useState(false)
    const [searchKeyword, addSearchKeyword] = useState("");
    const orderFrom = ["Facebook Live", "Facebook Messager", "Phone Call", "Viber", "Others"]
    const paymentStatus = ["UNPAID", "PARTIAL PAID", "PAID"]
    const deliveryStatus = ["Pending", "Ready to Ship", "Shipped",]

    const [variant, setVariant] = useState({
        productName: "",
        sku: "",
        size: "",
        color: "",
        stock: 0,
        quantity: 0,
        unitPrice: 0,
        orderType: "",
        retail: 0,
        wholesale: 0,
        originQuantity: 0,
    })

    const [formData, setFormData] = useState(
        {
            paymentHistoryId: "",
            draftOrdersId: "",
            bankCommission: "",
            discountType: "By amount",
            shippingMethods: "Delivery",
            discountValue: "",
            orderFromId: 0,
            orderNotes: "",
            paymentStatusId: 0,
            paymentTypeId: "",
            deliveryServiceId: "",
            deliveryPriceId: "",
            deliveryFee: "",
            deliveryStatusId: 0,
            deliveryNotes: "",
            totalAmountPaid: null,
        }
    )



    useEffect(() => {
        handleGetDeliveryServices()
        handleGetPayments()
        handleGetData()
        // eslint-disable-next-line
    }, [])


    const handleGetData = async () => {
        setLoading(true)
        getData(getDraftOrderDetailByIdRoute(order.id)).then((result) => {
            let tempOrder = result.data

            let temp: any = [];
            if (result.data.orderItems && result.data.orderItems.length > 0) {
                result.data.orderItems?.forEach((item: any) => temp.push(item));
                setOrderItems(temp);
            }

            getData(getCustomerDetailByIdRoute(tempOrder.customerId)).then(result => {
                setCustomer(result.data)
            })

            if (tempOrder.bankCommission) {
                setIsBankPercentage(true)
            }

            if (tempOrder.discountValue) {
                setIsDiscount(true)
            }

            if (tempOrder.deliveryServiceId) {
                // setIsDeliveryFee(true)

                getData(deliveryFeesRoute(tempOrder.deliveryServiceId)).then(result => {
                    setDeliveryFees(result.data.servicePrices)
                })
            }

            if (tempOrder.deliveryFee) {
                setDeliveryFee(parseInt(tempOrder.deliveryFee))
            }

            setFormData({
                paymentHistoryId: tempOrder.paymentHistory[0]?.id || "",
                draftOrdersId: tempOrder.id || "",
                bankCommission: tempOrder.bankCommission || "",
                discountType: tempOrder.discountType || "By amount",
                shippingMethods: tempOrder.shippingMethods,
                discountValue: tempOrder.discountValue || "",
                orderFromId: tempOrder.orderFrom ? orderFrom.indexOf(tempOrder.orderFrom) : 0,
                orderNotes: tempOrder.orderNotes || "",
                paymentStatusId: tempOrder.paymentHistory[0]?.paymentStatus ? paymentStatus.indexOf(tempOrder.paymentHistory[0]?.paymentStatus) : 0,
                paymentTypeId: tempOrder.paymentHistory[0]?.paymentTypeId || "",
                deliveryServiceId: tempOrder.deliveryServiceId || "",
                deliveryPriceId: tempOrder.deliveryPriceId || "",
                deliveryFee: tempOrder.deliveryFee || "",
                deliveryStatusId: tempOrder.deliveryStatus ? deliveryStatus.indexOf(tempOrder.deliveryStatus) : 0,
                deliveryNotes: tempOrder.deliveryNotes || "",
                totalAmountPaid: tempOrder.paymentHistory[0]?.totalAmountPaid || "",
            })
            setTimeout(() => {
                setLoading(false)
            }, 500)
        })
    }

    const handleGetDeliveryServices = async () => {
        getData(deliverySerivcesRoute).then(result => {
            setDeliveryServices(result.data)
        })
    }

    const handleGetPayments = async () => {
        getData(paymentRoute).then(result => {
            setPayments(result.data)
        })
    }

    const handleGetDeliveryFees = async (serviceId: string) => {
        getData(deliveryFeesRoute(serviceId)).then(result => {
            setDeliveryFees(result.data.servicePrices)
        })
    }

    const handleSelectDelivery = (evt: any) => {
        const value = evt.target.value
        setFormData({ ...formData, deliveryServiceId: value })
        handleGetDeliveryFees(value)
    }

    const handleSelectLocation = (evt: any) => {
        const value = evt.target.value
        let deliFee = "";

        deliveryFees && deliveryFees.length > 0 && deliveryFees.forEach((item: any) => {
            if (item.id === parseInt(value)) {
                deliFee = item.deliveryPrice
            }
        })

        setFormData({ ...formData, deliveryPriceId: value, deliveryFee: deliFee })
        setDeliveryFee(deliFee ? parseInt(deliFee) : 0)
    }

    const getSubtotalAmount = () => {
        let total = 0;
        orderItems?.forEach((item: any) => {
            total += item.quantity * item.unitPrice
        });
        return total;
    }

    const getDiscountAmountByPercentage = () => {
        let amount: any = 0;
        if (formData.discountValue) {
            let discountPercent = parseInt(formData.discountValue)
            amount = ((getSubtotalAmount() + (deliveryFee || 0) + getBankCommissionAmount()) / 100) * discountPercent
            amount = parseInt(amount)
        } else {
            amount = 0
        }
        return amount;
    }


    const getBankCommissionAmount = () => {
        let total = getSubtotalAmount();
        if (isBankPercentage && formData.bankCommission) {
            let commision = parseFloat(formData.bankCommission)
            total = ((getSubtotalAmount() + deliveryFee || 0) / 100) * commision
        } else {
            total = 0
        }
        return total;
    }

    const removeItem = (sku: string) => {
        let temp: any[] = [];
        orderItems?.forEach((item: any) => temp.push(item));

        if (temp.length === 1) {
            toast.error("One item in order at least!")
        } else {
            temp.forEach((data: any, index: number) => {
                if (data.productSKU === sku) {
                    temp.splice(index, 1);
                }
            });
            setOrderItems(temp)
        }

    }

    const showModelToEdit = (item: any) => {
        setVariant({
            productName: item.productName,
            sku: item.productSKU,
            size: item.productSize,
            color: item.productColor,
            stock: item.stockCount,
            quantity: item.quantity,
            unitPrice: item.unitPrice,
            wholesale: item.wholesalePrice,
            retail: item.retailPrice,
            orderType: item.orderType,
            originQuantity: item.quantity
        })
        handleShow()
    }

    const handleChangeQuantity = (quantity: number) => {
        if (quantity <= variant.stock + variant.originQuantity) {
            setVariant({ ...variant, quantity: quantity })
        } else {
            toast.error("Order quantity is greater than existing stock.")
        }
    }

    const handleOnChangeQuantity = () => {
        if (variant.quantity && variant.quantity > 0) {

            let temp: any = [];
            orderItems?.forEach((item: any) => temp.push(item));
            temp.forEach((data: any, index: number) => {
                if (data.productSKU === variant.sku) {
                    temp[index].quantity = parseInt(variant.quantity.toString())
                    temp[index].orderType = variant.orderType
                    temp[index].unitPrice = variant.unitPrice
                }
            });
            setOrderItems(temp)
            handleClose()
        } else {
            toast.error("Need order quantity at least one!.")
        }

    }

    const customerInputChange = (keyword: string) => {
        getData(getCustomerSuggestionRoute(keyword)).then(result => {
            let temp: any = []

            result.data.forEach((item: any) => {
                temp.push({
                    value: item.id,
                    label: `${item.fullName} (${item.phone})`,
                })
            })
            setCustomers(temp)
        })
    }

    const getCustomerDetail = (data: any) => {
        if (data) {
            setCustomerLoading(true)
            getData(getCustomerDetailByIdRoute(data.value)).then(result => {
                setCustomer(result.data)
                setTimeout(() => {
                    setCustomerLoading(false)
                }, 500)
            })
        } else {
            setCustomer(null)
        }
    }

    const customerResponseAction = (result: any) => {
        if (result.success) {
            toast.success(result.message)
            handleCloseCustomerModal()
        } else {
            toast.error(result.message)
        }
    }

    const handleChange = (evt: any) => {
        inputChangeService(evt, formData, setFormData);
    }

    // const changeRetailPrice = (retail: boolean) => {
    //     let temp: any = tempOrderItems;
    //     temp.forEach((data: any, index: number) => {
    //         temp[index].unitPrice = retail ? temp[index].retail : temp[index].wholesale
    //         temp[index].orderType = retail ? "Retail" : "Wholesale"
    //     });
    //     setTempOrderItems(temp)
    //     handleAdd(temp.filter((item: any) => item.isCheck === true))
    // }

    const handleEnterAction = (event: any) => {
        if (event.keyCode === 13) {

            setTimeout(() => {
                addSearchKeyword("")
            }, 500)


            getData(getVariantBySKU(event.target.value)).then(result => {
                let temp: any = []
                let tempVariant = result.data
                let isExist = false

                if (result.data) {
                    if (tempVariant.productVariant.stockCount > 0) {

                        orderItems?.forEach((item: any) => temp.push(item));


                        temp.forEach((item: any, index: number) => {
                            if (item.sku === tempVariant.productVariant.sku) {
                                isExist = true
                                temp[index].quantity = temp[index].quantity + 1
                            }
                        })

                        if (!isExist) {
                            temp.push({
                                productVariantsId: tempVariant.productVariant.id,
                                productName: tempVariant.productName,
                                sku: tempVariant.productVariant.sku,
                                size: tempVariant.productVariant.productSizes,
                                color: tempVariant.productVariant.productColors,
                                stock: tempVariant.productVariant.stockCount,
                                quantity: 1,
                                unitPrice: tempVariant.productVariant.retailPrice,
                                retail: tempVariant.productVariant.retailPrice,
                                wholesale: tempVariant.productVariant.wholesalePrice,
                                orderType: "Retail"
                            })
                        }

                        setOrderItems(temp)
                    } else {
                        toast.error("Empty stock!")
                    }
                } else {
                    toast.error("Not found variant!")
                }
            })

            //   if (!event.target.value) {
            //     showDetail(false)
            //   }
            //   getData(0, event.target.value, "sku", true)
        }
    }




    const finalSaveDraftOrder = () => {
        if (orderItems.length > 0) {
            if (customer) {

                const tempUser: any = localStorage.getItem("USER")
                const employee = tempUser && JSON.parse(tempUser)

                let _formData: any = {
                    paymentHistoryId: formData.paymentHistoryId,
                    draftOrdersId: formData.draftOrdersId,
                    customerId: customer.id,
                    employeeId: employee.id,
                    orderItems: orderItems,
                    shippingMethods: formData.shippingMethods,
                    totalAmountPaid: formData.totalAmountPaid,
                }

                _formData["bankCommission"] = formData.bankCommission || null;


                if (isDiscount) {
                    _formData["discountType"] = formData.discountValue ? formData.discountType : null;
                    _formData["discountValue"] = formData.discountValue || null;
                }

                _formData["paymentStatus"] = paymentStatus[formData.paymentStatusId];
                _formData["orderFrom"] = orderFrom[formData.orderFromId];

                _formData["paymentTypeId"] = formData.paymentTypeId || null;

                _formData["orderNotes"] = formData.orderNotes || "";

                if (formData.shippingMethods === "Delivery") {
                    if (formData.deliveryServiceId) {
                        _formData["deliveryServiceId"] = parseInt(formData.deliveryServiceId);
                    }

                    if (formData.deliveryPriceId) {
                        _formData["deliveryPriceId"] = parseInt(formData.deliveryPriceId);
                    }

                    _formData["deliveryFee"] = deliveryFee ? deliveryFee : null;

                    _formData["deliveryStatus"] = deliveryStatus[formData.deliveryStatusId];
                }

                _formData["deliveryNotes"] = formData.deliveryNotes || "";


                if (formData.paymentStatusId === 0) {
                    handleAction(_formData)
                } else {
                    if (formData.paymentTypeId) {
                        handleAction(_formData)
                    } else {
                        toast.error("Payment type is required!")
                    }
                }
            } else {
                toast.error("Choose customer!")
            }
        } else {
            toast.error("Order item must be at least one.")
        }
    }

    const handleAction = (submitData: any) => {
        actionData(putMethod, draftOrderRoute, submitData).then((result) => {
            if (result.success) {
                swal(result.message, {
                    icon: 'success',
                    buttons: [false],
                    timer: 1500,
                })
                setOrderItems([])
                if (routeFrom === "detail") {
                    history.push({
                        pathname: '/order-detail',
                        state: { id: formData.draftOrdersId }
                    })
                } else {
                    history.push("/orders")
                }
            } else {
                toast.error(result.message)
            }
        })
    }




    return (
        <Layout title={`Edit draft order (${translate('retail')})`}>
            <div className='flex-between mb-4'>
                <section className=" cursor" onClick={() => history.goBack()}>
                    <p className="mb-0"><IconLeftArrow /> {routeFrom === "detail" ? "Draft order detail" : translate('orders')}</p>
                </section>

                <Button className="text-white px-4" onClick={finalSaveDraftOrder}>Save</Button>
            </div>

            {loading ? <EditOrderDetailLoading /> :
                <>
                    <section className="shadow-sm p-4 rounded bg-card mb-4">
                        <section className="flex-between mb-4">
                            <AddOrderItemModal show={showOrderItemModal} handleClose={handleCloseOrderItemModal} />
                            <AddFreeOrderItemModal show={showFreeOrderItemModal} handleClose={handleCloseFreeOrderItemModal} />
                            {/* <Col md={6}> */}
                            <Col md={4}>
                                <InputGroup className="search-input">
                                    <InputGroup.Text className="bg-primary-grad text-white">
                                        <IconScanQR />
                                    </InputGroup.Text>
                                    <Form.Control
                                        placeholder="Scan qr code ..."
                                        value={searchKeyword}
                                        onChange={(e) => addSearchKeyword(e.target.value)}
                                        onKeyDown={(e) => handleEnterAction(e)}
                                        autoFocus
                                    />
                                </InputGroup>
                            </Col>

                            <div>
                                <Button className="text-white bg-primary-grad me-2" onClick={handleShowOrderItemModal}><IconAdd />  <span className="ms-2">Order items</span></Button>
                                <Button className="text-white bg-primary-grad" onClick={handleShowFreeOrderItemModal}><IconAdd />  <span className="ms-2">Free item</span></Button>
                            </div>
                            {/* </Col> */}
                        </section>

                        {orderItems.length > 0 ?
                            <>
                                <h6>Order items</h6>
                                <Table responsive className="mb-0" striped>
                                    <thead>
                                        <tr>
                                            {/* <th className='ps-4'>
                                                <OverlayTrigger
                                                    overlay={
                                                        <Tooltip>
                                                            Remove all item
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="text-danger cursor" onClick={() => setOrderItems([])}><IconDelete /></span>
                                                </OverlayTrigger>
                                            </th> */}
                                            <th />
                                            <th>{translate('no.')}</th>
                                            <th>{translate('item')}</th>
                                            <th>SKU</th>
                                            <th>{translate('size')}</th>
                                            <th>{translate('color')}</th>
                                            <th><span className="d-flex justify-content-end">{translate('quantity')}</span></th>
                                            <th><span className="d-flex justify-content-end">{translate('price')}</span></th>
                                            <th><span className="d-flex justify-content-end">{translate('amount')}</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderItems.map((item: any, index: number) =>
                                        (<tr key={index} className="cursor">
                                            <td className='ps-4'>
                                                <OverlayTrigger
                                                    overlay={
                                                        <Tooltip>
                                                            Remove item
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="text-danger cursor" onClick={() => removeItem(item.productSKU)}><IconDelete /></span>
                                                </OverlayTrigger>
                                            </td>
                                            <td onClick={() => showModelToEdit(item)}>{index + 1}</td>
                                            <td onClick={() => showModelToEdit(item)}>{item.productName}</td>
                                            <td onClick={() => showModelToEdit(item)}>{item.productSKU}</td>
                                            <td onClick={() => showModelToEdit(item)}>{item.productSize}</td>
                                            <td onClick={() => showModelToEdit(item)}>{item.productColor}</td>
                                            <td onClick={() => showModelToEdit(item)}><span className="d-flex justify-content-end">{numberFormat(item.quantity)}</span></td>
                                            <td onClick={() => showModelToEdit(item)}><span className="d-flex justify-content-end">{item.unitPrice.toString() !== "0" ? numberFormat(item.unitPrice) : "Free"}</span></td>
                                            <td onClick={() => showModelToEdit(item)}><span className="d-flex justify-content-end">{item.unitPrice.toString() !== "0" ? numberFormat(item.quantity * item.unitPrice) : 0}</span></td>
                                        </tr>))}
                                    </tbody>
                                </Table> <section className="mt-4 d-flex justify-content-end">
                                    <Col md={3}>
                                        <section className="mb-3 pb-3 border-bottom">
                                            <section className="flex-between mb-2">
                                                <small className="text-muted d-block">{translate('subTotal')}</small>
                                                <small className="d-block text-muted me-2">{numberFormat(getSubtotalAmount())}</small>
                                            </section>
                                            {formData.shippingMethods === "Delivery" && <section className="flex-between">
                                                <small className="text-muted d-block">{translate('deliveryFee')}</small>
                                                <small className="d-block text-muted me-2">{numberFormat(deliveryFee) || "0"}</small>
                                            </section>}
                                        </section>


                                        {isBankPercentage &&
                                            <section className="mb-3 pb-3 border-bottom">
                                                <section className="flex-between mb-2">
                                                    <small className="text-muted d-block"></small>
                                                    <small className="d-block text-muted me-2">{numberFormat(getSubtotalAmount() + deliveryFee || 0)}</small>
                                                </section>
                                                <section className="flex-between">
                                                    <small className="text-muted d-block">Bank ({formData?.bankCommission}%)</small>
                                                    <small className="d-block text-muted me-2">{numberFormat(getBankCommissionAmount())}</small>
                                                </section>
                                            </section>
                                        }
                                        {isDiscount &&
                                            <section className="mb-3 pb-3 border-bottom">
                                                <section className="flex-between mb-2">
                                                    <small className="text-muted d-block"></small>
                                                    <small className="d-block text-muted me-2">{numberFormat(getSubtotalAmount() + (deliveryFee || 0) + getBankCommissionAmount())}</small>
                                                </section>
                                                <section className="flex-between">
                                                    <small className="text-muted d-block">Discount {formData.discountType === "By percentage" && `(${formData.discountValue}%)`}</small>
                                                    <small className="d-block text-muted me-2">{formData.discountValue && "-"} {numberFormat(formData.discountType === "By percentage" ? getDiscountAmountByPercentage() : formData.discountValue)}</small>
                                                </section>
                                            </section>
                                        }
                                        <section className="flex-between mb-2">
                                            <small className="text-muted d-block">{translate('totalAmount')}</small>
                                            <small className="text-muted me-2 d-block">{numberFormat(getAllTotalAmount(orderItems, deliveryFee, formData.bankCommission, formData.discountType, formData.discountValue ? parseFloat(formData.discountValue) : 0))}</small>
                                        </section>
                                        <section className="flex-between border-bottom mb-3 pb-2">
                                            <small className="text-muted d-block">{translate('paidAmount')}</small>
                                            <small className="text-muted me-2 d-block">{numberFormat(`${formData.totalAmountPaid || 0}`)}</small>
                                        </section>
                                        <section className="flex-between">
                                            <small className="text-muted d-block">{translate('balance')} (MMK)</small>
                                            <small className="text-muted me-2 d-block">{numberFormat(getAllTotalAmount(orderItems, deliveryFee, formData.bankCommission, formData.discountType, formData.discountValue ? parseFloat(formData.discountValue) : 0) - (formData.totalAmountPaid || 0))}</small>
                                        </section>
                                    </Col>
                                </section></> : <NoData noImage title='order item' />}
                    </section>

                    <Row>
                        <Col md={8}>
                            <section className="shadow-sm p-4 rounded bg-card mb-4">
                                <AddCustomerModal show={showCustomerModal} handleClose={handleCloseCustomerModal} responseAction={customerResponseAction} />

                                <h6 className="mb-3">Customer Info</h6>

                                <InputGroup className="mb-4">
                                    <section style={{ width: "90%" }}>
                                        <Select
                                            isClearable
                                            options={customers}
                                            // defaultValue={variable?.buyer && { label: variable?.buyer.label! }}
                                            onInputChange={customerInputChange}
                                            onChange={(data: any) => getCustomerDetail(data)}
                                            placeholder={'Select Customer'}
                                            classNamePrefix="react-select"
                                            className="react-select-container-half-radius"
                                            styles={{
                                                control: (base: any, state: any) => ({
                                                    ...base,
                                                    boxShadow: state.isFocused ? 0 : 0
                                                })
                                            }}
                                            theme={(theme: any) => ({
                                                ...theme,
                                                borderRadius: 4,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#efefef',
                                                    primary: '#e9edef',
                                                    neutral0: '#212529',
                                                    primary50: '#e9edef'
                                                },
                                            })}
                                        />
                                    </section>
                                    <InputGroup.Text className="bg-primary text-white d-flex justify-content-center" style={{ width: "10%" }}>
                                        {/* <OverlayTrigger
                                    overlay={
                                        <Tooltip>
                                            Add New Customer
                                        </Tooltip>
                                    }
                                > */}
                                        <Button className="btn btn-grad btn-sm" onClick={handleShowCustomerModal}><IconAdd /></Button>
                                        {/* </OverlayTrigger> */}
                                    </InputGroup.Text>
                                </InputGroup>

                                {customerLoading ? <CustomerLoading /> :
                                    <>
                                        {customer ? <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className="pos-customer-info-header">Customer information</Accordion.Header>
                                                <Accordion.Body>
                                                    <Row>
                                                        <Col md={6} className="mb-3">
                                                            <small className="text-muted d-block">Customer code</small>
                                                            <small>{customer.uniqueCode}</small>
                                                        </Col>
                                                        <Col md={6} className="mb-3">
                                                            <small className="text-muted d-block">{translate('name')}</small>
                                                            <small>{customer.fullName || "-"}</small>
                                                        </Col>
                                                        <Col md={6} className="mb-3">
                                                            <small className="text-muted d-block">{translate('phone')}</small>
                                                            <small >{customer.phone || "-"}</small>
                                                        </Col>
                                                        <Col md={6} className="mb-3">
                                                            <small className="text-muted d-block">Facebook link</small>
                                                            {customer?.facebookUrl ? <Link to='#' onClick={() => openUrl(customer?.facebookUrl)}>{customer?.facebookUrl}</Link> : <small>-</small>}
                                                        </Col>
                                                        {/* <Col md={6} className="mb-3">
                                            <small className="text-muted d-block">No. of Orders</small>
                                            <small>10</small>
                                        </Col> */}
                                                        <Col md={6} className="mb-3">
                                                            <small className="text-muted d-block">{translate('township')}</small>
                                                            <small>{customer?.shippingAddresses[0]?.subSubAreaNameLocal || "-"}</small>
                                                        </Col>
                                                        <Col md={6} className="mb-3">
                                                            <small className="text-muted d-block">{translate('region')}</small>
                                                            <small>{customer?.shippingAddresses[0]?.areaNameLocal || "-"}</small>
                                                        </Col>
                                                        <Col md={12}>
                                                            <small className="text-muted d-block">{translate('address')}</small>
                                                            <small>{customer?.shippingAddresses[0]?.address || "-"}</small>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion> : <NoData title='customer' noImage />}
                                    </>
                                }
                            </section>

                            <section className="shadow-sm p-4 rounded bg-card mb-4">
                                <div className="d-flex mb-4">
                                    <Form.Check
                                        inline
                                        label={"Delivery service"}
                                        type={"radio"}
                                        id={`Delivery`}
                                        name="shippingMethods"
                                        checked={formData.shippingMethods === "Delivery"}
                                        onChange={() => setFormData({ ...formData, shippingMethods: "Delivery" })}
                                    />
                                    <Form.Check
                                        inline
                                        label={"Pick up"}
                                        name="shippingMethods"
                                        type={"radio"}
                                        id={`Local Pickup`}
                                        checked={formData.shippingMethods !== "Delivery"}
                                        onChange={() => setFormData({ ...formData, shippingMethods: "Local Pickup" })}
                                    />
                                </div>


                                <Row>
                                    {formData.shippingMethods === "Delivery" && <>
                                        <Col md={6}>
                                            <Form.Group className="mb-4 input-form-control" >
                                                <Form.Label>Delivery</Form.Label>
                                                <Form.Control as="select" aria-label="Select" id="deliveryServiceId" value={formData?.deliveryServiceId} onChange={handleSelectDelivery}>
                                                    <option value="">Select</option>
                                                    {deliveryServices.map((item: any, index: number) =>
                                                        <option value={item.id} key={index}>{item.deliveryService}</option>
                                                    )}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mb-4 input-form-control" >
                                                <Form.Label>Location</Form.Label>
                                                <Form.Control as="select" aria-label="Select" id="deliveryPriceId" value={formData?.deliveryPriceId} onChange={handleSelectLocation}>
                                                    <option value="">Select</option>
                                                    {deliveryFees.map((item: any, index: number) =>
                                                        <option value={item.id} key={index}>{item.locationName}</option>
                                                    )}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <section className="flex-between">
                                                <Form.Label>{translate("deliveryFee")}</Form.Label>
                                                <FormCheck
                                                    id="switch"
                                                    className="switch"
                                                    type="switch"
                                                    label={"Custom"}
                                                    checked={isCustomDeliyFee}
                                                    onChange={() => setCustomDeliyFee(!isCustomDeliyFee)}
                                                />
                                            </section>

                                            <Form.Group className="mb-4 input-form-control" >
                                                <Form.Control type="number" onWheel={disableScroll} value={deliveryFee} disabled={!isCustomDeliyFee} onChange={(e) => setDeliveryFee(parseInt(e.target.value))} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-4 input-form-control" >
                                                <Form.Label>Delivery status</Form.Label>
                                                <Form.Control as="select" aria-label="Select" id="deliveryStatusId" value={formData?.deliveryStatusId} onChange={handleChange} >
                                                    {deliveryStatus.map((item: string, index: number) => (
                                                        <option value={index} key={index}>{item}</option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </>}
                                    <Col md={12}>
                                        <Form.Group >
                                            <Form.Label>{formData.shippingMethods} note</Form.Label>
                                            <Form.Control as="textarea" rows={2} id="deliveryNotes" value={formData?.deliveryNotes} onChange={handleChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </section>

                            <section className="shadow-sm p-4 rounded bg-card">
                                <section className="d-flex">
                                    <Button className="text-white" onClick={finalSaveDraftOrder}>Save</Button>
                                    <Button className="btn-success text-white ms-2" onClick={() => history.goBack()}>Cancel</Button>
                                </section>
                            </section>
                        </Col>
                        <Col md={4}>
                            <section className="shadow-sm p-4 rounded bg-card mb-4">
                                <Form.Group className="mb-2" >
                                    <Checkbox
                                        label={"Bank %"}
                                        isChecked={isBankPercentage}
                                        handleClick={(e: any) => {
                                            setIsBankPercentage(e.target.checked);
                                            //  !e.target.checked && setFormData({ ...formData, bankCommission: "" })
                                        }} />
                                </Form.Group>

                                {isBankPercentage && <Form.Group className="mb-4 input-form-control" >
                                    <Form.Control type="number" onWheel={disableScroll} value={formData?.bankCommission} id="bankCommission" onChange={handleChange} />
                                </Form.Group>}

                                <section className="flex-between">
                                    <Form.Group className="mb-2" >
                                        <Checkbox label={`Discount ${formData.discountType === "By percentage" ? '(%)' : 'amount'}`} isChecked={isDiscount}
                                            // !e.target.checked && setFormData({ ...formData, discountValue: "" })
                                            handleClick={(e: any) => { setIsDiscount(e.target.checked); }} />
                                    </Form.Group>
                                    {isDiscount && <FormCheck
                                        id="switch"
                                        className="switch"
                                        type="switch"
                                        label={"%"}
                                        checked={formData.discountType === "By percentage"}
                                        onChange={() => setFormData({ ...formData, discountType: formData.discountType === "By percentage" ? "By amount" : "By percentage" })}
                                    />}
                                </section>

                                {isDiscount && <Form.Group className="mb-4 input-form-control" >
                                    <Form.Control type="number" value={formData?.discountValue} id="discountValue" onChange={handleChange} onWheel={disableScroll} />
                                </Form.Group>}

                                <Form.Group className="input-form-control mb-4" >
                                    <Form.Label>Payment status</Form.Label>
                                    <Form.Control as="select" aria-label="Select" id="paymentStatusId" value={formData?.paymentStatusId}
                                        onChange={(e) => {
                                            setFormData({ ...formData, paymentStatusId: parseInt(e.target.value), paymentTypeId: e.target.value === "0" ? "" : formData.paymentTypeId, totalAmountPaid: e.target.value === "2" ? getAllTotalAmount(orderItems, deliveryFee, formData.bankCommission, formData.discountType, formData.discountValue ? parseFloat(formData.discountValue) : 0) : 0 })
                                        }}
                                    >
                                        {paymentStatus.map((item: string, index: number) => (
                                            <option value={index} key={index}>{item}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>


                                {formData.paymentStatusId === 1 && <Form.Group className="input-form-control mb-4" >
                                    <Form.Label>Paid amount</Form.Label>
                                    <Form.Control type="number" value={formData?.totalAmountPaid || ""} id="totalAmountPaid" onChange={handleChange} onWheel={disableScroll} />
                                </Form.Group>}


                                {formData.paymentStatusId !== 0 && <Form.Group className="input-form-control mb-4" >
                                    <Form.Label>Payment type</Form.Label>
                                    <Form.Control as="select" aria-label="Select" id="paymentTypeId" value={formData?.paymentTypeId} onChange={handleChange} >
                                        <option value="">Select</option>
                                        {payments.length > 0 && payments.map((item: any, index: number) => (
                                            <option value={item.id} key={index}>{item.paymentName}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>}

                                <Form.Group className="mb-4 input-form-control" >
                                    <Form.Label>Order from</Form.Label>
                                    <Form.Control as="select" aria-label="Select" id="orderFromId" value={formData?.orderFromId} onChange={handleChange}>
                                        {orderFrom.map((item: string, index: number) => (
                                            <option value={index} key={index}>{item}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group >
                                    <Form.Label>Order note</Form.Label>
                                    <Form.Control as="textarea" rows={2} id="orderNotes" value={formData?.orderNotes} onChange={handleChange} />
                                </Form.Group>
                            </section>
                        </Col>
                    </Row>
                </>}



            <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{variant.productName} ({variant.sku})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        variant.unitPrice.toString() !== "0" ? <div className="flex-end mb-2">
                            <Form.Check
                                inline
                                label={translate('retailPrice')}
                                name="priceType1"
                                type={"radio"}
                                id={`Retail`}
                                checked={variant.orderType === "Retail"}
                                onChange={() => setVariant({ ...variant, orderType: "Retail", unitPrice: variant.retail })}
                            />
                            <Form.Check
                                inline
                                label={translate('wholesalePrice')}
                                name="priceType1"
                                type={"radio"}
                                id={`Wholesale`}
                                checked={variant.orderType === "Wholesale"}
                                onChange={() => setVariant({ ...variant, orderType: "Wholesale", unitPrice: variant.wholesale })}
                            />
                        </div> : <></>
                    }

                    <Table responsive className="mb-0">
                        <thead>
                            <tr>
                                <th>Size</th>
                                <th>{translate('color')}</th>
                                <th><span className="d-flex justify-content-end">Stock</span></th>
                                <th><span className="d-flex justify-content-center">{translate('quantity')} {variant.unitPrice.toString() !== "0" && `* ${translate('price')}`}</span></th>
                                {variant.unitPrice.toString() !== "0" && <th><span className="d-flex justify-content-end">{translate('amount')}</span></th>}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{variant.size || "-"}</td>
                                <td>{variant.color || '-'}</td>
                                <td><span className="d-flex justify-content-end">{variant.stock}</span></td>
                                <td>
                                    <span className="d-flex justify-content-center align-items-center">
                                        <Col md={5} className="me-2"><Form.Control type="number" min="0" onWheel={disableScroll} value={variant.quantity} onChange={(e: any) => handleChangeQuantity(e.target.value)} /></Col>
                                        {variant.unitPrice.toString() !== "0" && ` * ${numberFormat(variant.unitPrice)}`}</span></td>
                                {variant.unitPrice.toString() !== "0" && <td><span className="d-flex justify-content-end">{numberFormat(variant.unitPrice * variant.quantity)}</span></td>}
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleOnChangeQuantity}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </Layout >
    );
};

export default Edit;
