import { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap"
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import swal from "sweetalert";
import Layout from "../../components/Layout";
import { dataService, inputChangeService, putMethod } from "../../services";

function ChangePassword() {
    const history = useHistory();
    const tempUser: any = localStorage.getItem("USER")
    const user = tempUser && JSON.parse(tempUser)

    const [formData, setFormData] = useState({
        userId: user?.id,
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    })

    const handleChange = (evt: any) => {
        inputChangeService(evt, formData, setFormData);
    }

    const handleSubmit = () => {
        dataService(putMethod, formData, [], "change-password", true).then(result => {
            if (result?.success) {
                history.push("dashboard")
                swal(result?.message, {
                    icon: 'success',
                    buttons: [false],
                    timer: 1500,
                })
            } else {
                toast.error(result?.message)
            }
        })
    }

    return (
        <Layout title="Change Password">
            <section className="bg-card">
                <Form>
                    <div className="col-12 mb-4">
                        <p>Please follow this guide for a strong password:</p>
                        <small>
                            <ul className="text-muted">
                                <li>One special characters</li>
                                <li>Must be 8-20 characters long</li>
                                <li>One number (2 are recommended)</li>
                                <li>Must not contain spaces, special characters, or emoji</li>
                                <li>Change it often</li>
                            </ul>
                        </small>
                    </div>
                    <Row className="mb-4">
                        <Col>
                            <Form.Group controlId="oldPassword">
                                <Form.Label>Old password</Form.Label>
                                <Form.Control type="password" onChange={handleChange} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="newPassword">
                                <Form.Label>New password</Form.Label>
                                <Form.Control type="password" onChange={handleChange} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="confirmPassword">
                                <Form.Label>Confirm password</Form.Label>
                                <Form.Control type="password" onChange={handleChange} />
                            </Form.Group>
                        </Col>
                    </Row>

                    <div className="d-flex mt-4">
                        <Button variant="primary" onClick={handleSubmit} className="me-2">
                            Change password
                        </Button>

                        <Button variant="success" onClick={() => history.goBack()}>
                            Cancel
                        </Button>
                    </div>
                </Form>
            </section>
        </Layout>
    )
}

export default ChangePassword