import { useContext } from "react";
import { Button, Col, Form, Modal, Table } from "react-bootstrap";
import { LangContext } from "../../../context/Lang";
import { disableScroll } from "../../../utils";

const AddItemModal = ({ show, handleClose }: { show: boolean, handleClose: any }) => {
    const { dispatch: { translate } } = useContext(LangContext);

    return (
        <Modal show={show} onHide={handleClose} centered size="xl">
            <Modal.Header closeButton>
                <Modal.Title>ပိတ်ဖေါက်ဇာရှေ့ကွဲလက်တို</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <section className="flex-between mb-2">
                    <small className="text-muted d-block mb-1">SKU : <span className="fw-bold">#500-40</span></small>
                    <small className="text-muted d-block mb-1">Size : <span className="fw-bold">40</span></small>
                </section>
                <section className="flex-between">
                    <small className="text-muted d-block">Stock : <span className="fw-bold">900</span></small>
                    <small className="text-muted d-block">Price : <span className="fw-bold">13,000 Ks</span></small>
                </section>

                <Table responsive className="mb-4">
                    <thead>
                        <tr>
                            <th> <Form.Check type="checkbox" className="text-muted" id="isAll" /></th>
                            <th>{translate('color')}</th>
                            <th>Price</th>
                            <th><span className="d-flex justify-content-end">Stock</span></th>
                            <th><span className="d-flex justify-content-center">{translate('quantity')} * {translate('price')}</span></th>
                            <th><span className="d-flex justify-content-end">{translate('amount')}</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="cursor">
                            <td><Form.Check type="checkbox" label="" className="text-muted" id="isIncluded" /></td>
                            <td>Red</td>
                            <td>
                                <Form.Check
                                    inline
                                    label="Retail"
                                    name="priceType1"
                                    type={"radio"}
                                    id={`inline-1`}
                                />
                                <Form.Check
                                    inline
                                    label="Wholesale"
                                    name="priceType1"
                                    type={"radio"}
                                    id={`inline-2`}
                                />
                            </td>
                            <td><span className="d-flex justify-content-end">300</span></td>
                            <td> <span className="d-flex justify-content-center align-items-center"><Col md={5} className="me-2"><Form.Control type="number" min="0" onWheel={disableScroll} /></Col> *
                                14,000</span></td>
                            <td><span className="d-flex justify-content-end">0</span></td>
                        </tr>
                        <tr className="cursor">
                            <td><Form.Check type="checkbox" label="" className="text-muted" id="isIncluded" /></td>
                            <td>Yellow</td>
                            <td>
                                <Form.Check
                                    inline
                                    label="Retail"
                                    name="priceType2"
                                    type={"radio"}
                                    id={`inline-3`}
                                />
                                <Form.Check
                                    inline
                                    label="Wholesale"
                                    name="priceType2"
                                    type={"radio"}
                                    id={`inline-4`}
                                />
                            </td>
                            <td><span className="d-flex justify-content-end">500</span></td>
                            <td> <span className="d-flex justify-content-center align-items-center"><Col md={5} className="me-2"><Form.Control type="number" min="0" onWheel={disableScroll} /></Col> *
                                14,000</span></td>
                            <td><span className="d-flex justify-content-end">0</span></td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    Add Item
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddItemModal;
