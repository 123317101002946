import { Accordion, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { openUrl } from '../../../utils';

const CustomerInfoAccordion = () => {
    return (
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header className="pos-customer-info-header">Customer Info</Accordion.Header>
                <Accordion.Body>
                    <Row>
                        <Col md={6} >
                            <small className="text-muted d-block">Full name</small>
                            <small>Hari</small>
                        </Col>
                        <Col md={6} >
                            <small className="text-muted d-block">Phone</small>
                            <small >0934535353</small>
                        </Col>

                        <Col md={6} className="mt-3">
                            <small className="text-muted d-block">No. of Orders</small>
                            <small>10</small>
                        </Col>
                        <Col md={6} className="mt-3">
                            <small className="text-muted d-block">Township</small>
                            <small>Kamayut</small>
                        </Col>
                        <Col md={12} className="mt-3">
                            <small className="text-muted d-block">Facebook Link</small>
                            <small><Link to='#' onClick={() => openUrl('https://www.facebook.com/hari.dahal.mm')}>https://www.facebook.com/hari.dahal.mm</Link></small>
                        </Col>
                        <Col md={12} className="mt-3">
                            <small className="text-muted d-block">Address</small>
                            <small>Yadanar Myaing St, Kamayut Tsp, Yangon</small>
                        </Col>
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default CustomerInfoAccordion;
