import { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, FormCheck, Modal, OverlayTrigger, Row, Table, Tooltip, } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { IconAdd, IconDelete, IconLeftArrow, } from '../../components/Icons';
import { LangContext } from '../../context/Lang';
import { actionData, deliveryFeesRoute, deliverySerivcesRoute, disableScroll, getAllTotalAmount, getData, getSaleOrderDetailByIdRoute, handleDateTimeFormat, inputChangeService, numberFormat, putMethod, saleOrderRoute } from '../../services';
import { Layout, Checkbox, EditOrderDetailLoading, NoData, } from '../../components';
import { toast } from 'react-toastify';
import swal from 'sweetalert'
import { OrderContext } from '../../context';
import AddOrderItemModal from '../orders/wholesale/cpn/AddOrderItemModal';
import AddFreeOrderItemModal from '../orders/wholesale/cpn/AddFreeOrderItemModal';

const WholesaleEdit = () => {
    const history = useHistory()
    const { dispatch: { translate } } = useContext(LangContext);
    const [orderItems, setOrderItems] = useContext(OrderContext);
    const location: any = useLocation();
    const order = location.state.order
    const routeFrom = location.state.routeFrom
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showOrderItemModal, setShowOrderItemModal] = useState(false);
    const handleCloseOrderItemModal = () => setShowOrderItemModal(false);
    const handleShowOrderItemModal = () => setShowOrderItemModal(true);
    const [showFreeOrderItemModal, setFreeShowOrderItemModal] = useState(false);
    const handleCloseFreeOrderItemModal = () => setFreeShowOrderItemModal(false);
    const handleShowFreeOrderItemModal = () => setFreeShowOrderItemModal(true);
    const [isDiscount, setIsDiscount] = useState(false);
    const [isBankPercentage, setIsBankPercentage] = useState(false);
    const [deliveryServices, setDeliveryServices] = useState([])
    const [deliveryFees, setDeliveryFees] = useState([])
    const [deliveryFee, setDeliveryFee] = useState(0)
    const [isCustomDeliyFee, setCustomDeliyFee] = useState(false)
    // const [payments, setPayments] = useState([])
    const [loading, setLoading] = useState(false)
    const orderFrom = ["Facebook Live", "Facebook Messager", "Phone Call", "Viber", "Others"]
    const paymentStatus = ["UNPAID", "PAID"]
    const deliveryStatus = ["Pending", "Ready to Ship", "Shipped", "Delivered", "Canceled", "Lost & Damaged", "Failed Delivery", "Returned"]
    const [formData, setFormData] = useState(
        {
            ordersId: "",
            bankCommission: "",
            discountType: "By amount",
            shippingMethods: "Delivery",
            discountValue: "",
            orderFromId: 0,
            orderNotes: "",
            paymentStatusId: 0,
            paymentTypeId: "",
            deliveryServiceId: "",
            deliveryPriceId: "",
            deliveryFee: "",
            deliveryStatusId: 0,
            deliveryNotes: ""
        }
    )

    const [variant, setVariant] = useState({
        productName: "",
        sku: "",
        size: "",
        color: "",
        stock: 0,
        quantity: 0,
        unitPrice: 0,
        orderType: "",
        retail: 0,
        wholesale: 0,
        originQuantity: 0,
    })

    useEffect(() => {
        handleGetDeliveryServices()
        handleGetData()
        // eslint-disable-next-line
    }, [])


    const handleGetData = async () => {
        setLoading(true)
        getData(getSaleOrderDetailByIdRoute(order.id)).then((result) => {
            let tempOrder = result.data

            let temp: any = [];
            if (result.data.orderItems && result.data.orderItems.length > 0) {
                result.data.orderItems?.forEach((item: any) => temp.push(item));
                setOrderItems(temp);
            }

            if (tempOrder.bankCommission) {
                setIsBankPercentage(true)
            }

            if (tempOrder.discountValue) {
                setIsDiscount(true)
            }

            if (tempOrder.deliveryServiceId) {
                // setIsDeliveryFee(true)

                getData(deliveryFeesRoute(tempOrder.deliveryServiceId)).then(result => {
                    setDeliveryFees(result.data.servicePrices)
                })
            }

            if (tempOrder.deliveryFee) {
                setDeliveryFee(parseInt(tempOrder.deliveryFee))
            }

            setFormData({
                ordersId: tempOrder.id || "",
                bankCommission: tempOrder.bankCommission || "",
                discountType: tempOrder.discountType || "By amount",
                shippingMethods: tempOrder.shippingMethods,
                discountValue: tempOrder.discountValue || "",
                orderFromId: tempOrder.orderFrom ? orderFrom.indexOf(tempOrder.orderFrom) : 0,
                orderNotes: tempOrder.orderNotes || "",
                paymentStatusId: tempOrder.paymentStatus ? paymentStatus.indexOf(tempOrder.paymentStatus) : 0,
                paymentTypeId: tempOrder.paymentTypeId || "",
                deliveryServiceId: tempOrder.deliveryServiceId || "",
                deliveryPriceId: tempOrder.deliveryPriceId || "",
                deliveryFee: tempOrder.deliveryFee || "",
                deliveryStatusId: tempOrder.deliveryStatus ? deliveryStatus.indexOf(tempOrder.deliveryStatus) : 0,
                deliveryNotes: tempOrder.deliveryNotes || ""
            })
            setTimeout(() => {
                setLoading(false)
            }, 500)
        })
    }

    const showModelToEdit = (item: any) => {
        setVariant({
            productName: item.productName,
            sku: item.productSKU,
            size: item.productSize,
            color: item.productColor,
            stock: item.stockCount,
            quantity: item.quantity,
            unitPrice: item.unitPrice,
            wholesale: item.wholesalePrice,
            retail: item.retailPrice,
            orderType: item.orderType,
            originQuantity: item.quantity
        })
        handleShow()
    }


    const handleGetDeliveryServices = async () => {
        getData(deliverySerivcesRoute).then(result => {
            setDeliveryServices(result.data)
        })
    }

    const handleGetDeliveryFees = async (serviceId: string) => {
        getData(deliveryFeesRoute(serviceId)).then(result => {
            setDeliveryFees(result.data.servicePrices)
        })
    }

    const handleSelectDelivery = (evt: any) => {
        const value = evt.target.value
        setFormData({ ...formData, deliveryServiceId: value })
        handleGetDeliveryFees(value)
    }

    const handleSelectLocation = (evt: any) => {
        const value = evt.target.value
        let deliFee = "";

        deliveryFees && deliveryFees.length > 0 && deliveryFees.forEach((item: any) => {
            if (item.id === parseInt(value)) {
                deliFee = item.deliveryPrice
            }
        })

        setFormData({ ...formData, deliveryPriceId: value, deliveryFee: deliFee })
        setDeliveryFee(deliFee ? parseInt(deliFee) : 0)
    }

    const getSubtotalAmount = () => {
        let total = 0;
        orderItems?.forEach((item: any) => {
            total += item.quantity * item.unitPrice
        });
        return total;
    }

    const getDiscountAmountByPercentage = () => {
        let amount: any = 0;
        if (formData.discountValue) {
            let discountPercent = parseInt(formData.discountValue)
            amount = ((getSubtotalAmount() + (deliveryFee || 0) + getBankCommissionAmount()) / 100) * discountPercent
            amount = parseInt(amount)
        } else {
            amount = 0
        }
        return amount;
    }


    const getBankCommissionAmount = () => {
        let total = getSubtotalAmount();
        if (isBankPercentage && formData.bankCommission) {
            let commision = parseFloat(formData.bankCommission)
            total = ((getSubtotalAmount() + deliveryFee || 0) / 100) * commision
        } else {
            total = 0
        }
        return total;
    }

    const handleChange = (evt: any) => {
        inputChangeService(evt, formData, setFormData);
    }

    const removeItem = (sku: string) => {
        let temp: any[] = [];
        orderItems?.forEach((item: any) => temp.push(item));

        if (temp.length === 1) {
            toast.error("One item in order at least!")
        } else {
            temp.forEach((data: any, index: number) => {
                if (data.productSKU === sku) {
                    temp.splice(index, 1);
                }
            });
            setOrderItems(temp)
        }
    }

    const handleOnChangeQuantity = () => {
        if (variant.quantity && variant.quantity > 0) {

            let temp: any = [];
            orderItems?.forEach((item: any) => temp.push(item));
            temp.forEach((data: any, index: number) => {
                if (data.productSKU === variant.sku) {
                    temp[index].quantity = parseInt(variant.quantity.toString())
                    temp[index].orderType = variant.orderType
                    temp[index].unitPrice = variant.unitPrice
                }
            });
            setOrderItems(temp)
            handleClose()
        } else {
            toast.error("Need order quantity at least one!.")
        }
    }

    const handleChangeQuantity = (quantity: number) => {
        if (quantity <= variant.stock + variant.originQuantity) {
            setVariant({ ...variant, quantity: quantity })
        } else {
            toast.error("Order quantity is greater than existing stock.")
        }
    }

    const finalSaveDraftOrder = () => {
        if (orderItems.length > 0) {
            let _formData: any = {
                ordersId: formData.ordersId,
                orderItems: orderItems,
                shippingMethods: formData.shippingMethods
            }

            if (isDiscount) {
                _formData["discountType"] = formData.discountValue ? formData.discountType : null;
                _formData["discountValue"] = formData.discountValue || null;
            }

            _formData["bankCommission"] = formData.bankCommission || null;
            _formData["paymentStatus"] = paymentStatus[formData.paymentStatusId];
            _formData["orderFrom"] = orderFrom[formData.orderFromId];
            _formData["paymentTypeId"] = formData.paymentTypeId || null;
            _formData["orderNotes"] = formData.orderNotes;

            if (formData.shippingMethods === "Delivery") {
                _formData["deliveryStatus"] = deliveryStatus[formData.deliveryStatusId];
                if (formData.deliveryServiceId) {
                    _formData["deliveryServiceId"] = parseInt(formData.deliveryServiceId);
                }

                if (formData.deliveryPriceId) {
                    _formData["deliveryPriceId"] = parseInt(formData.deliveryPriceId);
                }

                _formData["deliveryFee"] = deliveryFee ? deliveryFee : null;
            }

            _formData["deliveryNotes"] = formData.deliveryNotes || "";

            actionData(putMethod, `${saleOrderRoute}/modify`, _formData).then((result) => {
                if (result.success) {
                    swal(result.message, {
                        icon: 'success',
                        buttons: [false],
                        timer: 1500,
                    })
                    setOrderItems([])

                    if (routeFrom === "detail") {
                        history.push({
                            pathname: '/sale-detail',
                            state: { id: formData.ordersId }
                        })
                    } else {
                        history.push("/sales")
                    }
                } else {
                    toast.error(result.message)
                }
            })
        } else {
            toast.error("Order item must be at least one.")
        }

    }

    return (
        <Layout title={`Edit sale (${translate('wholesale')})`}>
            <div className='flex-between mb-4'>
                <section className="cursor" onClick={() => history.goBack()}>
                    <p className="mb-0"><IconLeftArrow /> {routeFrom === "detail" ? "Sale order detail" : translate('sales')}</p>
                </section>

                <Button className="text-white px-4" onClick={finalSaveDraftOrder}>Save</Button>
            </div>

            {loading ? <EditOrderDetailLoading /> :
                <>
                    <section className="shadow-sm p-4 rounded bg-card mb-4">
                        <section className="flex-between mb-4">
                            <h6>Order items</h6>
                            <AddOrderItemModal show={showOrderItemModal} handleClose={handleCloseOrderItemModal} />
                            <AddFreeOrderItemModal show={showFreeOrderItemModal} handleClose={handleCloseFreeOrderItemModal} />

                            <div>
                                <Button className="text-white bg-primary-grad me-2" onClick={handleShowOrderItemModal}><IconAdd />  <span className="ms-2">Order items</span></Button>
                                <Button className="text-white bg-primary-grad" onClick={handleShowFreeOrderItemModal}><IconAdd />  <span className="ms-2">Free item</span></Button>
                            </div>

                        </section>

                        <section className="flex-between">
                            <section>
                                <span className="d-block mb-2"><small className="text-muted">{translate('name')}</small> :   <small>{order?.customerName || "-"}</small></span>
                                <span className="d-block mb-2"><small className="text-muted">{translate('phone')}</small> :   <small>{order?.customerPhone || "-"}</small></span>
                                <span className="d-block mb-2" ><small className="text-muted">{translate('address')}</small> :   <small >{order?.customerAddress[0].address || "-"} </small></span>
                            </section>
                            <section className="d-flex flex-column align-items-end">
                                <small className="d-block mb-2">{order?.saleOrdersCode}</small>
                                {/* <span className="d-block mb-2"><small className="text-muted">{translate('payment')}</small> :   <small>{order?.paymentStatus}</small></span> */}
                                <span className="d-block mb-2"><small className="text-muted">{translate('date')}</small> :   <small>{handleDateTimeFormat(order?.createdAt)}</small></span>
                            </section>
                        </section>

                        {orderItems.length > 0 ?
                            <>
                                <Table responsive className="mb-0" striped>
                                    <thead>
                                        <tr>
                                            <th />
                                            <th className="ps-4">{translate('no.')}</th>
                                            <th>{translate('item')}</th>
                                            <th>SKU</th>
                                            <th>{translate('size')}</th>
                                            <th>{translate('color')}</th>
                                            <th><span className="d-flex justify-content-end">{translate('quantity')}</span></th>
                                            <th><span className="d-flex justify-content-end">{translate('price')}</span></th>
                                            <th><span className="d-flex justify-content-end">{translate('amount')}</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderItems.map((item: any, index: number) =>
                                        (<tr key={index}>
                                            <td className='ps-4'>
                                                <OverlayTrigger
                                                    overlay={
                                                        <Tooltip>
                                                            Remove item
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="text-danger cursor" onClick={() => removeItem(item.productSKU)}><IconDelete /></span>
                                                </OverlayTrigger>
                                            </td>
                                            <td className="ps-4" onClick={() => showModelToEdit(item)}>{index + 1}</td>
                                            <td onClick={() => showModelToEdit(item)}>{item.productName}</td>
                                            <td onClick={() => showModelToEdit(item)}>{item.productSKU}</td>
                                            <td onClick={() => showModelToEdit(item)}>{item.productSize || "-"}</td>
                                            <td onClick={() => showModelToEdit(item)}>{item.productColor || "-"}</td>
                                            <td onClick={() => showModelToEdit(item)}><span className="d-flex justify-content-end">{numberFormat(item.quantity)}</span></td>
                                            <td onClick={() => showModelToEdit(item)}><span className="d-flex justify-content-end">{item.unitPrice.toString() !== "0" ? numberFormat(item.unitPrice) : "Free"}</span></td>
                                            <td onClick={() => showModelToEdit(item)}><span className="d-flex justify-content-end">{item.unitPrice.toString() !== "0" ? numberFormat(item.quantity * item.unitPrice) : 0}</span></td>
                                        </tr>))}
                                    </tbody>
                                </Table> <section className="mt-4 d-flex justify-content-end">
                                    <Col md={3}>
                                        <section className="mb-3 pb-3 border-bottom">
                                            <section className="flex-between mb-2">
                                                <small className="text-muted d-block">{translate('subTotal')}</small>
                                                <small className="d-block text-muted me-2">{numberFormat(getSubtotalAmount())}</small>
                                            </section>
                                            {formData.shippingMethods === "Delivery" && <section className="flex-between">
                                                <small className="text-muted d-block">{translate('deliveryFee')}</small>
                                                <small className="d-block text-muted me-2">{numberFormat(deliveryFee) || "0"}</small>
                                            </section>}
                                        </section>

                                        {isBankPercentage &&
                                            <section className="mb-3 pb-3 border-bottom">
                                                <section className="flex-between mb-2">
                                                    <small className="text-muted d-block"></small>
                                                    <small className="d-block text-muted me-2">{numberFormat(getSubtotalAmount() + deliveryFee || 0)}</small>
                                                </section>
                                                <section className="flex-between">
                                                    <small className="text-muted d-block">Bank ({formData?.bankCommission}%)</small>
                                                    <small className="d-block text-muted me-2">{numberFormat(getBankCommissionAmount())}</small>
                                                </section>
                                            </section>
                                        }
                                        {isDiscount &&
                                            <section className="mb-3 pb-3 border-bottom">
                                                <section className="flex-between mb-2">
                                                    <small className="text-muted d-block"></small>
                                                    <small className="d-block text-muted me-2">{numberFormat(getSubtotalAmount() + (deliveryFee || 0) + getBankCommissionAmount())}</small>
                                                </section>
                                                <section className="flex-between">
                                                    <small className="text-muted d-block">Discount {formData.discountType === "By percentage" && `(${formData.discountValue}%)`}</small>
                                                    <small className="d-block text-muted me-2">{formData.discountValue && "-"} {numberFormat(formData.discountType === "By percentage" ? getDiscountAmountByPercentage() : formData.discountValue)}</small>
                                                </section>
                                            </section>
                                        }
                                        <section className="flex-between">
                                            <h6 className="text-muted">{translate('total')} (MMK)</h6>
                                            <h6 className="text-muted me-2">{numberFormat(getAllTotalAmount(orderItems, deliveryFee, formData.bankCommission, formData.discountType, formData.discountValue ? parseFloat(formData.discountValue) : 0))}</h6>
                                        </section>
                                    </Col>
                                </section></> : <NoData noImage title='order item' />}
                    </section>

                    <Row>
                        <Col md={8}>
                            <section className="shadow-sm p-4 rounded bg-card mb-4">
                                <div className="d-flex mb-4">
                                    <Form.Check
                                        inline
                                        label={"Delivery service"}
                                        type={"radio"}
                                        id={`Delivery`}
                                        name="shippingMethods"
                                        checked={formData.shippingMethods === "Delivery"}
                                        onChange={() => setFormData({ ...formData, shippingMethods: "Delivery" })}
                                    />
                                    <Form.Check
                                        inline
                                        label={"Pick up"}
                                        name="shippingMethods"
                                        type={"radio"}
                                        id={`Local Pickup`}
                                        checked={formData.shippingMethods !== "Delivery"}
                                        onChange={() => setFormData({ ...formData, shippingMethods: "Local Pickup" })}
                                    />
                                </div>


                                <Row className='mt-4'>
                                    {formData.shippingMethods === "Delivery" && <>
                                        <Col md={6}>
                                            <Form.Group className="mb-4 input-form-control" >
                                                <Form.Label>Delivery</Form.Label>
                                                <Form.Control as="select" aria-label="Select" id="deliveryServiceId" value={formData?.deliveryServiceId} onChange={handleSelectDelivery}>
                                                    <option value="">Select</option>
                                                    {deliveryServices.map((item: any, index: number) =>
                                                        <option value={item.id} key={index}>{item.deliveryService}</option>
                                                    )}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mb-4 input-form-control" >
                                                <Form.Label>{translate("location")}</Form.Label>
                                                <Form.Control as="select" aria-label="Select" id="deliveryPriceId" value={formData?.deliveryPriceId} onChange={handleSelectLocation}>
                                                    <option value="">Select</option>
                                                    {deliveryFees.map((item: any, index: number) =>
                                                        <option value={item.id} key={index}>{item.locationName}</option>
                                                    )}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <section className="flex-between">
                                                <Form.Label>{translate("deliveryFee")}</Form.Label>
                                                <FormCheck
                                                    id="switch"
                                                    className="switch"
                                                    type="switch"
                                                    label={"Custom"}
                                                    checked={isCustomDeliyFee}
                                                    onChange={() => setCustomDeliyFee(!isCustomDeliyFee)}
                                                />
                                            </section>

                                            <Form.Group className="mb-4 input-form-control" >
                                                <Form.Control type="number" onWheel={disableScroll} value={deliveryFee} disabled={!isCustomDeliyFee} onChange={(e) => setDeliveryFee(parseInt(e.target.value))} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-4 input-form-control" >
                                                <Form.Label>Delivery status</Form.Label>
                                                <Form.Control as="select" aria-label="Select" id="deliveryStatusId" value={formData?.deliveryStatusId} onChange={handleChange} >
                                                    {deliveryStatus.map((item: string, index: number) => (
                                                        <option value={index} key={index}>{item}</option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col> </>}
                                    <Col md={12}>
                                        <Form.Group >
                                            <Form.Label>Delivery note</Form.Label>
                                            <Form.Control as="textarea" rows={2} id="deliveryNotes" value={formData?.deliveryNotes} onChange={handleChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </section>


                            <section className="shadow-sm p-4 rounded bg-card">
                                <section className="d-flex">
                                    <Button className="text-white" onClick={finalSaveDraftOrder}>Save</Button>
                                    <Button className="btn-success text-white ms-2" onClick={() => history.goBack()}>Cancel</Button>
                                </section>
                            </section>
                        </Col>
                        <Col md={4}>
                            <section className="shadow-sm p-4 rounded bg-card mb-4">
                                <Form.Group className="mb-2" >
                                    <Checkbox
                                        label={"Bank %"}
                                        isChecked={isBankPercentage}
                                        handleClick={(e: any) => {
                                            setIsBankPercentage(e.target.checked);
                                            !e.target.checked && setFormData({ ...formData, bankCommission: "" })
                                        }} />
                                </Form.Group>

                                {isBankPercentage && <Form.Group className="mb-4 input-form-control" >
                                    <Form.Control type="number" onWheel={disableScroll} value={formData?.bankCommission} id="bankCommission" onChange={handleChange} />
                                </Form.Group>}

                                <section className="flex-between">
                                    <Form.Group className="mb-2" >
                                        <Checkbox label={`Discount ${formData.discountType === "By percentage" ? '(%)' : 'amount'}`} isChecked={isDiscount}
                                            handleClick={(e: any) => {
                                                setIsDiscount(e.target.checked);
                                                !e.target.checked && setFormData({ ...formData, discountValue: "" })
                                            }} />
                                    </Form.Group>
                                    {isDiscount && <FormCheck
                                        id="switch"
                                        className="switch"
                                        type="switch"
                                        label={"%"}
                                        checked={formData.discountType === "By percentage"}
                                        onChange={() => setFormData({ ...formData, discountType: formData.discountType === "By percentage" ? "By amount" : "By percentage" })}
                                    />}
                                </section>

                                {isDiscount && <Form.Group className="mb-4 input-form-control" >
                                    <Form.Control type="number" value={formData?.discountValue} id="discountValue" onChange={handleChange} onWheel={disableScroll} />
                                </Form.Group>}

                                {/* <Form.Group className="input-form-control mb-4" >
                                    <Form.Label>Payment status</Form.Label>
                                    <Form.Control as="select" aria-label="Select" id="paymentStatusId" value={formData?.paymentStatusId} onChange={handleChange} >
                                        {paymentStatus.map((item: string, index: number) => (
                                            <option value={index} key={index}>{item}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group className="input-form-control mb-4" >
                                    <Form.Label>Payment type</Form.Label>
                                    <Form.Control as="select" aria-label="Select" id="paymentTypeId" value={formData?.paymentTypeId} onChange={handleChange} >
                                        <option value="">Select</option>
                                        {payments.length > 0 && payments.map((item: any, index: number) => (
                                            <option value={item.id} key={index}>{item.paymentName}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group> */}

                                <Form.Group className="mb-4 input-form-control" >
                                    <Form.Label>Order from</Form.Label>
                                    <Form.Control as="select" aria-label="Select" id="orderFromId" value={formData?.orderFromId} onChange={handleChange}>
                                        {orderFrom.map((item: string, index: number) => (
                                            <option value={index} key={index}>{item}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group >
                                    <Form.Label>Order note</Form.Label>
                                    <Form.Control as="textarea" rows={2} id="orderNotes" value={formData?.orderNotes} onChange={handleChange} />
                                </Form.Group>
                            </section>
                        </Col>
                    </Row>
                </>}

            <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{variant.productName} ({variant.sku})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {variant.unitPrice.toString() !== "0" ? <div className="flex-end mb-2">
                        <Form.Check
                            inline
                            label={translate('retailPrice')}
                            name="priceType1"
                            type={"radio"}
                            id={`Retail`}
                            checked={variant.orderType === "Retail"}
                            onChange={() => setVariant({ ...variant, orderType: "Retail", unitPrice: variant.retail })}
                        />
                        <Form.Check
                            inline
                            label={translate('wholesalePrice')}
                            name="priceType1"
                            type={"radio"}
                            id={`Wholesale`}
                            checked={variant.orderType === "Wholesale"}
                            onChange={() => setVariant({ ...variant, orderType: "Wholesale", unitPrice: variant.wholesale })}
                        />
                    </div> : <></>}
                    <Table responsive className="mb-0">
                        <thead>
                            <tr>
                                <th>Size</th>
                                <th>{translate('color')}</th>
                                <th><span className="d-flex justify-content-end">Stock</span></th>
                                <th><span className="d-flex justify-content-center">{translate('quantity')} {variant.unitPrice.toString() !== "0" && `* ${translate('price')}`}</span></th>
                                {variant.unitPrice.toString() !== "0" && <th><span className="d-flex justify-content-end">{translate('amount')}</span></th>}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{variant.size}</td>
                                <td>{variant.color}</td>
                                <td><span className="d-flex justify-content-end">{variant.stock}</span></td>
                                <td>
                                    <span className="d-flex justify-content-center align-items-center">
                                        <Col md={5} className="me-2"><Form.Control type="number" min="0" onWheel={disableScroll} value={variant.quantity} onChange={(e: any) => handleChangeQuantity(e.target.value)} /></Col>
                                        {variant.unitPrice.toString() !== "0" && ` * ${numberFormat(variant.unitPrice)}`}</span></td>
                                {variant.unitPrice.toString() !== "0" && <td><span className="d-flex justify-content-end">{numberFormat(variant.unitPrice * variant.quantity)}</span></td>}
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleOnChangeQuantity}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </Layout >
    );
};

export default WholesaleEdit;
