import { useState, createContext } from 'react';

const contextDefaultValues: any = {
    cartItems: [],
    setCartItems: () => { },
}

const CartContext = createContext<any>(contextDefaultValues);

function CartProvider(props: any) {

    const [cartItems, setCartItems] = useState([])

    return (
        <CartContext.Provider value={[cartItems, setCartItems]}>
            {props.children}
        </CartContext.Provider>
    );
}

export { CartContext, CartProvider };