import { useContext, useState } from "react";
import { Col, Container, Form, Image, Table, } from "react-bootstrap";
import { toast } from "react-toastify";
import { NoData } from "../../../../components";
import { LangContext } from "../../../../context";
import { disableScroll, getSubtotolInOrderItem, handleChangeQuantity, numberFormat } from "../../../../services";
const RES_API_URL = process.env.REACT_APP_RES_API_URL

const AddFreeOrderByProduct = ({ product, variants, handleAdd }: { product: any, variants: any, handleAdd: any }) => {
    const { dispatch: { translate } } = useContext(LangContext);
    const [isRetail, setChangeRetail] = useState(true)
    const [isCheckAll, setCheckAll] = useState(false)
    const [tempOrderItems, setTempOrderItems] = useState(variants)

    const productImage = () => {
        let tempDiv = <Image src={'images/noimage.png'} width={240} height={240} rounded />;

        if (product.productVariants) {
            product.productVariants.forEach((item: any) => {
                if (item.imageUrl && item.imageUrl.length > 0) {
                    let imageUrl = item.imageUrl[0].url;
                    tempDiv = <Image src={`${RES_API_URL}${imageUrl}`} alt={imageUrl} width={240} height={240} rounded />
                }
            })
        }

        return tempDiv;
    }

    const changeRetailPrice = (retail: boolean) => {
        let temp: any = tempOrderItems;
        temp.forEach((data: any, index: number) => {
            temp[index].unitPrice = retail ? temp[index].retailPrice : temp[index].wholesalePrice
            temp[index].orderType = retail ? "Retail" : "Wholesale"
        });
        setTempOrderItems(temp)
        handleAdd(temp.filter((item: any) => item.isCheck === true))
    }

    const Checkbox = ({ id, type, name, handleClick, isChecked }: { id: any, type: any, name: string, handleClick: any, isChecked: any }) => {
        return (
            <input
                id={id}
                name={name}
                type={type}
                onChange={handleClick}
                checked={isChecked}
                className="form-check-input cursor"
            />
        );
    };

    const handleClick = (evt: any, sku: string) => {
        const { checked } = evt.target;
        let temp: any = [];
        tempOrderItems?.forEach((item: any) => temp.push(item));

        temp.forEach((data: any, index: number) => {
            if (data.productSKU === sku) {
                if (temp[index].stockCount > 0) {
                    temp[index].isCheck = checked
                    temp[index].quantity = checked ? 1 : "";
                    setTempOrderItems(temp)
                    handleAdd(temp.filter((item: any) => item.isCheck === true))
                } else {
                    toast.error("Empty stock!")
                }
            }
        });
    };

    const handleAll = (evt: any) => {
        const { checked } = evt.target;
        let temp: any = [];
        tempOrderItems?.forEach((item: any) => temp.push(item));

        temp.forEach((data: any, index: number) => {
            if (checked) {
                if (temp[index].stockCount > 0) {
                    temp[index].isCheck = checked
                    temp[index].quantity = 1;
                }
            } else {
                temp[index].isCheck = checked
                temp[index].quantity = "";
            }
        });
        setCheckAll(checked)
        setTempOrderItems(temp)
        handleAdd(temp.filter((item: any) => item.isCheck === true))
    };



    return (
        <Container>
            <section className="d-flex justify-content-center align-items-center mb-4">
                {productImage()}
                <section className="ms-4">
                    <h6 className="fw-bold mb-4">{product.productName}</h6>
                    <small className="text-muted d-block mb-2">Code : <span className="fw-bold">{product.productCode}</span></small>
                    <small className="text-muted d-block mb-2">Quantity in Package : <span className="fw-bold">{product.qtyInPackage || "0"}</span></small>
                    <small className="text-muted d-block mb-4">Minimum Wholesale Quantity : <span className="fw-bold">{product.minWholesaleMoq || "0"}</span></small>
                </section>
            </section>



            {product.productVariants.length > 0 ? <>
                {/* <div className="flex-end mb-2">
                    <Form.Check
                        inline
                        label={translate('retailPrice')}
                        name="priceType1"
                        type={"radio"}
                        id={`Retail`}
                        checked={isRetail}
                        onChange={() => {
                            if (!isRetail) {
                                setChangeRetail(true)
                                changeRetailPrice(true)
                            }
                        }}
                    />
                    <Form.Check
                        inline
                        label={translate('wholesalePrice')}
                        name="priceType1"
                        type={"radio"}
                        id={`Wholesale`}
                        checked={!isRetail}
                        onChange={() => {
                            if (isRetail) {
                                setChangeRetail(false)
                                changeRetailPrice(false)
                            }
                        }}
                    />
                </div> */}
                <Table responsive className="mb-4" striped>
                    <thead>
                        <tr>
                            <th className="ps-4">
                                <Checkbox
                                    type="checkbox"
                                    name="selectAll"
                                    id="selectAll"
                                    handleClick={(e: any) => handleAll(e)}
                                    isChecked={isCheckAll}
                                />
                            </th>
                            <th>SKU</th>
                            <th>{translate('size')}</th>
                            <th>{translate('color')}</th>
                            <th><span className="d-flex justify-content-end">Stock</span></th>
                            <th><span className="d-flex justify-content-center">{translate('quantity')}</span></th>
                            {/* <th><span className="d-flex justify-content-end">{translate('amount')}</span></th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {(tempOrderItems && tempOrderItems.length > 0) && tempOrderItems.map((item: any, index: number) => (
                            <tr key={index}>
                                <td className="ps-4">
                                    <Checkbox
                                        key={index}
                                        type="checkbox"
                                        name={item.productSKU}
                                        id={index}
                                        handleClick={(e: any) => handleClick(e, item.productSKU)}
                                        isChecked={item.isCheck}
                                    />
                                    {/* <Form.Check
                                        type="checkbox"
                                        label=""
                                        className="text-muted cursor"
                                        handleClick={(e: any) => handleClick(e, item)}
                                        isChecked={item.isCheck}
                                        id="isIncluded" /> */}
                                </td>
                                <td>{item.productSKU}</td>
                                <td>{item.productSize}</td>
                                <td>{item.productColor}</td>
                                <td><span className="d-flex justify-content-end">{item.stockCount}</span></td>
                                <td> <span className="d-flex justify-content-center align-items-center">
                                    <Col md={5} className="me-2">
                                        <Form.Control
                                            type="number"
                                            min="0"
                                            onWheel={disableScroll}
                                            value={item.quantity}
                                            disabled={!item.isCheck}
                                            onChange={(e: any) => handleChangeQuantity(e.target.value, item.productSKU, item.stockCount, tempOrderItems, setTempOrderItems)}
                                            max={item.stockCount} />
                                    </Col></span></td>
                                {/* <td><span className="d-flex justify-content-end">{numberFormat(item.unitPrice * item.quantity)}</span></td> */}
                            </tr>
                        ))}
                    </tbody>
                </Table>
                {/* <section className="d-flex justify-content-end">
                    <Col md={2}>
                        <section className="flex-between">
                            <h6 className="text-muted">{translate("total")} (MMK)</h6>
                            <h6 className="text-muted me-2">{getSubtotolInOrderItem(tempOrderItems)}</h6>
                        </section>
                    </Col>
                </section> */}
            </> : <NoData title="variant" />}
        </Container>
    )
}

export default AddFreeOrderByProduct