import { useContext } from 'react'
import { Col, Row } from 'react-bootstrap'
import { LangContext } from '../../../../context';
import { numberFormat } from '../../../../services';

const Count = ({ counts }: { counts: any }) => {
    const { dispatch: { translate } } = useContext(LangContext);

    return (
        <Row className="my-4">
            <Col >
                <section className="widget-content bg-premium-dark">
                    <section>{translate('colors')}</section>
                    <section className="widget-numbers"><span>{counts.color}</span></section>
                </section>
            </Col>
            {counts.productTypeId === 1 && <Col>
                <section className="widget-content bg-happy-green">
                    <section>Sizes</section>
                    <section className="widget-numbers"><span>{counts.size}</span></section>
                </section>
            </Col>}
            <Col >
                <section className="widget-content bg-arielle-smile" >
                    <section>Variants</section>
                    <section className="widget-numbers"><span>{counts.variant}</span></section>
                </section>
            </Col>
            <Col>
                <section className="widget-content bg-night-fade">
                    <section>Stock</section>
                    <section className="widget-numbers"><span>{numberFormat(counts.stock)}</span></section>
                </section>
            </Col>
        </Row>
    )
}

export default Count