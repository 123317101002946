import { useContext } from "react"
import { Row, Col } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import { IconCardList, IconColor, IconDelivery, IconFlower, IconHouse, IconPayment, IconPeople, IconRuler, IconSale, IconStack, IconTag } from "../../components/Icons"
import Layout from "../../components/Layout"
import { LangContext } from "../../context/Lang"

function Configuration() {
    const history = useHistory()
    const { dispatch: { translate } } = useContext(LangContext);

    return (
        <Layout title={translate("configuration")}>
            <h6 className="fw-bold text-muted">Attributes</h6>
            <Row className="border-bottom pt-2 mb-4">
                <Col md={4} >
                    <section className="config-card bg-card mb-4 d-flex cursor align-items-center" onClick={() => history.push("/product-types")}>
                        <IconCardList size={32} />
                        <section className="ms-4">
                            <h6>{translate("productTypes")}</h6>
                            <small className="text-muted">Define product type of products</small>
                        </section>
                    </section>
                </Col>
                <Col md={4} >
                    <section className="config-card bg-card mb-4 d-flex cursor align-items-center" onClick={() => history.push("/brands")}>
                        <IconTag size={32} />
                        <section className="ms-4">
                            <h6>{translate("brands")}</h6>
                            <small className="text-muted">Define brand of products</small>
                        </section>
                    </section>
                </Col>
                <Col md={4}>
                    <section className="config-card bg-card mb-4 d-flex cursor align-items-center" onClick={() => history.push("/materials")}>
                        <IconStack size={32} />
                        <section className="ms-4">
                            <h6>{translate("materials")}</h6>
                            <small className="text-muted">Define materials of products</small>
                        </section>
                    </section>
                </Col>
                <Col md={4}>
                    <section className="config-card bg-card mb-4 d-flex cursor align-items-center" onClick={() => history.push("/styles")}>
                        <IconFlower size={32} />
                        <section className="ms-4">
                            <h6>{translate("styles")}</h6>
                            <small className="text-muted">Define styles of products</small>
                        </section>
                    </section>
                </Col>
                <Col md={4}>
                    <section className="config-card bg-card mb-4 d-flex cursor align-items-center" onClick={() => history.push("/sleeves")}>
                        <IconCardList size={32} />
                        <section className="ms-4">
                            <h6>{translate("sleeve")}</h6>
                            <small className="text-muted">Define sleeves of products</small>
                        </section>
                    </section>
                </Col>
                <Col md={4} >
                    <section className="config-card bg-card mb-4 d-flex cursor align-items-center" onClick={() => history.push("/colors")}>
                        <IconColor size={32} />
                        <section className="ms-4">
                            <h6> {translate("colors")}</h6>
                            <small className="text-muted">Define colors of products</small>
                        </section>
                    </section>
                </Col>
                <Col md={4} >
                    <section className="config-card bg-card mb-4 d-flex cursor align-items-center" onClick={() => history.push("/measurements")}>
                        <IconRuler size={32} />
                        <section className="ms-4">
                            <h6>Measurement size</h6>
                            <small className="text-muted">Define measurement size</small>
                        </section>
                    </section>
                </Col>
                <Col md={4} >
                    <section className="config-card bg-card mb-4 d-flex cursor align-items-center" onClick={() => history.push("/standard-size")}>
                        <IconRuler size={32} />
                        <section className="ms-4">
                            <h6>Standard size</h6>
                            <small className="text-muted">Define colors of products</small>
                        </section>
                    </section>
                </Col>
                <Col md={4} >
                    <section className="config-card bg-card mb-4 d-flex cursor align-items-center" onClick={() => history.push("/widths")}>
                        <IconRuler size={32} />
                        <section className="ms-4">
                            <h6>{translate("width")}</h6>
                            <small className="text-muted">Define width of products</small>
                        </section>
                    </section>
                </Col>
            </Row>

            <h6 className="fw-bold text-muted">Warehouses & Factories</h6>
            <Row className="border-bottom pt-2 mb-4">
                <Col md={4} >
                    <section className="config-card bg-card mb-4 d-flex cursor align-items-center" onClick={() => history.push("/warehouses")}>
                        <IconHouse size={32} />
                        <section className="ms-4">
                            <h6>Warehouses</h6>
                            <small className="text-muted">Manage warehouse information</small>
                        </section>
                    </section>
                </Col>
                <Col md={4}>
                    <section className="config-card bg-card mb-4 d-flex cursor align-items-center" onClick={() => history.push("/factories")}>
                        <IconHouse size={32} />
                        <section className="ms-4">
                            <h6>Factories</h6>
                            <small className="text-muted">Manage factory information</small>
                        </section>
                    </section>
                </Col>
            </Row>

            <h6 className="fw-bold text-muted">Delivery</h6>
            <Row className="border-bottom pt-2 mb-4">
                <Col md={4} >
                    <section className="config-card bg-card mb-4 d-flex cursor align-items-center" onClick={() => history.push("/delivery-services")}>
                        <IconDelivery size={32} />
                        <section className="ms-4">
                            <h6>Delivery services</h6>
                            <small className="text-muted">Manage delivery information</small>
                        </section>
                    </section>
                </Col>
                <Col md={4} >
                    <section className="config-card bg-card mb-4 d-flex cursor align-items-center" onClick={() => history.push("/delivery-fees")}>
                        <IconPayment size={32} />
                        <section className="ms-4">
                            <h6>{translate("deliveryFees")}</h6>
                            <small className="text-muted">Manage delivery fees</small>
                        </section>
                    </section>
                </Col>
            </Row>

            <h6 className="fw-bold text-muted">{translate('miscellaneous')}</h6>
            <Row className="pt-2">
                <Col md={4} >
                    <section className="config-card bg-card mb-4 d-flex cursor align-items-center" onClick={() => history.push("/payments")}>
                        <IconPayment size={32} />
                        <section className="ms-4">
                            <h6>Payments</h6>
                            <small className="text-muted">Manage payments</small>
                        </section>
                    </section>
                </Col>
                <Col md={4} >
                    <section className="config-card bg-card mb-4 d-flex cursor align-items-center" onClick={() => history.push("/staff-members")}>
                        <IconPeople size={32} />
                        <section className="ms-4">
                            <h6>{translate("staff")}</h6>
                            <small className="text-muted">Manage your employees</small>
                        </section>
                    </section>
                </Col>
                <Col md={4} >
                    <section className="config-card bg-card mb-4 d-flex cursor align-items-center" onClick={() => history.push("/receipt-header")}>
                        <IconSale size={32} />
                        <section className="ms-4">
                            <h6>Receipt header</h6>
                            <small className="text-muted">Print receipt header</small>
                        </section>
                    </section>
                </Col>
                <Col md={4} >
                    <section className="config-card bg-card mb-4 d-flex cursor align-items-center" onClick={() => history.push("/change-prices")}>
                        <IconPayment size={32} />
                        <section className="ms-4">
                            <h6>Change prices</h6>
                            <small className="text-muted">Update prices based on inflaction</small>
                        </section>
                    </section>
                </Col>
            </Row>

        </Layout>
    )
}

export default Configuration