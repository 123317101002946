import { useContext } from 'react';
import { Button, Col, Form, InputGroup, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { LoadingButtonLabel } from '.';
import { LangContext } from '../context';
import { postMethod, putMethod } from '../services';
import { IconSearch } from './Icons';

export const CustomInput = ({ label, id, handleChange, value }: { label: string, id: string, handleChange: any, value: any }) => {
    return (
        <Form.Group className="mb-4 input-form-control">
            <Form.Label>{label}</Form.Label>
            <Form.Control type="text" id={id} onChange={handleChange} value={value} />
        </Form.Group>
    )
}

export const CustomSelect = ({ label, data, selectAction, handleSelectInputChange }: { label: string, data: any, selectAction: any, handleSelectInputChange: any }) => {
    return <Select
        isClearable
        options={data}
        // defaultValue={variable?.buyer && { label: variable?.buyer.label! }}
        onInputChange={(newValue: string) => handleSelectInputChange(newValue)}
        onChange={(value: any) => selectAction(value)}
        placeholder={label}
        classNamePrefix="react-select"
        className="react-select-container me-3"
        styles={{
            control: (base: any, state: any) => ({
                ...base,
                boxShadow: state.isFocused ? 0 : 0
            })
        }}
        theme={(theme: any) => ({
            ...theme,
            borderRadius: 4,
            colors: {
                ...theme.colors,
                primary25: '#efefef',
                primary: '#e9edef',
                neutral0: '#212529',
                primary50: '#e9edef'
            },
        })}
    />
}

export const CustomModal = ({ show, handleSubmit, handleClose, id, loading, lg, children }: { show: boolean, handleSubmit: any, handleClose: any, id: any, loading: boolean, lg?: boolean, children: any }) => {
    const { dispatch: { translate } } = useContext(LangContext);

    return (
        <Modal show={show} onHide={handleClose} centered size={lg ? 'lg' : undefined}>
            <Modal.Header closeButton>
                <Modal.Title>{id ? translate("editInfo") : translate("createNew")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => handleSubmit(id ? putMethod : postMethod)} disabled={loading}>
                    <LoadingButtonLabel label='Save' disabled={loading} />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export const SearchInput = () => (
    <Col md={3}>
        <InputGroup className="search-input">
            <InputGroup.Text className="bg-primary-grad text-white">
                <IconSearch />
            </InputGroup.Text>
            <Form.Control placeholder="Search" onKeyDown={(e) => { }} />
        </InputGroup>
    </Col>
)


