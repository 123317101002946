
import React, { useContext } from "react";
import { Table } from "react-bootstrap";
import { LangContext, PageContext } from "../../../context";
import { getNumber, handleDateTimeFormat, numberFormat } from "../../../services";


const SaleListPrint = React.forwardRef((props: any, ref: any) => {
    const { dispatch: { translate } } = useContext(LangContext);
    const data = props.data
    const [page] = useContext(PageContext)

    return (
        <section ref={ref} className="default-margin">
            <div className="d-flex flex-column align-items-center mb-2">
                <h6 className="mt-2 mb-1 fw-bold">{translate('sales')}</h6>
            </div>

            <Table className="mb-0 print-table" bordered striped>
                <thead>
                    <tr>
                        <th><span className='flex-center'>{translate('no.')}</span></th>
                        <th><span className='flex-center'>{translate('saleNumber')}</span></th>
                        <th><span className='flex-center'>Order type</span></th>
                        <th><span className='flex-center'>{translate('customer')}</span></th>
                        <th><span className='flex-center'>{translate('paymentStatus')}</span></th>
                        <th><span className='flex-center'>{translate('date')}</span></th>
                        <th><span className='flex-center'>{translate('total')} (MMK)</span></th>
                    </tr>
                </thead>
                <tbody>
                    {data && data.length > 0 && data.map((item: any, index: number) =>
                        <tr key={index}>
                            <td><span className='flex-center'>{getNumber(index, page?.current)}</span></td>
                            <td><span className='flex-center'>{item.saleOrdersCode}</span></td>
                            <td><span className='flex-center'>{item.orderType}</span></td>
                            <td>{item.customerName}</td>
                            <td><span className='flex-center'>{item.paymentStatus}</span></td>
                            <td><span className='flex-center'>{handleDateTimeFormat(item.createdAt)}</span></td>
                            <td><span className="flex-end">{numberFormat(item.totalAmount)}</span></td>
                        </tr>)}
                    {/* <tr>
                        <th />
                        <th />
                        <th />
                        <th />
                        <th />
                        <th><span className="flex-end">123</span></th>
                        <th><span className="flex-end">23,343,32</span></th>
                        <th><span className="flex-end">23,343,32</span></th>
                        <th><span className="flex-end">23,343,32</span></th>
                        <th />
                    </tr> */}
                </tbody>
            </Table>
        </section >
    );
});

export default SaleListPrint