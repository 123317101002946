import { useContext, useState, } from "react";
import { Col, Image, Row, Table } from "react-bootstrap"
import { LangContext } from "../../../../context/Lang";
import { handleDateFormat } from "../../../../services";
import Lightbox from 'react-image-lightbox';
const RES_API_URL = process.env.REACT_APP_RES_API_URL

function Info({ product }: { product: any }) {
    const { dispatch: { translate } } = useContext(LangContext);
    const images = product.productColors;
    const [imageView, setImageView] = useState({ photoIndex: 0, isOpen: false, });
    const handleCloseImageModal = () => setImageView({ ...imageView, isOpen: false, });

    const details = [
        { label: "Product name", value: product.productName },
        { label: "Product code", value: product.productCode },
        { label: translate('brand'), value: product.brands?.brandName || "-" },
        { label: translate('material'), value: product?.attributes.material?.attrValue || "-" },
        { label: translate('style'), value: product?.attributes.style?.attrValue || "-" },
        { label: product.productTypes.id === 1 ? translate('sleeve') : translate('width'), value: product.productTypes.id === 1 ? product?.attributes.sleeve?.attrValue || "-" : product?.attributes.width?.attrValue || "-" },
    ]

    const otherDetails = [
        { label: "Quantity in package", value: product.qtyInPackage || "-" },
        { label: "Minium wholesale quantity", value: product.minWholesaleMoq || "-" },
        { label: "Low stock quantity", value: product.lowStockQty || "-" },
        { label: "Factory", value: product.factories?.factory || "-" },
        { label: "Description", value: product.description || "-" },
        { label: "Created Date", value: handleDateFormat(product.createdAt) },
    ]

    const productImage = () => {
        let tempImages = product?.productColors
        let tempDiv = <Image src={'images/noimage.png'} />;
        if (tempImages.length > 0) {
            tempDiv = <Image src={`${RES_API_URL}${tempImages[0]}`} alt={tempImages[0]}
                className="cursor"
                onClick={() => {
                    setImageView({
                        ...imageView,
                        photoIndex: 0,
                        isOpen: true
                    })
                }}
            />
        }
        return tempDiv;
    }

    return (
        <section className="bg-card mb-5">
            {imageView.isOpen &&
                <Lightbox
                    mainSrc={`${RES_API_URL}${images[imageView.photoIndex]}`}
                    nextSrc={`${RES_API_URL}${images[(imageView.photoIndex + 1) % images.length]}`}
                    prevSrc={`${RES_API_URL}${images[(imageView.photoIndex + images.length - 1) % images.length]}`}
                    imageTitle={product.productName}
                    onCloseRequest={handleCloseImageModal}
                    onMovePrevRequest={() =>
                        setImageView({
                            ...imageView,
                            photoIndex: (imageView.photoIndex + images.length - 1) % images.length,
                        })
                    }
                    onMoveNextRequest={() =>
                        setImageView({
                            ...imageView,
                            photoIndex: (imageView.photoIndex + 1) % images.length,
                        })
                    }
                />}

            <Row>
                <Col md={3}>
                    <section className="detail-image">
                        {product && productImage()}
                    </section>

                    <Row>
                        {product && product.productColors.length > 0 && <>
                            {product.productColors.map((imageUrl: any, index: number) => (
                                <Col md={3} key={index} className="mt-3">
                                    <section className="product-card-small-image cursor" >
                                        <Image src={`${RES_API_URL}${imageUrl}`}
                                            onClick={() => {
                                                setImageView({
                                                    ...imageView,
                                                    photoIndex: index,
                                                    isOpen: true
                                                })
                                            }} />
                                    </section>
                                </Col>
                            ))}
                        </>}
                    </Row>
                </Col>
                <Col md={4}>
                    <Table responsive striped className="mb-0">
                        <tbody>
                            {details.map((item: any, index: number) => (
                                <tr key={index}>
                                    <td className="ps-4 text-muted ">{item.label}</td>
                                    <td>{item.value}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
                <Col md={5}>
                    <Table responsive striped className="mb-0">
                        <tbody>
                            {otherDetails.map((item: any, index: number) => (
                                <tr key={index}>
                                    <td className="ps-4 text-muted ">{item.label}</td>
                                    <td>{item.value}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
                {/* <Col md={9}>
                    <Row>
                        <Col md={6}>
                            <small className="text-muted d-block mb-1">Product Name</small>
                            <h6 className="mb-4">{product.productName}</h6>
                        </Col>
                        <Col md={6}>
                            <small className="text-muted d-block mb-1">Product Code</small>
                            <h6 className="mb-4">{product.productCode}</h6>
                        </Col>
                        <Col md={6}>
                            <small className="text-muted d-block mb-1">{translate('brand')}</small>
                            <h6 className="mb-4">{product.brands?.brandName || "-"}</h6>
                        </Col>
                        <Col md={6}>
                            <small className="text-muted d-block mb-1">{translate('material')}</small>
                            <h6 className="mb-4">{product?.attributes.material?.attrValue || "-"}</h6>
                        </Col>
                        <Col md={6}>
                            <small className="text-muted d-block mb-1">{(translate('style'))}</small>
                            <h6 className="mb-4">{product?.attributes.style?.attrValue || "-"}</h6>
                        </Col>
                        {product.productTypes.id === 1 ?
                            <Col md={6}>
                                <small className="text-muted d-block mb-1">{translate('sleeve')}</small>
                                <h6 className="mb-4">{product?.attributes.sleeve?.attrValue || "-"}</h6>
                            </Col> : <Col md={6}>
                                <small className="text-muted d-block mb-1">{translate('width')}</small>
                                <h6 className="mb-4">{product?.attributes.width?.attrValue || "-"}</h6>
                            </Col>
                        }
                        <Col md={6}>
                            <small className="text-muted d-block mb-1">Quantity in Package</small>
                            <h6 className="mb-4">{product.qtyInPackage || "-"}</h6>
                        </Col>
                        <Col md={6}>
                            <small className="text-muted d-block mb-1">Minium Wholesale Quantity</small>
                            <h6 className="mb-4">{product.minWholesaleMoq || "-"}</h6>
                        </Col>
                        <Col md={6}>
                            <small className="text-muted d-block mb-1">Low Stock Quantity</small>
                            <h6 className="mb-4">{product.lowStockQty || "-"}</h6>
                        </Col>
                        <Col md={6}>
                            <small className="text-muted d-block mb-1">Factory</small>
                            <h6 className="mb-4">{product.factories?.factory || "-"}</h6>
                        </Col>
                        <Col md={6}>
                            <small className="text-muted d-block mb-1">Created Date</small>
                            <h6 className="mb-4">{handleDateFormat(product.createdAt)}</h6>
                        </Col>
                        <Col md={6}>
                            <small className="text-muted d-block mb-1">Description</small>
                            <h6 className="mb-4">{product.description || "-"}</h6>
                        </Col>
                    </Row>
                </Col> */}
            </Row >
        </section >
    )
}

export default Info