import { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, FormCheck, Row, Table, } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { IconLeftArrow } from '../../components/Icons';
import Layout from '../../components/Layout';
import { LangContext } from '../../context/Lang';
import { deliveryFeesRoute, deliverySerivcesRoute, getData } from '../../services';
import { disableScroll } from '../../utils';

const SaleInfo = () => {
    const history = useHistory()
    const { dispatch: { translate } } = useContext(LangContext);
    const [isDeliveryFee, setIsDeliveryFee] = useState(false);
    const [isDiscount, setIsDiscount] = useState(false);
    const [isDiscountPercentage, setIsDiscountPercentage] = useState(false);
    const [isBankPercentage, setIsBankPercentage] = useState(false);
    const [deliveryServices, setDeliveryServices] = useState([])
    const [deliveryFees, setDeliveryFees] = useState([])
    const [deliveryFee, setDeliveryFee] = useState(0)
    const [isCustomDeliyFee, setCustomDeliyFee] = useState(false)

    useEffect(() => {
        handleGetDeliveryServices()
    }, [])

    const handleGetDeliveryServices = async () => {
        getData(deliverySerivcesRoute).then(result => {
            setDeliveryServices(result.data)
        })
    }

    const handleGetDeliveryFees = async (serviceId: string) => {
        getData(deliveryFeesRoute(serviceId)).then(result => {
            setDeliveryFees(result.data.servicePrices)
        })
    }

    const handleSelectDelivery = (evt: any) => {
        const value = evt.target.value
        handleGetDeliveryFees(value)
    }

    const handleSelectLocation = (evt: any) => {
        const value = evt.target.value
        setDeliveryFee(value)
    }

    return (
        <Layout title="Sale Information">
            <section className="flex-between mb-4 ">
                <section className="cursor" onClick={() => history.goBack()}>
                    <p className="mb-0"><IconLeftArrow /> Back</p>
                </section>
            </section>

            <Row>
                <Col md={8}>
                    <section className="shadow-sm p-4 rounded bg-card mb-4">
                        <section className="flex-between mb-2">
                            <section>
                                <span className="d-block mb-2"><small className="text-muted">Full Name</small> :   <small>Hari</small></span>
                                <span className="d-block mb-2"><small className="text-muted">Phone</small> :   <small>0934535353</small></span>
                                <span className="d-block mb-2"><small className="text-muted">Address</small> :   <small>Yadanar Myaing St, Kamayut Tsp, Yangon</small></span>
                            </section>
                        </section>

                        <Table responsive className="mb-0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{translate('item')}</th>
                                    <th>Code</th>
                                    <th>{translate('size')}</th>
                                    <th>{translate('color')}</th>
                                    <th><span className="d-flex justify-content-end">{translate('quantity')}</span></th>
                                    <th><span className="d-flex justify-content-end">{translate('price')}</span></th>
                                    <th><span className="d-flex justify-content-end">{translate('amount')}</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="cursor">
                                    <td>1</td>
                                    <td>ပိတ်ဖေါက်ဇာရှေ့ကွဲလက်တို</td>
                                    <td>#500</td>
                                    <td>40</td>
                                    <td>{translate('all_colors')}</td>
                                    <td><span className="d-flex justify-content-end">15</span></td>
                                    <td><span className="d-flex justify-content-end">13,000</span></td>
                                    <td><span className="d-flex justify-content-end">195,000</span></td>
                                </tr>
                                <tr className="cursor">
                                    <td>2</td>
                                    <td>ပိတ်ဖေါက်ဇာရှေ့ကွဲလက်တို</td>
                                    <td>#500</td>
                                    <td>42</td>
                                    <td>{translate('all_colors')}</td>
                                    <td><span className="d-flex justify-content-end">3</span></td>
                                    <td><span className="d-flex justify-content-end">13,000</span></td>
                                    <td><span className="d-flex justify-content-end">39,000</span></td>
                                </tr>
                                <tr className="cursor">
                                    <td>3</td>
                                    <td>ပိတ်ဖေါက်ဇာရှေ့ကွဲလက်တို</td>
                                    <td>#500</td>
                                    <td>44</td>
                                    <td>Red</td>
                                    <td><span className="d-flex justify-content-end">10</span></td>
                                    <td><span className="d-flex justify-content-end">13,000</span></td>
                                    <td><span className="d-flex justify-content-end">130,000</span></td>
                                </tr>
                            </tbody>
                        </Table>
                        <section className="mt-3 d-flex justify-content-end">
                            <Col md={4}>
                                <section className="mb-3 pb-3 border-bottom">
                                    <section className="flex-between mb-2">
                                        <small className="text-muted d-block">{translate('subTotal')}</small>
                                        <small className="d-block text-muted me-2">328,900</small>
                                    </section>
                                    {isDeliveryFee && <section className="flex-between">
                                        <small className="text-muted d-block">{translate('deliveryFee')}</small>
                                        <small className="d-block text-muted me-2">{deliveryFee}</small>
                                    </section>}
                                </section>


                                {isBankPercentage &&
                                    <section className="mb-3 pb-3 border-bottom">
                                        <section className="flex-between mb-2">
                                            <small className="text-muted d-block"></small>
                                            <small className="d-block text-muted me-2">328,900</small>
                                        </section>
                                        <section className="flex-between">
                                            <small className="text-muted d-block">Bank (5%)</small>
                                            <small className="d-block text-muted me-2">5,000</small>
                                        </section>
                                    </section>
                                }
                                {isDiscount &&
                                    <section className="mb-3 pb-3 border-bottom">
                                        <section className="flex-between mb-2">
                                            <small className="text-muted d-block"></small>
                                            <small className="d-block text-muted me-2">328,900</small>
                                        </section>
                                        <section className="flex-between">
                                            <small className="text-muted d-block">Discount (3%)</small>
                                            <small className="d-block text-muted me-2">5,000</small>
                                        </section>
                                    </section>
                                }
                                <section className="flex-between">
                                    <h6 className="text-muted">{translate('total')} (MMK)</h6>
                                    <h6 className="text-muted me-2">328,900</h6>
                                </section>
                            </Col>
                        </section>
                    </section>
                </Col>
                <Col md={4}>
                    <section className="shadow-sm p-4 rounded bg-card mb-4">
                        <Form.Group className="mb-2" >
                            <Form.Check type="checkbox" label="Bank %" checked={isBankPercentage} onChange={(e) => { setIsBankPercentage(e.target.checked) }} />
                        </Form.Group>
                        {isBankPercentage && <Form.Group className="mb-4 input-form-control" >
                            <Form.Control type="number" onWheel={disableScroll} />
                        </Form.Group>}

                        <section className="flex-between">
                            <Form.Group className="mb-2" >
                                <Form.Check type="checkbox" label={`Discount ${isDiscountPercentage ? '(%)' : 'Amount'}`} checked={isDiscount} onChange={(e) => { setIsDiscount(e.target.checked) }} />
                            </Form.Group>
                            {isDiscount && <FormCheck
                                id="switch"
                                className="switch"
                                type="switch"
                                label={"%"}
                                checked={isDiscountPercentage}
                                onChange={() => setIsDiscountPercentage(!isDiscountPercentage)}
                            />}
                        </section>

                        {isDiscount && <Form.Group className="mb-4 input-form-control" >
                            <Form.Control type="number" id="discount" onWheel={disableScroll} />
                        </Form.Group>}

                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label>Payment Status</Form.Label>
                            <Form.Control as="select" aria-label="Select" id="order_status" >
                                <option value="">Select</option>
                                <option value="live_sale">Paid</option>
                                <option value="phone_call">Cash on Delivery</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group >
                            <Form.Label>Sale Note</Form.Label>
                            <Form.Control as="textarea" rows={2} id="sale_note" />
                        </Form.Group>
                    </section>
                    <section className="shadow-sm p-4 rounded bg-card mb-4">
                        <Form.Group  >
                            <Form.Check type="checkbox" label={"Delivery service"} checked={isDeliveryFee} onChange={(e) => { setIsDeliveryFee(e.target.checked) }} />
                        </Form.Group>

                        {isDeliveryFee && <section>
                            <Form.Group className="my-4 input-form-control" >
                                <Form.Label>Delivery</Form.Label>
                                <Form.Control as="select" aria-label="Select" onChange={handleSelectDelivery}>
                                    <option value="">Select</option>
                                    {deliveryServices.map((item: any, index: number) =>
                                        <option value={item.id} key={index}>{item.deliveryService}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>


                            <Form.Group className="my-4 input-form-control" >
                                <Form.Label>Location</Form.Label>
                                <Form.Control as="select" aria-label="Select" onChange={handleSelectLocation}>
                                    <option value="">Select</option>
                                    {deliveryFees.map((item: any, index: number) =>
                                        <option value={item.deliveryPrice} key={index}>{item.locationName}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>

                            <section className="flex-between">
                                <Form.Label>{translate("deliveryFee")}</Form.Label>
                                <FormCheck
                                    id="switch"
                                    className="switch"
                                    type="switch"
                                    label={"Custom"}
                                    checked={isCustomDeliyFee}
                                    onChange={() => setCustomDeliyFee(!isCustomDeliyFee)}
                                />
                            </section>

                            <Form.Group className="mb-4 input-form-control" >
                                <Form.Control type="number" onWheel={disableScroll} value={deliveryFee} disabled={!isCustomDeliyFee} onChange={(e) => setDeliveryFee(parseInt(e.target.value))} />
                            </Form.Group>

                            <Form.Group className="mb-4 input-form-control" >
                                <Form.Label>Delivery Status</Form.Label>
                                <Form.Control as="select" aria-label="Select" >
                                    <option value="">Select</option>
                                    <option value="pick_up">Pending</option>
                                    <option value="on_delivery">Ready to ship</option>
                                    <option value="on_delivery">Shipped</option>
                                    <option value="on_delivery">Delivered</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group >
                                <Form.Label>Delivery Note</Form.Label>
                                <Form.Control as="textarea" rows={2} id="delivery_note" />
                            </Form.Group>
                        </section>}
                    </section>
                </Col>
            </Row>

            <section className="shadow-sm p-4 rounded bg-card mb-4">
                <h5>Inventory</h5>
                <Table responsive className="mb-0">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{translate('item')}</th>
                            <th>Code</th>
                            <th>{translate('size')}</th>
                            <th>{translate('color')}</th>
                            <th><span className="d-flex justify-content-end">{translate('quantity')}</span></th>
                            <th><span className="d-flex justify-content-end">Warehouse</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="cursor">
                            <td>1</td>
                            <td>ပိတ်ဖေါက်ဇာရှေ့ကွဲလက်တို</td>
                            <td>#500</td>
                            <td>40</td>
                            <td>Red</td>
                            <td><span className="d-flex justify-content-end">5</span></td>
                            <td>
                                <span className="d-flex justify-content-end py-2">
                                    <Col md={8}>
                                        <Form.Control as="select" aria-label="Select">
                                            <option value="">Select</option>
                                            <option value="1">Warehouse 1 (100)</option>
                                            <option value="2">Warehouse 2 (200)</option>
                                            <option value="3">Warehouse 3 (150)</option>
                                        </Form.Control>
                                    </Col>
                                </span>
                            </td>
                        </tr>
                        <tr className="cursor">
                            <td />
                            <td>ပိတ်ဖေါက်ဇာရှေ့ကွဲလက်တို</td>
                            <td>#500</td>
                            <td>40</td>
                            <td>Yellow</td>
                            <td><span className="d-flex justify-content-end">5</span></td>
                            <td>
                                <span className="d-flex justify-content-end py-2">
                                    <Col md={8}>
                                        <Form.Control as="select" aria-label="Select">
                                            <option value="">Select</option>
                                            <option value="1">Warehouse 1 (100)</option>
                                            <option value="2">Warehouse 2 (200)</option>
                                            <option value="3">Warehouse 3 (150)</option>
                                        </Form.Control>
                                    </Col>
                                </span>
                            </td>
                        </tr>
                        <tr className="cursor">
                            <td />
                            <td>ပိတ်ဖေါက်ဇာရှေ့ကွဲလက်တို</td>
                            <td>#500</td>
                            <td>40</td>
                            <td>Pink</td>
                            <td><span className="d-flex justify-content-end">5</span></td>
                            <td>
                                <span className="d-flex justify-content-end py-2">
                                    <Col md={8}>
                                        <Form.Control as="select" aria-label="Select">
                                            <option value="">Select</option>
                                            <option value="1">Warehouse 1 (100)</option>
                                            <option value="2">Warehouse 2 (200)</option>
                                            <option value="3">Warehouse 3 (150)</option>
                                        </Form.Control>
                                    </Col>
                                </span>
                            </td>
                        </tr>
                        <tr className="cursor">
                            <td>2</td>
                            <td>ပိတ်ဖေါက်ဇာရှေ့ကွဲလက်တို</td>
                            <td>#500</td>
                            <td>42</td>
                            <td>Red</td>
                            <td><span className="d-flex justify-content-end">1</span></td>
                            <td>
                                <span className="d-flex justify-content-end py-2">
                                    <Col md={8}>
                                        <Form.Control as="select" aria-label="Select">
                                            <option value="">Select</option>
                                            <option value="1">Warehouse 1 (100)</option>
                                            <option value="2">Warehouse 2 (200)</option>
                                            <option value="3">Warehouse 3 (150)</option>
                                        </Form.Control>
                                    </Col>
                                </span>
                            </td>
                        </tr>
                        <tr className="cursor">
                            <td />
                            <td>ပိတ်ဖေါက်ဇာရှေ့ကွဲလက်တို</td>
                            <td>#500</td>
                            <td>42</td>
                            <td>Yellow</td>
                            <td><span className="d-flex justify-content-end">1</span></td>
                            <td>
                                <span className="d-flex justify-content-end py-2">
                                    <Col md={8}>
                                        <Form.Control as="select" aria-label="Select">
                                            <option value="">Select</option>
                                            <option value="1">Warehouse 1 (100)</option>
                                            <option value="2">Warehouse 2 (200)</option>
                                            <option value="3">Warehouse 3 (150)</option>
                                        </Form.Control>
                                    </Col>
                                </span>
                            </td>
                        </tr>
                        <tr className="cursor">
                            <td />
                            <td>ပိတ်ဖေါက်ဇာရှေ့ကွဲလက်တို</td>
                            <td>#500</td>
                            <td>42</td>
                            <td>Pink</td>
                            <td><span className="d-flex justify-content-end">1</span></td>
                            <td>
                                <span className="d-flex justify-content-end py-2">
                                    <Col md={8}>
                                        <Form.Control as="select" aria-label="Select">
                                            <option value="">Select</option>
                                            <option value="1">Warehouse 1 (100)</option>
                                            <option value="2">Warehouse 2 (200)</option>
                                            <option value="3">Warehouse 3 (150)</option>
                                        </Form.Control>
                                    </Col>
                                </span>
                            </td>
                        </tr>
                        <tr className="cursor">
                            <td>3</td>
                            <td>ပိတ်ဖေါက်ဇာရှေ့ကွဲလက်တို</td>
                            <td>#500</td>
                            <td>44</td>
                            <td>Red</td>
                            <td><span className="d-flex justify-content-end">10</span></td>
                            <td>
                                <span className="d-flex justify-content-end py-2">
                                    <Col md={8}>
                                        <Form.Control as="select" aria-label="Select">
                                            <option value="">Select</option>
                                            <option value="1">Warehouse 1 (100)</option>
                                            <option value="2">Warehouse 2 (200)</option>
                                            <option value="3">Warehouse 3 (150)</option>
                                        </Form.Control>
                                    </Col>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </section >

            {/* <Row>
                <Col md={6}>
                    <section className="shadow-sm p-4 rounded bg-card mb-4">
                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label>Payment Status</Form.Label>
                            <Form.Control as="select" aria-label="Select" id="order_status" >
                                <option value="">Select</option>
                                <option value="live_sale">Paid</option>
                                <option value="phone_call">Cash on Delivery</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group >
                            <Form.Label>Sale Note</Form.Label>
                            <Form.Control as="textarea" rows={2} id="sale_note" />
                        </Form.Group>
                    </section>
                </Col>
                <Col md={6}>
                    <section className="shadow-sm p-4 rounded bg-card mb-4">
                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label>Delivery Name</Form.Label>
                            <Form.Control as="select" aria-label="Select" id="order_status" >
                                <option value="">Select</option>
                                <option value="royal">Royal Express</option>
                                <option value="mgl">MGL</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label>Delivery Status</Form.Label>
                            <Form.Control as="select" aria-label="Select" id="order_status" >
                                <option value="">Select</option>
                                <option value="pick_up">Pick Up</option>
                                <option value="on_delivery">On Delivery</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group >
                            <Form.Label>Delivery Note</Form.Label>
                            <Form.Control as="textarea" rows={2} id="delivery_note" />
                        </Form.Group>
                    </section>
                </Col>
            </Row> */}

            <section className="shadow-sm p-4 rounded bg-card">
                <section className="d-flex">
                    <Button className="text-white" onClick={() => history.push("/sale-summary")}>Sale Now</Button>
                    <Button className="btn-success text-white ms-2" onClick={() => history.goBack()}>Cancel</Button>
                </section>
            </section>
        </Layout >
    );
};

export default SaleInfo;
