import { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { Layout, TableLayout } from '../../components'
import { IconClose, IconDelete, IconFile, IconSearch } from '../../components/Icons'
import { LangContext, PageContext } from '../../context'
import { deleteService, getAllVariantRoute, getDataService, getNumber, numberFormat, responseService, variantRoute } from '../../services'
import { handleCloseSearch } from '../../utils'

const Wholesale = () => {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const { dispatch: { translate } } = useContext(LangContext);
    const [page, setPage] = useContext(PageContext)
    const [data, setData] = useState([])
    const tempUser: any = localStorage.getItem("USER")
    const user = tempUser && JSON.parse(tempUser)
    const [searchString, setSearchString] = useState("")

    useEffect(() => {
        handleGetData(page.current, page.sorting)
        // eslint-disable-next-line
    }, [])

    const handleEnterAction = (e: any) => {
        if (e.keyCode === 13) {
            setSearchString(e.target.value)
            handleGetData(0, page.sorting, e.target.value)
        }
    }

    const handleGetData = (skip: number, sorting: string, sku?: string) => {
        getDataService(getAllVariantRoute(skip, sorting, sku || "", "Wholesale"), setLoading, setData, setPage, skip, sorting)
    }

    const handleDelete = (item: any) => {
        deleteService(item.sku, { "variantId": item.id }, variantRoute, handleResponse)
    }

    const handleResponse = (result: any, autoGenerate?: boolean) => {
        responseService(result, handleGetData, "", page.current, page.sorting)
    }

    const handleChangePage = (skip: number) => {
        handleGetData(skip, page.sorting, searchString)
    }

    return (
        <Layout title={translate('wholesale')}>
            <Col md={6} className="mb-4">
                <div className='d-flex align-items-center'>
                    <Col md={5}>
                        <InputGroup className="search-input">
                            <InputGroup.Text className="bg-primary-grad text-white">
                                <IconSearch />
                            </InputGroup.Text>
                            <Form.Control placeholder="Search" value={searchString} onChange={(e) => setSearchString(e.target.value)} onKeyDown={(e) => handleEnterAction(e)} />
                            {searchString && <OverlayTrigger overlay={<Tooltip>Clear Search</Tooltip>} >
                                <InputGroup.Text className="bg-danger cursor" onClick={() => handleCloseSearch(setSearchString, handleGetData)}>
                                    <IconClose />
                                </InputGroup.Text>
                            </OverlayTrigger>}
                        </InputGroup>
                    </Col>
                    {/* <div className='ms-3'></div>
                    <SortingButton page={page} setPage={setPage} handleGetData={handleGetData} /> */}
                    {page.total ? <span><small className='text-muted ms-3'>Result : </small>{numberFormat(page.total)}</span> : <></>}
                </div>
            </Col>


            <TableLayout loading={loading} data={data} page={page} setPage={setPage} handleGetData={handleGetData} isFilter handleChangePage={handleChangePage} noBg>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>SKU</th>
                        <th>Size</th>
                        <th>{translate('color')}</th>
                        <th>{translate('qty')} ({translate('in')})</th>
                        <th>{translate('qty')} ({translate('out')})</th>
                        <th>{translate('qty')} ({translate('remain')})</th>
                        <th>{translate('totalCost')}</th>
                        {/* <th>{translate('retailPrice')}</th> */}
                        <th>{translate('wholesalePrice')}</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {data.map((variant: any, index: any) =>
                        <tr key={index}>
                            <td className="ps-4">{getNumber(index, page?.current)}</td>
                            <td>{variant.sku}</td>
                            <td>{variant.productSizes || "-"}</td>
                            <td>{variant.productColors || "-"}</td>
                            <td><span className="flex-end">{numberFormat(variant.stockIn)}</span></td>
                            <td><span className="flex-end">{numberFormat(variant.stockOut)}</span></td>
                            <td><span className="flex-end">{numberFormat(variant.stockRemain)}</span></td>
                            {/* <td>600 available of 2 locations <Button className="btn-sm ms-2" onClick={handleShowInfo}><IconFile /></Button></td> */}
                            <td><span className="flex-end">{numberFormat(variant.investedCost)}</span></td>
                            {/* <td><span className="flex-end">{numberFormat(variant.retailPrice)}</span></td> */}
                            <td><span className="flex-end">{numberFormat(variant.wholesalePrice)}</span></td>
                            <td>
                                {(user?.userRole === "Owner" || user?.userRole === "Admin") && <span className="flex-center">
                                    <Button className="btn-sm" onClick={() => history.push({
                                        pathname: '/variant-detail',
                                        state: { variant: variant, product: null }
                                    })}><IconFile /></Button>
                                    <Button className="btn-danger btn-sm ms-2" onClick={() => handleDelete(variant)}>
                                        <IconDelete />
                                    </Button>
                                </span>}
                            </td>
                        </tr>
                    )}
                </tbody>
            </TableLayout>


        </Layout>
    )
}

export default Wholesale