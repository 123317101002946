import { useContext } from "react";
import { LangContext } from "../../context/Lang";
import { colorRoute, materialRoute, measurementRoute, sleeveRoute, standardSizeRoute, styleRoute, widthRoute } from "../../services"
import AttributeDetailComponent from "./components/AttributeDetailComponent"

export const Materials = () => {
    const { dispatch: { translate } } = useContext(LangContext);
    return (
        <AttributeDetailComponent
            attributes="material"
            route={materialRoute}
            title={translate("materials")}
            label={translate("material")}
            dependProductType
        />
    )
}

export const Styles = () => {
    const { dispatch: { translate } } = useContext(LangContext);
    return (
        <AttributeDetailComponent
            attributes="style"
            route={styleRoute}
            title={translate("styles")}
            label={translate("style")}
            dependProductType
        />
    )
}

export const Sleeves = () => {
    const { dispatch: { translate } } = useContext(LangContext);
    return (
        <AttributeDetailComponent
            attributes="sleeve"
            route={sleeveRoute}
            title={translate("sleeve")}
            label={translate("sleeve")}
        />
    )
}

export const Colors = () => {
    const { dispatch: { translate } } = useContext(LangContext);
    return (
        <AttributeDetailComponent
            attributes="color"
            route={colorRoute}
            title={translate("colors")}
            label={translate("color")}
            showCode
        />
    )
}

export const Widths = () => {
    const { dispatch: { translate } } = useContext(LangContext);
    return (
        <AttributeDetailComponent
            attributes="width"
            route={widthRoute}
            title={translate("width")}
            label={translate("width")}
        />
    )
}

export const StandardSize = () => {
    return (
        <AttributeDetailComponent
            attributes="standard_size"
            route={standardSizeRoute}
            title="Standard Size"
            label="Standard Size"
        />
    )
}

export const Measurement = () => {
    return (
        <AttributeDetailComponent
            attributes="measurement_size"
            route={measurementRoute}
            title="Measurement Size"
            label="Measurement Size"
        />
    )
}
