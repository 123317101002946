import { useContext, useEffect, useState } from "react"
import { Col, Row, Table } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import { InfoCard, Layout } from "../../components"
import { IconInventory, IconOrder, IconPeople, IconSale } from "../../components/Icons"
import { LangContext } from "../../context/Lang"
import { getData, numberFormat } from "../../services"
import ProductInfo from "./ProductInfo"
import TotalSale from "./TotalSale"

function Dashboard() {
    const history = useHistory()
    const iconSizee = 20
    const { dispatch: { translate } } = useContext(LangContext);
    const [data, setData] = useState<any>()
    const [stockData, setStockData] = useState<any>([])
    const [orderSources, setOrderSources] = useState<any>([])
    const [orderStatus, setOrderStatus] = useState<any>([])
    const cards = [
        { id: "products", label: translate('products'), value: numberFormat(data?.count.products), color: "primary", icon: <IconInventory size={iconSizee} /> },
        { id: "orders", label: translate('orders'), value: numberFormat(data?.count.orders), color: "success", icon: <IconOrder size={iconSizee} /> },
        { id: "sales", label: translate('sales'), value: numberFormat(data?.count.sales), color: "info", icon: <IconSale size={iconSizee} /> },
        { id: "customers", label: translate('customers'), value: numberFormat(data?.count.customers), color: "warning", icon: <IconPeople size={iconSizee} /> },
    ]

    // const stockCards = [
    //     { id: "products", label: `${translate('stock')} (${translate('in')})`, value: numberFormat(stockData.length > 0 ? stockData[0]?.quantity : 0), color: "primary", icon: <IconBoxs size={iconSizee} /> },
    //     { id: "products", label: `${translate('stock')} (${translate('out')})`, value: numberFormat(stockData.length > 0 ? stockData[1]?.quantity : 0), color: "success", icon: <IconBoxs size={iconSizee} /> },
    //     { id: "products", label: `${translate('stock')} (${translate('remain')})`, value: numberFormat(stockData.length > 0 ? stockData[2]?.quantity : 0), color: "info", icon: <IconBoxs size={iconSizee} /> },
    // ]

    useEffect(() => {
        handleGetData()
    }, [])

    const handleGetData = async () => {
        getData("dashboard/main").then(result => { setData(result) })
        getData("dashboard/inventories").then(result => { setStockData(result) })
        getData("dashboard/orders/from").then(result => { setOrderSources(result) })
        getData("dashboard/orders").then(result => { setOrderStatus(result) })
    }


    return (
        <Layout title={translate('dashboard')} >
            <Row className="mb-4">
                <Col md={8}>
                    <Row>
                        {cards.map((item: any, index: number) => (
                            <Col md={6} key={index}>
                                <InfoCard id={item.id} label={item.label} value={item.value} color={item.color} icon={item.icon} cardAction={() => history.push(item.id)} colCount={6} />
                            </Col>
                        ))}
                    </Row>

                    <Col md={12}>
                        <TotalSale />
                    </Col>
                </Col>
                <Col md={4}>
                    <h6>{translate('stock')} ({translate('in')})</h6>
                    <Table responsive striped className="mb-4 mt-2 border" bordered>
                        <tbody>
                            {stockData?.in?.map((item: any, index: number) => (
                                <tr key={index}>
                                    <td className="text-muted" style={{ width: "70%" }}>{item?.trxTypes}</td>
                                    <td><span className="flex-end">{numberFormat(item?.quantity)}</span></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <h6>{translate('stock')} ({translate('out')})</h6>
                    <Table responsive striped className="mb-4 mt-2 border" bordered>
                        <tbody>
                            {stockData?.out?.map((item: any, index: number) => (!item.hide) && (
                                <tr key={index}>
                                    <td className="text-muted" style={{ width: "70%" }} >{item?.trxTypes} {item?.trxTypes === "Goods Issue" && "(Sold items)"}</td>
                                    <td ><span className="flex-end">{numberFormat(item?.quantity)}</span></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>


                </Col>
            </Row>

            {/* <TotalCount /> */}
            <Row>
                <Col md={4}>
                    <ProductInfo />
                </Col>
                <Col md={4}>
                    <h6>Orders by status</h6>
                    <Table responsive striped className="mb-0 mt-2 border" bordered>
                        <tbody>
                            {orderStatus?.map((item: any, index: number) => (
                                <tr key={index}>
                                    <td className="text-muted" style={{ width: "70%" }} >{item?.orderStatus}</td>
                                    <td ><span className="flex-end">{numberFormat(item?.orderCount)}</span></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
                <Col md={4}>
                    <h6>Orders by source</h6>
                    <Table responsive striped className="mb-0 mt-2 border" bordered>
                        <tbody>
                            {orderSources?.map((item: any, index: number) => (!item.hide) && (
                                <tr key={index}>
                                    <td className="text-muted" style={{ width: "70%" }} >{item?.orderFrom}</td>
                                    <td  ><span className="flex-end">{numberFormat(item?.orderCount)}</span></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Layout>
    )
}

export default Dashboard


