import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, FormCheck, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom"
import { toast } from "react-toastify";
import { IconLeftArrow, } from "../../components/Icons";
import Layout from "../../components/Layout"
import { LangContext } from "../../context/Lang";
import { brandRoute, dataService, factoriesRoute, getData, materialRoute, productCodeExitRoute, productsRoute, putMethod, sleeveRoute, styleRoute, widthRoute } from "../../services";
import { disableScroll } from "../../utils";

function Edit() {
    const history = useHistory();
    const { dispatch: { translate } } = useContext(LangContext);
    const [isCustomText, setCustomText] = useState(false)
    const location: any = useLocation();
    const item = location.state.item
    const [formData, setFormData] = useState({
        productId: item.id,
        productTypesId: item.productTypes?.id,
        brandsId: item.brands?.id || "",
        factoriesId: item.factories?.id || "",
        productCode: item.productCode,
        productName: item.productName,
        description: item.description || "",
        shippable: item.shippable,
        soldBy: item.soldBy,
        lowStockQty: item.lowStockQty || "",
        minWholesaleMoq: item.minWholesaleMoq || "",
        qtyInPackage: item.qtyInPackage || "",
        isDelete: item.isDelete,
        publish: item.publish,
        attributes: {
            style: {
                id: item.attributes?.style?.id,
                attrValue: item.attributes?.style?.attrValue
            },
            material: {
                id: item.attributes?.material?.id,
                attrValue: item.attributes?.material?.attrValue
            },
            sleeve: {
                id: item.attributes?.sleeve?.id,
                attrValue: item.attributes?.sleeve?.attrValue
            },
            width: {
                id: item.attributes?.width?.id,
                attrValue: item.attributes?.width?.attrValue
            }
        }
    })

    const [productAttrubutes, setProductAttrubutes] = useState({
        material: item.attributes?.material?.attrValue || "",
        materialId: item.attributes?.material?.id,
        style: item.attributes?.style?.attrValue || "",
        styleId: item.attributes?.style?.id,
        sleeve: item.attributes?.sleeve?.attrValue || "",
        sleeveId: item.attributes?.sleeve?.id,
        width: item.attributes?.width?.attrValue || "",
        widthId: item.attributes?.width?.id
    })

    const required = ["productCode", "productName"]
    const [groupProductName, setGroupProductName] = useState("")
    const [brands, setBrands] = useState([])
    const [materials, setMaterials] = useState([])
    const [styles, setStyles] = useState([])
    const [sleeves, setSleeves] = useState([])
    const [widths, setWidths] = useState([])
    const [factories, setFactories] = useState([])

    useEffect(() => {
        handleGetAttributes()

        let productName = "";
        productName = productAttrubutes?.material + productAttrubutes?.style;
        if (formData.productTypesId === 1) {
            productName = productName + productAttrubutes?.sleeve
        } else {
            productName = productName + productAttrubutes?.width
        }

        setGroupProductName(productName)
        // eslint-disable-next-line
    }, [])

    const handleGetAttributes = async () => {
        getData(brandRoute).then(result => { setBrands(result.data) })
        getData(materialRoute).then(result => { setMaterials(result.data.values) })
        getData(styleRoute).then(result => { setStyles(result.data.values) })
        getData(sleeveRoute).then(result => { setSleeves(result.data.values) })
        getData(widthRoute).then(result => { setWidths(result.data.values) })
        getData(factoriesRoute).then(result => { setFactories(result.data) })
    }

    const handleChange = (evt: any, productTypesId?: boolean) => {
        const target = evt.target
        const value = target.type === "checkbox" ? target.checked : (target.type === "number" || target.type === "select-one") ? target.value === "" ? "" : parseInt(target.value) : target.value
        const name = target.id

        if (productTypesId) {
            setFormData({
                ...formData, [name]: value,
                productName: "",
                attributes: {
                    style: {
                        id: "",
                        attrValue: ""
                    },
                    material: {
                        id: "",
                        attrValue: ""
                    },
                    sleeve: {
                        id: "",
                        attrValue: ""
                    },
                    width: {
                        id: "",
                        attrValue: ""
                    }
                }
            })

            setProductAttrubutes({
                material: "",
                materialId: "",
                style: "",
                styleId: "",
                sleeve: "",
                sleeveId: "",
                width: "",
                widthId: ""
            })
        } else {
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleAttibuteChange = (evt: any) => {
        const target = evt.target
        const value = target.value
        const name = target.id
        const index = evt.nativeEvent.target.selectedIndex;
        const label = value === "" ? "" : evt.nativeEvent.target[index].text

        let tempAttributes: any = formData?.attributes;

        let attiabuteValueId: any = value ? parseInt(value) : ""

        if (name === 'material') {
            if (tempAttributes.material) {
                tempAttributes.material.id = attiabuteValueId
                tempAttributes.material.attrValue = value ? label : ""
            } else {
                tempAttributes = {
                    ...tempAttributes, material: {
                        id: attiabuteValueId,
                        attrValue: value ? label : ""
                    },
                }
            }
        } else if (name === 'style') {
            if (tempAttributes.style) {
                tempAttributes.style.id = attiabuteValueId
                tempAttributes.style.attrValue = value ? label : ""
            } else {
                tempAttributes = {
                    ...tempAttributes, style: {
                        id: attiabuteValueId,
                        attrValue: value ? label : ""
                    },
                }
            }
        } else if (name === 'sleeve') {

            if (tempAttributes.sleeve) {
                tempAttributes.sleeve.id = attiabuteValueId
                tempAttributes.sleeve.attrValue = value ? label : ""
            } else {
                tempAttributes = {
                    ...tempAttributes, sleeve: {
                        id: attiabuteValueId,
                        attrValue: value ? label : ""
                    },
                }
            }
        } else {
            if (tempAttributes.width) {
                tempAttributes.width.id = attiabuteValueId
                tempAttributes.width.attrValue = value ? label : ""
            } else {
                tempAttributes = {
                    ...tempAttributes, width: {
                        id: attiabuteValueId,
                        attrValue: value ? label : ""
                    },
                }
            }
        }


        let productName = "";
        if (isCustomText) {
            productName = formData?.productName
        } else {
            let material = name === "material" ? label : productAttrubutes?.material;
            let style = name === "style" ? label : productAttrubutes?.style;
            let sleeve = name === "sleeve" ? label : productAttrubutes?.sleeve;
            let width = name === "width" ? label : productAttrubutes?.width;

            productName = material + style;
            if (formData.productTypesId === 1) {
                productName = productName + sleeve
            } else {
                productName = productName + width
            }
        }
        setGroupProductName(productName)
        setProductAttrubutes({ ...productAttrubutes, [name]: label, [`${name}Id`]: value })
        setFormData({ ...formData, productName: productName, attributes: tempAttributes })

    }

    const editAction = () => {
        let data: any = {}

        for (let [key, value] of Object.entries(formData?.attributes)) {
            if (value.id !== null && value.id !== '' && value.id !== undefined) {
                data[key] = value
            }
        }

        let tempformData: any = formData;
        tempformData.attributes = data
        let finalData: any = {}

        for (let [key, value] of Object.entries(tempformData)) {
            if (value === "" || value === undefined) {
                finalData[key] = null
            } else {
                finalData[key] = value
            }
        }

        dataService(putMethod, finalData, required, productsRoute, true).then(result => {
            if (result?.success) {
                toast.success(result?.message)
                history.push("/products")
            } else {
                toast.error(result?.message)
            }
        })
    }

    const handleSubmit = () => {
        if (formData.productCode === item.productCode) {
            editAction()
        } else {
            getData(productCodeExitRoute(formData?.productCode)).then((result) => {
                if (result.exist) {
                    toast.error(result.message)
                } else {
                    editAction()
                }
            })
        }
    }


    return (
        <Layout title={"Edit info"}>
            <section className="mb-4 cursor" onClick={() => history.goBack()}>
                <p className="mb-0"><IconLeftArrow /> {translate('products')}</p>
            </section>

            <section className="bg-card">
                <Row>
                    {/* <Col md={4}>
                        <CustomInput label="Product code *" id="productCode" handleChange={handleChange} value={formData?.productCode} />
                    </Col> */}
                    {/* <Col md={4}>
                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label>{translate('productType')}</Form.Label>
                            <Form.Control
                                as="select"
                                aria-label="Select"
                                id="productTypesId"
                                onChange={handleProductTypeChange}
                                value={formData?.productTypesId} >
                                {productTypes.map((item: any, index: number) =>
                                    <option value={item.id} key={index}>{item.productType}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                    </Col> */}
                    <Col md={4}>
                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label>{translate('brand')}</Form.Label>
                            <Form.Control as="select" aria-label="Select" id="brandsId" onChange={handleChange} value={formData?.brandsId} >
                                <option value="">Select</option>
                                {brands.map((item: any, index: number) =>
                                    <option value={item.id} key={index}>{item.brandName}</option>
                                )}
                            </Form.Control>
                        </Form.Group>

                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label>{translate('material')}</Form.Label>
                            <Form.Control as="select" aria-label="Select" id="material" onChange={handleAttibuteChange} value={productAttrubutes?.materialId}>
                                <option value="">Select</option>
                                {materials.map((item: any, index: number) =>
                                    <option value={item.id} key={index}>{item.attrValue}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label>{translate('style')}</Form.Label>
                            <Form.Control as="select" aria-label="Select" id="style" onChange={handleAttibuteChange} value={productAttrubutes?.styleId} >
                                <option value="">Select</option>
                                {styles.map((item: any, index: number) =>
                                    <option value={item.id} key={index}>{item.attrValue}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    {formData.productTypesId === 1 ?
                        <Col md={4}>
                            <Form.Group className="mb-4 input-form-control" >
                                <Form.Label>{translate('sleeve')}</Form.Label>
                                <Form.Control as="select" aria-label="Select" id="sleeve" onChange={handleAttibuteChange} value={productAttrubutes?.sleeveId} >
                                    <option value="">Select</option>
                                    {sleeves.map((item: any, index: number) =>
                                        <option value={item.id} key={index}>{item.attrValue}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        :
                        <Col md={4}>
                            <Form.Group className="mb-4 input-form-control" >
                                <Form.Label>{translate('width')}</Form.Label>
                                <Form.Control as="select" aria-label="Select" id="width" onChange={handleAttibuteChange} value={productAttrubutes?.widthId} >
                                    <option value="">Select</option>
                                    {widths.map((item: any, index: number) =>
                                        <option value={item.id} key={index}>{item.attrValue}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    }
                    <Col md={4}>
                        <Form.Group className="mb-4 input-form-control">
                            <section className="flex-between">
                                <Form.Label>Product name *</Form.Label>
                                <FormCheck
                                    id="switch"
                                    className="switch"
                                    type="switch"
                                    label={"Custom Name"}
                                    checked={isCustomText}
                                    onChange={
                                        () => { setCustomText(!isCustomText); setFormData({ ...formData, productName: isCustomText ? groupProductName : item?.productName }) }
                                    }
                                />
                            </section>

                            <Form.Control type="text" id="productName" onChange={handleChange} value={formData?.productName} disabled={!isCustomText} />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label>Factory</Form.Label>
                            <Form.Control as="select" aria-label="Select" id="factoriesId" onChange={handleChange} value={formData?.factoriesId}>
                                <option value="">Select</option>
                                {factories.map((item: any, index: number) =>
                                    <option value={item.id} key={index}>{item.factory}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label>Quantity in package</Form.Label>
                            <Form.Control type="number" min="0" id="qtyInPackage" onWheel={disableScroll} onChange={handleChange} value={formData?.qtyInPackage} />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label>Low stock quantity</Form.Label>
                            <Form.Control type="number" min="0" id="lowStockQty" onWheel={disableScroll} onChange={handleChange} value={formData?.lowStockQty} />
                            {/* <Form.Text className="text-muted">
                                Product quantity at which you will be notified about low stock
                            </Form.Text> */}
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-4 input-form-control" >
                            <Form.Label>Minimum wholesale quantity</Form.Label>
                            <Form.Control type="number" min="0" id="minWholesaleMoq" onWheel={disableScroll} onChange={handleChange} value={formData?.minWholesaleMoq} />
                        </Form.Group>
                    </Col>

                    <section className="d-flex align-items-center justify-content-between">
                        <Form.Group className="mb-4" >
                            <Form.Check type="checkbox" label="Publish product" id="publish" checked={formData?.publish} onChange={handleChange} />
                        </Form.Group>
                    </section>

                    <Col md={12}>
                        <Form.Group className="mb-4">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={2} id="description" onChange={handleChange} value={formData?.description} />
                        </Form.Group>
                    </Col>

                    <section className="d-flex">
                        <Button className="text-white" onClick={handleSubmit}>Save</Button>
                        <Button className="btn-success text-white ms-2" onClick={() => history.goBack()}>Cancel</Button>
                    </section>
                </Row>
            </section>
        </Layout >
    )
}

export default Edit