import { BrowserRouter as Router, Switch } from 'react-router-dom'
import {
  PrivateRoute,
  PublicRoute,
  OwnerRoute,
  NotFound,
  ScrollToTop
} from './components';
import { Login, privateRoutes, ownerRoutes, } from './routes';


function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <PublicRoute exact path="/" component={Login} />

        {privateRoutes.map((item: any, index: number) =>
          <PrivateRoute exact path={`/${item.route}`} component={item.component} key={index} />
        )}

        {ownerRoutes.map((item: any, index: number) =>
          <OwnerRoute exact path={`/${item.route}`} component={item.component} key={index} />
        )}

        <PublicRoute component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
