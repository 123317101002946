import { useContext, useEffect, useState } from "react";
import { Col, Image, Table } from "react-bootstrap";
import { getAllQunatityAmount, getAllTotalAmount, handleDateTimeFormat, numberFormat } from "../services";
import QRCode from 'qrcode';
import { toast } from "react-toastify";
import { LangContext } from "../context";

const SaleReceipt = ({ order, draftOrder = false }: { order: any, draftOrder?: boolean }) => {
    const { dispatch: { translate } } = useContext(LangContext);
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        generateQrCode()
        // eslint-disable-next-line
    }, [])

    const totalPaidAmount = () => {
        let total = 0;
        if (order?.paymentHistory && order?.paymentHistory.length > 0) {
            order?.paymentHistory.forEach((item: any) => {
                total += item.totalAmountPaid ? +item.totalAmountPaid : 0
            })
        }
        return total;
    }

    const generateQrCode = async () => {
        try {
            const response = await QRCode.toDataURL(order?.draftOrdersCode);
            setImageUrl(response);
        } catch (error: any) {
            toast.error(error);
        }
    }

    const getSubtotalAmount = () => {
        let total = 0;
        order?.orderItems?.forEach((item: any) => {
            total += item.quantity * item.unitPrice
        });
        return total;
    }

    const getDiscountAmountByPercentage = () => {
        let amount: any = 0;
        if (order?.discountValue) {
            let discountPercent = parseInt(order?.discountValue)
            amount = ((getSubtotalAmount() + (order?.deliveryFee ? parseInt(order?.deliveryFee) : 0) + getBankCommissionAmount()) / 100) * discountPercent
            amount = parseInt(amount)
        } else {
            amount = 0
        }
        return amount;
    }


    const getBankCommissionAmount = () => {
        return getAllTotalAmount(order?.orderItems, parseInt(order?.deliveryFee)) / 100 * order?.bankCommission
    }

    return (
        <section>
            <section className="flex-between mb-5">
                <Image src="logo.png" width={"100"} height={"100"} />
                <section className="text-center">
                    <Image src="images/bawgawadi_text.png" width={"300"} />
                    <h3 className="mt-2 fw-bold">Cupid & Vision</h3>
                    <small>ကွန်ပျူတာပန်းထိုးလုံချည် နှင့် အမျိုးသမီးအင်္ကျီ လက်လီ / လက်ကား (ပင်ရင်း)</small>
                </section>
                <Image src="images/bawgawadi.png" width={"110"} height={"110"} />
            </section>

            <section className="flex-between">
                <section style={{ width: "50%" }}>
                    <span className="d-block mb-2"><small className="text-muted">{translate('name')}</small> :   <small>{order?.customerName || "-"}</small></span>
                    <span className="d-block mb-2"><small className="text-muted">{translate('phone')}</small> :   <small>{order?.customerPhone || "-"}</small></span>
                    {order?.customerAddress[0].address && <span className="d-block mb-2" ><small className="text-muted">{translate('address')}</small> :   <small >{order?.customerAddress[0].address || "-"} </small></span>}
                </section>
                <section className="d-flex flex-column align-items-end">
                    <small className="d-block mb-2">{draftOrder ? order?.draftOrdersCode : order?.saleOrdersCode}</small>
                    {/* <span className="d-block mb-2"><small className="text-muted">{translate('payment')}</small> :   <small>{order?.paymentStatus}</small></span> */}
                    <span className="d-block mb-2"><small className="text-muted">{translate('date')}</small> :   <small>{handleDateTimeFormat(order?.createdAt)}</small></span>
                </section>
            </section>

            <Table responsive className="my-2" bordered striped>
                <thead>
                    <tr>
                        <th><span className="d-flex justify-content-center">{translate('no.')}</span></th>
                        <th><span className="d-flex justify-content-center">Code</span></th>
                        <th><span className="d-flex justify-content-center">{translate('item')}</span></th>
                        <th><span className="flex-end">{translate('quantity')}</span></th>
                        <th><span className="flex-end">{translate('price')}</span></th>
                        <th><span className="flex-end">{translate('amount')}</span></th>
                    </tr>
                </thead>
                <tbody>
                    {order?.orderItems && order?.orderItems.map((item: any, index: number) => (
                        <tr key={index}>
                            <th><span className="d-flex justify-content-center">{index + 1}</span></th>
                            <th><span className="d-flex justify-content-center">{item.productCode}</span></th>
                            <th>{item.productName} {item.productSize && item.productSize} {item.productColor && item.productColor} {item.unitPrice === "0" ? `(${translate('gift')})` : ""}</th>
                            <th><span className="flex-end">{numberFormat(item.quantity)}</span></th>
                            <th><span className="flex-end">{item.unitPrice !== "0" ? numberFormat(item.unitPrice) : "Free"}</span></th>
                            <th><span className="flex-end">{item.unitPrice !== "0" ? numberFormat(item.quantity * item.unitPrice) : 0}</span></th>
                        </tr>
                    ))}
                    <tr>
                        <th />
                        <th />
                        <th />
                        <th><span className="flex-end">{numberFormat(getAllQunatityAmount(order?.orderItems))}</span></th>
                        <th />
                        <th><span className="flex-end">{numberFormat(getAllTotalAmount(order?.orderItems))}</span></th>
                    </tr>
                </tbody>
            </Table>

            <section className="d-flex justify-content-between pt-2">
                <section>
                    {/* <small className="text-muted d-block mb-2 mt-1">No. (M-35), မြေညီထပ်၊ဝင်ပေါက်(၁၅)၊ယုဇနပလာဇာ။</small> */}
                    <div className="d-flex">
                        <div>
                            <small className="text-muted">Facebook page</small>
                            <span className="d-block mb-2"><small>Cupid & Vision </small><small>ကွန်ပျူတာပန်းထိုးလုံချည်နှင့်အမျိုးသမီးအင်္ကျီလက်လီ/လက်ကား</small></span>
                            <small className="text-muted">{translate('phone')}</small>
                            <span className="d-block mb-2"><small>09-666669821 , 09-796666397</small></span>
                        </div>
                        {imageUrl && <Image src={imageUrl} alt="no_found" width={50} height={50} className="mx-4" />}
                    </div>

                    {order?.deliveryNotes && <>
                        <small className="text-muted">Note</small>
                        <small className="d-block mb-2" style={{ width: "90%" }}>{order?.deliveryNotes}</small>
                    </>}
                </section>


                <Col md={4} className="me-2">
                    {order?.deliveryFee && <section className="flex-between mb-2 pb-2">
                        <small className="text-muted d-block">{translate('deliveryFee')}</small>
                        <small>{numberFormat(order?.deliveryFee)}</small>
                    </section>}
                    {order?.bankCommission && <section className="flex-between mb-2 pb-2">
                        <small className="text-muted d-block">Bank ({order?.bankCommission}%)</small>
                        <small>{numberFormat(getAllTotalAmount(order?.orderItems, parseInt(order?.deliveryFee)) / 100 * order?.bankCommission)}</small>
                    </section>}
                    {order?.discountValue && <section className="flex-between mb-2 pb-2 border-bottom mb-2">
                        <small className="text-muted d-block">Discount {order?.discountType === "By percentage" && `(${order?.discountValue}%)`}</small>
                        <small>{order?.discountValue && "-"} {numberFormat(order?.discountType === "By percentage" ? getDiscountAmountByPercentage() : order?.discountValue)}</small>
                    </section>}
                    {/* {(order?.deliveryFee || order?.bankCommission || order?.discountValue) && <section className="flex-between pt-2 border-top">
                        <small className="text-muted d-block">{translate('total')} ({translate('mmk')})</small>
                        <small>{numberFormat(getAllTotalAmount(order?.orderItems, parseInt(order?.deliveryFee), parseFloat(order?.bankCommission), order?.discountType, parseFloat(order?.discountValue)))}</small>
                    </section>} */}
                    <div className="flex-between">
                        <small className="text-muted d-block">{translate('totalAmount')}</small>
                        <small>{numberFormat(getAllTotalAmount(order?.orderItems, parseInt(order?.deliveryFee), parseFloat(order?.bankCommission), order?.discountType, parseFloat(order?.discountValue)))}</small>
                    </div>
                    <div className="flex-between mt-2">
                        <small className="text-muted d-block">{translate('paidAmount')}</small>
                        <small>{numberFormat(totalPaidAmount())}</small>
                    </div>
                    <div className="flex-between pt-2 border-top mt-2">
                        <small className="text-muted d-block">{translate('balance')} ({translate('mmk')})</small>
                        <small>{numberFormat(getAllTotalAmount(order?.orderItems, parseInt(order?.deliveryFee), parseFloat(order?.bankCommission), order?.discountType, parseFloat(order?.discountValue)) - totalPaidAmount())}</small>
                    </div>
                </Col>

            </section>

            <small className="text-muted text-center d-block mt-4">{translate('thankForYourPurchase')}</small>
        </section >
    );
};

export default SaleReceipt;
